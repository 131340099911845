import { createSlice } from "@reduxjs/toolkit";


const userProfileSlice = createSlice({
    name: "userprofile",
    initialState: {
      currentUserProfile: null,
      profileCompleted: false,
      isFetching: false,
      error: false,
      isCreating: false,
      isUpdating: false

    },
    reducers:{
        getProfileStart: (state) => {
            state.isFetching = true
        },
        getProfileSuccess: (state, action) => {
            //console.log('payload',action.payload)
            state.isFetching = false;
            state.currentUserProfile = action.payload;
            state.profileCompleted = action.payload.profileCompleted;

        },
        getProfileFailure: (state) => {
            state.isFetching = false;
            state.error = true;
            state.currentUserProfile = null;
        },

        createProfileStart: (state) => {
            state.isCreating = true
        },
        createProfileSuccess: (state, action) => {
            state.isCreating = false;
            state.currentUserProfile = action.payload
            state.profileCompleted = action.payload.currentUserProfile.profileCompleted;

        },
        createProfileFailure: (state) => {
            state.isCreating = false;
            state.error = true;
            state.currentUserProfile = null;
            state.profileCompleted =  false;
        },

        
        updateProfileStart: (state) => {
            state.isUpdating = true
        },
        updateProfileSuccess: (state, action) => {
            state.isUpdating = false;
            state.currentUserProfile = action.payload

            if(action.payload.profileCompleted){
                state.profileCompleted =  true;
            }

        },
        updateProfileFailure: (state) => {
            state.isUpdating = false;
            state.error = true;
            //state.currentUserProfile = null;
        },

         
        clearProfileStart: (state) => {
            state.isClearing = true
        },
        clearProfileSuccess: (state) => {
            state.isClearing = false;
            state.currentUserProfile = null
            state.profileCompleted =  false;

        },
        clearProfileFailure: (state) => {
            state.isClearing = false;
            state.error = true;
            //state.currentUserProfile = null;
        },







    }
})



export const {
    getProfileStart,
    getProfileSuccess,
    getProfileFailure,
    createProfileStart,
    createProfileSuccess,
    createProfileFailure,
    updateProfileStart,
    updateProfileSuccess,
    updateProfileFailure,
    clearProfileStart,
    clearProfileFailure,
    clearProfileSuccess
} = userProfileSlice.actions;

export default userProfileSlice.reducer;