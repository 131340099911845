import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./MessageView.css";

const MessageView = () => {
  const navigate = useNavigate();

  const onMyMessagesHeaderBackClick = useCallback(() => {
    navigate("/mymessages");
  }, [navigate]);

  return (
    <div className="message-view">
      <div className="mymessagesheader">
        <button
          className="mymessagesheaderback"
          onClick={onMyMessagesHeaderBackClick}
        >
          Back
        </button>
      </div>
      <div className="mymessagesheader1">
        <div className="writemessagebody">
          <div className="writemessagebodyreceiver">
           
             <img
              className="writemessagebodyreceiverprofil-icon1"
              alt=""
              src="https://i.imgur.com/t8BvZHF.jpg"
            />

            <div className="writemessagebodyreceivername">Jessica Howes</div>
          </div>
          <div className="writemessagebodyheader">
            <div className="today">Today</div>
          </div>
          <div className="writemessagebodybox">
            <div className="writemessagebodysendermessage">
              <div className="writemessagebodysendermessage1">
                <div className="writebodysendermessagetext">{`hvnsxd vgfgh gvbgvgvfcfv `}</div>
              </div>
            </div>
            <div className="writemessagebodyreceivermessag">
              <div className="writemessagebodyreceivermessag1">
                <div className="writebodysendermessagetext">{`hvnsxd vgfgh gvbgvgvfcfv `}</div>
              </div>
            </div>
          </div>
          <div className="writemessagebodytypemessage">
            <div className="writemessagebodytypemessage1">
              <button className="writemessagepaperclip">
                {/* <img
                  className="writemessagepaperclipicon"
                  alt=""
                  src="/writemessagepaperclipicon.svg"
                /> */}
                <svg     className="writemessagepaperclipicon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M21.4403 11.0499L12.2503 20.2399C11.1244 21.3658 9.59747 21.9983 8.00529 21.9983C6.41311 21.9983 4.88613 21.3658 3.76029 20.2399C2.63445 19.1141 2.00195 17.5871 2.00195 15.9949C2.00195 14.4027 2.63445 12.8758 3.76029 11.7499L12.9503 2.55992C13.7009 1.80936 14.7188 1.3877 15.7803 1.3877C16.8417 1.3877 17.8597 1.80936 18.6103 2.55992C19.3609 3.31048 19.7825 4.32846 19.7825 5.38992C19.7825 6.45138 19.3609 7.46936 18.6103 8.21992L9.41029 17.4099C9.03501 17.7852 8.52602 17.996 7.99529 17.996C7.46456 17.996 6.95557 17.7852 6.58029 17.4099C6.20501 17.0346 5.99418 16.5256 5.99418 15.9949C5.99418 15.4642 6.20501 14.9552 6.58029 14.5799L15.0703 6.09992" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
              </button>
              <input
                className="writebodytypemessageinput1"
                placeholder="Hello"
                type="text"
              />
              <button className="writemessagebodysendbutton1">
                <div className="writemessagebodysendbuttontext">Send</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageView;
