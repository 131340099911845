import { useEffect, useState } from "react";
import "antd/dist/antd.min.css";
import { Slider, Box } from "@mui/material";
import { Switch } from "antd";
import "./Morefilters.css";
import {
  Companies,
  Disabilities,
  Organizations,
  PersonalAttributes,
  Religion,
  Sports,
} from "../Data/data";

import debounce from "lodash.debounce";
import axios from "axios";
import { BASE_Server_URL } from "../requestMethods";

const MoreFilters = ({
  setMoreFiltersData,
  onClose,
  setMatchesFilterData,
  setClearMoreFilters,
  selectedData,
  setSelectedData,
  handleItemClick,
  handleApplyFilters,
  handleClearFilters,
}) => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const [raceDropdown, setRaceDropdown] = useState(false);

  const [genderDropdown, setGenderDropdown] = useState(false);
  const [religionDropdown, setReligionDropdown] = useState(false);
  const [disabilityDropdown, setDisabilityDropdown] = useState(false);
  const [personalAttributesDropdown, setPersonalAttributesDropdown] =
    useState(false);
  const [sportsDropdown, setSportsDropdown] = useState(false);
  const [organizationDropdown, setOrganizationDropdown] = useState(false);
  const [companiesDropdown, setCompaniesDropdown] = useState(false);
  const [collegesDropdown, setCollegesDropdown] = useState(false);

  //const [searchCompanyInput, setSearchCompanyInput] = useState('');

  const raceCategories = [
    "Asian",
    "Black/African American",
    "Hispanic/Latino",
    "Middle Eastern",
    "American Indian/Alaskan Native",
    "Pacific Islander, including Native Hawaiian",
    "White or Caucasian",
    "Prefer not to say",
  ];

  const convertedRaceCategories = raceCategories.map((category) => {
    const formattedCategory = {
      name: category,
      value: category,
      checked: false,
    };
    return formattedCategory;
  });

  const genderCategories = [
    "Female",
    "Male",
    "Non-Binary",
    "Transgender",
    "Other",
    "Prefer not to answer",
  ];

  const convertedGenderCategories = genderCategories.map((category) => {
    const formattedCategory = {
      name: category,
      value: category,
      checked: false,
    };
    return formattedCategory;
  });

  console.log(convertedGenderCategories);
  //console.log(convertedRaceCategories);

  const [selectedRaces, setSelectedRaces] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedReligions, setSelectedReligions] = useState([]);
  const [selectedDisabilities, setSelectedDisabilities] = useState([]);
  const [selectedPersonalAttributes, setSelectedPersonalAttributes] = useState(
    []
  );
  const [selectedSports, setSelectedSports] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedColleges, setSelectedColleges] = useState([]);

  const [selectedScholarships, setSelectedScholarships] = useState([]);
  const [value, setValue] = useState(0);

  function valuetext(value) {
    return `${value}`;
  }
  const [switchValue, setSwitchValue] = useState(false); // State to store the switch value

  const handleSwitchChange = (checked) => {
    setSwitchValue(checked); // Update the state with the new switch value
  };

  const [companySearchTerm, setCompanySearchTerm] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState(Companies);
  const [orgSearchTerm, setOrgSearchTerm] = useState("");
  const [filteredOrg, setFilteredOrg] = useState(Organizations);

  // const handleItemClick = (item, setSelectedItems, selectedItems) => {
  //   const isSelected = selectedItems.includes(item);
  //   if (isSelected) {
  //     setSelectedItems(selectedItems.filter(selectedItem => selectedItem !== item));
  //   } else {
  //     setSelectedItems([...selectedItems, item]);
  //   }
  // };

  // function toggleSelection(scholarshipType) {
  //   // Check if the scholarship type is already selected
  //   const isSelected = selectedScholarships.includes(scholarshipType);

  //   // Update selected scholarships based on the current selection status
  //   if (isSelected) {
  //       setSelectedScholarships(prevSelected => prevSelected.filter(type => type !== scholarshipType));
  //   } else {
  //       setSelectedScholarships(prevSelected => [...prevSelected, scholarshipType]);
  //   }
  // }
  function toggleSelection(scholarshipType) {
    // Check if the scholarship type is already selected
    const isSelected =
      selectedData.selectedScholarships.includes(scholarshipType);

    // Update selected scholarships based on the current selection status
    setSelectedData((prevSelectedData) => {
      const prevSelected = prevSelectedData.selectedScholarships;

      if (isSelected) {
        // Remove the scholarship type if it's already selected
        return {
          ...prevSelectedData,
          selectedScholarships: prevSelected.filter(
            (type) => type !== scholarshipType
          ),
        };
      } else {
        // Add the scholarship type if it's not selected
        return {
          ...prevSelectedData,
          selectedScholarships: [...prevSelected, scholarshipType],
        };
      }
    });
  }

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const handleChange = (event, newValue) => {
    setSelectedData((prevSelectedData) => ({
      ...prevSelectedData,
      value: newValue,
    }));
  };

  const [collegeSearchTerm, setCollegeSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const PROXY_URL = `${BASE_Server_URL}proxy`; // Update this with your actual proxy server URL.

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  //Adjust the debounce delay as needed (500ms in this case)

  const handleInputChange = (event) => {
    const value = event.target.value;
    setCollegeSearchTerm(value);

    // Call the debounced function instead of making immediate requests
    //debouncedFetchSuggestions(value);
  };

  const handleSuggestionClick = (suggestion) => {
    // Set the selected suggestion as the search term
    setCollegeSearchTerm(suggestion);

    // Clear the suggestion list
    setSuggestions([]);
  };

  // const debouncedFetchSuggestions = debounce(async (inputValue) => {
  //   setLoading(true);
  //   setError(null);
  //   try {
  //     // const response = await axios.get(`${AUTOCOMPLETE_API_URL}&input=${inputValue}`);
  //     // const { predictions } = response.data;
  //     // const locationSuggestions = predictions.map((prediction) => prediction.description);

  //     const response = await axios.get(`${PROXY_URL}/?input=${inputValue}`);
  //     const { predictions } = response.data;

  //     console.log(predictions)
  //     const locationSuggestions = predictions.map((prediction) => prediction.description);

  //     console.log('location suggestion', locationSuggestions)
  //     setSuggestions(locationSuggestions);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error('Error fetching location suggestions:', error);
  //     setError('Error fetching suggestions. Please try again later.');
  //     setLoading(false);
  //   }
  // }, 300);

  // useEffect(()=>{
  //    // Debounce the API call to avoid excessive requests
  //      debouncedFetchSuggestions(collegeSearchTerm);
  //     // console.log('fnnmmlmzxmkdems',collegeSearchTerm)
  // }, [collegeSearchTerm])

  const debouncedFetchSuggestions = debounce(async (inputValue) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${PROXY_URL}/?input=${inputValue}`);
      const { predictions } = response.data;

      // Filter predictions to include only universities or colleges
      const filteredPredictions = predictions.filter(
        (prediction) =>
          prediction.types.includes("university") ||
          prediction.types.includes("school") ||
          prediction.types.includes("college")
      );

      const locationSuggestions = filteredPredictions.map(
        (prediction) => prediction.description
      );
      setSuggestions(locationSuggestions);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
      setError("Error fetching suggestions. Please try again later.");
      setLoading(false);
    }
  }, 300);

  useEffect(() => {
    debouncedFetchSuggestions(collegeSearchTerm);
  }, [collegeSearchTerm]);

  useEffect(() => {
    console.log(selectedGenders);
    console.log(selectedRaces);
    console.log(selectedReligions);
    console.log(selectedDisabilities);
    console.log(selectedPersonalAttributes);
    console.log(selectedSports);
    console.log(selectedOrganizations);
    console.log(selectedScholarships);
    console.log(selectedColleges);
    console.log(selectedCompanies);
    console.log("whsbnmk,xs", value);
    console.log("xhsbdck,xs", switchValue);

    //setMoreFiltersData()
    setMatchesFilterData(
      selectedRaces,
      selectedColleges,
      selectedGenders,
      selectedReligions,
      selectedDisabilities,
      selectedPersonalAttributes,
      selectedSports,
      selectedOrganizations,
      selectedScholarships,
      selectedCompanies,
      value,
      switchValue
    );
  }, [
    selectedRaces,
    selectedColleges,
    selectedGenders,
    selectedReligions,
    selectedDisabilities,
    selectedPersonalAttributes,
    selectedSports,
    selectedOrganizations,
    selectedScholarships,
    selectedCompanies,
    value,
    switchValue,
  ]);

  // useEffect(()=>{
  //   c
  // },[])
  // const handleApplyFilters = () => {
  //   // Prepare the filters data object with updated values
  //   const filtersData = {
  //     selectedRaces,
  //     selectedColleges,
  //     selectedGenders,
  //     selectedReligions,
  //     selectedDisabilities,
  //     selectedPersonalAttributes,
  //     selectedSports,
  //     selectedOrganizations,
  //     selectedScholarships,
  //     selectedCompanies,
  //     value,
  //     switchValue
  //   };

  //   // Update the moreFiltersData object using setMoreFiltersData
  //   setMoreFiltersData(filtersData);

  //   // Close the More Filters popup
  //   onClose();
  // };

  // const clearApplyFilters = () => {
  //   setSelectedRaces([]);
  //   setSelectedColleges([]);
  //   setSelectedGenders([]);
  //   setSelectedReligions([]);
  //   setSelectedDisabilities([]);
  //   setSelectedPersonalAttributes([]);
  //   setSelectedSports([]);
  //   setSelectedOrganizations([]);
  //   setSelectedScholarships([]);
  //   setSelectedCompanies([]);
  //   setValue(''); // Assuming value is a string
  //   setSwitchValue(false); // Assuming switchValue is a boolean

  // };

  // ;
  // const handleClearFilters = () => {
  //   clearApplyFilters();
  //   // Additional logic if needed
  //   setClearMoreFilters(true);

  //   handleApplyFilters()
  //   onClose();
  // };

  const handleMatchesFoundFilter = () => {
    // Prepare the filters data object with updated values
    const filtersData = {
      selectedRaces,
      selectedColleges,
      selectedGenders,
      selectedReligions,
      selectedDisabilities,
      selectedPersonalAttributes,
      selectedSports,
      selectedOrganizations,
      selectedScholarships,
      selectedCompanies,
      value,
      switchValue,
    };

    // Update the moreFiltersData object using setMoreFiltersData
    setMatchesFilterData(filtersData);

    // Close the More Filters popup
    // onClose();
  };

  const handleCompanyInputChange = (event) => {
    const term = event.target.value;
    setCompanySearchTerm(term);

    // Filter companies based on input
    const filtered = Companies.filter((company) =>
      company.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredCompanies(filtered);
  };

  const handleOrgInputChange = (event) => {
    const term = event.target.value;
    setOrgSearchTerm(term);

    // Filter companies based on input
    const filtered = Organizations.filter((org) =>
      org.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredOrg(filtered);
  };

  function convertToFormat(inputArray) {
    return inputArray.map((name) => ({
      name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
      value: name.charAt(0).toUpperCase(),
    }));
  }
  return (
    <div className="morefiltersbox" data-animate-on-scroll>
      <div className="morefiltersbox1" data-animate-on-scroll>
        <div className="morefiltersbox2">
          <div className="gpa-wrapper">
            <b className="morefiltersboxheader">Filters</b>
            {/* <img
              className="morefiltersboxheadericon"
              alt=""
              src="/group-117.svg"
            /> */}
            <svg
              className="morefiltersboxheadericon"
              onClick={onClose}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                fill="#E7E7E7"
              />
              <path
                d="M15 9L9 15"
                stroke="black"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9 9L15 15"
                stroke="black"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="morefiltersbox1-child">
          <div className="gpa-wrapper">
            <b className="morefiltersboxheader">{`Opportunity Type `}</b>
          </div>
        </div>
        <div className="morefiltersbox1-inner">
          <div className="frame-group">
            <div
              className="summer-programs-wrapper"
              style={{
                borderWidth: selectedData.selectedScholarships.includes(
                  "Scholarship"
                )
                  ? 2
                  : 1,
                borderColor: selectedData.selectedScholarships.includes(
                  "Scholarship"
                )
                  ? "#0D0ED9"
                  : "black",
                borderStyle: "solid", // Ensure border style is explicitly set to solid
              }}
              onClick={() => toggleSelection("Scholarship")}
            >
              <div className="scholarship">Scholarship</div>
            </div>
            <div
              className="summer-programs-wrapper"
              style={{
                borderWidth: selectedData.selectedScholarships.includes(
                  "Fellowship"
                )
                  ? 2
                  : 1,
                borderColor: selectedData.selectedScholarships.includes(
                  "Fellowship"
                )
                  ? "#0D0ED9"
                  : "black",
                borderStyle: "solid", // Ensure border style is explicitly set to solid
              }}
              onClick={() => toggleSelection("Fellowship")}
            >
              <div className="scholarship">Fellowship</div>
            </div>
            <div
              className="summer-programs-wrapper"
              style={{
                borderWidth: selectedData.selectedScholarships.includes("Grant")
                  ? 2
                  : 1,
                borderColor: selectedData.selectedScholarships.includes("Grant")
                  ? "#0D0ED9"
                  : "black",
                borderStyle: "solid", // Ensure border style is explicitly set to solid
              }}
              onClick={() => toggleSelection("Grant")}
            >
              <div className="scholarship">Grants</div>
            </div>
            <div
              className="summer-programs-wrapper"
              style={{
                borderWidth: selectedData.selectedScholarships.includes(
                  "Research"
                )
                  ? 2
                  : 1,
                borderColor: selectedData.selectedScholarships.includes(
                  "Research"
                )
                  ? "#0D0ED9"
                  : "black",
                borderStyle: "solid", // Ensure border style is explicitly set to solid
              }}
              onClick={() => toggleSelection("Research")}
            >
              <div className="scholarship">Research</div>
            </div>
            <div
              className="summer-programs-wrapper"
              style={{
                borderWidth: selectedData.selectedScholarships.includes("Loan")
                  ? 2
                  : 1,
                borderColor: selectedData.selectedScholarships.includes("Loan")
                  ? "#0D0ED9"
                  : "black",
                borderStyle: "solid", // Ensure border style is explicitly set to solid
              }}
              onClick={() => toggleSelection("Loan")}
            >
              <div className="scholarship">Loans</div>
            </div>

            <div
              className="conference-grants-wrapper"
              style={{
                borderWidth: selectedData.selectedScholarships.includes(
                  "Conference Grants"
                )
                  ? 2
                  : 1,
                borderColor: selectedData.selectedScholarships.includes(
                  "Conference Grants"
                )
                  ? "#0D0ED9"
                  : "black",
                borderStyle: "solid", // Ensure border style is explicitly set to solid
              }}
              onClick={() => toggleSelection("Conference Grants")}
            >
              <div className="scholarship">Conference Grants</div>
            </div>
            <div
              className="summer-programs-wrapper"
              style={{
                borderWidth: selectedData.selectedScholarships.includes(
                  "Tuition Waiver"
                )
                  ? 2
                  : 1,
                borderColor: selectedData.selectedScholarships.includes(
                  "Tuition Waiver"
                )
                  ? "#0D0ED9"
                  : "black",
                borderStyle: "solid", // Ensure border style is explicitly set to solid
              }}
              onClick={() => toggleSelection("Tuition Waiver")}
            >
              <div className="scholarship">Tuition Waiver</div>
            </div>
            <div
              className="summer-programs-wrapper"
              style={{
                borderWidth: selectedData.selectedScholarships.includes(
                  "Summer Programs"
                )
                  ? 2
                  : 1,
                borderColor: selectedData.selectedScholarships.includes(
                  "Summer Programs"
                )
                  ? "#0D0ED9"
                  : "black",
                borderStyle: "solid", // Ensure border style is explicitly set to solid
              }}
              onClick={() => toggleSelection("Summer Programs")}
            >
              <div className="scholarship">Summer Programs</div>
            </div>
          </div>
        </div>
        <div className="morefiltersbox1-child">
          <div className="gpa-wrapper">
            <b className="morefiltersboxheader">GPA</b>
          </div>
        </div>
        <div className="morefilterssliderbox">
          <Box
            className="morefiltersboxslidercontinuou"
            sx={{ marginLeft: 5, width: 321 }}
          >
            <Slider
              getAriaLabel={() => "Temperature range"}
              value={selectedData.value}
              onChange={handleChange}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              min={0} // Set the minimum value of the Slider
              max={4} // Set the maximum value of the Slider
              step={0.2}
            />
          </Box>
        </div>
        <div className="morefilterssliderboxeasyapply">
          <div className="gpa-wrapper">
            <b className="morefiltersboxheader">Easy Apply</b>
          </div>

          <Switch size="default" defaultChecked onChange={handleSwitchChange} />
        </div>
        <div className="morefilterslinebox">
          <div className="morefiltersline" />
        </div>
        <div className="morefiltersbox1-child">
          <div className="gpa-wrapper">
            <b className="morefiltersboxheader">Personal</b>
          </div>
        </div>

        <div
          className="morefiltersdropdownbox"
          onClick={() => setRaceDropdown(!raceDropdown)}
        >
          <div className="buttonxxlarge-parent">
            <b className="morefiltersboxheader">
              {selectedData.selectedRaces.length === 1
                ? selectedData.selectedRaces[0]
                : selectedData.selectedRaces.length > 1 &&
                  `Race . ${selectedData.selectedRaces.length}`}
              {selectedData.selectedRaces.length === 0 && "Race"}
            </b>

            {/* <img
              className="morefiltersdropdownboxicon"
              alt=""
              src="/vector5.svg"
            /> */}
            {raceDropdown ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
              >
                <path
                  d="M8 0.5C8.19133 0.5 8.37644 0.538169 8.55534 0.614505C8.73519 0.690841 8.8849 0.792622 9.00448 0.919849L15.6054 7.94275C15.8685 8.22265 16 8.57888 16 9.01145C16 9.44402 15.8685 9.80026 15.6054 10.0802C15.3423 10.3601 15.0075 10.5 14.6009 10.5C14.1943 10.5 13.8595 10.3601 13.5964 10.0802L8 4.12595L2.40359 10.0802C2.14051 10.3601 1.80568 10.5 1.3991 10.5C0.992527 10.5 0.6577 10.3601 0.394621 10.0802C0.131541 9.80026 0 9.44402 0 9.01145C0 8.57888 0.131541 8.22265 0.394621 7.94275L6.99552 0.919849C7.13901 0.767177 7.29447 0.659289 7.46188 0.596184C7.6293 0.532063 7.80867 0.5 8 0.5Z"
                  fill="#79797D"
                />
              </svg>
            ) : (
              <svg
                className="morefiltersdropdownboxicon"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
              >
                <path
                  d="M8 10.5C7.80867 10.5 7.62356 10.4618 7.44466 10.3855C7.26481 10.3092 7.1151 10.2074 6.99552 10.0802L0.394618 3.05725C0.131539 2.77735 0 2.42112 0 1.98855C0 1.55598 0.131539 1.19974 0.394618 0.919847C0.657698 0.639949 0.992526 0.5 1.3991 0.5C1.80568 0.5 2.14051 0.639949 2.40359 0.919847L8 6.87405L13.5964 0.919847C13.8595 0.639949 14.1943 0.5 14.6009 0.5C15.0075 0.5 15.3423 0.639949 15.6054 0.919847C15.8685 1.19974 16 1.55598 16 1.98855C16 2.42112 15.8685 2.77735 15.6054 3.05725L9.00448 10.0802C8.86099 10.2328 8.70553 10.3407 8.53812 10.4038C8.3707 10.4679 8.19133 10.5 8 10.5Z"
                  fill="#79797D"
                />
              </svg>
            )}
          </div>
        </div>

        {raceDropdown && (
          <div className="morefiltersdropdownitembox">
            {/* <input
            className="morefiltersdropdownitemsearch"
            placeholder="Select Race"
            type="text"
          /> */}
            <div className="morefiltersdropdownitembox1-wrapper">
              <div className="morefiltersdropdownitembox1">
                {convertedRaceCategories.map((category) => (
                  <div className="morefiltersdropdownitembox1lis">
                    {/* <div
          className={`buttonxxlarge-parent ${selectedRaces.includes(category.name) ? 'active' : ''}`}
          key={category.value}
          onClick={() => handleItemClick(category.name, setSelectedRaces, selectedRaces)}
        >
      <b className="morefiltersboxheader">{category.name}</b>
     
      {selectedRaces.includes(category.name) && (
           
      <svg className="morefiltersdropdownitembox1lis-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
  <path d="M5.78723 10.5C5.19689 10.5 4.71202 10.3262 4.2727 9.94049L0.424884 6.76951C0.144143 6.52616 0 6.28062 0 6.01112C0 5.46922 0.634332 5.02971 1.43134 5.02971C1.92908 5.02971 2.3056 5.14272 2.70945 5.49373L5.73578 8.09685L12.2284 1.05441C12.5691 0.691254 13.0141 0.5 13.4957 0.5C14.28 0.5 15 0.872722 15 1.43011C15 1.67561 14.81 1.93559 14.5905 2.18003L7.24474 9.93286C6.88391 10.3049 6.37476 10.5 5.78723 10.5Z" fill="#0D0ED9"/>
</svg>)}
    </div> */}

                    <div
                      className={`buttonxxlarge-parent ${
                        selectedData.selectedRaces.includes(category.name)
                          ? "active"
                          : ""
                      }`}
                      key={category.value}
                      onClick={() =>
                        handleItemClick("selectedRaces", category.name)
                      }
                    >
                      <b className="morefiltersboxheader">{category.name}</b>

                      {selectedData.selectedRaces.includes(category.name) && (
                        <svg
                          className="morefiltersdropdownitembox1lis-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="11"
                          viewBox="0 0 15 11"
                          fill="none"
                        >
                          <path
                            d="M5.78723 10.5C5.19689 10.5 4.71202 10.3262 4.2727 9.94049L0.424884 6.76951C0.144143 6.52616 0 6.28062 0 6.01112C0 5.46922 0.634332 5.02971 1.43134 5.02971C1.92908 5.02971 2.3056 5.14272 2.70945 5.49373L5.73578 8.09685L12.2284 1.05441C12.5691 0.691254 13.0141 0.5 13.4957 0.5C14.28 0.5 15 0.872722 15 1.43011C15 1.67561 14.81 1.93559 14.5905 2.18003L7.24474 9.93286C6.88391 10.3049 6.37476 10.5 5.78723 10.5Z"
                            fill="#0D0ED9"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div
          className="morefiltersdropdownbox"
          onClick={() => setGenderDropdown(!genderDropdown)}
        >
          <div className="buttonxxlarge-parent">
            <b className="morefiltersboxheader">
              {selectedData.selectedGenders.length === 1
                ? selectedData.selectedGenders[0]
                : selectedData.selectedGenders.length > 1 &&
                  `Gender . ${selectedData.selectedGenders.length}`}
              {selectedData.selectedGenders.length === 0 && "Gender"}
            </b>
            {/* <img
              className="morefiltersdropdownboxicon"
              alt=""
              src="/vector5.svg"
            /> */}
            {genderDropdown ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
              >
                <path
                  d="M8 0.5C8.19133 0.5 8.37644 0.538169 8.55534 0.614505C8.73519 0.690841 8.8849 0.792622 9.00448 0.919849L15.6054 7.94275C15.8685 8.22265 16 8.57888 16 9.01145C16 9.44402 15.8685 9.80026 15.6054 10.0802C15.3423 10.3601 15.0075 10.5 14.6009 10.5C14.1943 10.5 13.8595 10.3601 13.5964 10.0802L8 4.12595L2.40359 10.0802C2.14051 10.3601 1.80568 10.5 1.3991 10.5C0.992527 10.5 0.6577 10.3601 0.394621 10.0802C0.131541 9.80026 0 9.44402 0 9.01145C0 8.57888 0.131541 8.22265 0.394621 7.94275L6.99552 0.919849C7.13901 0.767177 7.29447 0.659289 7.46188 0.596184C7.6293 0.532063 7.80867 0.5 8 0.5Z"
                  fill="#79797D"
                />
              </svg>
            ) : (
              <svg
                className="morefiltersdropdownboxicon"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
              >
                <path
                  d="M8 10.5C7.80867 10.5 7.62356 10.4618 7.44466 10.3855C7.26481 10.3092 7.1151 10.2074 6.99552 10.0802L0.394618 3.05725C0.131539 2.77735 0 2.42112 0 1.98855C0 1.55598 0.131539 1.19974 0.394618 0.919847C0.657698 0.639949 0.992526 0.5 1.3991 0.5C1.80568 0.5 2.14051 0.639949 2.40359 0.919847L8 6.87405L13.5964 0.919847C13.8595 0.639949 14.1943 0.5 14.6009 0.5C15.0075 0.5 15.3423 0.639949 15.6054 0.919847C15.8685 1.19974 16 1.55598 16 1.98855C16 2.42112 15.8685 2.77735 15.6054 3.05725L9.00448 10.0802C8.86099 10.2328 8.70553 10.3407 8.53812 10.4038C8.3707 10.4679 8.19133 10.5 8 10.5Z"
                  fill="#79797D"
                />
              </svg>
            )}
          </div>
        </div>

        {genderDropdown && (
          <div className="morefiltersdropdownitembox">
            <div className="morefiltersdropdownitembox1-wrapper">
              <div className="morefiltersdropdownitembox1">
                {convertedGenderCategories.map((category) => (
                  <div className="morefiltersdropdownitembox1lis">
                    {/* <div
          className={`buttonxxlarge-parent ${selectedGenders.includes(category.name) ? 'active' : ''}`}
          key={category.value}
          onClick={() => handleItemClick(category.name, setSelectedGenders, selectedGenders)}
        >
      <b className="morefiltersboxheader">{category.name}</b>
     
      {selectedGenders.includes(category.name) && (
           
      <svg className="morefiltersdropdownitembox1lis-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
  <path d="M5.78723 10.5C5.19689 10.5 4.71202 10.3262 4.2727 9.94049L0.424884 6.76951C0.144143 6.52616 0 6.28062 0 6.01112C0 5.46922 0.634332 5.02971 1.43134 5.02971C1.92908 5.02971 2.3056 5.14272 2.70945 5.49373L5.73578 8.09685L12.2284 1.05441C12.5691 0.691254 13.0141 0.5 13.4957 0.5C14.28 0.5 15 0.872722 15 1.43011C15 1.67561 14.81 1.93559 14.5905 2.18003L7.24474 9.93286C6.88391 10.3049 6.37476 10.5 5.78723 10.5Z" fill="#0D0ED9"/>
</svg>)}
    </div> */}

                    <div
                      className={`buttonxxlarge-parent ${
                        selectedData.selectedGenders.includes(category.name)
                          ? "active"
                          : ""
                      }`}
                      key={category.value}
                      onClick={() =>
                        handleItemClick("selectedGenders", category.name)
                      }
                    >
                      <b className="morefiltersboxheader">{category.name}</b>

                      {selectedData.selectedGenders.includes(category.name) && (
                        <svg
                          className="morefiltersdropdownitembox1lis-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="11"
                          viewBox="0 0 15 11"
                          fill="none"
                        >
                          <path
                            d="M5.78723 10.5C5.19689 10.5 4.71202 10.3262 4.2727 9.94049L0.424884 6.76951C0.144143 6.52616 0 6.28062 0 6.01112C0 5.46922 0.634332 5.02971 1.43134 5.02971C1.92908 5.02971 2.3056 5.14272 2.70945 5.49373L5.73578 8.09685L12.2284 1.05441C12.5691 0.691254 13.0141 0.5 13.4957 0.5C14.28 0.5 15 0.872722 15 1.43011C15 1.67561 14.81 1.93559 14.5905 2.18003L7.24474 9.93286C6.88391 10.3049 6.37476 10.5 5.78723 10.5Z"
                            fill="#0D0ED9"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div
          className="morefiltersdropdownbox"
          onClick={() => setReligionDropdown(!religionDropdown)}
        >
          <div className="buttonxxlarge-parent">
            <b className="morefiltersboxheader">
              {selectedData.selectedReligions.length === 1
                ? selectedData.selectedReligions[0]
                : selectedData.selectedReligions.length > 1 &&
                  `Religion . ${selectedData.selectedReligions.length}`}
              {selectedData.selectedReligions.length === 0 && "Religion"}
            </b>
            {/* <img
              className="morefiltersdropdownboxicon"
              alt=""
              src="/vector5.svg"
            /> */}
            {religionDropdown ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
              >
                <path
                  d="M8 0.5C8.19133 0.5 8.37644 0.538169 8.55534 0.614505C8.73519 0.690841 8.8849 0.792622 9.00448 0.919849L15.6054 7.94275C15.8685 8.22265 16 8.57888 16 9.01145C16 9.44402 15.8685 9.80026 15.6054 10.0802C15.3423 10.3601 15.0075 10.5 14.6009 10.5C14.1943 10.5 13.8595 10.3601 13.5964 10.0802L8 4.12595L2.40359 10.0802C2.14051 10.3601 1.80568 10.5 1.3991 10.5C0.992527 10.5 0.6577 10.3601 0.394621 10.0802C0.131541 9.80026 0 9.44402 0 9.01145C0 8.57888 0.131541 8.22265 0.394621 7.94275L6.99552 0.919849C7.13901 0.767177 7.29447 0.659289 7.46188 0.596184C7.6293 0.532063 7.80867 0.5 8 0.5Z"
                  fill="#79797D"
                />
              </svg>
            ) : (
              <svg
                className="morefiltersdropdownboxicon"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
              >
                <path
                  d="M8 10.5C7.80867 10.5 7.62356 10.4618 7.44466 10.3855C7.26481 10.3092 7.1151 10.2074 6.99552 10.0802L0.394618 3.05725C0.131539 2.77735 0 2.42112 0 1.98855C0 1.55598 0.131539 1.19974 0.394618 0.919847C0.657698 0.639949 0.992526 0.5 1.3991 0.5C1.80568 0.5 2.14051 0.639949 2.40359 0.919847L8 6.87405L13.5964 0.919847C13.8595 0.639949 14.1943 0.5 14.6009 0.5C15.0075 0.5 15.3423 0.639949 15.6054 0.919847C15.8685 1.19974 16 1.55598 16 1.98855C16 2.42112 15.8685 2.77735 15.6054 3.05725L9.00448 10.0802C8.86099 10.2328 8.70553 10.3407 8.53812 10.4038C8.3707 10.4679 8.19133 10.5 8 10.5Z"
                  fill="#79797D"
                />
              </svg>
            )}
          </div>
        </div>

        {religionDropdown && (
          <div className="morefiltersdropdownitembox">
            {/* <input
            className="morefiltersdropdownitemsearch"
            placeholder="Select Race"
            type="text"
          /> */}
            <div className="morefiltersdropdownitembox1-wrapper">
              <div className="morefiltersdropdownitembox1">
                {Religion.map((category) => (
                  <div className="morefiltersdropdownitembox1lis">
                    {/* <div
          className={`buttonxxlarge-parent ${selectedReligions.includes(category.name) ? 'active' : ''}`}
          key={category.value}
          onClick={() => handleItemClick(category.name, setSelectedReligions, selectedReligions)}
        >
      <b className="morefiltersboxheader">{category.name}</b>
     
      {selectedReligions.includes(category.name) && (
           
      <svg className="morefiltersdropdownitembox1lis-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
  <path d="M5.78723 10.5C5.19689 10.5 4.71202 10.3262 4.2727 9.94049L0.424884 6.76951C0.144143 6.52616 0 6.28062 0 6.01112C0 5.46922 0.634332 5.02971 1.43134 5.02971C1.92908 5.02971 2.3056 5.14272 2.70945 5.49373L5.73578 8.09685L12.2284 1.05441C12.5691 0.691254 13.0141 0.5 13.4957 0.5C14.28 0.5 15 0.872722 15 1.43011C15 1.67561 14.81 1.93559 14.5905 2.18003L7.24474 9.93286C6.88391 10.3049 6.37476 10.5 5.78723 10.5Z" fill="#0D0ED9"/>
</svg>)}
    </div> */}

                    <div
                      className={`buttonxxlarge-parent ${
                        selectedData.selectedReligions.includes(category.name)
                          ? "active"
                          : ""
                      }`}
                      key={category.value}
                      onClick={() =>
                        handleItemClick("selectedReligions", category.name)
                      }
                    >
                      <b className="morefiltersboxheader">{category.name}</b>

                      {selectedData.selectedReligions.includes(
                        category.name
                      ) && (
                        <svg
                          className="morefiltersdropdownitembox1lis-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="11"
                          viewBox="0 0 15 11"
                          fill="none"
                        >
                          <path
                            d="M5.78723 10.5C5.19689 10.5 4.71202 10.3262 4.2727 9.94049L0.424884 6.76951C0.144143 6.52616 0 6.28062 0 6.01112C0 5.46922 0.634332 5.02971 1.43134 5.02971C1.92908 5.02971 2.3056 5.14272 2.70945 5.49373L5.73578 8.09685L12.2284 1.05441C12.5691 0.691254 13.0141 0.5 13.4957 0.5C14.28 0.5 15 0.872722 15 1.43011C15 1.67561 14.81 1.93559 14.5905 2.18003L7.24474 9.93286C6.88391 10.3049 6.37476 10.5 5.78723 10.5Z"
                            fill="#0D0ED9"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div
          className="morefiltersdropdownbox"
          onClick={() => setDisabilityDropdown(!disabilityDropdown)}
        >
          <div className="buttonxxlarge-parent">
            <b className="morefiltersboxheader">
              {selectedData.selectedDisabilities.length === 1
                ? selectedData.selectedDisabilities[0]
                : selectedData.selectedDisabilities.length > 1 &&
                  `Disability . ${selectedData.selectedDisabilities.length}`}
              {selectedData.selectedDisabilities.length === 0 && "Disability"}
            </b>
            {/* <img
              className="morefiltersdropdownboxicon"
              alt=""
              src="/vector5.svg"
            /> */}
            {disabilityDropdown ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
              >
                <path
                  d="M8 0.5C8.19133 0.5 8.37644 0.538169 8.55534 0.614505C8.73519 0.690841 8.8849 0.792622 9.00448 0.919849L15.6054 7.94275C15.8685 8.22265 16 8.57888 16 9.01145C16 9.44402 15.8685 9.80026 15.6054 10.0802C15.3423 10.3601 15.0075 10.5 14.6009 10.5C14.1943 10.5 13.8595 10.3601 13.5964 10.0802L8 4.12595L2.40359 10.0802C2.14051 10.3601 1.80568 10.5 1.3991 10.5C0.992527 10.5 0.6577 10.3601 0.394621 10.0802C0.131541 9.80026 0 9.44402 0 9.01145C0 8.57888 0.131541 8.22265 0.394621 7.94275L6.99552 0.919849C7.13901 0.767177 7.29447 0.659289 7.46188 0.596184C7.6293 0.532063 7.80867 0.5 8 0.5Z"
                  fill="#79797D"
                />
              </svg>
            ) : (
              <svg
                className="morefiltersdropdownboxicon"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
              >
                <path
                  d="M8 10.5C7.80867 10.5 7.62356 10.4618 7.44466 10.3855C7.26481 10.3092 7.1151 10.2074 6.99552 10.0802L0.394618 3.05725C0.131539 2.77735 0 2.42112 0 1.98855C0 1.55598 0.131539 1.19974 0.394618 0.919847C0.657698 0.639949 0.992526 0.5 1.3991 0.5C1.80568 0.5 2.14051 0.639949 2.40359 0.919847L8 6.87405L13.5964 0.919847C13.8595 0.639949 14.1943 0.5 14.6009 0.5C15.0075 0.5 15.3423 0.639949 15.6054 0.919847C15.8685 1.19974 16 1.55598 16 1.98855C16 2.42112 15.8685 2.77735 15.6054 3.05725L9.00448 10.0802C8.86099 10.2328 8.70553 10.3407 8.53812 10.4038C8.3707 10.4679 8.19133 10.5 8 10.5Z"
                  fill="#79797D"
                />
              </svg>
            )}
          </div>
        </div>

        {disabilityDropdown && (
          <div className="morefiltersdropdownitembox">
            {/* <input
            className="morefiltersdropdownitemsearch"
            placeholder="Select Race"
            type="text"
          /> */}
            <div className="morefiltersdropdownitembox1-wrapper">
              <div className="morefiltersdropdownitembox1">
                {Disabilities.map((category) => (
                  <div className="morefiltersdropdownitembox1lis">
                    {/* 
<div
          className={`buttonxxlarge-parent ${selectedDisabilities.includes(category.name) ? 'active' : ''}`}
          key={category.value}
          onClick={() => handleItemClick(category.name, setSelectedDisabilities, selectedDisabilities)}
        >
      <b className="morefiltersboxheader">{category.name}</b>
     
      {selectedDisabilities.includes(category.name) && (
           
      <svg className="morefiltersdropdownitembox1lis-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
  <path d="M5.78723 10.5C5.19689 10.5 4.71202 10.3262 4.2727 9.94049L0.424884 6.76951C0.144143 6.52616 0 6.28062 0 6.01112C0 5.46922 0.634332 5.02971 1.43134 5.02971C1.92908 5.02971 2.3056 5.14272 2.70945 5.49373L5.73578 8.09685L12.2284 1.05441C12.5691 0.691254 13.0141 0.5 13.4957 0.5C14.28 0.5 15 0.872722 15 1.43011C15 1.67561 14.81 1.93559 14.5905 2.18003L7.24474 9.93286C6.88391 10.3049 6.37476 10.5 5.78723 10.5Z" fill="#0D0ED9"/>
</svg>)}
    </div> */}

                    <div
                      className={`buttonxxlarge-parent ${
                        selectedData.selectedDisabilities.includes(
                          category.name
                        )
                          ? "active"
                          : ""
                      }`}
                      key={category.value}
                      onClick={() =>
                        handleItemClick("selectedDisabilities", category.name)
                      }
                    >
                      <b className="morefiltersboxheader">{category.name}</b>

                      {selectedData.selectedDisabilities.includes(
                        category.name
                      ) && (
                        <svg
                          className="morefiltersdropdownitembox1lis-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="11"
                          viewBox="0 0 15 11"
                          fill="none"
                        >
                          <path
                            d="M5.78723 10.5C5.19689 10.5 4.71202 10.3262 4.2727 9.94049L0.424884 6.76951C0.144143 6.52616 0 6.28062 0 6.01112C0 5.46922 0.634332 5.02971 1.43134 5.02971C1.92908 5.02971 2.3056 5.14272 2.70945 5.49373L5.73578 8.09685L12.2284 1.05441C12.5691 0.691254 13.0141 0.5 13.4957 0.5C14.28 0.5 15 0.872722 15 1.43011C15 1.67561 14.81 1.93559 14.5905 2.18003L7.24474 9.93286C6.88391 10.3049 6.37476 10.5 5.78723 10.5Z"
                            fill="#0D0ED9"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div
          className="morefiltersdropdownbox"
          onClick={() =>
            setPersonalAttributesDropdown(!personalAttributesDropdown)
          }
        >
          <div className="buttonxxlarge-parent">
            <b className="morefiltersboxheader">
              {selectedData.selectedPersonalAttributes.length === 1
                ? selectedData.selectedPersonalAttributes[0]
                : selectedData.selectedPersonalAttributes.length > 1 &&
                  `Personal Attribute . ${selectedData.selectedPersonalAttributes.length}`}
              {selectedData.selectedPersonalAttributes.length === 0 &&
                "Personal Attribute"}
            </b>
            {/* <img
              className="morefiltersdropdownboxicon"
              alt=""
              src="/vector5.svg"
            /> */}
            {personalAttributesDropdown ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
              >
                <path
                  d="M8 0.5C8.19133 0.5 8.37644 0.538169 8.55534 0.614505C8.73519 0.690841 8.8849 0.792622 9.00448 0.919849L15.6054 7.94275C15.8685 8.22265 16 8.57888 16 9.01145C16 9.44402 15.8685 9.80026 15.6054 10.0802C15.3423 10.3601 15.0075 10.5 14.6009 10.5C14.1943 10.5 13.8595 10.3601 13.5964 10.0802L8 4.12595L2.40359 10.0802C2.14051 10.3601 1.80568 10.5 1.3991 10.5C0.992527 10.5 0.6577 10.3601 0.394621 10.0802C0.131541 9.80026 0 9.44402 0 9.01145C0 8.57888 0.131541 8.22265 0.394621 7.94275L6.99552 0.919849C7.13901 0.767177 7.29447 0.659289 7.46188 0.596184C7.6293 0.532063 7.80867 0.5 8 0.5Z"
                  fill="#79797D"
                />
              </svg>
            ) : (
              <svg
                className="morefiltersdropdownboxicon"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
              >
                <path
                  d="M8 10.5C7.80867 10.5 7.62356 10.4618 7.44466 10.3855C7.26481 10.3092 7.1151 10.2074 6.99552 10.0802L0.394618 3.05725C0.131539 2.77735 0 2.42112 0 1.98855C0 1.55598 0.131539 1.19974 0.394618 0.919847C0.657698 0.639949 0.992526 0.5 1.3991 0.5C1.80568 0.5 2.14051 0.639949 2.40359 0.919847L8 6.87405L13.5964 0.919847C13.8595 0.639949 14.1943 0.5 14.6009 0.5C15.0075 0.5 15.3423 0.639949 15.6054 0.919847C15.8685 1.19974 16 1.55598 16 1.98855C16 2.42112 15.8685 2.77735 15.6054 3.05725L9.00448 10.0802C8.86099 10.2328 8.70553 10.3407 8.53812 10.4038C8.3707 10.4679 8.19133 10.5 8 10.5Z"
                  fill="#79797D"
                />
              </svg>
            )}
          </div>
        </div>

        {personalAttributesDropdown && (
          <div className="morefiltersdropdownitembox">
            {/* <input
            className="morefiltersdropdownitemsearch"
            placeholder="Select Race"
            type="text"
          /> */}
            <div className="morefiltersdropdownitembox1-wrapper">
              <div className="morefiltersdropdownitembox1">
                {PersonalAttributes.map((category) => (
                  <div className="morefiltersdropdownitembox1lis">
                    {/* <div
          className={`buttonxxlarge-parent ${selectedPersonalAttributes.includes(category.name) ? 'active' : ''}`}
          key={category.value}
          onClick={() => handleItemClick(category.name, setSelectedPersonalAttributes, selectedPersonalAttributes)}
        >
      <b className="morefiltersboxheader">{category.name}</b>
     
      {selectedPersonalAttributes.includes(category.name) && (
           
      <svg className="morefiltersdropdownitembox1lis-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
  <path d="M5.78723 10.5C5.19689 10.5 4.71202 10.3262 4.2727 9.94049L0.424884 6.76951C0.144143 6.52616 0 6.28062 0 6.01112C0 5.46922 0.634332 5.02971 1.43134 5.02971C1.92908 5.02971 2.3056 5.14272 2.70945 5.49373L5.73578 8.09685L12.2284 1.05441C12.5691 0.691254 13.0141 0.5 13.4957 0.5C14.28 0.5 15 0.872722 15 1.43011C15 1.67561 14.81 1.93559 14.5905 2.18003L7.24474 9.93286C6.88391 10.3049 6.37476 10.5 5.78723 10.5Z" fill="#0D0ED9"/>
</svg>)}
    </div> */}

                    <div
                      className={`buttonxxlarge-parent ${
                        selectedData.selectedPersonalAttributes.includes(
                          category.name
                        )
                          ? "active"
                          : ""
                      }`}
                      key={category.value}
                      onClick={() =>
                        handleItemClick(
                          "selectedPersonalAttributes",
                          category.name
                        )
                      }
                    >
                      <b className="morefiltersboxheader">{category.name}</b>

                      {selectedData.selectedPersonalAttributes.includes(
                        category.name
                      ) && (
                        <svg
                          className="morefiltersdropdownitembox1lis-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="11"
                          viewBox="0 0 15 11"
                          fill="none"
                        >
                          <path
                            d="M5.78723 10.5C5.19689 10.5 4.71202 10.3262 4.2727 9.94049L0.424884 6.76951C0.144143 6.52616 0 6.28062 0 6.01112C0 5.46922 0.634332 5.02971 1.43134 5.02971C1.92908 5.02971 2.3056 5.14272 2.70945 5.49373L5.73578 8.09685L12.2284 1.05441C12.5691 0.691254 13.0141 0.5 13.4957 0.5C14.28 0.5 15 0.872722 15 1.43011C15 1.67561 14.81 1.93559 14.5905 2.18003L7.24474 9.93286C6.88391 10.3049 6.37476 10.5 5.78723 10.5Z"
                            fill="#0D0ED9"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="morefiltersbox1-child">
          <div className="gpa-wrapper">
            <b className="morefiltersboxheader">More Filters</b>
          </div>
        </div>

        <div
          className="morefiltersdropdownbox"
          onClick={() => setCollegesDropdown(!collegesDropdown)}
        >
          <div className="buttonxxlarge-parent">
            <b className="morefiltersboxheader">
              {selectedData.selectedColleges.length === 1
                ? selectedData.selectedColleges[0]
                : selectedData.selectedColleges.length > 1 &&
                  `College . ${selectedData.selectedColleges.length}`}
              {selectedData.selectedColleges.length === 0 && "College"}
            </b>
            {/* <img
              className="morefiltersdropdownboxicon"
              alt=""
              src="/vector5.svg"
            /> */}
            {collegesDropdown ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
              >
                <path
                  d="M8 0.5C8.19133 0.5 8.37644 0.538169 8.55534 0.614505C8.73519 0.690841 8.8849 0.792622 9.00448 0.919849L15.6054 7.94275C15.8685 8.22265 16 8.57888 16 9.01145C16 9.44402 15.8685 9.80026 15.6054 10.0802C15.3423 10.3601 15.0075 10.5 14.6009 10.5C14.1943 10.5 13.8595 10.3601 13.5964 10.0802L8 4.12595L2.40359 10.0802C2.14051 10.3601 1.80568 10.5 1.3991 10.5C0.992527 10.5 0.6577 10.3601 0.394621 10.0802C0.131541 9.80026 0 9.44402 0 9.01145C0 8.57888 0.131541 8.22265 0.394621 7.94275L6.99552 0.919849C7.13901 0.767177 7.29447 0.659289 7.46188 0.596184C7.6293 0.532063 7.80867 0.5 8 0.5Z"
                  fill="#79797D"
                />
              </svg>
            ) : (
              <svg
                className="morefiltersdropdownboxicon"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
              >
                <path
                  d="M8 10.5C7.80867 10.5 7.62356 10.4618 7.44466 10.3855C7.26481 10.3092 7.1151 10.2074 6.99552 10.0802L0.394618 3.05725C0.131539 2.77735 0 2.42112 0 1.98855C0 1.55598 0.131539 1.19974 0.394618 0.919847C0.657698 0.639949 0.992526 0.5 1.3991 0.5C1.80568 0.5 2.14051 0.639949 2.40359 0.919847L8 6.87405L13.5964 0.919847C13.8595 0.639949 14.1943 0.5 14.6009 0.5C15.0075 0.5 15.3423 0.639949 15.6054 0.919847C15.8685 1.19974 16 1.55598 16 1.98855C16 2.42112 15.8685 2.77735 15.6054 3.05725L9.00448 10.0802C8.86099 10.2328 8.70553 10.3407 8.53812 10.4038C8.3707 10.4679 8.19133 10.5 8 10.5Z"
                  fill="#79797D"
                />
              </svg>
            )}
          </div>
        </div>

        {collegesDropdown && (
          <div className="morefiltersdropdownitembox">
            <input
              className="morefiltersdropdownitemsearch"
              placeholder="Search Colleges"
              type="text"
              value={collegeSearchTerm}
              onChange={handleInputChange}
            />
            <div className="morefiltersdropdownitembox1-wrapper">
              <div className="morefiltersdropdownitembox1">
                {convertToFormat(suggestions).map((category) => (
                  <div className="morefiltersdropdownitembox1lis">
                    {/* <div
          className={`buttonxxlarge-parent ${selectedColleges.includes(category.name) ? 'active' : ''}`}
          key={category.value}
          onClick={() => handleItemClick(category.name, setSelectedColleges, selectedColleges)}
        >
      <b className="morefiltersboxheader">{category.name}</b>
     
      {selectedColleges.includes(category.name) && (
           
      <svg className="morefiltersdropdownitembox1lis-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
  <path d="M5.78723 10.5C5.19689 10.5 4.71202 10.3262 4.2727 9.94049L0.424884 6.76951C0.144143 6.52616 0 6.28062 0 6.01112C0 5.46922 0.634332 5.02971 1.43134 5.02971C1.92908 5.02971 2.3056 5.14272 2.70945 5.49373L5.73578 8.09685L12.2284 1.05441C12.5691 0.691254 13.0141 0.5 13.4957 0.5C14.28 0.5 15 0.872722 15 1.43011C15 1.67561 14.81 1.93559 14.5905 2.18003L7.24474 9.93286C6.88391 10.3049 6.37476 10.5 5.78723 10.5Z" fill="#0D0ED9"/>
</svg>)}
    </div> */}

                    <div
                      className={`buttonxxlarge-parent ${
                        selectedData.selectedColleges.includes(category.name)
                          ? "active"
                          : ""
                      }`}
                      key={category.value}
                      onClick={() =>
                        handleItemClick("selectedColleges", category.name)
                      }
                    >
                      <b className="morefiltersboxheader">{category.name}</b>

                      {selectedData.selectedColleges.includes(
                        category.name
                      ) && (
                        <svg
                          className="morefiltersdropdownitembox1lis-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="11"
                          viewBox="0 0 15 11"
                          fill="none"
                        >
                          <path
                            d="M5.78723 10.5C5.19689 10.5 4.71202 10.3262 4.2727 9.94049L0.424884 6.76951C0.144143 6.52616 0 6.28062 0 6.01112C0 5.46922 0.634332 5.02971 1.43134 5.02971C1.92908 5.02971 2.3056 5.14272 2.70945 5.49373L5.73578 8.09685L12.2284 1.05441C12.5691 0.691254 13.0141 0.5 13.4957 0.5C14.28 0.5 15 0.872722 15 1.43011C15 1.67561 14.81 1.93559 14.5905 2.18003L7.24474 9.93286C6.88391 10.3049 6.37476 10.5 5.78723 10.5Z"
                            fill="#0D0ED9"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div
          className="morefiltersdropdownbox"
          onClick={() => setCompaniesDropdown(!companiesDropdown)}
        >
          <div className="buttonxxlarge-parent">
            <b className="morefiltersboxheader">
              {selectedData.selectedCompanies.length === 1
                ? selectedData.selectedCompanies[0]
                : selectedData.selectedCompanies.length > 1 &&
                  `Company . ${selectedData.selectedCompanies.length}`}
              {selectedData.selectedCompanies.length === 0 && "Company"}
            </b>
            {/* <img
              className="morefiltersdropdownboxicon"
              alt=""
              src="/vector5.svg"
            /> */}
            {companiesDropdown ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
              >
                <path
                  d="M8 0.5C8.19133 0.5 8.37644 0.538169 8.55534 0.614505C8.73519 0.690841 8.8849 0.792622 9.00448 0.919849L15.6054 7.94275C15.8685 8.22265 16 8.57888 16 9.01145C16 9.44402 15.8685 9.80026 15.6054 10.0802C15.3423 10.3601 15.0075 10.5 14.6009 10.5C14.1943 10.5 13.8595 10.3601 13.5964 10.0802L8 4.12595L2.40359 10.0802C2.14051 10.3601 1.80568 10.5 1.3991 10.5C0.992527 10.5 0.6577 10.3601 0.394621 10.0802C0.131541 9.80026 0 9.44402 0 9.01145C0 8.57888 0.131541 8.22265 0.394621 7.94275L6.99552 0.919849C7.13901 0.767177 7.29447 0.659289 7.46188 0.596184C7.6293 0.532063 7.80867 0.5 8 0.5Z"
                  fill="#79797D"
                />
              </svg>
            ) : (
              <svg
                className="morefiltersdropdownboxicon"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
              >
                <path
                  d="M8 10.5C7.80867 10.5 7.62356 10.4618 7.44466 10.3855C7.26481 10.3092 7.1151 10.2074 6.99552 10.0802L0.394618 3.05725C0.131539 2.77735 0 2.42112 0 1.98855C0 1.55598 0.131539 1.19974 0.394618 0.919847C0.657698 0.639949 0.992526 0.5 1.3991 0.5C1.80568 0.5 2.14051 0.639949 2.40359 0.919847L8 6.87405L13.5964 0.919847C13.8595 0.639949 14.1943 0.5 14.6009 0.5C15.0075 0.5 15.3423 0.639949 15.6054 0.919847C15.8685 1.19974 16 1.55598 16 1.98855C16 2.42112 15.8685 2.77735 15.6054 3.05725L9.00448 10.0802C8.86099 10.2328 8.70553 10.3407 8.53812 10.4038C8.3707 10.4679 8.19133 10.5 8 10.5Z"
                  fill="#79797D"
                />
              </svg>
            )}
          </div>
        </div>

        {companiesDropdown && (
          <div className="morefiltersdropdownitembox">
            <input
              className="morefiltersdropdownitemsearch"
              placeholder="Search Company"
              type="text"
              value={companySearchTerm}
              onChange={handleCompanyInputChange}
            />
            <div className="morefiltersdropdownitembox1-wrapper">
              <div className="morefiltersdropdownitembox1">
                {filteredCompanies.map((category) => (
                  <div className="morefiltersdropdownitembox1lis">
                    {/* <div
          className={`buttonxxlarge-parent ${selectedCompanies.includes(category.name) ? 'active' : ''}`}
          key={category.value}
          onClick={() => handleItemClick(category.name, setSelectedCompanies, selectedCompanies)}
        >
      <b className="morefiltersboxheader">{category.name}</b>
     
      {selectedCompanies.includes(category.name) && (
           
      <svg className="morefiltersdropdownitembox1lis-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
  <path d="M5.78723 10.5C5.19689 10.5 4.71202 10.3262 4.2727 9.94049L0.424884 6.76951C0.144143 6.52616 0 6.28062 0 6.01112C0 5.46922 0.634332 5.02971 1.43134 5.02971C1.92908 5.02971 2.3056 5.14272 2.70945 5.49373L5.73578 8.09685L12.2284 1.05441C12.5691 0.691254 13.0141 0.5 13.4957 0.5C14.28 0.5 15 0.872722 15 1.43011C15 1.67561 14.81 1.93559 14.5905 2.18003L7.24474 9.93286C6.88391 10.3049 6.37476 10.5 5.78723 10.5Z" fill="#0D0ED9"/>
</svg>)}
    </div> */}

                    <div
                      className={`buttonxxlarge-parent ${
                        selectedData.selectedCompanies.includes(category.name)
                          ? "active"
                          : ""
                      }`}
                      key={category.value}
                      onClick={() =>
                        handleItemClick("selectedCompanies", category.name)
                      }
                    >
                      <b className="morefiltersboxheader">{category.name}</b>

                      {selectedData.selectedCompanies.includes(
                        category.name
                      ) && (
                        <svg
                          className="morefiltersdropdownitembox1lis-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="11"
                          viewBox="0 0 15 11"
                          fill="none"
                        >
                          <path
                            d="M5.78723 10.5C5.19689 10.5 4.71202 10.3262 4.2727 9.94049L0.424884 6.76951C0.144143 6.52616 0 6.28062 0 6.01112C0 5.46922 0.634332 5.02971 1.43134 5.02971C1.92908 5.02971 2.3056 5.14272 2.70945 5.49373L5.73578 8.09685L12.2284 1.05441C12.5691 0.691254 13.0141 0.5 13.4957 0.5C14.28 0.5 15 0.872722 15 1.43011C15 1.67561 14.81 1.93559 14.5905 2.18003L7.24474 9.93286C6.88391 10.3049 6.37476 10.5 5.78723 10.5Z"
                            fill="#0D0ED9"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div
          className="morefiltersdropdownbox"
          onClick={() => setOrganizationDropdown(!organizationDropdown)}
        >
          <div className="buttonxxlarge-parent">
            <b className="morefiltersboxheader">
              {selectedData.selectedOrganizations.length === 1
                ? selectedData.selectedOrganizations[0]
                : selectedData.selectedOrganizations.length > 1 &&
                  `Organization . ${selectedData.selectedOrganizations.length}`}
              {selectedData.selectedOrganizations.length === 0 &&
                "Organization"}
            </b>
            {/* <img
              className="morefiltersdropdownboxicon"
              alt=""
              src="/vector5.svg"
            /> */}
            {organizationDropdown ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
              >
                <path
                  d="M8 0.5C8.19133 0.5 8.37644 0.538169 8.55534 0.614505C8.73519 0.690841 8.8849 0.792622 9.00448 0.919849L15.6054 7.94275C15.8685 8.22265 16 8.57888 16 9.01145C16 9.44402 15.8685 9.80026 15.6054 10.0802C15.3423 10.3601 15.0075 10.5 14.6009 10.5C14.1943 10.5 13.8595 10.3601 13.5964 10.0802L8 4.12595L2.40359 10.0802C2.14051 10.3601 1.80568 10.5 1.3991 10.5C0.992527 10.5 0.6577 10.3601 0.394621 10.0802C0.131541 9.80026 0 9.44402 0 9.01145C0 8.57888 0.131541 8.22265 0.394621 7.94275L6.99552 0.919849C7.13901 0.767177 7.29447 0.659289 7.46188 0.596184C7.6293 0.532063 7.80867 0.5 8 0.5Z"
                  fill="#79797D"
                />
              </svg>
            ) : (
              <svg
                className="morefiltersdropdownboxicon"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
              >
                <path
                  d="M8 10.5C7.80867 10.5 7.62356 10.4618 7.44466 10.3855C7.26481 10.3092 7.1151 10.2074 6.99552 10.0802L0.394618 3.05725C0.131539 2.77735 0 2.42112 0 1.98855C0 1.55598 0.131539 1.19974 0.394618 0.919847C0.657698 0.639949 0.992526 0.5 1.3991 0.5C1.80568 0.5 2.14051 0.639949 2.40359 0.919847L8 6.87405L13.5964 0.919847C13.8595 0.639949 14.1943 0.5 14.6009 0.5C15.0075 0.5 15.3423 0.639949 15.6054 0.919847C15.8685 1.19974 16 1.55598 16 1.98855C16 2.42112 15.8685 2.77735 15.6054 3.05725L9.00448 10.0802C8.86099 10.2328 8.70553 10.3407 8.53812 10.4038C8.3707 10.4679 8.19133 10.5 8 10.5Z"
                  fill="#79797D"
                />
              </svg>
            )}
          </div>
        </div>

        {organizationDropdown && (
          <div className="morefiltersdropdownitembox">
            <input
              className="morefiltersdropdownitemsearch"
              placeholder="Search Organizations"
              type="text"
              value={orgSearchTerm}
              onChange={handleOrgInputChange}
            />
            <div className="morefiltersdropdownitembox1-wrapper">
              <div className="morefiltersdropdownitembox1">
                {filteredOrg.map((category) => (
                  <div className="morefiltersdropdownitembox1lis">
                    {/* <div
          className={`buttonxxlarge-parent ${selectedOrganizations.includes(category.name) ? 'active' : ''}`}
          key={category.value}
          onClick={() => handleItemClick(category.name, setSelectedOrganizations, selectedOrganizations)}
        >
      <b className="morefiltersboxheader">{category.name}</b>
     
      {selectedOrganizations.includes(category.name) && (
           
      <svg className="morefiltersdropdownitembox1lis-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
  <path d="M5.78723 10.5C5.19689 10.5 4.71202 10.3262 4.2727 9.94049L0.424884 6.76951C0.144143 6.52616 0 6.28062 0 6.01112C0 5.46922 0.634332 5.02971 1.43134 5.02971C1.92908 5.02971 2.3056 5.14272 2.70945 5.49373L5.73578 8.09685L12.2284 1.05441C12.5691 0.691254 13.0141 0.5 13.4957 0.5C14.28 0.5 15 0.872722 15 1.43011C15 1.67561 14.81 1.93559 14.5905 2.18003L7.24474 9.93286C6.88391 10.3049 6.37476 10.5 5.78723 10.5Z" fill="#0D0ED9"/>
</svg>)}
    </div> */}

                    <div
                      className={`buttonxxlarge-parent ${
                        selectedData.selectedOrganizations.includes(
                          category.name
                        )
                          ? "active"
                          : ""
                      }`}
                      key={category.value}
                      onClick={() =>
                        handleItemClick("selectedOrganizations", category.name)
                      }
                    >
                      <b className="morefiltersboxheader">{category.name}</b>

                      {selectedData.selectedOrganizations.includes(
                        category.name
                      ) && (
                        <svg
                          className="morefiltersdropdownitembox1lis-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="11"
                          viewBox="0 0 15 11"
                          fill="none"
                        >
                          <path
                            d="M5.78723 10.5C5.19689 10.5 4.71202 10.3262 4.2727 9.94049L0.424884 6.76951C0.144143 6.52616 0 6.28062 0 6.01112C0 5.46922 0.634332 5.02971 1.43134 5.02971C1.92908 5.02971 2.3056 5.14272 2.70945 5.49373L5.73578 8.09685L12.2284 1.05441C12.5691 0.691254 13.0141 0.5 13.4957 0.5C14.28 0.5 15 0.872722 15 1.43011C15 1.67561 14.81 1.93559 14.5905 2.18003L7.24474 9.93286C6.88391 10.3049 6.37476 10.5 5.78723 10.5Z"
                            fill="#0D0ED9"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div
          className="morefiltersdropdownbox"
          onClick={() => setSportsDropdown(!sportsDropdown)}
        >
          <div className="buttonxxlarge-parent">
            <b className="morefiltersboxheader">
              {selectedData.selectedSports.length === 1
                ? selectedData.selectedSports[0]
                : selectedData.selectedSports.length > 1 &&
                  `Sport . ${selectedData.selectedSports.length}`}
              {selectedData.selectedSports.length === 0 && "Sport"}
            </b>
            {/* <img
              className="morefiltersdropdownboxicon"
              alt=""
              src="/vector5.svg"
            /> */}
            {sportsDropdown ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
              >
                <path
                  d="M8 0.5C8.19133 0.5 8.37644 0.538169 8.55534 0.614505C8.73519 0.690841 8.8849 0.792622 9.00448 0.919849L15.6054 7.94275C15.8685 8.22265 16 8.57888 16 9.01145C16 9.44402 15.8685 9.80026 15.6054 10.0802C15.3423 10.3601 15.0075 10.5 14.6009 10.5C14.1943 10.5 13.8595 10.3601 13.5964 10.0802L8 4.12595L2.40359 10.0802C2.14051 10.3601 1.80568 10.5 1.3991 10.5C0.992527 10.5 0.6577 10.3601 0.394621 10.0802C0.131541 9.80026 0 9.44402 0 9.01145C0 8.57888 0.131541 8.22265 0.394621 7.94275L6.99552 0.919849C7.13901 0.767177 7.29447 0.659289 7.46188 0.596184C7.6293 0.532063 7.80867 0.5 8 0.5Z"
                  fill="#79797D"
                />
              </svg>
            ) : (
              <svg
                className="morefiltersdropdownboxicon"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
              >
                <path
                  d="M8 10.5C7.80867 10.5 7.62356 10.4618 7.44466 10.3855C7.26481 10.3092 7.1151 10.2074 6.99552 10.0802L0.394618 3.05725C0.131539 2.77735 0 2.42112 0 1.98855C0 1.55598 0.131539 1.19974 0.394618 0.919847C0.657698 0.639949 0.992526 0.5 1.3991 0.5C1.80568 0.5 2.14051 0.639949 2.40359 0.919847L8 6.87405L13.5964 0.919847C13.8595 0.639949 14.1943 0.5 14.6009 0.5C15.0075 0.5 15.3423 0.639949 15.6054 0.919847C15.8685 1.19974 16 1.55598 16 1.98855C16 2.42112 15.8685 2.77735 15.6054 3.05725L9.00448 10.0802C8.86099 10.2328 8.70553 10.3407 8.53812 10.4038C8.3707 10.4679 8.19133 10.5 8 10.5Z"
                  fill="#79797D"
                />
              </svg>
            )}
          </div>
        </div>

        {sportsDropdown && (
          <div className="morefiltersdropdownitembox">
            {/* <input
            className="morefiltersdropdownitemsearch"
            placeholder="Select Race"
            type="text"
          /> */}
            <div className="morefiltersdropdownitembox1-wrapper">
              <div className="morefiltersdropdownitembox1">
                {Sports.map((category) => (
                  <div className="morefiltersdropdownitembox1lis">
                    {/* <div
          className={`buttonxxlarge-parent ${selectedSports.includes(category.name) ? 'active' : ''}`}
          key={category.value}
          onClick={() => handleItemClick(category.name, setSelectedSports, selectedSports)}
        >
      <b className="morefiltersboxheader">{category.name}</b>
     
      {selectedSports.includes(category.name) && (
           
      <svg className="morefiltersdropdownitembox1lis-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
  <path d="M5.78723 10.5C5.19689 10.5 4.71202 10.3262 4.2727 9.94049L0.424884 6.76951C0.144143 6.52616 0 6.28062 0 6.01112C0 5.46922 0.634332 5.02971 1.43134 5.02971C1.92908 5.02971 2.3056 5.14272 2.70945 5.49373L5.73578 8.09685L12.2284 1.05441C12.5691 0.691254 13.0141 0.5 13.4957 0.5C14.28 0.5 15 0.872722 15 1.43011C15 1.67561 14.81 1.93559 14.5905 2.18003L7.24474 9.93286C6.88391 10.3049 6.37476 10.5 5.78723 10.5Z" fill="#0D0ED9"/>
</svg>)}
    </div> */}

                    <div
                      className={`buttonxxlarge-parent ${
                        selectedData.selectedSports.includes(category.name)
                          ? "active"
                          : ""
                      }`}
                      key={category.value}
                      onClick={() =>
                        handleItemClick("selectedSports", category.name)
                      }
                    >
                      <b className="morefiltersboxheader">{category.name}</b>

                      {selectedData.selectedSports.includes(category.name) && (
                        <svg
                          className="morefiltersdropdownitembox1lis-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="11"
                          viewBox="0 0 15 11"
                          fill="none"
                        >
                          <path
                            d="M5.78723 10.5C5.19689 10.5 4.71202 10.3262 4.2727 9.94049L0.424884 6.76951C0.144143 6.52616 0 6.28062 0 6.01112C0 5.46922 0.634332 5.02971 1.43134 5.02971C1.92908 5.02971 2.3056 5.14272 2.70945 5.49373L5.73578 8.09685L12.2284 1.05441C12.5691 0.691254 13.0141 0.5 13.4957 0.5C14.28 0.5 15 0.872722 15 1.43011C15 1.67561 14.81 1.93559 14.5905 2.18003L7.24474 9.93286C6.88391 10.3049 6.37476 10.5 5.78723 10.5Z"
                            fill="#0D0ED9"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="morefiltersbox1-inner1">
          <div className="buttonxxlarge-parent">
            <button
              className="buttonxxlarge"
              onClick={() => {
                handleApplyFilters();
                onClose();
              }}
            >
              <b className="show-results">Show Results</b>
            </button>
            <button
              className="button-text2"
              onClick={() => {
                handleClearFilters();
                onClose();
              }}
            >
              Clear all
            </button>
          </div>
        </div>
        <div className="morefiltersbox1-inner2">
          <div className="gpa-wrapper">
            {/* <b className="morefiltersboxheader">6000 matches found</b> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreFilters;
