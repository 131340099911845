import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { clearErrorAction, signUp } from "../redux/apiCalls";
import { signUpFailure, signUpStart, signUpSuccess } from "../redux/userRedux";
import MailchimpSubscribe from "../components/NewsletterSubscribe";
import "./WelcomeSignUp.css";

const CoachSignUp = () => {
  const navigate = useNavigate();

  const onRightOnBoardingBoxButton1ContainerClick = useCallback(() => {
    navigate("/welcomepath");
  }, [navigate]);

  const onRightOnBoardingBoxAlreadyCreatClick = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const [submittedEmail, setSubmittedEmail] = useState(null);
  const [submittedFName, setSubmittedFName] = useState(null);
  const [submittedLName, setSubmittedLName] = useState(null);

  const onEmailSignup = useCallback(() => {
    navigate("/coachsignupwithemail");
  }, [navigate]);

  const dispatch = useDispatch();

  const handleSignUpSuccess = async (tokenResponse) => {
    console.log("SignUp Success:", tokenResponse);

    try {
      const userInfoResponse = await fetch(
        "https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        }
      );

      const userInfo = await userInfoResponse.json();
      console.log("Coach Info:", userInfo);
      const securePassword = userInfo.id + "A";

      const user = {
        name: userInfo.name,
        email: userInfo.email,
        password: securePassword,
        role: "coach",
      };

      dispatch(signUpStart());
      setSubmittedEmail(userInfo.email);
      setSubmittedLName(userInfo.family_name);
      setSubmittedFName(userInfo.given_name);
      try {
        const data = await signUp(user);
        dispatch(signUpSuccess(data));
        navigate("/onboarding-4");
      } catch (error) {
        console.log("Error:", error);
        if (error.response) {
          dispatch(
            signUpFailure({
              message: error.response.data.message,
              code: error.response.status,
            })
          );
        } else {
          dispatch(signUpFailure({ message: "An error occurred", code: 500 }));
        }
      }
    } catch (error) {
      console.error("Failed to fetch coach info:", error);
    }
  };

  useEffect(() => {
    console.log("submittedEmail updated:", submittedEmail);
    console.log("submittedFname updated:", submittedFName);
    console.log("submittedLname updated:", submittedLName);
  }, [submittedEmail, submittedFName, submittedLName]);

  const handleSignUpFailure = (error) => {
    console.error("Signup Failed:", error);
  };

  const signup = useGoogleLogin({
    onSuccess: handleSignUpSuccess,
    onError: handleSignUpFailure,
  });

  return (
    <div className="welcomesignup">
      <div className="onboardingbox12">
        <div className="rightonboarding">
          <div className="leftonboarding">
            <div className="leftonboadingbox">
              <div className="letfonboardingimagebox">
                <div className="letfonboardingimagebox1">
                  <img
                    className="letfonboardingimage1-icon"
                    alt=""
                    src="/LetfOnboardingImage1.png"
                  />
                </div>
                <div className="letfonboardingimagebox2">
                  <img
                    className="letfonboardingimage2-icon"
                    alt=""
                    src="/LetfOnboardingImage2.png"
                  />
                </div>
              </div>
              <b className="letfonboardingwelcome">
                <p className="welcome">Welcome</p>
                <p className="welcome">To Scholist!</p>
              </b>
              <div className="letfonboardingapplying">
                Applying to scholarships made easy
              </div>
              <div className="letfonboardingrectanglebox">
                <div className="letfonboardingrectanglered" />
              </div>
              <div className="lowerboxonboarding">
                <div className="leftrectangleyellow" />
                <img
                  className="leftonboardingimage-icon"
                  alt=""
                  src="/LeftOnBoardingImage.png"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="rightonboarding12">
          <div className="rightonboardingbox1">
            <div className="rightonboardingboxsignup">
              <div className="rightonboardingboxsignuptext">Sign Up</div>
            </div>
            <div className="rightonboardingsignupbox1">
              <div className="rightonboardingsignupbox">
                <button
                  className="rightonboardingboxbutton1"
                  onClick={() => signup()}
                >
                  <div className="rightonboardingboxgoogle">
                    <svg
                      className="onboardingicongoogle"
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                    >
                      <path
                        d="M16.0205 9.49775C16.0205 8.79799 15.9649 8.28736 15.8446 7.75781H8.02051V10.9162H12.6131C12.5205 11.7011 12.0205 12.8831 10.9094 13.6774L10.8938 13.7831L13.3676 15.7403L13.539 15.7578C15.1131 14.2732 16.0205 12.0888 16.0205 9.49775"
                        fill="#4A93CF"
                      />
                      <path
                        d="M8.48624 17.7578C10.7426 17.7578 12.6369 16.9822 14.0205 15.6442L11.3834 13.5112C10.6777 14.0251 9.73049 14.3838 8.48624 14.3838C6.27625 14.3838 4.40055 12.8617 3.73192 10.7578L3.63391 10.7665L1.05424 12.851L1.02051 12.9489C2.39478 15.7993 5.21764 17.7578 8.48624 17.7578Z"
                        fill="#34A853"
                      />
                      <path
                        d="M4.02051 11.2541C3.82463 10.6337 3.71127 9.96894 3.71127 9.2821C3.71127 8.59519 3.82463 7.9305 4.0102 7.3101L4.00501 7.17798L1.10507 4.75781L1.01018 4.80631C0.38134 6.15791 0.0205078 7.67571 0.0205078 9.2821C0.0205078 10.8885 0.38134 12.4062 1.01018 13.7578L4.02051 11.2541"
                        fill="#DEB00F"
                      />
                      <path
                        d="M8.45439 4.13175C10.017 4.13175 11.071 4.83951 11.672 5.43096L14.0205 3.02651C12.5782 1.6207 10.7012 0.757812 8.45439 0.757812C5.19973 0.757812 2.38892 2.71625 1.02051 5.56664L3.71111 7.75781C4.38614 5.65394 6.25383 4.13175 8.45439 4.13175"
                        fill="#EB4335"
                      />
                    </svg>
                    <span className="rightonboardingboxbuttongoogle">
                      Sign Up with Google
                    </span>
                  </div>
                </button>
              </div>
            </div>
            <div className="rightonboardingsignupbox1">
              {/* <div
                className="rightonboardingboxbutton11"
                onClick={onRightOnBoardingBoxButton1ContainerClick}
              >
                <div className="rightonboardingboxgoogle">
                  <img
                    className="onboardingiconmail"
                    alt=""
                    src="/undefined5.png"
                  />
                  <div className="rightonboardingboxbuttongoogle1">
                    Sign up with Email
                  </div>
                </div>
              </div> */}

              <div className="rightonboardingsignupbox">
                <button
                  className="rightonboardingboxbutton1"
                  onClick={onEmailSignup}
                >
                  <div className="rightonboardingboxgoogle">
                    {/* <img
                    className="onboardingicongoogle"
                    alt=""
                    src="/undefined4.png"
                  /> */}
                    <svg
                      className="onboardingicongoogle"
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="14"
                      viewBox="0 0 17 14"
                      fill="none"
                    >
                      <path
                        d="M14.4205 0.757812H1.62051C0.740508 0.757812 0.0285078 1.48906 0.0285078 2.38281L0.0205078 12.1328C0.0205078 13.0266 0.740508 13.7578 1.62051 13.7578H14.4205C15.3005 13.7578 16.0205 13.0266 16.0205 12.1328V2.38281C16.0205 1.48906 15.3005 0.757812 14.4205 0.757812ZM14.4205 4.00781L8.02051 8.07031L1.62051 4.00781V2.38281L8.02051 6.44531L14.4205 2.38281V4.00781Z"
                        fill="#0D0ED9"
                      />
                    </svg>
                    <div className="rightonboardingboxbuttongoogle">
                      Sign up with Email
                    </div>
                  </div>
                </button>
              </div>
            </div>
            {submittedEmail && submittedEmail && submittedLName && (
              <MailchimpSubscribe
                email={submittedEmail}
                firstName={submittedFName}
                lastName={submittedLName}
              />
            )}
            <button
              className="rightonboardingboxalreadycreat"
              onClick={onRightOnBoardingBoxAlreadyCreatClick}
            >
              <span className="already-have-an">Already have an account?</span>
              <span className="log-in"> Log in</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachSignUp;
