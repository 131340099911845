import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ScholarshipFilters from "../components/ScholarshipFilters";
import PortalDrawer from "../components/PortalDrawer";
import "./Popular.css";
const Popular = () => {
  const navigate = useNavigate();
  const [isScholarshipFiltersOpen, setScholarshipFiltersOpen] = useState(false);

  const onHeadernavLinkContainer1Click = useCallback(() => {
    navigate("/scholarships-category-page");
  }, [navigate]);

  const onHeadernavLinkContainer2Click = useCallback(() => {
    navigate("/services");
  }, [navigate]);

  const onHeadernavLinkContainer3Click = useCallback(() => {
    navigate("/communities");
  }, [navigate]);

  const onPageTitleContainerClick = useCallback(() => {
    navigate("/scholarships-category-page");
  }, [navigate]);

  const openScholarshipFilters = useCallback(() => {
    setScholarshipFiltersOpen(true);
  }, []);

  const closeScholarshipFilters = useCallback(() => {
    setScholarshipFiltersOpen(false);
  }, []);

  return (
    <>
      <div className="popular1">
        {/* <div className="navbar8">
          <div className="info8">
            <div className="left-side-top-nav9">
              <img className="headerlogo-icon9" alt="" src="/headerlogo.svg" />
              <div className="top-nav-left9">
                <div className="headernav-link45">
                  <div className="nav-link-default36">Home</div>
                </div>
                <div
                  className="headernav-link46"
                  onClick={onHeadernavLinkContainer1Click}
                >
                  <div className="nav-link-default36">Scholarships</div>
                </div>
                <div
                  className="headernav-link47"
                  onClick={onHeadernavLinkContainer2Click}
                >
                  <div className="nav-link-default36">Services</div>
                </div>
                <div
                  className="headernav-link47"
                  onClick={onHeadernavLinkContainer3Click}
                >
                  <div className="nav-link-default36">{`Community `}</div>
                </div>
                <div className="headernav-link49">
                  <div className="nav-link-default36">Resources</div>
                </div>
              </div>
            </div>
            <div className="right-side-top-nav9">
              <button className="menu9">
                <img className="vector-icon63" alt="" />
                <img className="vector-icon64" alt="" src="/vector.svg" />
              </button>
              <div className="messages-parent6">
                <img className="messages-icon8" alt="" src="/messages.svg" />
                <img
                  className="notifications-icon8"
                  alt=""
                  src="/notifications1.svg"
                />
                <div className="mask-group-parent6">
                  <img
                    className="mask-group-icon8"
                    alt=""
                    src="/mask-group4@2x.png"
                  />
                  <img className="vector-icon65" alt="" src="/vector1.svg" />
                </div>
              </div>
            </div>
          </div>
          <img
            className="scholarships-line-icon8"
            alt=""
            src="/scholarships-line.svg"
          />
          <img
            className="services-line-icon8"
            alt=""
            src="/services-line.svg"
          />
          <img
            className="community-line-icon8"
            alt=""
            src="/community-line.svg"
          />
          <img className="resources-icon8" alt="" src="/resources.svg" />
        </div> */}
        <div className="search6">
          <div className="header9">
            <div className="headertop-navprimarybreadcr5">
              <div className="page-title24" onClick={onPageTitleContainerClick}>
                <div className="page-title25">Scholarships</div>
                <img className="underline-icon15" alt="" src="/underline.svg" />
              </div>
              <img className="arrow-icon11" alt="" src="/arrow.svg" />
              <div className="page-title26">
                <div className="popular-scholarships">Popular Scholarships</div>
                <img className="underline-icon16" alt="" />
              </div>
              <img className="arrow-icon12" alt="" src="/arrow1.svg" />
              <div className="page-title27">
                <div className="page-title25">Page Title</div>
                <img className="underline-icon16" alt="" />
              </div>
            </div>
            <div className="allpage-header6">
              <b className="popular-scholarships1">Popular Scholarships</b>
            </div>
          </div>
          <div className="search-and-filter4">
            <input className="search-bar4" type="text" placeholder="Search" />
            <div className="filter4">
              <div className="filters-wrapper4">
                <div className="filter-button24">
                  <div className="button-text64">Category</div>
                  <div className="circle-124">
                    <img className="vector-icon66" alt="" src="/vector2.svg" />
                    <div className="div51">1</div>
                  </div>
                  <div className="arrows25">
                    <img
                      className="chevrondown-icon24"
                      alt=""
                      src="/chevrondown.svg"
                    />
                  </div>
                </div>
                <div className="filter-button25">
                  <div className="circle-125">
                    <img className="vector-icon66" alt="" src="/vector3.svg" />
                    <div className="div51">1</div>
                  </div>
                  <div className="button-text-parent10">
                    <div className="button-text65">Award Amount</div>
                    <div className="arrows25">
                      <img
                        className="chevrondown-icon24"
                        alt=""
                        src="/chevrondown1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className="filter-button26">
                  <div className="circle-125">
                    <img className="vector-icon66" alt="" src="/vector4.svg" />
                    <div className="div51">1</div>
                  </div>
                  <div className="button-text-parent11">
                    <div className="button-text64">Location</div>
                    <div className="arrows25">
                      <img
                        className="chevrondown-icon24"
                        alt=""
                        src="/chevrondown2.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className="filter-button27">
                  <div className="circle-125">
                    <img className="vector-icon66" alt="" src="/vector5.svg" />
                    <div className="div51">1</div>
                  </div>
                  <div className="button-text-parent11">
                    <div className="button-text64">Major</div>
                    <div className="arrows25">
                      <img
                        className="chevrondown-icon24"
                        alt=""
                        src="/chevrondown3.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className="filter-button28">
                  <div className="button-text64">Grade Level</div>
                  <div className="circle-124">
                    <img className="vector-icon66" alt="" src="/vector6.svg" />
                    <div className="div51">1</div>
                  </div>
                  <div className="arrows25">
                    <img
                      className="chevrondown-icon24"
                      alt=""
                      src="/chevrondown4.svg"
                    />
                  </div>
                </div>
                <div className="filter-button29">
                  <div className="button-text64">Citizenship</div>
                  <div className="circle-124">
                    <img className="vector-icon66" alt="" src="/vector7.svg" />
                    <div className="div51">1</div>
                  </div>
                  <div className="arrows25">
                    <img
                      className="chevrondown-icon24"
                      alt=""
                      src="/chevrondown5.svg"
                    />
                  </div>
                </div>
                <div className="ellipse-button4">
                  <img className="vector-icon72" alt="" src="/vector8.svg" />
                </div>
              </div>
            </div>
            <div className="search-and-filter-inner3">
              <div className="frame-parent9">
                <div
                  className="frame-wrapper58"
                  onClick={openScholarshipFilters}
                >
                  <div className="tune-parent2">
                    <img className="tune-icon4" alt="" src="/tune@2x.png" />
                    <div className="filters4">Filters</div>
                  </div>
                </div>
                <div className="frame-wrapper59">
                  <div className="tune-parent2">
                    <img className="vector-icon73" alt="" src="/vector9.svg" />
                    <div className="filters4">Sort</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="available4">
          <div className="body9">
            <div className="scholarships-available4">
              785 scholarships available
            </div>
            <div className="filter-dropdown-button4">
              <div className="filter-dropdown-button-inner2">
                <div className="sort-by-parent2">
                  <div className="sort-by4">{`Sort by `}</div>
                  <select className="relevance-parent1">
                    <option value="relevance">Relevance</option>
                    <option value="a-z">A-Z</option>
                    <option value="deadline">Deadline</option>
                    <option value="newest">Newest</option>
                    <option value="awardamount">Award Amount</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="availble4">
          <div className="see-more-progress-bar9">
            <div className="scholarship-available4">
              785 Scholarship Available
            </div>
          </div>
        </div>
        <div className="scholarship-body4">
          <div className="row-2-wrapper6">
            <div className="card36">
              <div className="cardscholarshipsbackgroundco36">
                <img
                  className="cardscholarshipsimage-icon36"
                  alt=""
                  src="/cardscholarshipsimage@2x.png"
                />
              </div>
              <b className="title36">“Be Bold” No-Essay Scholarship</b>
              <div className="organization36">Bold.org</div>
              <div className="award-amount37">$25,000</div>
              <div className="deadline35">Deadline: 04/30/2023</div>
              <img
                className="component-2-icon36"
                alt=""
                src="/component-21.svg"
              />
            </div>
            <div className="card36">
              <div className="cardscholarshipsbackgroundco37">
                <img
                  className="cardscholarshipsimage-icon36"
                  alt=""
                  src="/cardscholarshipsimage1@2x.png"
                />
              </div>
              <b className="title36">$2,000 Nitro Scholarship Sweepstakes</b>
              <div className="organization36">Nitro</div>
              <div className="award-amount37">$2,000</div>
              <div className="deadline35">Deadline: 12/31/2023</div>
              <img
                className="component-2-icon36"
                alt=""
                src="/component-21.svg"
              />
            </div>
            <div className="card36">
              <div className="cardscholarshipsbackgroundco38">
                <img
                  className="cardscholarshipsimage-icon38"
                  alt=""
                  src="/cardscholarshipsimage2@2x.png"
                />
              </div>
              <b className="title36">
                $50,000 ScholarshipOwl No Essay Scholarship
              </b>
              <div className="organization36">ScholarshipOwl</div>
              <div className="award-amount37">$50,000</div>
              <div className="deadline35">Deadline: 06/17/2023</div>
              <img
                className="component-2-icon36"
                alt=""
                src="/component-21.svg"
              />
            </div>
            <div className="card36">
              <div className="cardscholarshipsbackgroundco39">
                <img
                  className="cardscholarshipsimage-icon38"
                  alt=""
                  src="/cardscholarshipsimage3@2x.png"
                />
              </div>
              <b className="title36">Coca-Cola Scholars Scholarship</b>
              <div className="organization36">Coca-Cola</div>
              <div className="award-amount37">$20,000</div>
              <div className="deadline35">Deadline: 10/18/2023</div>
              <img
                className="component-2-icon36"
                alt=""
                src="/component-21.svg"
              />
            </div>
          </div>
        </div>
        <div className="see-more6">
          <div className="see-more-progress-bar10">
            <div className="div57">24 / 785</div>
            <div className="buttonlarge28">
              <div className="button36" />
              <div className="button-text70">See More</div>
            </div>
            <img
              className="progress-bar-icon5"
              alt=""
              src="/progress-bar.svg"
            />
          </div>
        </div>
        <div className="footer9">
          <div className="footer-grid-row9">
            <div className="footer-menu45">
              <img className="footerlogo-icon9" alt="" src="/footerlogo1.svg" />
              <div className="footer-links45">
                <div className="footerlinknav27">
                  <div className="nav-link27">info@example.com</div>
                </div>
                <div className="footerlinknav27">
                  <div className="nav-link27">+1 234 567 9830</div>
                </div>
                <div className="footerlinknav27">
                  <div className="nav-link27">Address</div>
                </div>
              </div>
              <div className="icon-wrapper9">
                <img
                  className="footericoninstagram9"
                  alt=""
                  src="/footericoninstagram.svg"
                />
                <img
                  className="footericonfacbook9"
                  alt=""
                  src="/footericonfacbook.svg"
                />
                <img
                  className="footericontwitter9"
                  alt=""
                  src="/footericontwitter1.svg"
                />
                <img
                  className="footericonfacbook9"
                  alt=""
                  src="/footericonlinkedin.svg"
                />
                <img
                  className="footericonyoutube9"
                  alt=""
                  src="/footericonyoutube2.svg"
                />
              </div>
            </div>
            <div className="footer-menu-parent7">
              <div className="footer-menu46">
                <div className="students9">Students</div>
                <div className="footer-links45">
                  <div className="main-footer-link117">
                    <div className="footer-link117">How it works</div>
                  </div>
                  <div className="main-footer-link117">
                    <div className="footer-link117">Scholarships</div>
                  </div>
                  <div className="main-footer-link117">
                    <div className="footer-link117">Community</div>
                  </div>
                  <div className="main-footer-link117">
                    <div className="footer-link117">Services</div>
                  </div>
                </div>
              </div>
              <div className="footer-menu46">
                <div className="students9">Coaches</div>
                <div className="footer-links45">
                  <div className="main-footer-link117">
                    <div className="footer-link117">How it works</div>
                  </div>
                  <div className="main-footer-link117">
                    <div className="footer-link117">Blog</div>
                  </div>
                  <div className="main-footer-link117">
                    <div className="footer-link117">Mission</div>
                  </div>
                  <div className="main-footer-link117">
                    <div className="footer-link117">Footer Link</div>
                  </div>
                </div>
              </div>
              <div className="footer-menu46">
                <div className="students9">Company</div>
                <div className="footer-links45">
                  <div className="main-footer-link117">
                    <div className="footer-link117">About Us</div>
                  </div>
                  <div className="main-footer-link117">
                    <div className="footer-link117">Partners</div>
                  </div>
                  <div className="main-footer-link117">
                    <div className="footer-link117">Careers</div>
                  </div>
                  <div className="main-footer-link117">
                    <div className="footer-link117">Contact</div>
                  </div>
                </div>
              </div>
              <div className="footer-menu46">
                <div className="students9">Join our Email List</div>
                <div className="footer-links49">
                  <div className="main-footer-link129">
                    <div className="footer-link129">
                      Be the first to know about new scholarships, events,
                      workshops and much more!
                    </div>
                  </div>
                  <div className="subscribe-email-text-box9">
                    <div className="text-box8" />
                    <div className="input-placeholder8">Email</div>
                  </div>
                  <div className="buttonlarge29">
                    <div className="button37" />
                    <div className="button-text70">Sign Up</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-legal9">
            <div className="page-title25">
              © 2022 Scholist. All rights reserved.
            </div>
            <div className="page-title25">Disclaimer Cookies</div>
          </div>
        </div>
      </div>
      {isScholarshipFiltersOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Bottom"
          onOutsideClick={closeScholarshipFilters}
        >
          <ScholarshipFilters onClose={closeScholarshipFilters} />
        </PortalDrawer>
      )}
    </>
  );
};

export default Popular;
