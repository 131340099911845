import axios from "axios";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_Server_URL } from "../requestMethods";

const Success = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const payment_intent = params.get("payment_intent");

  useEffect(() => {
    const makeRequest = async () => {
      try {

        const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
        const currentUser = user && JSON.parse(user).currentUser;
        const TOKEN = currentUser?.tokens.access.token;
      
    
         const client = axios.create({
          baseURL: BASE_Server_URL,
          headers: { Authorization: `Bearer ${TOKEN}` },
      });
        await client.put("/booking/order", { payment_intent });


        setTimeout(() => {

            //nVigate to coach booking page
            navigate(`/coachtimeslot/${payment_intent}`);
        }, 5000);
      } catch (err) {
        console.log(err);
      }
    };

    makeRequest();
  }, []);

  return (
    <div>
      Payment successful. You are being redirected to the coaches calendlybooking page. Please do
      not close the page
    </div>
  );
};

export default Success;