import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_Server_URL, publicRequest } from "../requestMethods";



// const CoachTimeSlot = () => {
//     const { search } = useLocation();
//     const navigate = useNavigate();
//     const params = new URLSearchParams(search);
//     const payment_intent = params.get("intent");

//     console.log("intent", payment_intent)
//     const { intentD} = useParams();

//     useEffect(() => {
//         console.log("intent", payment_intent)

//         console.log('search', search)

//         console.log('intentd', intentD)
//     }, [])




//   return (
//     <div>CoachTimeSlot Booking</div>
//   )
// }
import CircularProgress from '@mui/material/CircularProgress';
import { InlineWidget } from "react-calendly";

import cheerio from 'cheerio';
import { EventRepeat } from "@mui/icons-material";
import PortalPopup from "../components/PortalPopup";
import SessionBooked from "../components/SessionBooked";



import { jwtDecode } from 'jwt-decode';
function getServiceById(serviceId, services) {

    //console.log('xcfgvhbnm,', services)

    //console.log('dfgfhghxcfgvhbnm,', serviceId)
    return services.find(service => service._id === serviceId);
}

const CoachTimeSlot = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(search);

    const [order, setOrder] = useState(null)
    const [loading, setLoading] = useState(true)
    const [coach, setCoach] = useState(null);
    const [bookingFound, setBookingFound] = useState(false)
    const [booking, setBooking] = useState(null)

    const [bookingData, setBookingData] = useState(null)
    const [service, setService] = useState({})
    // Use useParams to get the 'intent' parameter from the route
    const { intent: payment_intent } = useParams();
  const [eventUrl, setEventUrl] = useState(null)

  const [codes, setCodes] = useState({})



  const [
    isSavedScholarshipNotificationPopupOpen,
    setSavedScholarshipNotificationPopupOpen,
  ] = useState(false);
  const [isSessionBookedPopupOpen, setSessionBookedPopupOpen] = useState(false);

  const openSessionBookedPopup = useCallback(() => {
    setSessionBookedPopupOpen(true);
  }, []);

  const closeSessionBookedPopup = useCallback(() => {
    setSessionBookedPopupOpen(false);
  }, []);


    useEffect(() => {
      // Ensure payment_intent is not undefined or null before making the API call
      if (payment_intent) {
        const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
        const currentUser = user && JSON.parse(user).currentUser;
        const TOKEN = currentUser?.tokens.access.token;
  
        const client = axios.create({
          baseURL: BASE_Server_URL,
          headers: { Authorization: `Bearer ${TOKEN}` },
        });
  
        client
          .get(`/booking/order/${payment_intent}`)
          .then((data) => {
            //console.log('profile fnckme order', data.data[0]);
            // Do something with the data if needed
            setOrder(data.data[0])
            
          })
          .catch((err) => {
            console.error(err);
          });
      }

     
    }, [payment_intent]);


    const getCoach = async ()=>{

       // console.log('gbhnmk,l.', order.coachId)
        try{
          const res = await publicRequest.get("/coach/coachProfileOwner/"+order.coachId)
          setCoach(res.data);
          //console.log('mxd c,dx',res.data)


        




        }catch(err){
          console.log(err)
        }
      }
    
    useEffect(() => {
       
        if(order){
          getCoach()
          getBooking()
        }
        // else{
        //   console.log('you cannot make a book a session without payment')
        //   navigate('/services')
        // }
    }, [order])


    useEffect(() => {
      if(coach) {


        console.log('coaches  bnbn', coach)

        if(isTokenExpired(coach.calToken)){
          const tokens = tokenExpiredUpdate()

          console.log('tokens', tokens)
        } 

        else{
          const service=  getServiceById(order.gigId, coach.services)

          setService(service)
        }
      

      console.log('services', service)
        setLoading(false)
      }
     


    }, [coach])


    const [extractedHtml, setExtractedHtml] = useState('');

    const [htmlContent, setHtmlContent] = useState('');

    // useEffect(() => {
     
    // }, []);




    useEffect(() => {
      // Extract the div with data-container="details"
      const tempElement = document.createElement('div');
      tempElement.innerHTML = htmlContent;
  
      const detailsDiv = tempElement.querySelector('div[data-container="details"]');
  
      if (detailsDiv) {
        setExtractedHtml(detailsDiv.outerHTML);

        console.log('frdnkms,.', detailsDiv)
      } else {
        setExtractedHtml('Details div not found');
      }
    }, [htmlContent]);
  

    const getBooking= async ()=>{

     //console.log('order booking', order)
      try{
       // const res = await publicRequest.get("/booking/"+order.payment_intent) 

        const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
        const currentUser = user && JSON.parse(user).currentUser;
        const TOKEN = currentUser?.tokens.access.token;

        const client = axios.create({
            baseURL: BASE_Server_URL,
            headers: { Authorization: `Bearer ${TOKEN}` },
        });

        const res = await client.get("/booking/"+order.payment_intent) 

       // console.log('booking bnb  gvhb hbh hbn',res.data)

      // console.log('hsgxhkdc nbnnkm ',res.data)

        if(res.data.status == 'not_found'){
          console.log('booking not found',booking)
         // setBookingFound(false)
          
        }

        if(res.data.status == 'success'){
          //console.log('booking not found',booking)
          setBookingFound(true)
          //navigate('/')


          console.log('ypu have booked already do you want to make another payment')
          // console.log(coach.id)
          // console.log(order.gigId)

          navigate('/pay/'+coach.owner+'/'+order.gigId)
          
        }

        

        // if(res.data.payment_intent !== ''){
        //   console.log('res.data bookins', res.data)
        //   //setBookingFound(true)

        //   navigate('/services')
        // }

        
        return(res.data);
      


      




      }catch(err) {
        console.log(err)
      }
    }
  
    useEffect(() => {
        const handleCalendlyEvent = (event) => {
         
          if (event.data.event === "calendly.event_scheduled") {
            
           // console.log('Calendly date and time selected:', event);
            setEventUrl(event.data.payload.event.uri)
      
      
           
      
            console.log(window.Calendly)
      
           
             // Get the HTML content of the current window
      const currentHtmlContent = document.documentElement.outerHTML;

     // console.log('htmlcontnet', currentHtmlContent)
      setHtmlContent(currentHtmlContent);
      
          
            
          }

        //  if(event.data.event === "calendly.date_and_time_selected"){

        //   console.log('bookings', booking)
        //   if(bookingFound){
        //     console.log('you have already booked')

        //     //navigate to coach page
        //     //navigate('/services')
        //   }

        //   if(!bookingFound){
        //     console.log('no booking found')
        //   }

          
        //  }

       

       // console.log('hgtgyhuk', event)
        };
      
        // Add an event listener to the window object
        window.addEventListener('message', handleCalendlyEvent);
      
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('message', handleCalendlyEvent);
        };
      }, []); // Empty dependency array ensures that the effect runs only once (on mount)
      





      const [scrapedData, setScrapedData] = useState(null);
      const [eventData, setEventData] = useState(null);
      const [codeUpdate, setCodeUpdate] = useState(null);


      function isTokenExpired(accessToken) {
        // Decode the JWT (JSON Web Token) to access its payload
        const decodedToken = decodeJWT(accessToken);
      
        if (!decodedToken || !decodedToken.exp) {
          // If the token cannot be decoded or does not have an expiration time, consider it expired
          return true;
        }
      
        // Get the expiration timestamp in seconds
        const expirationTimestamp = decodedToken.exp;
      
        // Get the current time in seconds since the Unix epoch
        const currentTime = Math.floor(Date.now() / 1000);
      
        // Check if the token has expired
        return expirationTimestamp < currentTime;
      }
      
      function decodeJWT(token) {
        try {
          // Decode the JWT using a library like jwt-decode
          // npm install jwt-decode
          
          return jwtDecode(token);
        } catch (error) {
          console.error('Error decoding JWT:', error.message);
          return null;
        }
      }


      const tokenExpiredUpdate = async () =>{

       
        const clientId = process.env.Calendy_clientId;
         const clientSecret = process.env.Calendy_clientSecret;
        // const redirectUri = 'http://localhost:3002/calendlyauth'; // Must match the redirect URI used in the initial authorization request
       
         const tokenEndpoint = process.env.Calendy_TokenEndpoint;
       
         try {
           const response =  await axios.post(tokenEndpoint, {
            grant_type: 'refresh_token',
            client_id: clientId,
            client_secret: clientSecret,
            refresh_token: coach.calRefresh,
           });
       
           const accessToken = response.data.access_token;
           const refreshToken = response.data.refresh_token;
       
           // Store the access token and refresh token securely (e.g., in a database)
       
          setCodes({ success: true, accessToken, refreshToken });
         } catch (error) {
           console.error('Error exchanging code for token:', error.message);
           //res.status(500).json({ success: false, error: 'Internal server error' });
         }
    }


    const updateToken = async (codes) => {
    
    
       
      try {

          const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
          const currentUser = user && JSON.parse(user).currentUser;
          const TOKEN = currentUser?.tokens.access.token;
  
          const client = axios.create({
              baseURL: BASE_Server_URL,
              headers: { Authorization: `Bearer ${TOKEN}` },
          });




          console.log('coach id dcnkxm', codes.accessToken)
          console.log('coach id dcnkxm', codes.refreshToken)



        const res = await client.patch("/coach/caltoken/" + order.payment_intent, {
          coachId: coach.id,
          calToken: codes.accessToken,
          calRefresh: codes.refreshToken
        });






        console.log('coach  token updated successfully from user', res)
        setCoach(res.data.coach)


       // setCodeUpdate(res.data.coach.calToken)



       // navigate("/welcome")
      } catch (err) {
        console.log(err);
      }
    };


    useEffect(() => {
      if(codeUpdate) {
        const fetchEventData = async () => {

          if(eventUrl){
            try {
          
              var options = {
                method: 'GET',
                url: eventUrl,
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer '+ codeUpdate
                }
              };
  
            
              
             await  axios.request(options).then(function (response) {
                console.log('event data',response.data);
                setEventData(response.data.resource)
              }).catch(function (error) {
                console.error(error);
              });
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          }
          
        };


        fetchEventData()
      }
 
    }, [codeUpdate])
    
    

  
      useEffect(() => {

        if(eventUrl){
          const fetchEventData = async () => {

            if(eventUrl){
              try {
            
                var options = {
                  method: 'GET',
                  url: eventUrl,
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer '+ coach.calToken
                  }
                };
    
              
                
               await  axios.request(options).then(function (response) {
                  console.log('event data',response.data);
                  setEventData(response.data.resource)
                }).catch(function (error) {
                  console.error(error);
                });
              } catch (error) {
                console.error('Error fetching data:', error);
              }
            }
            
          };
      
          // Call the fetchData function
  
          console.log('coaches ', coach)
  
          // if(isTokenExpired(coach.calToken)){
          //   console.log('token has expired ms', isTokenExpired(coach.calToken))
          //   tokenExpiredUpdate()

          // }
  
            fetchEventData();
          
        } 
   
       
      }, [eventUrl]); // Run this effect only once, when the component mounts


    useEffect(() => {
      //console.log('codes new th ', codes)
      if(codes.success){
        //updtaeCoachProfile
        console.log('codes ',codes)


       updateToken(codes)
    }


    }, [codes])
    


const addBooking = async () => {
  try {
    const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
    const currentUser = user && JSON.parse(user).currentUser;
    const TOKEN = currentUser?.tokens.access.token;

    const client = axios.create({
      baseURL: BASE_Server_URL,
      headers: { Authorization: `Bearer ${TOKEN}` },
    });


   console.log('event  deata fvbhdn', eventData)


    const response =  await client.post('/booking/new', {
      orderId: order.id,
      gigId: order.gigId,
      coachId: coach.owner,
      bookingDate: eventData.start_time,
      startTime: eventData.start_time,
      endTime: eventData.end_time,
      eventUrl:  eventUrl,
      coachEmail: eventData.event_memberships[0].user_email,
      coachCalendlyUser: eventData.event_memberships[0].user,
      payment_intent:  payment_intent,

    });


    console.log('booking data gfhk hhnm nbn', eventData.start_time)
    setBookingData(response.data)

    if(eventData.created_at){
      const eventReminder  =  await client.post('/event', {
     

        
        itemId: response.data.id,
        name: coach.name,
        date: eventData.start_time,
        eventType: 'booking'
        
        
      })
  
      //console.log(res)
      console.log('event reminder', eventReminder)
    }

    else{
      console.log('error no event data')
    }



    ///poup show
    //openSessionBookedPopup()

   /// navigate('/mybookings')


  } catch (error) {
    console.log(error)
  }
}
useEffect(() => {

  if(eventData){


    addBooking()

    
  
  }
  

 

},[eventData])


useEffect(() => {
  if(bookingData){
    openSessionBookedPopup()
  }
}, [bookingData])




    return (
      <>
      <div>
  {loading ? (
    <CircularProgress />
  ) : (
    service ? (
      service.bookingLink ? (
        <InlineWidget url={service.bookingLink} />
      ) : (
        <p>{service.bookingLink}</p>
      )
    ) : (
      <p>No service available</p>
    )
  )}
</div>
     {isSessionBookedPopupOpen && (
      <PortalPopup
        overlayColor="rgba(113, 113, 113, 0.3)"
        placement="Centered"
        //onOutsideClick={closeSessionBookedPopup}
      >
        <SessionBooked onClose={closeSessionBookedPopup} coach={coach} bookingData={bookingData} service= {service} />
      </PortalPopup>
    )}

    
    </>


      );
  };
export default CoachTimeSlot