import { useEffect, useState } from "react";
import "./WriteMessagesPopUp.css";
import axios from "axios";
import { BASE_Server_URL } from "../requestMethods";
import { SMTPClient } from "smtp-client"; // <-- New import

const WriteMessagesPopUp = (props) => {
  const [messagedetails, setMessageDetails] = useState("");
  const [messageSent, setmessageSent] = useState(false);
  const [currentChat, setCurrentChat] = useState(null);

  const handleTextareaChange = (event) => {
    setMessageDetails(event.target.value);
  };

  const handleSendMessage = () => {
    StartConversation();
    sendEmail();
    setmessageSent(true);
  };

  const buttonStyle = {
    backgroundColor: messagedetails ? "#4A93CF" : "#E7E7E7",
    cursor: messagedetails ? "pointer" : "not-allowed",
  };

  useEffect(() => {
    console.log("write message", props);
  }, []);

  function CheckIfConversationExist(userId, coachId) {
    const conversationToFind = [userId, coachId];
    const foundConversation = props.conversations.find((conversation) =>
      conversation.members.every((member) =>
        conversationToFind.includes(member)
      )
    );
    return foundConversation;
  }

  const ConversationStart = async (userId, coachId) => {
    const conversation = {
      senderId: userId,
      receiverId: coachId,
    };

    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });

      const res = await client.post("/conversation", conversation);
      setCurrentChat(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  function StartConversation() {
    const convo = CheckIfConversationExist(
      props.user.user.id,
      props.coach.owner
    );

    if (convo) {
      setCurrentChat(convo);
    } else {
      ConversationStart(props.user.user.id, props.coach.owner);
    }
  }

  const handleSubmit = async (e) => {
    const message = {
      sender: props.user.user.id,
      text: messagedetails,
      conversationId: currentChat.id,
    };

    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });

      const res = await client.post("/message", message);
      setMessageDetails("");

      console.log("new message", res.data);
      setmessageSent(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log("current chat", currentChat);
    if (currentChat) {
      handleSubmit();
    }
  }, [currentChat]);

  const sendEmail = async () => {
    const client = new SMTPClient({
      host: "adeoyemayopoelijah@gmail.com", // <-- Replace with your SMTP server
      port: 587,
      secure: false,
    });

    try {
      await client.connect();
      await client.greet({ hostname: "localhost" }); // Your domain name
      await client.authPlain({
        username: "your-username", // <-- Replace with your email username
        password: "your-password", // <-- Replace with your email password
      });
      await client.mail({ from: "your-email@example.com" }); // <-- Replace with your email
      await client.rcpt({ to: props.coach.email }); // <-- Email of the coach
      await client.data(
        `Subject: New Message from ${props.user.user.name}\n\n${messagedetails}`
      );
      await client.quit();

      console.log("Email sent successfully!");
    } catch (err) {
      console.error("Failed to send email. Error:", err);
    }
  };

  return messageSent ? (
    <div className="writemessagespopupsent">
      <div className="writemessagespopupsent-inner">
        <div className="ellipse-group" onClick={props.onClose}>
          <div className="group-item" />
          <div className="div1">+</div>
        </div>
      </div>
      <div className="message-sent-wrapper">
        <b className="message-sent">{`Message Sent `}</b>
      </div>
    </div>
  ) : (
    <div className="writemessagespopup">
      <div className="writemessagespopup-inner">
        <div className="ellipse-group" onClick={props.onClose}>
          <div className="group-item" />
          <div className="div1">+</div>
        </div>
      </div>
      <div className="new-message-wrapper">
        <div className="new-message">New Message</div>
      </div>
      <div className="writemessagesreceiverbox">
        <div className="new-message">To:</div>
        <div className="writemessagesreceiverinput">
          <p className="writemessagesreceiverinputemai" type="text">
            {props.coach.name}
          </p>
          <input type="hidden" name="coachEmail" value={props.coach.email} />
        </div>
      </div>
      <div className="writemessagesdetailsbox">
        <div className="new-message">Your Message:</div>
        <div className="writemessageinput">
          <textarea
            className="jessicahowesgmailcom-nsxbnnc"
            placeholder="Type Message"
            value={messagedetails}
            onChange={handleTextareaChange}
          />
        </div>
      </div>
      <button
        className="writemessagessendbutton"
        style={buttonStyle}
        onClick={handleSendMessage}
        disabled={!messagedetails}
      >
        <div className="writemessagessendtext">Send Message</div>
      </button>
    </div>
  );
};

export default WriteMessagesPopUp;
