import { useCallback, useEffect, useState } from "react";
import "./SCHOLARSHIPSDESCRIPTION1.css";

import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { Button } from "antd";
import SavedScholarshipNotification from "../components/SavedScholarshipNotification";
import PortalPopupNot from "../components/PortalPopupNot";

import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import CircularProgress from "@mui/material/CircularProgress";
import { BASE_Server_URL, Server_URL } from "../requestMethods";

const SCHOLARSHIPSDESCRIPTION1 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split("/")[2];

  const [scholarship, setScholarship] = useState({
    name: "",
    link: "",
    deadline: "",
    details: "",
  });

  const [checklists, setChecklists] = useState([]);
  const user = useSelector((state) => state.user.currentUser);

  const [scholarshipBookmarks, setScholarshipBookmarks] = useState([]);
  const [scholarshipApplied, setScholarshipApplied] = useState([]);
  const [didYouApply, setDidYouApply] = useState(false);

  const colors = ["#F8D100", "#5D57F9", " #F40000", "#00B8C3"];
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [basedOnProfile, setBasedOnProfile] = useState([
    {
      amount: " ",
      name: "",
      link: "",
      deadline: "",
    },
  ]);

  const [appliedDate, setAppliedDate] = useState("");

  //console.log(id)

  const [scholarshipNotDetail, setScholarshipNotDetai] = useState({
    link: "",
    name: "",
  });

  const [loading, setLoading] = useState(false);
  const [
    isSavedScholarshipNotificationPopupOpen,
    setSavedScholarshipNotificationPopupOpen,
  ] = useState(false);

  const openSavedScholarshipNotificationPopup = useCallback(() => {
    setSavedScholarshipNotificationPopupOpen(true);
  }, []);

  const closeSavedScholarshipNotificationPopup = useCallback(() => {
    setSavedScholarshipNotificationPopupOpen(false);
  }, []);

  const backtoscholarships = useCallback(() => {
    navigate("/scholarships-category-page");
  }, [navigate]);

  // useEffect(()=>{
  //   console.log('id dkmm')
  //   setLoading(true)
  //   const Data = []
  //   const getScholarship = async ()=>{
  //     try{
  //       const res = await axios.get("http://localhost:3000/v1/scholarship/"+id)
  //      console.log('res data',res.data)
  //       setScholarship(res.data);
  //       Data.push(res.data)
  //       //console.log(Data)
  //     // setBasedOnProfile(Data)
  //      setLoading(false)
  //     }catch{

  //     }
  //   }
  //   getScholarship()

  //   console.log('getscolarshipsithID')
  //   //setLoading(false)
  // }, [id])

  /*  useEffect(() => {
    setLoading(true)
    const checklist = []
    const item = scholarship.details.replaceAll(",", "").replaceAll(".", "").toLowerCase().split(' ')

    if (item.includes('essay') ){
      checklist.push('Essay')
    }
    if (item.includes('fafsa') ){
      checklist.push('FAFSA')
    }
    if (item.includes('transcripts')|| item.includes('transcript')){
      checklist.push('Transcripts')
    }

    if (item.includes('letters')  || item.includes('recommendations')  ){
      checklist.push('Letter Of Recomendation')
    }

    setChecklists(checklist)


   

    getBasedOnYourProfileScholarships()
    console.log('scholarship based oon profile')
    setLoading(false)
  }, [scholarship])
  */

  // const handleScholarshipBookmark = async(event, data) =>{
  //   //console.log(data)
  //   event.preventDefault();

  //   try {
  //     const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
  //     const currentUser = user && JSON.parse(user).currentUser;
  //     const TOKEN = currentUser?.tokens.access.token;

  // //console.log(currentUser)
  //      const client = axios.create({
  //       baseURL: "http://localhost:3000/v1/",
  //       headers: { Authorization: `Bearer ${TOKEN}` },
  //   });
  //     const res =  await client.post('/scholarshipbookmark', {

  //       scholarshipId: data.id,
  //       name: data.name,
  //       deadline: data.deadline,
  //       amount: data.amount ? data.amount : 'NaN',
  //       details: data.details,
  //       link: data.link

  //     })
  //     //console.log(res)
  //     getScholarshipBookmarks()
  //   } catch (error) {
  //      console.log(error)
  //   }

  // }

  // useEffect(()=>{
  //   console.log('id dkmm')
  //   setLoading(true)
  //   const Data = []
  //   const getScholarship = async ()=>{
  //     try{
  //       const res = await axios.get("http://localhost:3000/v1/scholarship/"+id)
  //      console.log('res data',res.data)
  //       //setScholarship(res.data);
  //       Data.push(res.data)
  //       //console.log(Data)
  //       setBasedOnProfile(Data)
  //       setLoading(false)

  //     }catch(err){
  //       console.log(err)
  //     }

  //   }

  //   const getBasedOnYourProfileScholarships= async () =>{

  //     try {
  //      // console.log('called')
  //         //const res = await axios.get(`http://localhost:3000/v1/scholarship?page=${page}&limit=8`);
  //         const res = await axios.get(`http://localhost:3000/v1/scholarship`);
  //         //console.log(res.data.results)
  //         const pageC = await res.data.totalPages
  //         //console.log(basedOnProfile)

  //         const Data = res.data.slice(0,6)
  //         //console.log('fhcdnkm',basedOnProfile)
  //         Data.push(scholarship)
  //          Data.unshift(basedOnProfile[0])
  //         //console.log('grhndeks', Data)
  //         setBasedOnProfile(
  //          Data
  //           )
  //        // setPageCount(pageC)

  //     } catch (error) {

  //     }
  //   };
  //   getScholarship()

  //   console.log('getscolarshipsithID')

  //   //setLoading(false)
  // }, [id])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch scholarship data based on id
        const scholarshipRes = await axios.get(
          `${BASE_Server_URL}scholarship/${id}`
        );
        console.log("Scholarship data:", scholarshipRes.data);
        setBasedOnProfile([scholarshipRes.data]);

        // Fetch additional scholarship data
        const allScholarshipsRes = await axios.get(
          `${BASE_Server_URL}scholarship`
        );
        const pageC = allScholarshipsRes.data.totalPages;

        const data = allScholarshipsRes.data.slice(0, 6);
        data.unshift(scholarshipRes.data);
        setBasedOnProfile(data);

        //setPageCount(pageC);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error, e.g., set an error state
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (basedOnProfile?.[0]?.details) {
      const checklist = [];
      
      const item = basedOnProfile[0].details
        .replace(/,/g, "") 
        .replace(/\./g, "") 
        .toLowerCase()
        .split(" ");
      
      // Check for specific keywords and update the checklist
      item.includes("essay") && checklist.push("Essay");
      item.includes("fafsa") && checklist.push("FAFSA");
      (item.includes("transcripts") || item.includes("transcript")) && checklist.push("Transcripts");
      (item.includes("letters") || item.includes("recommendations")) && checklist.push("Letter Of Recommendation");
  
      setChecklists(checklist);
      console.log("checklists", basedOnProfile[0].details);
    } else {
      console.warn("basedOnProfile or basedOnProfile[0].details is undefined");
    }
  }, [basedOnProfile]);
  

  // useEffect(() => {
  //   const checklist = [];
  //   const item = basedOnProfile[0].details
  //     .replace(/,/g, "") 
  //     .replace(/\./g, "") 
  //     .toLowerCase()
  //     .split(" ");

  //   if (item.includes("essay")) {
  //     checklist.push("Essay");
  //   }
  //   if (item.includes("fafsa")) {
  //     checklist.push("FAFSA");
  //   }
  //   if (item.includes("transcripts") || item.includes("transcript")) {
  //     checklist.push("Transcripts");
  //   }

  //   if (item.includes("letters") || item.includes("recommendations")) {
  //     checklist.push("Letter Of Recomendation");
  //   }

  //   setChecklists(checklist);
  //   console.log("checklists", basedOnProfile[0]);
  // }, [basedOnProfile]);

  const handleScholarshipBookmark = async (event, data) => {
    console.log(data);
    event.preventDefault();

    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      //console.log(currentUser)
      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.post("/scholarshipbookmark", {
        scholarshipId: data.id,
        name: data.name,
        deadline: data.deadline,
        amount: data.amount ? data.amount : "Varies",
        details: data.details,
        link: data.link,
      });

      //const eventReminder = await

      //console.log('gvhbnm vghcrcfghkhgb gghk gyhh ,',data.deadline)
      if (data.deadline) {
        const eventReminder = await client.post("/event", {
          itemId: data.id,
          name: data.name,
          link: data.link,
          date: data.deadline,
          eventType: "scholarship",
        });

        //console.log(res)

        setScholarshipNotDetai({
          scholarshipId: data.id,
          name: data.name,
          deadline: data.deadline,
          amount: data.amount ? data.amount : "Varies",
          details: data.details,
          link: data.link,
        });
        // console.log('event reminder', eventReminder)
      }

      getScholarshipBookmarks();
    } catch (error) {
      console.log(error);
    }
  };

  // const handleScholarshipBookmark = async (event, data) => {
  //   console.log(data);
  //   event.preventDefault();

  //   try {
  //     const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
  //     const currentUser = user && JSON.parse(user).currentUser;
  //     const TOKEN = currentUser?.tokens.access.token;

  //     //console.log(currentUser)
  //     const client = axios.create({
  //       baseURL: { BASE_Server_URL },
  //       headers: { Authorization: `Bearer ${TOKEN}` },
  //     });
  //     const res = await client.post("/scholarshipbookmark", {
  //       scholarshipId: data.id,
  //       name: data.name,
  //       deadline: data.deadline,
  //       amount: data.amount ? data.amount : "NaN",
  //       details: data.details,
  //       link: data.link,
  //     });

  //     //const eventReminder = await

  //     //console.log('gvhbnm vghcrcfghkhgb gghk gyhh ,',data.deadline)
  //     if (data.deadline) {
  //       const eventReminder = await client.post("/event", {
  //         itemId: data.id,
  //         name: data.name,
  //         link: data.link,
  //         date: data.deadline,
  //         eventType: "scholarship",
  //       });

  //       //console.log(res)

  //       setScholarshipNotDetai({
  //         scholarshipId: data.id,
  //         name: data.name,
  //         deadline: data.deadline,
  //         amount: data.amount ? data.amount : "NaN",
  //         details: data.details,
  //         link: data.link,
  //       });
  //       // console.log('event reminder', eventReminder)
  //     }

  //     getScholarshipBookmarks();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleScholarshipApplied = async (event, data) => {
    //console.log(data)
    event.preventDefault();

    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      //console.log(currentUser)
      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.post("/appliedscholarship", {
        scholarshipId: data.id,
        name: data.name,
        deadline: data.deadline,
        amount: data.amount ? data.amount : "Varies",
        details: data.details,
        link: data.link,
      });
      //console.log(res)

      getScholarshipApplied();
    } catch (error) {
      console.log(error);
    }
  };

  const getScholarshipBookmarks = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      //console.log(currentUser)
      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.get("/scholarshipbookmark");
      console.log("scholarship bookmarks", res.data);
      setScholarshipBookmarks(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    getScholarshipBookmarks();
    console.log("getscholarshipbookmarks");
  }, []);

  // useEffect(() => {

  // },[scholarship])

  useEffect(() => {
    getScholarshipApplied();
  }, []);

  useEffect(() => {
    if (scholarshipNotDetail) {
      openSavedScholarshipNotificationPopup();

      setTimeout(() => {
        closeSavedScholarshipNotificationPopup();
      }, 5000); // 5000 milliseconds (5 seconds)
    }

    console.log("sc details");
  }, [scholarshipNotDetail]);

  const deleteScholarshipBookmark = async (event, id) => {
    //console.log('delete',event)
    //console.log(id)
    event.preventDefault();

    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      //console.log(currentUser)
      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.delete("/scholarshipbookmark/" + id);
      //console.log(res)
      getScholarshipBookmarks();
    } catch (error) {
      console.log(error);
    }
  };

  function BookmarkChecker(Id, elem) {
    //console.log(coachBookmarks)
    // const filteredResult = coachBookmarks.filter((item) => {
    //   return (item.coachId.indexOf(coachId) > 0);

    //});
    const isObjectPresent = scholarshipBookmarks.find(
      (o) => o.scholarshipId === Id
    );
    if (isObjectPresent) {
      //console.log(isObjectPresent)
      return (
        //red love

        <img
          // className="health-icon"
          className="sdessdetailsbox1health-icon"
          alt=""
          src="/component-23.svg"
          onClick={(e) => deleteScholarshipBookmark(e, elem.id)}
        />
      );
    } else {
      return (
        // white love

        <img
          //className="health-icon"
          className="sdessdetailsbox1health-icon"
          alt=""
          src="/component-22.svg"
          onClick={(e) => handleScholarshipBookmark(e, elem)}
        />
      );
    }
  }

  function DeadlineOrganiser(deadline) {
    if (deadline == "not specified") {
      return "not specified";
    } else {
      //console.log('fgvhbnbhgfc',deadline)
      const date = deadline.split("-");
      return date[1] + "/" + date[2] + "/" + date[0];
    }
  }
  function BookmarkCheckerL(Id, elem) {
    //console.log(coachBookmarks)
    // const filteredResult = coachBookmarks.filter((item) => {
    //   return (item.coachId.indexOf(coachId) > 0);

    //});
    const isObjectPresent = scholarshipBookmarks.find(
      (o) => o.scholarshipId === Id
    );
    if (isObjectPresent) {
      //console.log(isObjectPresent)
      return (
        //red love

        <img
          //className="component-2-icon12"
          className="sdesdetailssidecardcurdetheart-icon"
          alt=""
          src="/component-23.svg"
          onClick={(e) => deleteScholarshipBookmark(e, elem.id)}
        />
      );
    } else {
      return (
        // white love

        <img
          // className="component-2-icon12"
          className="sdesdetailssidecardcurdetheart-icon"
          alt=""
          src="/component-22.svg"
          onClick={(e) => handleScholarshipBookmark(e, elem)}
        />
      );
    }
  }

  function getSubstring(string, char1, char2) {
    //console.log(string.split(".").length - 1)
    if (string === undefined || string === null) {
      return "No Link"; // or handle the error in an appropriate way
    }
    if (string.split(".").length - 1 > 1) {
      const first = string.indexOf(char1) + 1;
      const link = string.slice(
        string.indexOf(char1) + 1,
        //string.lastIndexOf(char2),
        string.indexOf(char2, first + 1)
      );

      return link.charAt(0).toUpperCase() + link.slice(1);
    } else {
      //console.log(string)
      const firstSlash = string.indexOf("/") + 1;
      //console.log(string.indexOf("/", firstSlash + 1))
      // console.log(  string.indexOf('/') + 1)
      // console.log( string.indexOf(char1) + 1)
      const link = string.slice(
        // string.indexOf(char1) + 1,
        //string.lastIndexOf(char2),
        //string.indexOf("/", firstSlash + 1),
        string.indexOf("/") + 2,
        string.indexOf(char1)
      );

      return link.charAt(0).toUpperCase() + link.slice(1);
      //return ()
    }
    //count number of dot in string
    //get word between second slash
  }

  function getSubstringForLogo(string, char1, char2) {
    //console.log(string.split(".").length - 1)
    if (string === undefined || string === null) {
      return "No Link"; // or handle the error in an appropriate way
    }
    if (string.split(".").length - 1 > 1) {
      const first = string.indexOf(char1) + 1;
      const link = string.slice(
        string.indexOf(char1) + 1,
        //string.lastIndexOf(char2),
        string.indexOf(char2, first + 1) + 4
      );
      //console.log(link)

      return link.charAt(0).toUpperCase() + link.slice(1);
    } else {
      //console.log(string)
      const firstSlash = string.indexOf("/") + 1;
      //console.log(string.indexOf("/", firstSlash + 1))
      // console.log(  string.indexOf('/') + 1)
      // console.log( string.indexOf(char1) + 1)
      const link = string.slice(
        // string.indexOf(char1) + 1,
        //string.lastIndexOf(char2),
        //string.indexOf("/", firstSlash + 1),
        string.indexOf("/") + 2,
        string.indexOf(char1) + 4
      );
      //console.log(link)

      return link.charAt(0).toUpperCase() + link.slice(1);
      //return ()
    }
    //count number of dot in string
    //get word between second slash
  }
  const getBasedOnYourProfileScholarships = async () => {
    try {
      // console.log('called')
      //const res = await axios.get(`http://localhost:3000/v1/scholarship?page=${page}&limit=8`);
      const res = await axios.get(`${BASE_Server_URL}scholarship`);
      //console.log(res.data.results)
      const pageC = await res.data.totalPages;
      //console.log(basedOnProfile)

      const Data = res.data.slice(0, 6);
      //console.log('fhcdnkm',basedOnProfile)
      Data.push(scholarship);
      Data.unshift(basedOnProfile[0]);
      //console.log('grhndeks', Data)
      setBasedOnProfile(Data);
      // setPageCount(pageC)
    } catch (error) {}
  };

  const SelectScholarship = (id) => {
    //setLoading(true)
    const scholarshipIndex = basedOnProfile.findIndex(
      (scholarship) => scholarship.id === id
    );

    if (scholarshipIndex !== -1) {
      // Remove the scholarship from its current position
      const removedScholarship = basedOnProfile.splice(scholarshipIndex, 1);
      // Add the removed scholarship to the front of the array
      setBasedOnProfile([removedScholarship[0], ...basedOnProfile]);
    }

    setDidYouApply(false);
  };
  // const handleApplyRedirect = (e,link) => {
  //   e.preventDefault();
  //   window.open(link)
  //   setDidYouApply(true)
  //   //window.location.href = link;
  // };
  const isUserSignedIn = () => {
    const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
    const currentUser = user && JSON.parse(user).currentUser;
    return !!currentUser; // Check if currentUser exists
  };

  // Function to handle the redirection
  const handleApplyRedirect = (e, link) => {
    e.preventDefault();
    if (isUserSignedIn()) {
      window.open(link);

      setTimeout(() => {
        setDidYouApply(true);
      }, 10000); // 10000 milliseconds (10 seconds)
    } else {
      // alert("Please sign in to continue.");
      // // Optionally redirect to a sign-in page
      window.location.href = "/login";
    }
  };
  // const handleApplyRedirect = (e, link) => {
  //   e.preventDefault();
  //   window.open(link);

  //   setTimeout(() => {
  //     setDidYouApply(true);
  //   }, 10000); // 10000 milliseconds (10 seconds)
  // };

  const getScholarshipApplied = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      //console.log(currentUser)
      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.get("/appliedscholarship");
      console.log("scholarship applied", res.data);
      setScholarshipApplied(res.data);
    } catch (error) {}
  };

  function AppliedChecker(Id, elem) {
    //console.log(coachBookmarks)
    // const filteredResult = coachBookmarks.filter((item) => {
    //   return (item.coachId.indexOf(coachId) > 0);

    //});
    const isObjectPresent = scholarshipApplied.find(
      (o) => o.scholarshipId === Id
    );
    // const appliedD =
    if (isObjectPresent) {
      console.log("date", isObjectPresent);
      return (
        <div className="sdessdetailsschecklistbox12">
          <div className="sdessdetailssbuttonbox11">
            <div className="didyouapplysdes">
              <div className="didyouapplysdesbox">
                <div className="applied-on-4192023-container">
                  <b className="applied-on-4192023">
                    Applied on {formatDate(isObjectPresent.createdAt)}!{" "}
                  </b>
                  <span>{`Follow your progress in `}</span>
                  <span>
                    <Link to="/applied-scholarship" className="our-tracker">
                      our tracker
                    </Link>
                    !
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="sdessdetailsschecklistbox12">
          <div className="sdessdetailssbuttonbox11">
            <div className="didyouapplysdes">
              <div className="didyouapplysdesbox">
                <div className="didyouapplysdesbox2">
                  <b className="didyouapplysdestext1">Did you apply?</b>
                  <div className="didyouapplysdestext2">
                    Let us know, and we’ll help you track your application.
                  </div>
                </div>
                <div className="didyouapplysdesydidyouapplysde">
                  <button
                    className="didyouapplysdesyes"
                    onClick={(e) => handleScholarshipApplied(e, elem)}
                  >
                    <div className="didyouapplysdesyesyestext">Yes</div>
                  </button>
                  <button
                    className="didyouapplysdesyes"
                    onClick={(e) => setDidYouApply(false)}
                  >
                    <div className="didyouapplysdesyesyestext">No</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  const [textToCopy, setTextToCopy] = useState("Text to be copied");

  const copyToClipboard = (event, id) => {
    const textToCopy = `${Server_URL}scholarships-description1/${id}`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log("Text successfully copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });

    handleClick(event);
  };

  function formatDate(dateString) {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  }

  function extractScholarshipInfo(scholarshipName) {
    const awardTypes = /(scholarship|grant|loan|fellowship)/i;
    const match = scholarshipName.match(awardTypes);

    if (match) {
      const words = scholarshipName.split(" ");
      const awardIndex = words.findIndex(
        (word) => word.toLowerCase() === match[1].toLowerCase()
      );

      if (awardIndex > 0) {
        if (awardIndex >= 2) {
          return `${words.slice(0, 2).join(" ")} ${words[awardIndex]}`;
        } else {
          return `${words[0]} ${words[awardIndex]}`;
        }
      }
    }

    return scholarshipName.split(" ").slice(0, 2).join(" ");
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idP = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (anchorEl) {
      const timeoutId = setTimeout(() => {
        handleClose(); // Call your handleClose function here
      }, 3000); // 5000 milliseconds (5 seconds)

      return () => {
        clearTimeout(timeoutId); // Clear the timeout if the component unmounts or anchorEl changes before 5 seconds
      };
    }
  }, [anchorEl]);

  console.log("Loading:", loading);
  let index;

  return (
    <>
      <div className="scholarship-description">
        <div className="sdescriptiontitlebox">
          <div className="sdescriptiontitlebox1">
            <div className="sdescriptiontitlebox2">
              <button className="sdescriptiontitlebox3">
                <div
                  className="sdescriptiontitlebox1text"
                  onClick={() => backtoscholarships()}
                >
                  Scholarships
                </div>
                <div className="sdescriptiontitleboxarrow">{`>`}</div>
                <div className="sdescriptiontitlebox1text">
                  {basedOnProfile[0].name}
                </div>
              </button>
            </div>
            <div className="sdescriptiontitle2box">
              <div className="sdescriptiontitle2boxsname">
                {scholarship.name}
              </div>
            </div>
          </div>
        </div>
        <div className="sdescriptiontitleback">
          <div className="sdescriptiontitlebackbox1">
            <div
              className="sdescriptiontitlebackbox2"
              onClick={() => backtoscholarships()}
            >
              <div className="sdescriptiontitlebackbox1arrow">{`<`}</div>
              <div
                className="sdescriptiontitlebackbox1text"
                style={{ cursor: "pointer" }}
              >
                Back
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="sdesdetailsbox">
            <CircularProgress />
          </div>
        ) : (
          <div className="sdesdetailsbox">
            <div className="sdesdetailsbox1">
              <div className="sdesdetailsbox2">
                <div className="sdesdetailssidebox">
                  <div className="sdesdetailssidecard">
                    <div className="sdesdetailssidecardcurrent">
                      <div className="sdesdetailssidecardcurrenthead">
                        Based on Your Profile
                      </div>
                    </div>
                    <div
                      className="sdesdetailssidecardcurrentbox"
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      <div
                        className="sdesdetailssidecardcurimgbox"
                        style={{
                          backgroundColor:
                            hoveredIndex === index
                              ? "#ccc"
                              : Number(basedOnProfile[0].amount) > 1000 &&
                                Number(basedOnProfile[0].amount) < 3000
                              ? colors[0]
                              : Number(basedOnProfile[0].amount) > 3000 &&
                                Number(basedOnProfile[0].amount) < 6000
                              ? colors[1]
                              : Number(basedOnProfile[0].amount) > 6000 &&
                                Number(basedOnProfile[0].amount) < 10000
                              ? colors[2]
                              : Number(basedOnProfile[0].amount) > 10000
                              ? colors[3]
                              : colors[1],
                        }}
                        onClick={() => SelectScholarship(basedOnProfile[0])}
                      >
                        <img
                          className="sdesdetailssidecardcurimgbox1-icon"
                          alt=""
                          src={
                            "https://logo.clearbit.com/" +
                            getSubstringForLogo(
                              basedOnProfile[0].link,
                              ".",
                              "."
                            ) +
                            "/"
                          }
                        />
                      </div>
                      <div className="sdesdetailssidecardcurdetbox">
                        <b
                          className="sdesdetailssidecardcurdetsname"
                          onClick={() => SelectScholarship(basedOnProfile[0])}
                          style={{ cursor: "pointer" }}
                        >
                          {extractScholarshipInfo(basedOnProfile[0].name)}
                        </b>
                        <div className="sdesdetailssidecardcurdetslink">
                          {getSubstring(basedOnProfile[0].link, ".", ".")}
                        </div>
                        <div className="sdesdetailssidecardcurdetslink">
                          {basedOnProfile[0].amount}
                        </div>
                        <div className="sdesdetailssidecardcurdetdeadl">
                          <div className="sdesdetailssidecardcurdetsdead">
                            Deadline:
                            {DeadlineOrganiser(
                              basedOnProfile[0].deadline !== null
                                ? basedOnProfile[0].deadline.slice(
                                    0,
                                    basedOnProfile[0].deadline.indexOf("T")
                                  )
                                : "not specified"
                            )}
                          </div>
                          {/* <img
                    className="sdesdetailssidecardcurdetheart-icon"
                    alt=""
                    src="/group.svg"
                  /> */}
                          {BookmarkCheckerL(
                            basedOnProfile[0].id,
                            basedOnProfile[0]
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {basedOnProfile.slice(1, 7).map((elem, index) => (
                    <div
                      className="sdesdetailssidecardbox"
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      <div
                        className="sdesdetailssidecardcurimgbox"
                        style={{
                          backgroundColor:
                            hoveredIndex === index
                              ? "#ccc"
                              : Number(elem.amount) > 1000 &&
                                Number(elem.amount) < 3000
                              ? colors[0]
                              : Number(elem.amount) > 3000 &&
                                Number(elem.amount) < 6000
                              ? colors[1]
                              : Number(elem.amount) > 6000 &&
                                Number(elem.amount) < 10000
                              ? colors[2]
                              : Number(elem.amount) > 10000
                              ? colors[3]
                              : colors[1],
                        }}
                        onClick={() => SelectScholarship(elem.id)}
                      >
                        <Link
                          className="link"
                          to={
                            user
                              ? `/scholarships-description1/${elem.id}`
                              : `/login`
                          }
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <img
                            className="sdesdetailssidecardcurimgbox1-icon"
                            alt=""
                            //  src="/cardscholarshipsimage@2x.png"
                            src={
                              "https://logo.clearbit.com/" +
                              getSubstringForLogo(elem.link, ".", ".") +
                              "/"
                            }
                          />
                        </Link>
                      </div>
                      <div className="sdesdetailssidecardcurdetbox">
                        <b
                          className="sdesdetailssidecardcurdetsname"
                          onClick={() => SelectScholarship(elem.id)}
                          style={{ cursor: "pointer" }}
                        >
                          {extractScholarshipInfo(elem.name)}
                        </b>
                        <div className="sdesdetailssidecardcurdetslink">
                          {getSubstring(elem.link, ".", ".")}
                        </div>
                        <div className="sdesdetailssidecardcurdetslink">
                          ${elem.amount ? elem.amount : "Varies"}
                        </div>
                        <div className="sdesdetailssidecardcurdetdeadl">
                          <div className="sdesdetailssidecardcurdetsdead">
                            Deadline:
                            {DeadlineOrganiser(
                              elem.deadline !== null
                                ? elem.deadline.slice(
                                    0,
                                    elem.deadline.indexOf("T")
                                  )
                                : "not specified"
                            )}
                          </div>
                          {/* <img
                   className="sdesdetailssidecardcurdetheart-icon"
                   alt=""
                   src="/group.svg"
                 /> */}
                          {BookmarkCheckerL(elem.id, elem)}
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* <div className="sdesdetailssidecardbox">
            <div className="sdesdetailssidecardcurimgbox">
              <img
                className="sdesdetailssidecardcurimgbox1-icon"
                alt=""
                src="/cardscholarshipsimage@2x.png"
              />
            </div>
            <div className="sdesdetailssidecardcurdetbox">
              <b className="sdesdetailssidecardcurdetsname">
                “Be Bold” No-Essay Scholarship
              </b>
              <div className="sdesdetailssidecardcurdetslink">Bold.org</div>
              <div className="sdesdetailssidecardcurdetslink">$25,000</div>
              <div className="sdesdetailssidecardcurdetdeadl">
                <div className="sdesdetailssidecardcurdetsdead">
                  Deadline: 04/30/2023
                </div>
                <img
                  className="sdesdetailssidecardcurdetheart-icon"
                  alt=""
                  src="/group.svg"
                />
              </div>
            </div>
          </div>

          <div className="sdesdetailssidecardbox">
            <div className="sdesdetailssidecardcurimgbox">
              <img
                className="sdesdetailssidecardcurimgbox1-icon"
                alt=""
                src="/cardscholarshipsimage@2x.png"
              />
            </div>
            <div className="sdesdetailssidecardcurdetbox">
              <b className="sdesdetailssidecardcurdetsname">
                “Be Bold” No-Essay Scholarship
              </b>
              <div className="sdesdetailssidecardcurdetslink">Bold.org</div>
              <div className="sdesdetailssidecardcurdetslink">$25,000</div>
              <div className="sdesdetailssidecardcurdetdeadl">
                <div className="sdesdetailssidecardcurdetsdead">
                  Deadline: 04/30/2023
                </div>
                <img
                  className="sdesdetailssidecardcurdetheart-icon"
                  alt=""
                  src="/group.svg"
                />
              </div>
            </div>
          </div>

          <div className="sdesdetailssidecardbox">
            <div className="sdesdetailssidecardcurimgbox">
              <img
                className="sdesdetailssidecardcurimgbox1-icon"
                alt=""
                src="/cardscholarshipsimage@2x.png"
              />
            </div>
            <div className="sdesdetailssidecardcurdetbox">
              <b className="sdesdetailssidecardcurdetsname">
                “Be Bold” No-Essay Scholarship
              </b>
              <div className="sdesdetailssidecardcurdetslink">Bold.org</div>
              <div className="sdesdetailssidecardcurdetslink">$25,000</div>
              <div className="sdesdetailssidecardcurdetdeadl">
                <div className="sdesdetailssidecardcurdetsdead">
                  Deadline: 04/30/2023
                </div>
                <img
                  className="sdesdetailssidecardcurdetheart-icon"
                  alt=""
                  src="/group1.svg"
                />
              </div>
            </div>
          </div> */}
                </div>
                {loading ? (
                  <div className="sdessdetailsbox">
                    <CircularProgress />{" "}
                  </div>
                ) : (
                  <div className="sdessdetailsbox">
                    <div
                      className="sdessdetailsimgbox"
                      style={{
                        backgroundColor:
                          Number(basedOnProfile[0].amount) > 1000 &&
                          Number(basedOnProfile[0].amount) < 3000
                            ? colors[0]
                            : Number(basedOnProfile[0].amount) > 3000 &&
                              Number(basedOnProfile[0].amount) < 6000
                            ? colors[1]
                            : Number(basedOnProfile[0].amount) > 6000 &&
                              Number(basedOnProfile[0].amount) < 10000
                            ? colors[2]
                            : Number(basedOnProfile[0].amount) > 10000
                            ? colors[3]
                            : colors[1],
                      }}
                    >
                      <img
                        className="sdessdetailsimg-icon"
                        alt=""
                        // src="/image-32@2x.png"
                        src={
                          "https://logo.clearbit.com/" +
                          getSubstringForLogo(
                            basedOnProfile[0].link,
                            ".",
                            "."
                          ) +
                          "/"
                        }
                        onClick={(e) =>
                          handleApplyRedirect(e, basedOnProfile[0].link)
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="sdessdetailsbox1">
                      <div className="sdessdetailsbox1snamebox">
                        <b
                          className="sdessdetailsbox1sname"
                          onClick={(e) =>
                            handleApplyRedirect(e, basedOnProfile[0].link)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {basedOnProfile[0].name}
                        </b>
                        <div className="sdessdetailslovebox1">
                          {/* <img
                  className="sdessdetailsbox1health-icon"
                  alt=""
                  src="/health.svg"
                /> */}
                          {BookmarkChecker(
                            basedOnProfile[0].id,
                            basedOnProfile[0]
                          )}
                          {/* <img
                  className="sdessdetailsbox1arrows-icon"
                  alt=""
                  src="/arrows.svg"
                /> */}
                          <svg
                            className="sdessdetailsbox1arrows-icon"
                            onClick={(e) =>
                              copyToClipboard(e, basedOnProfile[0].id)
                            }
                            aria-describedby={idP}
                            variant="contained"
                            xmlns="http://www.w3.org/2000/svg"
                            width="76"
                            height="88"
                            viewBox="0 0 76 88"
                            fill="none"
                          >
                            <path
                              d="M56 0H8C3.6 0 0 3.6 0 8V64H8V8H56V0ZM68 16H24C19.6 16 16 19.6 16 24V80C16 84.4 19.6 88 24 88H68C72.4 88 76 84.4 76 80V24C76 19.6 72.4 16 68 16ZM68 80H24V24H68V80Z"
                              fill="#434343"
                              fill-opacity="0.44"
                            />
                          </svg>
                          {/* 
<Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Open Popover
      </Button> */}
                          <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Typography sx={{ p: 2 }}>Copied.</Typography>
                          </Popover>
                        </div>
                      </div>
                      <div className="sdessdetailsslinkbox1">
                        <div className="sdessdetailsslinkbox1text">
                          {getSubstring(basedOnProfile[0].link, ".", ".")}
                        </div>
                      </div>
                      <div className="sdessdetailssamountbox1">
                        <div className="sdessdetailssamountboxtext1">
                          $
                          {basedOnProfile[0].amount
                            ? basedOnProfile[0].amount
                            : "Varies"}
                        </div>
                      </div>
                      <div className="sdessdetailssamountbox1">
                        <div className="sdessdetailssdeadlinetextbox1">
                          Deadline:{" "}
                          {DeadlineOrganiser(
                            basedOnProfile[0].deadline !== null
                              ? basedOnProfile[0].deadline.slice(
                                  0,
                                  basedOnProfile[0].deadline.indexOf("T")
                                )
                              : "not specified"
                          )}
                        </div>
                      </div>
                      <div className="sdessdetailsschecklistbox1">
                        <div className="sdessdetailsscheclistboxtext1">
                          Checklist
                        </div>
                      </div>

                      {/* <div className="sdessdetailsschecklistbox11">
              <div className="checkmarkcirclefill-wrapper">
                <div className="checkmarkcirclefill">
                  <div className="group">
                    <img className="vector-icon" alt="" src="/vector.svg" />
                    <img
                      className="vector-icon1"
                      alt=""
                      src="/vector1.svg"
                    />
                    <div className="vector-parent">
                      <img
                        className="vector-icon2"
                        alt=""
                        src="/vector2.svg"
                      />
                      <div className="letters-of-recommendation">
                        Letters of Recommendation
                      </div>
                    </div>
                  </div>
                  <div className="group-parent">
                    <img className="group-icon" alt="" src="/group2.svg" />
                    <div className="letters-of-recommendation">
                      Transcripts
                    </div>
                  </div>
                  <div className="group-group">
                    <img className="group-icon1" alt="" src="/group3.svg" />
                    <div className="letters-of-recommendation">Essay</div>
                  </div>
                </div>
              </div>
            </div>
 */}
                      {checklists.map((elem) => (
                        <div className="checkRow">
                          <img
                            className="checkmarkcirclefill-icon"
                            alt=""
                            src="/check.png"
                          />
                          <p className="checkText">{elem}</p>
                        </div>
                      ))}

                      <div className="sdessdetailssbuttonbox1">
                        <div className="sdessdetailssbuttonbox2">
                          <button
                            className="sdessdetailsslbuttonbox3"
                            onClick={(e) =>
                              handleApplyRedirect(e, basedOnProfile[0].link)
                            }
                          >
                            <div className="button-text-apply">Apply</div>
                            <img
                              className="vector-icon45"
                              alt=""
                              src="/vector16.svg"
                            />
                          </button>
                        </div>
                      </div>

                      {didYouApply ? (
                        AppliedChecker(basedOnProfile[0].id, basedOnProfile[0])
                      ) : (
                        <div></div>
                      )}
                      <div className="sdessdetailssdesbox1">
                        <div className="sdessdetailssdesboxtext">
                          {/* <p className="the-2000-nitro">
                  The $2,000 Nitro Scholarship Sweepstakes (the
                  “Sweepstakes”) begins at 12:00:01 AM Eastern Time (“ET”)
                  Sunday, January 01, 2023 and ends at 11:59:59 PM ET on
                  Sunday, December 31, 2023 (the “Sweepstakes Period”).
                  Sponsor’s computer is the official clock for this
                  Sweepstakes. The $2,000 Nitro Scholarship Sweepstakes is
                  only open to legal residents of the 50 United States, the
                  District of Columbia and any US Territory age 17 or older
                  at the time of entry, and who meet one (1) of the four (4)
                  following eligibility requirements at the time of entry:
                </p>
                <ul className="a-parent-who-currently-has-a-c">
                  <li className="a-student-who-is-currently-enr">
                    a parent who currently has a child enrolled in an
                    accredited college or university located within the
                    United States;
                  </li>
                  <li className="a-student-who-is-currently-enr">
                    a student who is currently enrolled in an accredited
                    college or university located within the United States;
                  </li>
                  <li className="a-student-who-is-currently-enr">
                    a parent who currently has a child enrolled as a high
                    school senior located within the United States;
                  </li>
                  <li className="a-student-who-is-currently-enr">
                    a student who is currently enrolled as a high school
                    junior or senior who intends to enroll in an accredited
                    college or university located with the United States,
                    within a year of graduating high school (“Entrant”).
                  </li>
                </ul> */}
                          <p className="the-2000-nitro">
                            {basedOnProfile[0].details}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="sdescriptiontitleunderbox1">
          <div className="sdescriptiontitlebox1">
            <div className="sdescriptiontitlebox2">
              <button className="sdescriptiontitlebox3">
                <div
                  className="sdescriptiontitlebox1text"
                  onClick={() => backtoscholarships()}
                >
                  Scholarships
                </div>
                <div className="sdescriptiontitleboxarrow">{`>`}</div>
                <div className="sdescriptiontitlebox1text">
                  {basedOnProfile[0].name}
                </div>
              </button>
            </div>
            <div className="sdescriptiontitle2box">
              <div className="sdescriptiontitle2boxsname">
                {scholarship.name}
              </div>
            </div>
          </div>
        </div>
        <div className="sdescriptiontitleunderbox1">
          <div className="sdescriptiontitlebackbox1">
            <div
              className="sdescriptiontitlebackbox2"
              onClick={() => backtoscholarships()}
            >
              <div className="sdescriptiontitlebackbox1arrow">{`<`}</div>
              <div className="sdescriptiontitlebackbox1text">Back</div>
            </div>
          </div>
        </div>
        <div className="sdessdetailsunderbox">
          <div className="sdessdetailsunder2box">
            <div className="selection-wrapper">
              <div className="selection">
                <div className="sdesdetailssidecard-parent">
                  <div className="sdesdetailssidecard">
                    <div className="sdesdetailssidecardcurrent">
                      <div className="sdesdetailssidecardcurrenthead1">
                        Based on Your Profile
                      </div>
                    </div>

                    {/* <div className="sdesdetailssidecardcurrentbox">
                <div className="sdesdetailssidecardcurimgbox">
                  <img
                    className="sdesdetailssidecardcurimgbox1-icon"
                    alt=""
                    src="/cardscholarshipsimage@2x.png"
                  />
                </div>
                <div className="sdesdetailssidecardcurdetbox">
                  <b className="sdesdetailssidecardcurdetsname">
                    “Be Bold” No-Essay Scholarship
                  </b>
                  <div className="sdesdetailssidecardcurdetslink">
                    Bold.org
                  </div>
                  <div className="sdesdetailssidecardcurdetslink">
                    $25,000
                  </div>
                  <div className="sdesdetailssidecardcurdetdeadl">
                    <div className="sdesdetailssidecardcurdetsdead">
                      Deadline: 04/30/2023
                    </div>
                    <img
                      className="sdesdetailssidecardcurdetheart-icon1"
                      alt=""
                      src="/group1.svg"
                    />
                  </div>
                </div>
              </div> */}

                    <div
                      className="sdesdetailssidecardcurrentbox"
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      <div
                        className="sdesdetailssidecardcurimgbox"
                        style={{
                          backgroundColor:
                            hoveredIndex === index
                              ? "#ccc"
                              : Number(basedOnProfile[0].amount) > 1000 &&
                                Number(basedOnProfile[0].amount) < 3000
                              ? colors[0]
                              : Number(basedOnProfile[0].amount) > 3000 &&
                                Number(basedOnProfile[0].amount) < 6000
                              ? colors[1]
                              : Number(basedOnProfile[0].amount) > 6000 &&
                                Number(basedOnProfile[0].amount) < 10000
                              ? colors[2]
                              : Number(basedOnProfile[0].amount) > 10000
                              ? colors[3]
                              : colors[1],
                        }}
                      >
                        <img
                          className="sdesdetailssidecardcurimgbox1-icon"
                          alt=""
                          src={
                            "https://logo.clearbit.com/" +
                            getSubstringForLogo(
                              basedOnProfile[0].link,
                              ".",
                              "."
                            ) +
                            "/"
                          }
                        />
                      </div>
                      <div className="sdesdetailssidecardcurdetbox">
                        <b className="sdesdetailssidecardcurdetsname">
                          {extractScholarshipInfo(basedOnProfile[0].name)}
                        </b>
                        <div className="sdesdetailssidecardcurdetslink">
                          {getSubstring(basedOnProfile[0].link, ".", ".")}
                        </div>
                        <div className="sdesdetailssidecardcurdetslink">
                          $
                          {basedOnProfile[0].amount
                            ? basedOnProfile[0].amount
                            : "Varies"}
                        </div>
                        <div className="sdesdetailssidecardcurdetdeadl">
                          <div className="sdesdetailssidecardcurdetsdead">
                            Deadline:
                            {DeadlineOrganiser(
                              basedOnProfile[0].deadline !== null
                                ? basedOnProfile[0].deadline.slice(
                                    0,
                                    basedOnProfile[0].deadline.indexOf("T")
                                  )
                                : "not specified"
                            )}
                          </div>
                          {/* <img
                    className="sdesdetailssidecardcurdetheart-icon"
                    alt=""
                    src="/group.svg"
                  /> */}
                          {BookmarkCheckerL(
                            basedOnProfile[0].id,
                            basedOnProfile[0]
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 
            <div className="sdesdetailssidecardbox">
              <div className="sdesdetailssidecardcurimgbox">
                <img
                  className="sdesdetailssidecardcurimgbox1-icon"
                  alt=""
                  src="/cardscholarshipsimage@2x.png"
                />
              </div>
              <div className="sdesdetailssidecardcurdetbox">
                <b className="sdesdetailssidecardcurdetsname">
                  “Be Bold” No-Essay Scholarship
                </b>
                <div className="sdesdetailssidecardcurdetslink">
                  Bold.org
                </div>
                <div className="sdesdetailssidecardcurdetslink">
                  $25,000
                </div>
                <div className="sdesdetailssidecardcurdetdeadl">
                  <div className="sdesdetailssidecardcurdetsdead">
                    Deadline: 04/30/2023
                  </div>
                  <img
                    className="sdesdetailssidecardcurdetheart-icon1"
                    alt=""
                    src="/group1.svg"
                  />
                </div>
              </div>
            </div>
            <div className="sdesdetailssidecardbox">
              <div className="sdesdetailssidecardcurimgbox">
                <img
                  className="sdesdetailssidecardcurimgbox1-icon"
                  alt=""
                  src="/cardscholarshipsimage@2x.png"
                />
              </div>
              <div className="sdesdetailssidecardcurdetbox">
                <b className="sdesdetailssidecardcurdetsname">
                  “Be Bold” No-Essay Scholarship
                </b>
                <div className="sdesdetailssidecardcurdetslink">
                  Bold.org
                </div>
                <div className="sdesdetailssidecardcurdetslink">
                  $25,000
                </div>
                <div className="sdesdetailssidecardcurdetdeadl">
                  <div className="sdesdetailssidecardcurdetsdead">
                    Deadline: 04/30/2023
                  </div>
                  <img
                    className="sdesdetailssidecardcurdetheart-icon1"
                    alt=""
                    src="/group1.svg"
                  />
                </div>
              </div>
            </div>
            <div className="sdesdetailssidecardbox">
              <div className="sdesdetailssidecardcurimgbox">
                <img
                  className="sdesdetailssidecardcurimgbox1-icon"
                  alt=""
                  src="/cardscholarshipsimage@2x.png"
                />
              </div>
              <div className="sdesdetailssidecardcurdetbox">
                <b className="sdesdetailssidecardcurdetsname">
                  “Be Bold” No-Essay Scholarship
                </b>
                <div className="sdesdetailssidecardcurdetslink">
                  Bold.org
                </div>
                <div className="sdesdetailssidecardcurdetslink">
                  $25,000
                </div>
                <div className="sdesdetailssidecardcurdetdeadl">
                  <div className="sdesdetailssidecardcurdetsdead">
                    Deadline: 04/30/2023
                  </div>
                  <img
                    className="sdesdetailssidecardcurdetheart-icon1"
                    alt=""
                    src="/group1.svg"
                  />
                </div>
              </div>
            </div>
            <div className="sdesdetailssidecardbox">
              <div className="sdesdetailssidecardcurimgbox">
                <img
                  className="sdesdetailssidecardcurimgbox1-icon"
                  alt=""
                  src="/cardscholarshipsimage@2x.png"
                />
              </div>
              <div className="sdesdetailssidecardcurdetbox">
                <b className="sdesdetailssidecardcurdetsname">
                  “Be Bold” No-Essay Scholarship
                </b>
                <div className="sdesdetailssidecardcurdetslink">
                  Bold.org
                </div>
                <div className="sdesdetailssidecardcurdetslink">
                  $25,000
                </div>
                <div className="sdesdetailssidecardcurdetdeadl">
                  <div className="sdesdetailssidecardcurdetsdead">
                    Deadline: 04/30/2023
                  </div>
                  <img
                    className="sdesdetailssidecardcurdetheart-icon1"
                    alt=""
                    src="/group1.svg"
                  />
                </div>
              </div>
            </div> */}

                  {basedOnProfile.slice(1, 7).map((elem) => (
                    <div className="sdesdetailssidecardbox">
                      <div
                        className="sdesdetailssidecardcurimgbox"
                        style={{
                          backgroundColor:
                            Number(elem.amount) > 1000 &&
                            Number(elem.amount) < 3000
                              ? colors[0]
                              : Number(elem.amount) > 3000 &&
                                Number(elem.amount) < 6000
                              ? colors[1]
                              : Number(elem.amount) > 6000 &&
                                Number(elem.amount) < 10000
                              ? colors[2]
                              : Number(elem.amount) > 10000
                              ? colors[3]
                              : colors[1],
                        }}
                        onClick={() => SelectScholarship(elem.id)}
                      >
                        <img
                          className="sdesdetailssidecardcurimgbox1-icon"
                          alt=""
                          //  src="/cardscholarshipsimage@2x.png"
                          src={
                            "https://logo.clearbit.com/" +
                            getSubstringForLogo(elem.link, ".", ".") +
                            "/"
                          }
                        />
                      </div>
                      <div className="sdesdetailssidecardcurdetbox">
                        <b
                          className="sdesdetailssidecardcurdetsname"
                          onClick={() => SelectScholarship(elem.id)}
                          style={{ cursor: "pointer" }}
                        >
                          {extractScholarshipInfo(elem.name)}
                        </b>
                        <div className="sdesdetailssidecardcurdetslink">
                          {getSubstring(elem.link, ".", ".")}
                        </div>
                        <div className="sdesdetailssidecardcurdetslink">
                          ${elem.amount ? elem.amount : "Varies"}
                        </div>
                        <div className="sdesdetailssidecardcurdetdeadl">
                          <div className="sdesdetailssidecardcurdetsdead">
                            Deadline:
                            {DeadlineOrganiser(
                              elem.deadline !== null
                                ? elem.deadline.slice(
                                    0,
                                    elem.deadline.indexOf("T")
                                  )
                                : "not specified"
                            )}
                          </div>
                          {/* <img
                   className="sdesdetailssidecardcurdetheart-icon"
                   alt=""
                   src="/group.svg"
                 /> */}
                          {BookmarkCheckerL(elem.id, elem)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {loading ? (
            <div className="sdesdetailsbox">
              <CircularProgress />
            </div>
          ) : (
            <div
              className="sdessdetailsbox2"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div
                className="sdessdetailsimgbox"
                style={{
                  backgroundColor:
                    hoveredIndex === index
                      ? "ccc"
                      : Number(basedOnProfile[0].amount) > 1000 &&
                        Number(basedOnProfile[0].amount) < 3000
                      ? colors[0]
                      : Number(basedOnProfile[0].amount) > 3000 &&
                        Number(scholarship.amount) < 6000
                      ? colors[1]
                      : Number(basedOnProfile[0].amount) > 6000 &&
                        Number(scholarship.amount) < 10000
                      ? colors[2]
                      : Number(basedOnProfile[0].amount) > 10000
                      ? colors[3]
                      : colors[1],
                }}
                onClick={(e) => handleApplyRedirect(e, basedOnProfile[0].link)}
              >
                <img
                  className="sdessdetailsimg-icon"
                  alt=""
                  // src="/image-32@2x.png"
                  src={
                    "https://logo.clearbit.com/" +
                    getSubstringForLogo(basedOnProfile[0].link, ".", ".") +
                    "/"
                  }
                />
              </div>
              <div className="sdessdetailsbox1">
                <div className="sdessdetailsbox1snamebox">
                  <b
                    className="sdessdetailsbox1sname"
                    onClick={(e) =>
                      handleApplyRedirect(e, basedOnProfile[0].link)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {basedOnProfile[0].name}
                  </b>
                  <div className="sdessdetailslovebox1">
                    {/* <img
                  className="sdessdetailsbox1health-icon"
                  alt=""
                  src="/health.svg"
                /> */}
                    {BookmarkChecker(basedOnProfile[0].id, basedOnProfile[0])}
                    {/* <img
                  className="sdessdetailsbox1arrows-icon"
                  alt=""
                  src="/arrows.svg"
                /> */}
                    <svg
                      className="sdessdetailsbox1arrows-icon"
                      onClick={(e) => copyToClipboard(e, basedOnProfile[0].id)}
                      aria-describedby={idP}
                      variant="contained"
                      xmlns="http://www.w3.org/2000/svg"
                      width="76"
                      height="88"
                      viewBox="0 0 76 88"
                      fill="none"
                    >
                      <path
                        d="M56 0H8C3.6 0 0 3.6 0 8V64H8V8H56V0ZM68 16H24C19.6 16 16 19.6 16 24V80C16 84.4 19.6 88 24 88H68C72.4 88 76 84.4 76 80V24C76 19.6 72.4 16 68 16ZM68 80H24V24H68V80Z"
                        fill="#434343"
                        fill-opacity="0.44"
                      />
                    </svg>
                    {/* 
<Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Open Popover
      </Button> */}
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Typography sx={{ p: 2 }}>Copied.</Typography>
                    </Popover>
                  </div>
                </div>
                <div className="sdessdetailsslinkbox1">
                  <div className="sdessdetailsslinkbox1text">
                    {getSubstring(basedOnProfile[0].link, ".", ".")}
                  </div>
                </div>
                <div className="sdessdetailssamountbox1">
                  <div className="sdessdetailssamountboxtext1">
                    $
                    {basedOnProfile[0].amount
                      ? basedOnProfile[0].amount
                      : "Varies"}
                  </div>
                </div>
                <div className="sdessdetailssamountbox1">
                  <div className="sdessdetailssdeadlinetextbox1">
                    Deadline:{" "}
                    {DeadlineOrganiser(
                      basedOnProfile[0].deadline !== null
                        ? basedOnProfile[0].deadline.slice(
                            0,
                            basedOnProfile[0].deadline.indexOf("T")
                          )
                        : "not specified"
                    )}
                  </div>
                </div>
                <div className="sdessdetailsschecklistbox1">
                  <div className="sdessdetailsscheclistboxtext1">Checklist</div>
                </div>

                {/* <div className="sdessdetailsschecklistbox11">
              <div className="checkmarkcirclefill-wrapper">
                <div className="checkmarkcirclefill">
                  <div className="group">
                    <img className="vector-icon" alt="" src="/vector.svg" />
                    <img
                      className="vector-icon1"
                      alt=""
                      src="/vector1.svg"
                    />
                    <div className="vector-parent">
                      <img
                        className="vector-icon2"
                        alt=""
                        src="/vector2.svg"
                      />
                      <div className="letters-of-recommendation">
                        Letters of Recommendation
                      </div>
                    </div>
                  </div>
                  <div className="group-parent">
                    <img className="group-icon" alt="" src="/group2.svg" />
                    <div className="letters-of-recommendation">
                      Transcripts
                    </div>
                  </div>
                  <div className="group-group">
                    <img className="group-icon1" alt="" src="/group3.svg" />
                    <div className="letters-of-recommendation">Essay</div>
                  </div>
                </div>
              </div>
            </div>
 */}
                {checklists.map((elem) => (
                  <div className="checkRow">
                    <img
                      className="checkmarkcirclefill-icon"
                      alt=""
                      src="/check.png"
                    />
                    <p className="checkText">{elem}</p>
                  </div>
                ))}

                <div className="sdessdetailssbuttonbox1">
                  <div className="sdessdetailssbuttonbox2">
                    <button
                      className="sdessdetailsslbuttonbox3"
                      onClick={(e) =>
                        handleApplyRedirect(e, basedOnProfile[0].link)
                      }
                    >
                      <div className="button-text-apply">Apply</div>
                      <img
                        className="vector-icon45"
                        alt=""
                        src="/vector16.svg"
                      />
                    </button>
                  </div>
                </div>

                {didYouApply ? (
                  AppliedChecker(basedOnProfile[0].id, basedOnProfile[0])
                ) : (
                  <div></div>
                )}
                <div className="sdessdetailssdesbox1">
                  <div className="sdessdetailssdesboxtext">
                    {/* <p className="the-2000-nitro">
                  The $2,000 Nitro Scholarship Sweepstakes (the
                  “Sweepstakes”) begins at 12:00:01 AM Eastern Time (“ET”)
                  Sunday, January 01, 2023 and ends at 11:59:59 PM ET on
                  Sunday, December 31, 2023 (the “Sweepstakes Period”).
                  Sponsor’s computer is the official clock for this
                  Sweepstakes. The $2,000 Nitro Scholarship Sweepstakes is
                  only open to legal residents of the 50 United States, the
                  District of Columbia and any US Territory age 17 or older
                  at the time of entry, and who meet one (1) of the four (4)
                  following eligibility requirements at the time of entry:
                </p>
                <ul className="a-parent-who-currently-has-a-c">
                  <li className="a-student-who-is-currently-enr">
                    a parent who currently has a child enrolled in an
                    accredited college or university located within the
                    United States;
                  </li>
                  <li className="a-student-who-is-currently-enr">
                    a student who is currently enrolled in an accredited
                    college or university located within the United States;
                  </li>
                  <li className="a-student-who-is-currently-enr">
                    a parent who currently has a child enrolled as a high
                    school senior located within the United States;
                  </li>
                  <li className="a-student-who-is-currently-enr">
                    a student who is currently enrolled as a high school
                    junior or senior who intends to enroll in an accredited
                    college or university located with the United States,
                    within a year of graduating high school (“Entrant”).
                  </li>
                </ul> */}
                    <p className="the-2000-nitro">
                      {basedOnProfile[0].details}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {scholarshipNotDetail.link !== "" &&
        isSavedScholarshipNotificationPopupOpen && (
          <PortalPopupNot
            overlayColor="rgba(255, 255, 255, 0)"
            placement="Top right"
            onOutsideClick={closeSavedScholarshipNotificationPopup}
          >
            <SavedScholarshipNotification
              onClose={closeSavedScholarshipNotificationPopup}
              scholarship={scholarshipNotDetail}
            />
          </PortalPopupNot>
        )}
    </>
  );
};

export default SCHOLARSHIPSDESCRIPTION1;
