import { useState, useCallback, useEffect, useRef } from "react";
import NewMessages from "../components/NewMessages";
import PortalPopup from "../components/PortalPopup";
import "./MyMessages.css";
import { useSelector } from "react-redux";
import axios from "axios";
import { click } from "@testing-library/user-event/dist/click";
import { useNavigate } from "react-router-dom";
// import SenderConversation from "../components/Conversation";
import Message from "../components/Message";
import Conversation from "../components/Conversation";
import { BASE_Server_URL, publicRequest } from "../requestMethods";



function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Event listener to update the window size whenever the window is resized
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures that this effect runs once after the initial render

  return windowSize;
}

const MyMessages = () => {
  const containerRef = useRef(null);

  const windowSize = useWindowSize();
  // const [isNewMessagesPopupOpen, setNewMessagesPopupOpen] = useState(false);

  // const openNewMessagesPopup = useCallback(() => {
  //   setNewMessagesPopupOpen(true);
  // }, []);

  // const closeNewMessagesPopup = useCallback(() => {
  //   setNewMessagesPopupOpen(false);
  // }, []);
  const PF = process.env.React_App_Public;

  const [isNewMessagePopupOpen, setNewMessagePopupOpen] = useState(false);
  const navigate = useNavigate();

  const openNewMessagePopup = useCallback(() => {
    setNewMessagePopupOpen(true);
  }, []);

  const closeNewMessagePopup = useCallback(() => {
    setNewMessagePopupOpen(false);
  }, []);

  const [conversations, setConversations] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [coach, setCoach] = useState({});
  const [mobileWindow, SetMobileWindow] = useState(false);

  const [newWindow, SetNewWindow] = useState(false);



  const scrollRef = useRef();


  const [selectedCoach, setSelectedCoach] = useState(null);


//   const [user, setUser] = useState({
//     id: '1'
//   });

//   const userd = useSelector(state => state.user.currentUser)
// useEffect(() => {
 
// setUser(userd)
// },[])


//   useEffect(() => {

//     console.log(user.id)
//     const getConversations = async () => {
//       try {

        
//   const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
//   const currentUser = user && JSON.parse(user).currentUser;
//   const TOKEN = currentUser?.tokens.access.token;
  
//         const client = axios.create({
//           baseURL: "http://localhost:3000/v1/",
//           headers: { Authorization: `Bearer ${TOKEN}` },
//           });
//         const res = await client.get("/conversation");
//         console.log('message', res)
//         setConversations(res.data);
//       } catch (err) {
//         console.log(err);
//       }
//     };
//     getConversations();
//   }, [user.id]);


  const [user, setUser] = useState({
    id: '1'
  });
  
  const userd = useSelector(state => state.user.currentUser);
  
  useEffect(() => {
    if (userd) {
      setUser(userd);
    }
  }, [userd]);

  const getConversations = async () => {
    try {
      // ... (rest of your code)
    const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
    const currentUser = user && JSON.parse(user).currentUser;
    const TOKEN = currentUser?.tokens.access.token;

    const client = axios.create({
      baseURL: BASE_Server_URL,
      headers: { Authorization: `Bearer ${TOKEN}` },
      });
    const res = await client.get("/conversation");
      console.log('concos', res.data)
    setConversations(res.data);
      
    } catch (err) {
      console.log(err);
    }
  };
  
  useEffect(() => {
    if (user && user.id) {
      console.log(user.id);
      
      getConversations();
    }
  }, [user && user.id]);

//   useEffect(()=>{
// console.log(currentChat)
//   },[currentChat])

useEffect(() => {
  // console.log('window size nnnn', windowSize)

  if(windowSize.width  > 850) {
    SetMobileWindow(true)
    SetNewWindow(false)
  }
  if(windowSize.width  < 850) {
    SetMobileWindow(false)
    SetNewWindow(true)
  }
}, [windowSize])


// const divStyle = {
//   display: mobileWindow ? 'none' : 'block',
//   // Add other styles here
// };
  
  useEffect(() => {
    const getMessages = async () => {
      console.log('called')
      try {


        const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
        const currentUser = user && JSON.parse(user).currentUser;
        const TOKEN = currentUser?.tokens.access.token;
  
        const client = axios.create({
          baseURL: BASE_Server_URL,
          headers: { Authorization: `Bearer ${TOKEN}` },
          });


        const res = await client.get("/message/" + currentChat?.id);
        // const res = await axios.get("/messages/" + currentChat?._id);
        console.log('messages',res )
        setMessages(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getMessages();

    // console.log(currentUser)
  // const friendId  =  currentChat.members.find(member => member !== user.user.id)
  const friendId = currentChat?.members?.find(member => member !== user.user.id);



  // console.log(friendId)
  // console.log(conversation.members)

  const getCoach = async ()=>{
    try{
      const res = await publicRequest.get("/coach/coachProfileOwner/"+friendId)
      setCoach(res.data);
      console.log('current coaches',res)
    }catch (err) {
            console.log(err);
          }
  }
  getCoach()



  }, [currentChat]);

  // useEffect(() =>{
  //   console.log('selectedcoach', selectedCoach)

  // }, [selectedCoach])


  const [coaches, setCoaches]  = useState([]);

  const getCoaches = async () =>{
    console.log('getCoaches called')
  try {
      const res = await publicRequest.get(`/coach`);
      //console.log('rfvnmd,s.de',res)
      const pageC = await res.data.totalPages
      setCoaches(
        res.data.results
        )
   

        //console.log('rfvnmd,s.de',  res.data.results)

    

  } catch (error) {
      
  }
};
useEffect(() => {

    getCoaches()
},[])
//   return (
//     <>
//       <div className="my-messages">
//         <div className="mymessagesheader">
//           <div className="messages">Messages</div>
//         </div>
//         <div className="mymessagesheader1">
//           <div className="sidemymessgage">
//             <div className="mymessageslistbox">

//               <div className="mymessageslistbox1">
//                 <div className="mymessageslistbox2">
//                   <img
//                     className="mymessageslistprofile-icon"
//                     alt=""
//                     src="https://i.imgur.com/wfnoQM1.jpg"
//                   />
//                   <div className="mymessageslistbox3">
//                     <div className="mymessageslistheader">
//                       <div className="mymessageslistname">
//                         Jessica Howes nowle
//                       </div>
//                       <div className="mymessageslistdate">June 17, 2023</div>
//                     </div>
//                     <div className="mymessageslistsummary">
//                       <span className="mymessageslistsummary-txt">
//                         <p className="coca-cola-scholarship-form">{`$50,000 Coca-cola scholarship form. `}</p>
//                         <p className="coca-cola-scholarship-form">{`Mode of app... `}</p>
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="mymessageslistbox1">
//                 <div className="mymessageslistbox2">
//                   <img
//                     className="mymessageslistprofile-icon"
//                     alt=""
//                     src="https://i.imgur.com/wfnoQM1.jpg"
//                   />
//                   <div className="mymessageslistbox3">
//                     <div className="mymessageslistheader">
//                       <div className="mymessageslistname">
//                         Jessica Howes nowle
//                       </div>
//                       <div className="mymessageslistdate">June 17, 2023</div>
//                     </div>
//                     <div className="mymessageslistsummary">
//                       <span className="mymessageslistsummary-txt">
//                         <p className="coca-cola-scholarship-form">{`$50,000 Coca-cola scholarship form. `}</p>
//                         <p className="coca-cola-scholarship-form">{`Mode of app... `}</p>
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="mymessageslistbox1">
//                 <div className="mymessageslistbox2">
//                   <img
//                     className="mymessageslistprofile-icon"
//                     alt=""
//                     src="https://i.imgur.com/wfnoQM1.jpg"
//                   />
//                   <div className="mymessageslistbox3">
//                     <div className="mymessageslistheader">
//                       <div className="mymessageslistname">
//                         Jessica Howes nowle
//                       </div>
//                       <div className="mymessageslistdate">June 17, 2023</div>
//                     </div>
//                     <div className="mymessageslistsummary">
//                       <span className="mymessageslistsummary-txt">
//                         <p className="coca-cola-scholarship-form">{`$50,000 Coca-cola scholarship form. `}</p>
//                         <p className="coca-cola-scholarship-form">{`Mode of app... `}</p>
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="mymessageslistbox1">
//                 <div className="mymessageslistbox2">
//                   <img
//                     className="mymessageslistprofile-icon"
//                     alt=""
//                     src="https://i.imgur.com/t8BvZHF.jpg"
//                   />
//                   <div className="mymessageslistbox3">
//                     <div className="mymessageslistheader">
//                       <div className="mymessageslistname">
//                         Jessica Howes nowle
//                       </div>
//                       <div className="mymessageslistdate">June 17, 2023</div>
//                     </div>
//                     <div className="mymessageslistsummary">
//                       <span className="mymessageslistsummary-txt">
//                         <p className="coca-cola-scholarship-form">{`$50,000 Coca-cola scholarship form. `}</p>
//                         <p className="coca-cola-scholarship-form">{`Mode of app... `}</p>
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="mymessageslistbox1">
//                 <div className="mymessageslistbox2">
//                   <img
//                     className="mymessageslistprofile-icon"
//                     alt=""
//                     src="https://i.imgur.com/t8BvZHF.jpg"
//                   />
//                   <div className="mymessageslistbox3">
//                     <div className="mymessageslistheader">
//                       <div className="mymessageslistname">
//                         Jessica Howes nowle
//                       </div>
//                       <div className="mymessageslistdate">June 17, 2023</div>
//                     </div>
//                     <div className="mymessageslistsummary">
//                       <span className="mymessageslistsummary-txt">
//                         <p className="coca-cola-scholarship-form">{`$50,000 Coca-cola scholarship form. `}</p>
//                         <p className="coca-cola-scholarship-form">{`Mode of app... `}</p>
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="mymessagessearch">
//               <div className="mymessagessearchinput">
//                 <div className="searchinputnynessages">{`Search... `}</div>
//                 {/* <img
//                   className="searchmessahesicm-icon"
//                   alt=""
//                   src="/searchmessahesicm.svg"
//                 /> */}
//                 <svg  className="searchmessahesicm-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
//   <path d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z" stroke="#B2B1B1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
//   <path d="M17.5 17.5L13.875 13.875" stroke="#B2B1B1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// </svg>
//               </div>
//               <button
//                 className="mymessagesnewicon"
//                 onClick={openNewMessagesPopup}
//               >
//                 {/* <img
//                   className="mymessagesnewicon1"
//                   alt=""
//                   src="/mymessagesnewicon1.svg"
//                 />
//                 <div className="div">+</div> */}
//                 <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
//   <path d="M24.25 12.375C24.2543 14.0249 23.8688 15.6524 23.125 17.125C22.243 18.8897 20.8872 20.374 19.2093 21.4116C17.5314 22.4493 15.5978 22.9993 13.625 23C11.9752 23.0043 10.3476 22.6189 8.875 21.875L1.75 24.25L4.125 17.125C3.38116 15.6524 2.9957 14.0249 3 12.375C3.00076 10.4022 3.55076 8.4686 4.5884 6.79072C5.62603 5.11285 7.11032 3.75699 8.875 2.87504C10.3476 2.1312 11.9752 1.74573 13.625 1.75004H14.25C16.8554 1.89378 19.3163 2.99349 21.1614 4.83861C23.0065 6.68373 24.1063 9.1446 24.25 11.75V12.375Z" stroke="#010101" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// </svg>
//               </button>
//               <img
//                 className="mymessagesnewicon11"
//                 alt=""
//                 src="/mymessagesnewicon11.svg"
//               />
//             </div>
//           </div>
//           <div className="writemessagebody">
//             <div className="writemessagebodytypemessage">
//               <div className="writemessagebodytypemessage1">
//                 {/* <img
//                   className="writemessagepaperclip-icon"
//                   alt=""
//                   src="/writemessagepaperclip.svg"
//                 /> */}
//                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
//   <path d="M21.4403 11.0499L12.2503 20.2399C11.1244 21.3658 9.59747 21.9983 8.00529 21.9983C6.41311 21.9983 4.88613 21.3658 3.76029 20.2399C2.63445 19.1141 2.00195 17.5871 2.00195 15.9949C2.00195 14.4027 2.63445 12.8758 3.76029 11.7499L12.9503 2.55992C13.7009 1.80936 14.7188 1.3877 15.7803 1.3877C16.8417 1.3877 17.8597 1.80936 18.6103 2.55992C19.3609 3.31048 19.7825 4.32846 19.7825 5.38992C19.7825 6.45138 19.3609 7.46936 18.6103 8.21992L9.41029 17.4099C9.03501 17.7852 8.52602 17.996 7.99529 17.996C7.46456 17.996 6.95557 17.7852 6.58029 17.4099C6.20501 17.0346 5.99418 16.5256 5.99418 15.9949C5.99418 15.4642 6.20501 14.9552 6.58029 14.5799L15.0703 6.09992" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// </svg>
//                 <input
//                   className="writebodytypemessageinput1"
//                   type="text"
//                   placeholder="Hello"
//                 />
//                 <div className="writemessagebodysendbutton">
//                   <div className="writemessagebodysendbutton1">
//                     <div className="writemessagebodysendbuttontext">Send</div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="writemessagebodybox">
//               <div className="writemessagebodysendermessage">
//                 <div className="writemessagebodysendermessage1">
//                   <div className="writebodysendermessagetext">{`hvnsxd vgfgh gvbgvgvfcfv `}</div>
//                 </div>
//               </div>
//               <div className="writemessagebodyreceivermessag">
//                 <div className="writemessagebodyreceivermessag1">
//                   <div className="writebodysendermessagetext">{`hvnsxd vgfgh gvbgvgvfcfv `}</div>
//                 </div>
//               </div>
//             </div>
//             <div className="writemessagebodyheader">
//               <div className="today">Today</div>
//             </div>
//             <div className="writemessagebodyreceiver">
//               <img
//                 className="writemessagebodyreceiverprofil-icon"
//                 alt=""
//                 src="https://i.imgur.com/t8BvZHF.jpg"
//               />
//               <div className="today">Jessica Howes</div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {isNewMessagesPopupOpen && (
//         <PortalPopup
//           overlayColor="rgba(113, 113, 113, 0.3)"
//           placement="Centered"
//           onOutsideClick={closeNewMessagesPopup}
//         >
//           <NewMessages onClose={closeNewMessagesPopup} />
//         </PortalPopup>
//       )}
//     </>
//   );

const handleSubmit = async (e) => {
  e.preventDefault();
  const message = {
    sender: user.user.id,
    text: newMessage,
    conversationId: currentChat.id,
  };

  // const receiverId = currentChat.members.find(
  //   (member) => member !== user._id
  // );

  // socket.current.emit("sendMessage", {
  //   senderId: user._id,
  //   receiverId,
  //   text: newMessage,
  // });

 

  try {
    // ... (rest of your code)
    
  
const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
const currentUser = user && JSON.parse(user).currentUser;
const TOKEN = currentUser?.tokens.access.token;

  const client = axios.create({
    baseURL: BASE_Server_URL,
    headers: { Authorization: `Bearer ${TOKEN}` },
    });
  // const res = await client.get("/conversation");

  const res = await client.post("/message", message);
    setMessages([...messages, res.data]);
    setNewMessage("");
    
    console.log('new message',res.data)
    
  } catch (err) {
    console.log(err);
  }
};

useEffect(() => {
  scrollRef.current?.scrollIntoView({ behavior: "smooth" });
}, [messages]);
// {
//   "id": "6578e2ff8a97326bb4bcf6e5",
//   "members": [
//       "650b7710a52fd0614c0dea72",
//       "657487ff11b923ae38a4c6d5"
//   ],
//   "createdAt": "2023-12-12T22:47:27.465Z"
// }

function CheckIfConversationExist(userId, coachId){
  const conversationToFind = [userId, coachId]

  const foundConversation = conversations.find((conversation) =>
  conversation.members.every((member) => conversationToFind.includes(member))
);

return(foundConversation);


  
}
const ConversationStart = async (userId, coachId, userName, coachName) => {


  console.log('coachbh.,mhb', coachName)
  console.log('cuser.,mhb', userName)
 
  const conversation = {
    
    senderId: userId,
    receiverId: coachId,
    coachName: coachName,
    userName: userName

  };

  // const receiverId = currentChat.members.find(
  //   (member) => member !== user._id
  // );

  // socket.current.emit("sendMessage", {
  //   senderId: user._id,
  //   receiverId,
  //   text: newMessage,
  // });

 

  try {
    // ... (rest of your code)
    
  
const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
const currentUser = user && JSON.parse(user).currentUser;
const TOKEN = currentUser?.tokens.access.token;

  const client = axios.create({
    baseURL:BASE_Server_URL,
    headers: { Authorization: `Bearer ${TOKEN}` },
    });
  // const res = await client.get("/conversation");

  const res = await client.post("/conversation",conversation);
    //setMessages([...messages, res.data]);
    //setNewMessage("");
    //setc
    
    console.log('new conversatiob',res.data)

    getConversations()
    
  } catch (err) {
    console.log(err);
  }
};

function StartConversation(coachId, coachName){

  console.log('coachId',coachId)
  console.log('Id',userd.user.id)

  console.log(coachId)

  //check if conversations exist

  const convo = CheckIfConversationExist(userd.user.id, coachId)

  if(convo){
    setCurrentChat(convo)
  }
  else{
    //console.log('convofound',convo)
  
    ConversationStart(userd.user.id, coachId, userd.user.name, coachName)
  
  
  }

  closeNewMessagePopup()



  
}


const [searchTerm, setSearchTerm] = useState(''); // Initialize search term state

    const filteredConversations = conversations.filter(conversation =>
        conversation.coachName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

return (
  <>
    <div className="my-messages"> 
      <div className="mymessagesheader2">
        <div className="messages">Messages</div>
      </div>

      {/* <div className="mymessagesheader">
        <button
          className="mymessagesheaderback"
        //  onClick={onMyMessagesHeaderBackClick}
        >
          Back
        </button>
      </div> */}

      
      <div className="mymessagesheader11">
        <div className="sidemymessgage" >
          <div className="mymessageslistbox">

         {filteredConversations.map((c) => (
              // <Conversation conversation = {c} currentUser={user}/>
              <div onClick={() => {
               
                if(mobileWindow) {
                  // console.log('current chatsxfgvhbnm', c)
                  // console.log('click mobile window yesn', mobileWindow)
                  // console.log('click mobile new', newWindow)
                  setCurrentChat(c)}
              
                else{
                  // console.log('click mobile window yesn', mobileWindow)
                  // console.log('click mobile new', newWindow)
                  // console.log('current chatsxfgvhbnm', c)
                  setCurrentChat(c)
                }
              }
               
                }>
              <Conversation conversation={c} currentUser={user} />
            </div>
            ))}

          </div>
          <div className="mymessagessearch">
            <input
              className="mymessagessearchinput"
              placeholder="Search... "
              type="text"
              value={searchTerm}
              onChange={handleInputChange}
            />
       
           <button
                className="mymessagesnewicon"
                onClick={openNewMessagePopup}

              >
                {/* <img
                  className="mymessagesnewicon1"
                  alt=""
                  src="/mymessagesnewicon1.svg"
                />
                <div className="div">+</div> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                  <path d="M24.25 12.375C24.2543 14.0249 23.8688 15.6524 23.125 17.125C22.243 18.8897 20.8872 20.374 19.2093 21.4116C17.5314 22.4493 15.5978 22.9993 13.625 23C11.9752 23.0043 10.3476 22.6189 8.875 21.875L1.75 24.25L4.125 17.125C3.38116 15.6524 2.9957 14.0249 3 12.375C3.00076 10.4022 3.55076 8.4686 4.5884 6.79072C5.62603 5.11285 7.11032 3.75699 8.875 2.87504C10.3476 2.1312 11.9752 1.74573 13.625 1.75004H14.25C16.8554 1.89378 19.3163 2.99349 21.1614 4.83861C23.0065 6.68373 24.1063 9.1446 24.25 11.75V12.375Z" stroke="#010101" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>



             
            <img
              className="mymessagesnewicon11"
              alt=""
              src="/mymessagesnewicon11.svg"
            />
          </div>
        </div>



        
        
        {mobileWindow && !newWindow && (
        <div className="writemessagebody1" >
           <div className="mymessagesheader">
          
      </div>

        {currentChat ? (
              <>
                  <div className="writemessagebodyreceiver1">
            <img
              className="writemessagebodyreceiverprofil-icon1"
              alt=""
              // src="https://i.imgur.com/t8BvZHF.jpg"
              src={
                coach?.img
                  ? coach.img
                  : PF + "noprofile.png"
              }
            />

            
            <div className="writemessagebodyreceivername1">{coach.name}</div>
          </div>
          <div className="writemessagebodyheader1">
            <div className="today1">Today</div>
          </div>
          <div className="writemessagebodybox1" >

            {messages.map( m => (
              <div ref={scrollRef}>
               <Message message={m} own={m.sender === user.user.id} currentUser={user} />
              </div>
            )

            )}


          </div>



          <div className="writemessagebodytypemessage2">
            <div className="writemessagebodytypemessage11">
              <button className="writemessagepaperclip1">
                <svg   className="writemessagepaperclipicon1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M21.4403 11.0499L12.2503 20.2399C11.1244 21.3658 9.59747 21.9983 8.00529 21.9983C6.41311 21.9983 4.88613 21.3658 3.76029 20.2399C2.63445 19.1141 2.00195 17.5871 2.00195 15.9949C2.00195 14.4027 2.63445 12.8758 3.76029 11.7499L12.9503 2.55992C13.7009 1.80936 14.7188 1.3877 15.7803 1.3877C16.8417 1.3877 17.8597 1.80936 18.6103 2.55992C19.3609 3.31048 19.7825 4.32846 19.7825 5.38992C19.7825 6.45138 19.3609 7.46936 18.6103 8.21992L9.41029 17.4099C9.03501 17.7852 8.52602 17.996 7.99529 17.996C7.46456 17.996 6.95557 17.7852 6.58029 17.4099C6.20501 17.0346 5.99418 16.5256 5.99418 15.9949C5.99418 15.4642 6.20501 14.9552 6.58029 14.5799L15.0703 6.09992" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
              </button>
              <textarea
                className="writebodytypemessageinput11"
                placeholder="Hello"
                type="text"
                onChange={(e) => setNewMessage(e.target.value)}
                value={newMessage}
               
              />
              <button className="writemessagebodysendbutton11" onClick={handleSubmit} disabled={newMessage === ''}>
                <div className="writemessagebodysendbuttontext1">Send</div>
              </button>
            </div>
          </div>
              </>
            ) : (

              <div className="noconversationBox">
              <span className="noConversationText">
                Open a conversation to start a chat.
              </span>
              </div>
            )}
      
        </div>
          )}
      </div>


    </div>
    {isNewMessagePopupOpen && (
      <PortalPopup
        overlayColor="rgba(113, 113, 113, 0.3)"
        placement="Centered"
        onOutsideClick={closeNewMessagePopup}
      >
        <NewMessages
          onClose={closeNewMessagePopup}
          selectedCoach = {selectedCoach}
          setSelectedCoach = {setSelectedCoach}
          coaches={coaches}
          StartConversation={StartConversation}
        />
      </PortalPopup>
    )}
  </>
);
};

export default MyMessages;
