import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BASE_Server_URL } from '../requestMethods';

export const ReviewNumber = (props) => {

    const [reviewLength, setReviewLength] = useState(0)

    console.log('chdbxnkmzl,.;', props)

    const getReviews = async (id) =>{
        console.log('id ndnsk', id)
        try {
    
          console.log('get reviews')
          const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
          const currentUser = user && JSON.parse(user).currentUser;
          const TOKEN = currentUser?.tokens.access.token;
      
      //console.log(currentUser)
           const client = axios.create({
            baseURL: BASE_Server_URL,
            headers: { Authorization: `Bearer ${TOKEN}` },});
            const res = await client.get('/review/'+ id)
            //console.log('coach',coach)
            console.log('reviews',res.data)
            setReviewLength(res.data.length)
            
           
    
    
        } catch (error) {
            console.log(error)
        }
    };

    useEffect (() => {
        getReviews(props.id)
    }, [])
  return (
    <a className="ratingBoxNumber1">{`(${reviewLength})`}</a>

  )
}
