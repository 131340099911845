import { useState, useCallback, useEffect } from "react";
import SavedCoachNotification from "./SavedCoachNotification";
import PortalPopup from "./PortalPopup";
import BookedSessionNotification from "./BookedSessionNotification";
import "./SessionBooked.css";
import { format, parseISO } from 'date-fns';
import { useNavigate } from "react-router-dom";


const SessionBooked = ({ onClose, coach, bookingData, service }) => {
  const navigate = useNavigate();
  console.log('coachmnxm session', coach)
  console.log('bookings session', bookingData)

  const bookingDate = bookingData.startTime;
  const parsedDate = parseISO(bookingDate);
  const formattedDate = format(parsedDate, 'dd MMMM, yyyy');




// Format date as "10:30 AM"
const formattedTime = format(parsedDate, 'hh:mm a');

  const [
    isSavedCoachNotificationPopupOpen,
    setSavedCoachNotificationPopupOpen,
  ] = useState(false);
  const [
    isBookedSessionNotificationPopupOpen,
    setBookedSessionNotificationPopupOpen,
  ] = useState(false);

  const openSavedCoachNotificationPopup = useCallback(() => {
    setSavedCoachNotificationPopupOpen(true);
  }, []);

  const closeSavedCoachNotificationPopup = useCallback(() => {
    setSavedCoachNotificationPopupOpen(false);
  }, []);

  const openBookedSessionNotificationPopup = useCallback(() => {
    setBookedSessionNotificationPopupOpen(true);
  }, []);

  const closeBookedSessionNotificationPopup = useCallback(() => {
    setBookedSessionNotificationPopupOpen(false);
  }, []);


  useEffect(() => {
    openBookedSessionNotificationPopup();

    // Set a timeout to close the notification after 5 seconds
    const timeoutId = setTimeout(() => {
      closeBookedSessionNotificationPopup();
    }, 3000);

    // Clean up the timeout when the component unmounts or the effect re-runs
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      <div className="sessionbooked">
        <div className="leftsessionbooked">
          <div className="leftsessionbookedbox">
            {/* <img
              className="leftsessionbookedimgbox-icon"
              alt=""
              src="/leftsessionbookedimgbox@2x.png"
            /> */}
            <img
             className="leftsessionbookedimgbox-icon"
              alt=""
              src={coach.img}
            />
            <div className="leftsessionbookeddetails">
              <div className="leftsessionbookeddetailsbox">
                <b className="leftsessionbookedname">{coach.name}</b>
                <div className="leftsessionbookedexpertise">
                  {coach.expertise} Expertise
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rightsessionbookedbox">
          <div className="rightsessionbookedbox1">
            <b className="rightsessionbookedboxheader">Session Booked</b>
            {/* <img
              className="rightsessionbookedboxcheckbox-icon"
              alt=""
              src="/rightsessionbookedboxcheckbox.svg"
            /> */}

<svg xmlns="http://www.w3.org/2000/svg" width="131" height="131" viewBox="0 0 131 131" fill="none">
<path d="M57.3125 87.6636L36.8438 67.1907L42.6282 61.4062L57.3125 76.0864L88.3636 45.0312L94.1562 50.8239L57.3125 87.6636Z" fill="#3DD598"/>
<path d="M65.5 8.1875C54.1647 8.1875 43.0839 11.5488 33.6589 17.8464C24.2339 24.144 16.888 33.095 12.5502 43.5675C8.21233 54.0399 7.07735 65.5636 9.28877 76.6811C11.5002 87.7986 16.9587 98.0108 24.974 106.026C32.9893 114.041 43.2014 119.5 54.3189 121.711C65.4365 123.923 76.9601 122.788 87.4326 118.45C97.9051 114.112 106.856 106.766 113.154 97.3411C119.451 87.9161 122.813 76.8353 122.813 65.5C122.813 50.2998 116.774 35.7221 106.026 24.9739C95.2779 14.2258 80.7002 8.1875 65.5 8.1875ZM65.5 114.625C55.784 114.625 46.2862 111.744 38.2076 106.346C30.1291 100.948 23.8326 93.2757 20.1144 84.2993C16.3963 75.3229 15.4234 65.4455 17.3189 55.9162C19.2144 46.3869 23.8931 37.6336 30.7634 30.7634C37.6337 23.8931 46.3869 19.2144 55.9162 17.3189C65.4455 15.4234 75.3229 16.3963 84.2993 20.1144C93.2758 23.8326 100.948 30.129 106.346 38.2076C111.744 46.2862 114.625 55.784 114.625 65.5C114.625 78.5288 109.449 91.0239 100.237 100.237C91.0239 109.449 78.5288 114.625 65.5 114.625Z" fill="#3DD598"/>
</svg>
            <div className="rightsessionbookeddatesessionb">
              <div className="rightsessiondatebox1">
                <b className="rightsessiondateboxtitle1">Date</b>
                <div className="rightsessiondatebox3">
                  <div className="rightsessiondateboxdate">{formattedDate}</div>
                  <div className="rightsessiondatebox3time">{formattedTime}</div>
                </div>
              </div>
              <div className="rightsessiontypebox">
                <b className="rightsessiontypeboxtitle">
                  <p className="session-type">Session Type</p>
                </b>
                <div className="rightsessiontypeboxtitlemin">{service.duration}min 1:1</div>
              </div>
            </div>
            <div className="rightsessionbutton">
              <div
                className="rightsessionbuttonbox"
                //onClick={openSavedCoachNotificationPopup}
               // openBookedSessionNotificationPopup
               onClick={() => navigate('/mybookings')}
              >
                <div className="rightsessionbuttonboxtext">
                  View Session Details
                </div>
              </div>
              <div
                className="rightsessionbuttonbox1"
                onClick={() => navigate('/dashboard')}
              >
                <div className="rightsessionbuttonboxtext">
                  Back to Dashboard
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isBookedSessionNotificationPopupOpen && (
        <PortalPopup
          placement="Top right"
          onOutsideClick={closeBookedSessionNotificationPopup}
        >
          <BookedSessionNotification
            onClose={closeBookedSessionNotificationPopup}
            coach={coach}
            bookingDate={bookingData.startTime}

          />
        </PortalPopup>
      )}
    </>
  );
};

export default SessionBooked;
