import { useEffect, useState } from "react";
import "./BookedSessionNotification.css";
import axios from "axios";
const BookedSessionNotification = ({ onClose, coach, bookingDate }) => {

  const date = new Date(bookingDate);

const options = {
  year: "numeric",
  month: "short",
  day: "numeric"
};

const formattedDate = date.toLocaleDateString("en-US", options);


  return (
    <div className="bookedsessionnotification">
      <div className="rightbookedsessionnot">
        {/* <img
          className="rightbookedsessionnoticon"
          alt=""
          src="/carboncheckmarkoutline.svg"
        /> */}
        <svg className="rightbookedsessionnoticon" xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none">
  <path d="M24.0625 36.8053L15.4688 28.2098L17.8973 25.7812L24.0625 31.9447L37.0992 18.9062L39.5312 21.3383L24.0625 36.8053Z" fill="#3DD598"/>
  <path d="M27.5 3.4375C22.7409 3.4375 18.0887 4.84874 14.1316 7.49276C10.1745 10.1368 7.09039 13.8948 5.26916 18.2917C3.44793 22.6885 2.97141 27.5267 3.89986 32.1944C4.82832 36.862 7.12005 41.1496 10.4853 44.5148C13.8505 47.88 18.138 50.1717 22.8057 51.1001C27.4733 52.0286 32.3115 51.5521 36.7083 49.7309C41.1052 47.9096 44.8632 44.8255 47.5072 40.8684C50.1513 36.9114 51.5625 32.2591 51.5625 27.5C51.5625 21.1182 49.0274 14.9978 44.5148 10.4852C40.0022 5.97265 33.8818 3.4375 27.5 3.4375ZM27.5 48.125C23.4208 48.125 19.4331 46.9154 16.0414 44.6491C12.6496 42.3828 10.0061 39.1616 8.445 35.3928C6.88394 31.6241 6.47549 27.4771 7.27131 23.4763C8.06713 19.4754 10.0315 15.8004 12.9159 12.9159C15.8004 10.0315 19.4754 8.06712 23.4763 7.2713C27.4771 6.47548 31.6241 6.88393 35.3929 8.44498C39.1616 10.006 42.3828 12.6496 44.6491 16.0414C46.9154 19.4331 48.125 23.4208 48.125 27.5C48.125 32.9701 45.952 38.2161 42.0841 42.0841C38.2161 45.952 32.9701 48.125 27.5 48.125Z" fill="#3DD598"/>
</svg>
        <div className="rightbookedsessionnotdetails">
          <b className="rightbookedsessionnotdetailste">Upcoming Session</b>
          <div className="rightbookedsessionnotdetailste1">
            on {formattedDate}
            {/* Dec 8, 2023 */}
            </div>
        </div>
      </div>
      <div className="leftbookedsessionnotbox">
        <img
          className="leftbookedsessionnotboximg-icon"
          alt=""
          src={coach.img}
        />
        <div className="leftbookedsessionnotdetails">
          <b className="leftbookedsessionnotboxname">{coach.name}</b>
          <div className="leftbookedsessionnotboxexperti">
            {coach.expertise} Expertise
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookedSessionNotification;
