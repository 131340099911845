import { useState, useCallback } from "react";
import SavedScholarshipNotification from "./SavedScholarshipNotification";
import PortalPopup from "./PortalPopup";
import SessionBooked from "./SessionBooked";

import Rating from '@mui/material/Rating';
import { Avatar } from "@mui/material";


import StripeCheckout from 'react-stripe-checkout'

import "./BookCoach.css";
import { Link } from "react-router-dom";
const BookCoach = ({ onClose, coach, review,selectedService }) => {

  console.log('bsgvhbn', selectedService)
  const [
    isSavedScholarshipNotificationPopupOpen,
    setSavedScholarshipNotificationPopupOpen,
  ] = useState(false);
  const [isSessionBookedPopupOpen, setSessionBookedPopupOpen] = useState(false);

  const onLeftBookCoachBookingServiceIcoIconClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='leftBookCoachBookingServiceMinText']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const openSavedScholarshipNotificationPopup = useCallback(() => {
    setSavedScholarshipNotificationPopupOpen(true);
  }, []);

  const closeSavedScholarshipNotificationPopup = useCallback(() => {
    setSavedScholarshipNotificationPopupOpen(false);
  }, []);

  const openSessionBookedPopup = useCallback(() => {
    setSessionBookedPopupOpen(true);
  }, []);

  const closeSessionBookedPopup = useCallback(() => {
    setSessionBookedPopupOpen(false);
  }, []);


  //const Key = process.env.REACT_APP_STRIPE;




  return (
    <>
      <div className="bookcoach">
        <div className="rightbookcoach">
          <div className="rightbookcoachinner">
            {/* <img
              className="rightbookcoachprofile-icon"
              alt=""
              src="/cardimgsmallshadow@2x.png"
            /> */}

             <Avatar
              alt="Remy Sharp"
              className="rightbookcoachprofile-icon"
                src={coach.img}
                    sx={{ width: 154, height: 174 }}
                />
            <div className="rightbookcoachdetails">
              <div className="rightbookcoachdetailsbox">
                <b className="rightbookcoachdetailsname">{coach.name}</b>
                <div className="rightbookcoachdetailsexpertise">
                 {coach.expertise} Expertise
                </div>
                {/* <div className="rightbookcoachratingbox">
                  
                  <Rating
                     name="read-only"
                     value={coach.rating ? coach.rating.$numberDecimal : 0}
                     size="small"
                     className="stars-icon3"
                     readOnly
                   />
               
                  <div className="rightbookcoachratingamount">{review.length}</div>
                </div> */}
                  {/* <div className="coach-review-stars"> */}
                 
                  {/* <img className="stars-icon3" alt="" src="/stars3.svg" /> */}
                  <div className="coach-review-stars-book">
<div style={{width: '100px'}}>
                  <Rating
  name="read-only"
  value={coach.rating ? coach.rating.$numberDecimal : 0}
  size="small"
  className="stars-icon3"
  readOnly
/>
</div>


          </div>
                 
                
                {/* <div className="rightbookcoachhoursbox">
                
                  <svg className="rightbookcoachhoursicon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M12.55 14.55L9 11V6H11V10.175L13.95 13.125L12.55 14.55ZM9 4V2H11V4H9ZM16 11V9H18V11H16ZM9 18V16H11V18H9ZM2 11V9H4V11H2ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z" fill="#010101"/>
</svg>
                  <div className="rightbookcoachnumber">150 hours tutoring</div>
                </div> */}
                {/* <div className="rightbookcoachratebox">
                  <div className="rightbookcoachnumber">
                    Hourly Rate: $40.00
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="leftbookcoachdetails">
          <div className="leftbookcoachdetailsbox">
            <b className="leftbookcoachheading">Confirm Your Booking</b>
{/* 
            <div className="leftbookcoachbookingdatebox">
              <div className="leftbookcoachbookingdateday">
                <div className="leftbookcoachbookingdateboxico">
                  

                  <svg className="vector-icon1-clock" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
  <path d="M36.6912 4.21547H30.4867V1.11319C30.4867 0.838934 30.3777 0.57591 30.1838 0.38198C29.9899 0.18805 29.7268 0.0791016 29.4526 0.0791016C29.1783 0.0791016 28.9153 0.18805 28.7214 0.38198C28.5274 0.57591 28.4185 0.838934 28.4185 1.11319V4.21547H13.9412V1.11319C13.9412 0.838934 13.8323 0.57591 13.6384 0.38198C13.4444 0.18805 13.1814 0.0791016 12.9071 0.0791016C12.6329 0.0791016 12.3699 0.18805 12.1759 0.38198C11.982 0.57591 11.873 0.838934 11.873 1.11319V4.21547H5.6685C4.29772 4.21711 2.98354 4.76238 2.01425 5.73167C1.04496 6.70096 0.499689 8.01513 0.498047 9.38592V36.2723C0.499689 37.6431 1.04496 38.9572 2.01425 39.9265C2.98354 40.8958 4.29772 41.4411 5.6685 41.4427H36.6912C38.0622 41.4416 39.3767 40.8965 40.3461 39.9271C41.3155 38.9577 41.8606 37.6432 41.8617 36.2723V9.38592C41.8606 8.01497 41.3155 6.70048 40.3461 5.73107C39.3767 4.76166 38.0622 4.21656 36.6912 4.21547ZM39.7935 36.2723C39.7935 37.0951 39.4667 37.8841 38.8849 38.4659C38.3031 39.0477 37.514 39.3746 36.6912 39.3746H5.6685C4.84573 39.3746 4.05665 39.0477 3.47486 38.4659C2.89307 37.8841 2.56623 37.0951 2.56623 36.2723V18.6927H39.7935V36.2723ZM39.7935 16.6246H2.56623V9.38592C2.56623 7.67346 3.95191 6.28365 5.6685 6.28365H11.873V9.38592C11.873 9.66018 11.982 9.9232 12.1759 10.1171C12.3699 10.3111 12.6329 10.42 12.9071 10.42C13.1814 10.42 13.4444 10.3111 13.6384 10.1171C13.8323 9.9232 13.9412 9.66018 13.9412 9.38592V6.28365H28.4185V9.38592C28.4185 9.66018 28.5274 9.9232 28.7214 10.1171C28.9153 10.3111 29.1783 10.42 29.4526 10.42C29.7268 10.42 29.9899 10.3111 30.1838 10.1171C30.3777 9.9232 30.4867 9.66018 30.4867 9.38592V6.28365H36.6912C37.514 6.28365 38.3031 6.61049 38.8849 7.19228C39.4667 7.77407 39.7935 8.56315 39.7935 9.38592V16.6246Z" fill="black"/>
</svg>
                </div>
                <div className="leftbookcoachbookingdatetext">8 Dec 2023</div>
              </div>
              <div className="leftbookcoachbookingtimebox">
                <div className="leftbookcoachbookingtimebox1">
                  <div className="leftbookcoachbookingtimebox">

                    <svg  className="vector-icon1-clock" xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51" fill="none">
  <path d="M25.6813 0.940918C32.264 0.940918 38.5771 3.55587 43.2317 8.21053C47.8864 12.8652 50.5013 19.1782 50.5013 25.7609C50.5013 32.3436 47.8864 38.6567 43.2317 43.3113C38.5771 47.966 32.264 50.5809 25.6813 50.5809C19.0987 50.5809 12.7856 47.966 8.13094 43.3113C3.47628 38.6567 0.861328 32.3436 0.861328 25.7609C0.861328 19.1782 3.47628 12.8652 8.13094 8.21053C12.7856 3.55587 19.0987 0.940918 25.6813 0.940918ZM25.6813 3.55355C19.7916 3.55355 14.143 5.89325 9.97835 10.0579C5.81366 14.2226 3.47396 19.8712 3.47396 25.7609C3.47396 31.6507 5.81366 37.2992 9.97835 41.4639C14.143 45.6286 19.7916 47.9683 25.6813 47.9683C28.5976 47.9683 31.4854 47.3939 34.1797 46.2779C36.874 45.1618 39.3222 43.526 41.3843 41.4639C43.4465 39.4018 45.0822 36.9536 46.1983 34.2593C47.3143 31.565 47.8887 28.6772 47.8887 25.7609C47.8887 19.8712 45.549 14.2226 41.3843 10.0579C37.2196 5.89325 31.5711 3.55355 25.6813 3.55355ZM24.375 11.3914H26.9876V25.5519L39.267 32.6321L37.9607 34.9051L24.375 27.0672V11.3914Z" fill="black"/>
</svg>
                  </div>
                  <div className="leftbookcoachbookingdatetext">8:30 AM</div>
                </div>
              </div>
            </div>
 */}


            <div className="leftbookcoachbookingrate">
              <div className="leftbookcoachbookingrateprice">US${selectedService.price}.00</div>
              <div className="leftbookcoachbookingservice">
                {selectedService.name}
              </div>
            </div>
            <div className="leftbookcoachbookingservicebox">
              {/* <img
                className="leftbookcoachbookingserviceico-icon"
                alt=""
                src="/vector4.svg"
                onClick={onLeftBookCoachBookingServiceIcoIconClick}
              /> */}
              <svg xmlns="http://www.w3.org/2000/svg"     className="leftbookcoachbookingserviceico-icon" width="25" height="19" viewBox="0 0 25 19" fill="none">
  <path d="M22.5715 0.753418L8.2535 15.0224L2.42922 9.21808L0.730469 10.9715L8.2535 18.4083L24.2703 2.44635L22.5715 0.753418Z" fill="#43A047"/>
</svg>
              <b
                className="leftbookcoachbookingservicemin"
                data-scroll-to="leftBookCoachBookingServiceMinText"
              >
                {" "}
                {selectedService.duration} min 1:1
              </b>
            </div>
            <div className="leftbookcoachbookingbuttonbox">
              <div
                className="leftbookcoachbookingbuttonsess"
                //onClick={openSavedScholarshipNotificationPopup}
                onClick={onClose}
              >
                <div className="leftbookcoachbookingbuttonsess1" >
                  Change Service
                </div>
              </div>

              <Link to= {`/pay/${coach.owner}/${selectedService._id}`}>
              <div
                className="leftbookcoachbookingbuttonsess2"
               // onClick={openSessionBookedPopup}
              >
                <div className="leftbookcoachbookingbuttonsess1">
                  Continue (${selectedService.price})
                </div>
              </div>
              </Link>
            
            </div>
            <div className="leftbookcoachbookingwontcharge">
              You wont be charged yet
            </div>
          </div>
        </div>
      </div>
      {isSavedScholarshipNotificationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(255, 255, 255, 0.3)"
          placement="Top right"
          onOutsideClick={closeSavedScholarshipNotificationPopup}
        >
          <SavedScholarshipNotification
            onClose={closeSavedScholarshipNotificationPopup}
          />
        </PortalPopup>
      )}
      {isSessionBookedPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeSessionBookedPopup}
        >
          <SessionBooked onClose={closeSessionBookedPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default BookCoach;
