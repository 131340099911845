import React, { useState, useEffect } from "react";
import "./Footer.css";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorAction } from "../redux/apiCalls";
import MailchimpSubscribe from "./NewsletterSubscribe";

const NewsletterForm = ({ status, message, onValidated }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [values, setValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [submittedEmail, setSubmittedEmail] = useState(null);
  const dispatch = useDispatch();
  const error = useSelector((state) => state.user.error);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (error && error.message) {
      console.log(error);
      return () => {
        console.log("error clearing xsnb");
        clearErrorAction()(dispatch);
      };
    }
  }, [error, dispatch]);

  function isValidEmail(email) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  const validateForm = (values) => {
    const errorsF = {};
    if (!values.email) {
      errorsF.email = "Please Fill in the form!";
    } else if (!isValidEmail(values.email)) {
      errorsF.email = "Not a Valid Email!";
    }
    return errorsF;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setFormErrors({});

    const errorsF = validateForm(values);
    setFormErrors(errorsF);

    if (Object.keys(errorsF).length === 0) {
      setSubmittedEmail(values.email);
      setValues({});
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="footer-menu4">
      <span className="students">Join our Email List</span>
      <div className="footer-links4">
        <div className="main-footer-link12">
          <span className="footer-link12">
            Be the first to know about new scholarships, events, workshops and
            much more!
          </span>
        </div>
        <input
          className="subscribe-email-text-box"
          placeholder="Email"
          type="text"
          name="email"
          onChange={onChange}
          value={values.email || ""}
        />
        <span>{formErrors.email}</span>
        <button className="buttonlargefooter" onClick={handleFormSubmit}>
          <span className="buttontextfooter">Subscribe</span>
        </button>
        {/* <div>{error && <b>{error.message}</b>}</div> */}
        {submittedEmail && <MailchimpSubscribe email={submittedEmail} />}
      </div>
    </div>
  );
};

export default NewsletterForm;
