import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./NavigationDrawer.css";

import DashboardIcon from "@mui/icons-material/Dashboard";
import HomeIcon from "@mui/icons-material/Home";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import HandymanIcon from "@mui/icons-material/Handyman";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import Tooltip from "@mui/material/Tooltip";
import LoginIcon from "@mui/icons-material/Login";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/apiCalls";

import { BASE_Server_URL } from "../requestMethods";

import Avatar from "@mui/material/Avatar";

import axios from "axios";

const NavigationDrawer = ({ onClose }) => {
  const dispatch = useDispatch();
  const { isFetching, error } = useSelector((state) => state.user);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltip1, setShowTooltip1] = useState(false);
  const cuser = useSelector((state) => state.user.currentUser);
  const [appliedScholarship, setAppliedScholarship] = useState([]);
  const [upcomingBookings, setUpcomingBookings] = useState([]);
  const [savedScholarship, setSavedScholarship] = useState([]);
  const [savedCoaches, setSavedCoaches] = useState([]);
  const [filteredScholarship, setFilteredScholarship] = useState([]);

  const getAppliedScholarships = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.get("/appliedscholarship");
      setAppliedScholarship(res.data);
      // console.log("applied@@@mayopo", appliedScholarship.length);
    } catch (error) {}
  };

  useEffect(() => {
    getAppliedScholarships();
  }, []);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);

  const toggleServicesDropdown = () => {
    setIsServicesDropdownOpen((prev) => !prev);
  };

  const getScholarships = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      // const client = axios.create({
      //   baseURL: BASE_Server_URL,
      //   headers: { Authorization: `Bearer ${TOKEN}` },
      // });

      const res = await axios.get(`${BASE_Server_URL}scholarship`);
      setFilteredScholarship(res.data);
    } catch (error) {}
  };
  useEffect(() => {
    getScholarships();
  }, []);

  const getSavedScholarships = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.get("/scholarshipbookmark");
      setSavedScholarship(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    getSavedScholarships();
  }, []);

  const getUpcomingBookings = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.get("/mybookings");
      setUpcomingBookings(res.data);
    } catch (error) {}
  };

  const getBookmarkedCoaches = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.get("/coachbookmark");
      setSavedCoaches(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    getUpcomingBookings();
  }, []);

  useEffect(() => {
    getBookmarkedCoaches();
  }, []);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  const navigate = useNavigate();

  const handleTooltipOpen = useCallback(() => {
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 2000); // Tooltip visible for 2 seconds
  }, []);

  const handleTooltipOpen1 = useCallback(() => {
    setShowTooltip1(true);
    setTimeout(() => setShowTooltip1(false), 2000); // Tooltip visible for 2 seconds
  }, []);

  const onHeadernavLink3Click = useCallback(() => {
    handleTooltipOpen(); // Show tooltip instead of navigating
    console.log("Community Coming Soon");
  }, [handleTooltipOpen]);

  const onResourcesClick = useCallback(() => {
    handleTooltipOpen1(); // Show tooltip instead of navigating
    console.log("Resources Coming Soon");
  }, [handleTooltipOpen]);

  const onHeadernavLink1Click = useCallback(() => {
    navigate("/services");
    onClose();
    // if (!cuser) {
    //   navigate("/login");
    //   onClose();
    // } else {
    //   navigate("/services");
    //   onClose();
    // }
  }, [navigate]);

  const onButtonClick = useCallback(
    (e) => {
      if (!cuser) {
        navigate("/loginmobile");
        onClose();
      } else {
        try {
          // Clear local storage
          localStorage.removeItem("persist:root");

          e.preventDefault();

          console.log("Clearing local storage and logging out...");
          console.log(isFetching);

          // Perform the logout action
          logout(dispatch, { refreshToken: cuser.tokens.refresh.token });

          // Redirect to login page after logout
          navigate("/loginmobile");
        } catch (error) {
          console.error("Error during logout:", error);
        } finally {
          onClose();
        }
      }
    },
    [cuser, dispatch, navigate, onClose, isFetching]
  );

  const onHeadernavLink2Click = useCallback(() => {
    if (cuser) {
      navigate("/scholarships-category-page");
      onClose();
    } else {
      navigate("/loginmobile");
      onClose();
    }
  }, [navigate]);

  // const onHeadernavLink3Click = useCallback(() => {
  //   // navigate("/communities");

  //   console.log("or alert community coming son ");

  //   //
  // }, [navigate]);

  const openNavigationDrawer = useCallback(() => {
    setNavigationDrawerOpen(true);
  }, []);

  const closeNavigationDrawer = useCallback(() => {
    setNavigationDrawerOpen(false);
  }, []);

  const onButtonmediumClick = useCallback(() => {
    onClose();
    navigate("/loginmobile");
  }, [navigate]);

  const onButtonmedium1Click = useCallback(() => {
    onClose();
    navigate("/welcomepathmobile");
  }, [navigate]);

  const onButtonHomeClick = useCallback(() => {
    if (!cuser) {
      navigate("/");
      onClose();
    } else {
      navigate("/dashboard");
      onClose();
    }
  }, [navigate]);

  const onDashBoardClick = useCallback(() => {
    onClose();
    navigate("/dashboard");
  }, [navigate]);

  const onAppliedSClick = useCallback(() => {
    navigate("/applied-scholarship");
    onClose();
    // if (cuser) {
    //   navigate("/applied-scholarship");
    //   onClose();
    // } else {
    //   navigate("/login");
    //   onClose();
    // }
  }, [navigate]);

  const onSavedSClick = useCallback(() => {
    navigate("/saved-scholarship");
    onClose();
    // if (cuser) {
    //   navigate("/saved-scholarship");
    //   onClose();
    // } else {
    //   navigate("/login");
    //   onClose();
    // }
  }, [navigate]);

  const onScholarshipClick = useCallback(() => {
    navigate("/scholarships-category-page");
    onClose();
    // if (cuser) {
    //   navigate("/scholarships-category-page");
    //   onClose();
    // } else {
    //   navigate("/login");
    //   onClose();
    // }
  }, [navigate]);

  const onCoachBookmakClick = useCallback(() => {
    navigate("/bookmarkedcoaches");
    onClose();
    // if (!cuser) {
    //   navigate("/login");
    //   onClose();
    // } else {
    //   navigate("/bookmarkedcoaches");
    //   onClose();
    // }
  }, [navigate]);
  const onViewProfileClick = useCallback(() => {
    if (!cuser) {
      navigate("/loginmobile");
      onClose();
    } else {
      navigate("/viewprofile");
      onClose();
    }
  }, [navigate]);

  const onMessageClick = useCallback(() => {
    onClose();
    navigate("/mymessages");
  }, [navigate]);

  const onNotifyClick = useCallback(() => {
    onClose();

    navigate("/notifications");
  }, [navigate]);

  const onBookingsClick = useCallback(() => {
    navigate("/mybookings");
    onClose();
    // if (!cuser) {
    //   navigate("/login");
    //   onClose();
    // } else {
    //   navigate("/mybookings");
    //   onClose();
    // }
  }, [navigate]);

  const onSettingsClick = useCallback(() => {
    setNav("settings");
    onClose();

    navigate("/settings");
  }, [navigate]);

  const onSupportClick = useCallback(() => {
    if (!cuser) {
      navigate("/loginmobile");
      onClose();
    } else {
      navigate("/support");
      onClose();
    }
  }, [navigate]);

  useEffect(() => {
    console.log("settings nav", nav);
  }, []);

  const [nav, setNav] = useState("");
  const [profile, setProfile] = useState({});

  const viewUserProfile = () => {
    //console.log('view User Profile', userRequest)
    // viewUserProfile()
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });

      // console.log(currentUser)
      // console.log(userRequest)

      const res = client.get("/profile");
      console.log("res", res);
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    viewUserProfile()
      .then((result) => {
        setProfile(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    console.log(profile);
  }, [profile]);

  return (
    <div className="navigation-drawer" data-animate-on-scroll>
      <div className="navigationdrawerbody">
        <div className="navigationdrawerbody1">
          <div className="navigationdrawernamebody">
            <div className="navigationdrawernamebody2">
              {/* <img
                className="navigationavatar-icon"
                alt=""
                src="/navigationavatar@2x.png"
              /> */}
              <Avatar
                sx={{ width: 48, height: 48 }}
                alt={profile.name ? profile.name.charAt(0) : ""}
                /* src="https://i.imgur.com/QpTeP2S.jpg" */ src={`${BASE_Server_URL}files/${profile.profilePic}`}
              />

              {cuser && (
                <div className="navigationname">
                  <div className="navigationnametext">{profile.name}</div>
                  <div className="navigationnameemail">{profile.email}</div>
                  <a
                    className="profiledropdownview"
                    style={{ marginTop: "10px" }}
                    onClick={onViewProfileClick}
                  >
                    View Profile
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="navigationdrawernamebody3">
            <div className="nav-item1" onClick={onButtonHomeClick}>
              <div className="left">
                {/* <img className="dashboard-icon" alt="" src="/home-filled.svg" /> */}
                {cuser ? (
                  <DashboardIcon className="dashboard-icon" />
                ) : (
                  <HomeIcon className="sidenavigationmenunavitemarrow-icon" />
                )}
                <div className="left2">
                  <div
                    className="sidenavigationmenunavitemtext"
                    style={{ marginLeft: "2ppx" }}
                  >
                    {cuser ? "Dashboard" : "Home"}
                  </div>
                </div>
              </div>
            </div>
            <div className="sidenavigationmenu">
              <div className="sidenavigationmenunavitem">
                <div className="left" onClick={toggleDropdown}>
                  <LibraryBooksIcon className="sidenavigationmenunavitemarrow-icon" />

                  <div className="sidenavigationmenunavitemtext">
                    Scholarships
                  </div>

                  {/* <KeyboardArrowDownIcon className="sidenavigationmenunavitemarrow-icon" /> */}
                  {isDropdownOpen ? (
                    <KeyboardArrowUpIcon className="sidenavigationmenunavitemarrow-icon" />
                  ) : (
                    <KeyboardArrowDownIcon className="sidenavigationmenunavitemarrow-icon" />
                  )}
                </div>
              </div>
              {isDropdownOpen && (
                <div className="sidenavigationmenudropdown">
                  <div className="sidemenudropdownnavitem">
                    <div className="sidemenudropdownnavitemleftbod">
                      <div
                        className="sidemenudropdownnavitemlefttex"
                        onClick={onScholarshipClick}
                      >
                        All Scholarships
                      </div>
                      <div className="sidenavno">
                        <div className="sidenavnotext">
                          {filteredScholarship.length}
                        </div>
                      </div>
                    </div>
                  </div>
                  {cuser && (
                    <div className="sidemenudropdownnavitem">
                      <div className="sidemenudropdownnavitemleftbod">
                        <diy
                          className="sidemenudropdownnavitemlefttex"
                          onClick={onSavedSClick}
                        >
                          Saved Scholarships
                        </diy>
                        <div className="sidenavno">
                          <div className="sidenavnotext">
                            {savedScholarship.length}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {cuser && (
                    <div className="sidemenudropdownnavitem">
                      <div className="sidemenudropdownnavitemleftbod">
                        <div
                          className="sidemenudropdownnavitemlefttex"
                          onClick={onAppliedSClick}
                        >
                          Applied Scholarships
                        </div>
                        <div className="sidenavno">
                          <div className="sidenavnotext">
                            {appliedScholarship.length}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="sidenavigationmenu">
              <div className="sidenavigationmenunavitem">
                <div className="left" onClick={toggleServicesDropdown}>
                  <HomeRepairServiceIcon className="sidenavigationmenunavitemarrow-icon" />

                  <div className="sidenavigationmenunavitemtext">Services</div>

                  {/* <KeyboardArrowUpIcon className="sidenavigationmenunavitemarrow-icon" /> */}
                  {isServicesDropdownOpen ? (
                    <KeyboardArrowUpIcon className="sidenavigationmenunavitemarrow-icon" />
                  ) : (
                    <KeyboardArrowDownIcon className="sidenavigationmenunavitemarrow-icon" />
                  )}
                </div>
              </div>
              {isServicesDropdownOpen && (
                <div className="sidenavigationmenudropdown">
                  <div
                    className="sidemenudropdownnavitem"
                    onClick={onHeadernavLink1Click}
                  >
                    <div className="navigationdrawernamebody">
                      <div className="sidemenudropdownnavitemlefttex">
                        Coaches
                      </div>
                    </div>
                  </div>
                  <div
                    className="sidemenudropdownnavitem"
                    onClick={onBookingsClick}
                  >
                    {cuser && (
                      <div className="sidemenudropdownnavitemleftbod">
                        <div className="sidemenudropdownnavitemlefttex">
                          Upcoming Bookings
                        </div>
                        <div className="sidenavnobookings">
                          <div className="sidenavnotext">
                            {upcomingBookings.length}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="sidemenudropdownnavitem"
                    onClick={onCoachBookmakClick}
                  >
                    {cuser && (
                      <div className="sidemenudropdownnavitemleftbod">
                        <div className="sidemenudropdownnavitemlefttex">
                          Saved Coaches
                        </div>
                        <div className="sidenavno">
                          <div className="sidenavnotext">
                            {savedCoaches.length}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="sidenavigationmenu">
              <Tooltip
                title="Coming Soon"
                open={showTooltip}
                disableHoverListener
              >
                <div
                  className="sidenavigationmenunavitem"
                  onClick={onHeadernavLink3Click}
                >
                  <div className="left">
                    <Diversity3Icon className="dashboard-icon" />
                    <div className="dashboard-sideNav">Community</div>
                  </div>
                </div>
              </Tooltip>
              {/* <div className="sidenavigationmenunavitem">
                <div className="left">
                  <Diversity3Icon className="dashboard-icon" />
                  <div className="dashboard-sideNav">Community</div>
                </div>
              </div> */}
            </div>
            <div className="sidenavigationmenu">
              <Tooltip
                title="Coming Soon"
                open={showTooltip1}
                disableHoverListener
              >
                <div
                  className="sidenavigationmenunavitem"
                  onClick={onResourcesClick}
                >
                  <div className="left">
                    <HandymanIcon className="dashboard-icon" />
                    <div className="dashboard-sideNav">Resources</div>
                  </div>
                </div>
              </Tooltip>
            </div>
            {cuser && (
              <div className="sidenavigationmenu">
                <div className="sidenavigationmenunavitem">
                  <div className="left">
                    <LocalPostOfficeIcon className="dashboard-icon" />
                    <div className="sidenavigationmenunavitemtext4">
                      Messages
                    </div>
                    <div className="sidenavnomessages">
                      <div className="sidenavnotext">0</div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="sidenavigationmenu">
              <div className="sidenavigationmenunavitem">
                <div className="left">
                  <NotificationsIcon className="dashboard-icon" />
                  <div className="sidenavigationmenunavitemtext4">
                    Notifications
                  </div>
                  <div className="sidenavnonotification">
                    <div className="sidenavnotext">0</div>
                  </div>
                </div>
              </div>
            </div>

            {cuser && (
              <div className="sidenavigationmenu">
                <div
                  className="sidenavigationmenunavitem"
                  style={{
                    backgroundColor: nav === "settings" ? "blue" : "white",
                  }}
                  onClick={onSettingsClick}
                >
                  <div className="left">
                    <SettingsIcon className="dashboard-icon" />
                    <div className="dashboard-sideNav">Settings</div>
                  </div>
                </div>
              </div>
            )}
            <div className="sidenavigationmenu">
              <div
                className="sidenavigationmenunavitem"
                onClick={onSupportClick}
              >
                <div className="left">
                  <SupportAgentIcon className="dashboard-icon" />
                  <div className="dashboard-sideNav">Support</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sidenavloginsignup">
          <div className="sidenavloginsignupbox">
            <div className="sidenavloginbox" onClick={onButtonClick}>
              <div className="sidenavloginboxtext">
                {cuser ? "Logout" : "Login"}
              </div>

              <LoginIcon className="sidenavloginboxicon" />
            </div>
            {!cuser && (
              <div className="sidenavloginbox1" onClick={onButtonmedium1Click}>
                <div className="sidenavloginboxtext">Signup</div>

                <FollowTheSignsIcon className="sidenavloginboxicon1" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavigationDrawer;
