import { useState, useCallback, useEffect, useRef } from "react";
import NavigationDrawer from "../components/NavigationDrawer";
import PortalDrawer from "../components/PortalDrawer";
import { useNavigate } from "react-router-dom";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import debounce from "lodash.debounce";

import "./PERSONALPROFILE.css";
import axios from "axios";
import { BASE_Server_URL } from "../requestMethods";
const PERSONALPROFILE = (props) => {
  const [isNavigationDrawerOpen, setNavigationDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const openNavigationDrawer = useCallback(() => {
    setNavigationDrawerOpen(true);
  }, []);

  const closeNavigationDrawer = useCallback(() => {
    setNavigationDrawerOpen(false);
  }, []);

  const onFrameButton1Click = useCallback(() => {
    navigate("/education-profile");
  }, [navigate]);

  const onFrameButton2Click = useCallback(() => {
    navigate("/student-activities-profile");
  }, [navigate]);

  const onFrameButton4Click = useCallback(() => {
    navigate("/education-profile");
  }, [navigate]);

  // useEffect(() => {
  //   console.log('hdnkm',profile)
  // },[])

  // const onChange = (e) => {
  //   setValues({...values, [e.target.name]: e.target.value });
  // }
  console.log(props);
  const allCitizenships = [
    { name: "Select Citizenship", value: "" },
    { name: "Afghanistan", value: "Afghan" },
    { name: "Albania", value: "Albanian" },
    { name: "Algeria", value: "Algerian" },
    { name: "Andorra", value: "Andorran" },
    { name: "Angola", value: "Angolan" },
    { name: "Antigua and Barbuda", value: "Antiguan, Barbudan" },
    { name: "Argentina", value: "Argentine" },
    { name: "Armenia", value: "Armenian" },
    { name: "Australia", value: "Australian" },
    { name: "Austria", value: "Austrian" },
    { name: "Azerbaijan", value: "Azerbaijani" },
    { name: "Bahamas", value: "Bahamian" },
    { name: "Bahrain", value: "Bahraini" },
    { name: "Bangladesh", value: "Bangladeshi" },
    { name: "Barbados", value: "Barbadian" },
    { name: "Belarus", value: "Belarusian" },
    { name: "Belgium", value: "Belgian" },
    { name: "Belize", value: "Belizean" },
    { name: "Benin", value: "Beninese" },
    { name: "Bhutan", value: "Bhutanese" },
    { name: "Bolivia", value: "Bolivian" },
    { name: "Bosnia and Herzegovina", value: "Bosnian, Herzegovinian" },
    { name: "Botswana", value: "Botswana" },
    { name: "Brazil", value: "Brazilian" },
    { name: "Brunei", value: "Bruneian" },
    { name: "Bulgaria", value: "Bulgarian" },
    { name: "Burkina Faso", value: "Burkinabé" },
    { name: "Burundi", value: "Burundian" },
    { name: "Cabo Verde", value: "Cabo Verdean" },
    { name: "Cambodia", value: "Cambodian" },
    { name: "Cameroon", value: "Cameroonian" },
    { name: "Canada", value: "Canadian" },
    { name: "Central African Republic", value: "Central African" },
    { name: "Chad", value: "Chadian" },
    { name: "Chile", value: "Chilean" },
    { name: "China", value: "Chinese" },
    { name: "Colombia", value: "Colombian" },
    { name: "Comoros", value: "Comorian" },
    { name: "Congo (Congo-Brazzaville)", value: "Congolese" },
    { name: "Costa Rica", value: "Costa Rican" },
    { name: "Croatia", value: "Croatian" },
    { name: "Cuba", value: "Cuban" },
    { name: "Cyprus", value: "Cypriot" },
    { name: "Czechia (Czech Republic)", value: "Czech" },
    { name: "Denmark", value: "Danish" },
    { name: "Djibouti", value: "Djiboutian" },
    { name: "Dominica", value: "Dominican" },
    { name: "Dominican Republic", value: "Dominican" },
    { name: "Ecuador", value: "Ecuadorian" },
    { name: "Egypt", value: "Egyptian" },
    { name: "El Salvador", value: "Salvadoran" },
    { name: "Equatorial Guinea", value: "Equatorial Guinean" },
    { name: "Eritrea", value: "Eritrean" },
    { name: "Estonia", value: "Estonian" },
    { name: 'Eswatini (fmr. "Swaziland")', value: "Swazi" },
    { name: "Ethiopia", value: "Ethiopian" },
    { name: "Fiji", value: "Fijian" },
    { name: "Finland", value: "Finnish" },
    { name: "France", value: "French" },
    { name: "Gabon", value: "Gabonese" },
    { name: "Gambia", value: "Gambian" },
    { name: "Georgia", value: "Georgian" },
    { name: "Germany", value: "German" },
    { name: "Ghana", value: "Ghanaian" },
    { name: "Greece", value: "Greek" },
    { name: "Grenada", value: "Grenadian" },
    { name: "Guatemala", value: "Guatemalan" },
    { name: "Guinea", value: "Guinean" },
    { name: "Guinea-Bissau", value: "Guinea-Bissauan" },
    { name: "Guyana", value: "Guyanese" },
    { name: "Haiti", value: "Haitian" },
    { name: "Holy See", value: "Vatican" },
    { name: "Honduras", value: "Honduran" },
    { name: "Hungary", value: "Hungarian" },
    { name: "Iceland", value: "Icelandic" },
    { name: "India", value: "Indian" },
    { name: "Indonesia", value: "Indonesian" },
    { name: "Iran", value: "Iranian" },
    { name: "Iraq", value: "Iraqi" },
    { name: "Ireland", value: "Irish" },
    { name: "Israel", value: "Israeli" },
    { name: "Italy", value: "Italian" },
    { name: "Jamaica", value: "Jamaican" },
    { name: "Japan", value: "Japanese" },
    { name: "Jordan", value: "Jordanian" },
    { name: "Kazakhstan", value: "Kazakhstani" },
    { name: "Kenya", value: "Kenyan" },
    { name: "Kiribati", value: "I-Kiribati" },
    { name: "Korea, North", value: "North Korean" },
    { name: "Korea, South", value: "South Korean" },
    { name: "Kosovo", value: "Kosovar" },
    { name: "Kuwait", value: "Kuwaiti" },
    { name: "Kyrgyzstan", value: "Kyrgyzstani" },
    { name: "Laos", value: "Laotian" },
    { name: "Latvia", value: "Latvian" },
    { name: "Lebanon", value: "Lebanese" },
    { name: "Lesotho", value: "Basotho" },
    { name: "Liberia", value: "Liberian" },
    { name: "Libya", value: "Libyan" },
    { name: "Liechtenstein", value: "Liechtensteiner" },
    { name: "Lithuania", value: "Lithuanian" },
    { name: "Luxembourg", value: "Luxembourger" },
    { name: "Madagascar", value: "Malagasy" },
    { name: "Malawi", value: "Malawian" },
    { name: "Malaysia", value: "Malaysian" },
    { name: "Maldives", value: "Maldivian" },
    { name: "Mali", value: "Malian" },
    { name: "Malta", value: "Maltese" },
    { name: "Marshall Islands", value: "Marshallese" },
    { name: "Mauritania", value: "Mauritanian" },
    { name: "Mauritius", value: "Mauritian" },
    { name: "Mexico", value: "Mexican" },
    { name: "Micronesia", value: "Micronesian" },
    { name: "Moldova", value: "Moldovan" },
    { name: "Monaco", value: "Monegasque" },
    { name: "Mongolia", value: "Mongolian" },
    { name: "Montenegro", value: "Montenegrin" },
    { name: "Morocco", value: "Moroccan" },
    { name: "Mozambique", value: "Mozambican" },
    { name: "Myanmar (formerly Burma)", value: "Burmese" },
    { name: "Namibia", value: "Namibian" },
    { name: "Nauru", value: "Nauruan" },
    { name: "Nepal", value: "Nepali" },
    { name: "Netherlands", value: "Dutch" },
    { name: "New Zealand", value: "New Zealander" },
    { name: "Nicaragua", value: "Nicaraguan" },
    { name: "Niger", value: "Nigerien" },
    { name: "Nigeria", value: "Nigerian" },
    { name: "North Macedonia", value: "Macedonian" },
    { name: "Norway", value: "Norwegian" },
    { name: "Oman", value: "Omani" },
    { name: "Pakistan", value: "Pakistani" },
    { name: "Palau", value: "Palauan" },
    { name: "Palestine State", value: "Palestinian" },
    { name: "Panama", value: "Panamanian" },
    { name: "Papua New Guinea", value: "Papua New Guinean" },
    { name: "Paraguay", value: "Paraguayan" },
    { name: "Peru", value: "Peruvian" },
    { name: "Philippines", value: "Filipino" },
    { name: "Poland", value: "Polish" },
    { name: "Portugal", value: "Portuguese" },
    { name: "Qatar", value: "Qatari" },
    { name: "Romania", value: "Romanian" },
    { name: "Russia", value: "Russian" },
    { name: "Rwanda", value: "Rwandan" },
    { name: "Saint Kitts and Nevis", value: "Kittitian, Nevisian" },
    { name: "Saint Lucia", value: "Saint Lucian" },
    { name: "Saint Vincent and the Grenadines", value: "Saint Vincentian" },
    { name: "Samoa", value: "Samoan" },
    { name: "San Marino", value: "Sammarinese" },
    { name: "Sao Tome and Principe", value: "São Toméan" },
    { name: "Saudi Arabia", value: "Saudi" },
    { name: "Senegal", value: "Senegalese" },
    { name: "Serbia", value: "Serbian" },
    { name: "Seychelles", value: "Seychellois" },
    { name: "Sierra Leone", value: "Sierra Leonean" },
    { name: "Singapore", value: "Singaporean" },
    { name: "Slovakia", value: "Slovak" },
    { name: "Slovenia", value: "Slovenian" },
    { name: "Solomon Islands", value: "Solomon Islander" },
    { name: "Somalia", value: "Somali" },
    { name: "South Africa", value: "South African" },
    { name: "South Sudan", value: "South Sudanese" },
    { name: "Spain", value: "Spanish" },
    { name: "Sri Lanka", value: "Sri Lankan" },
    { name: "Sudan", value: "Sudanese" },
    { name: "Suriname", value: "Surinamese" },
    { name: "Sweden", value: "Swedish" },
    { name: "Switzerland", value: "Swiss" },
    { name: "Syria", value: "Syrian" },
    { name: "Taiwan", value: "Taiwanese" },
    { name: "Tajikistan", value: "Tajikistani" },
    { name: "Tanzania", value: "Tanzanian" },
    { name: "Thailand", value: "Thai" },
    { name: "Timor-Leste", value: "East Timorese" },
    { name: "Togo", value: "Togolese" },
    { name: "Tonga", value: "Tongan" },
    { name: "Trinidad and Tobago", value: "Trinidadian or Tobagonian" },
    { name: "Tunisia", value: "Tunisian" },
    { name: "Turkey", value: "Turkish" },
    { name: "Turkmenistan", value: "Turkmen" },
    { name: "Tuvalu", value: "Tuvaluan" },
    { name: "Uganda", value: "Ugandan" },
    { name: "Ukraine", value: "Ukrainian" },
    { name: "United Arab Emirates", value: "Emirati" },
    { name: "United Kingdom", value: "British" },
    { name: "United States", value: "United States" },
    { name: "Uruguay", value: "Uruguayan" },
    { name: "Uzbekistan", value: "Uzbekistani" },
    { name: "Vanuatu", value: "Ni-Vanuatu" },
    { name: "Venezuela", value: "Venezuelan" },
    { name: "Vietnam", value: "Vietnamese" },
    { name: "Yemen", value: "Yemeni" },
    { name: "Zambia", value: "Zambian" },
    { name: "Zimbabwe", value: "Zimbabwean" },
  ];

  const statesList = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const religions = [
    { name: "Select Religion", value: "" },
    {
      name: "African Methodist Episcopal",
      value: "african-methodist-episcopal",
    },
    { name: "Agnostic", value: "agnostic" },
    { name: "Assemblies of God", value: "assemblies-of-god" },
    { name: "Baptist", value: "baptist" },
    { name: "Buddhist", value: "buddhist" },
    { name: "Catholic", value: "catholic" },
    { name: "Christian", value: "christian" },
    { name: "Eastern Orthodox", value: "eastern-orthodox" },
    { name: "Episcopalian", value: "episcopalian" },
    { name: "Hindu", value: "hindu" },
    { name: "Jehovah’s Witness", value: "jehovah-s-witness" },
    { name: "Jewish", value: "jewish" },
    { name: "Lutheran", value: "lutheran" },
    { name: "Methodist", value: "methodist" },
    { name: "Muslim", value: "muslim" },
    { name: "Pagan/wican", value: "pagan-wican" },
    { name: "Pentecostal", value: "pentecostal" },
    { name: "Quaker", value: "quaker" },
    { name: "Seventh-day Adventist", value: "seventh-day-adventist" },
    { name: "Sikh", value: "sikh" },
    { name: "Other", value: "other" },
  ];

  // const [inputValue, setInputValue] = useState('');
  //   const [suggestions, setSuggestions] = useState([]);
  //   const [googlePlacesService, setGooglePlacesService] = useState(null);

  //   useEffect(() => {
  //     // Load the Google Places API once when the component mounts
  //     const script = document.createElement('script');
  //     script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB1GfqChRIciJi3fL5DoqX7btjEf2vKmz0&libraries=places`;
  //     script.async = true;
  //     script.defer = true;
  //     script.onload = () => {
  //       // Initialize the PlacesService once the script is loaded
  //       const service = new window.google.maps.places.AutocompleteService();
  //       setGooglePlacesService(service);
  //     };
  //     document.head.appendChild(script);

  //     // Cleanup function to remove the script when the component unmounts
  //     return () => {
  //       document.head.removeChild(script);
  //     };
  //   }, []);

  //   const handleInputChange = (e) => {
  //     const newInputValue = e.target.value;
  //     setInputValue(newInputValue);

  //     if (newInputValue && googlePlacesService) {
  //       googlePlacesService.getPlacePredictions(
  //         {
  //           input: newInputValue,
  //           types: ['geocode'], // Restrict suggestions to addresses
  //         },
  //         handleSuggestions
  //       );
  //     } else {
  //       setSuggestions([]);
  //     }
  //   };

  //   const handleSuggestions = (predictions, status) => {
  //     if (status === window.google.maps.places.PlacesServiceStatus.OK) {
  //       setSuggestions(predictions);
  //     } else {
  //       setSuggestions([]);
  //     }
  //   };

  //   const handleSuggestionClick = (suggestion) => {
  //     setInputValue(suggestion.description);
  //     setSuggestions([]);
  //   };
  const [searchTerm, setSearchTerm] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const PROXY_URL = `${BASE_Server_URL}proxy`; // Update this with your actual proxy server URL.

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setSearchValue(value);

    // Call the debounced function instead of making immediate requests
    //debouncedFetchSuggestions(value);
  };

  const handleSuggestionClick = (suggestion) => {
    // Set the selected suggestion as the search term
    //setSearchTerm(suggestion);
    setSearchValue(suggestion);

    // Clear the suggestion list
    setSuggestions([]);
    props.onChangeAddress(suggestion);
  };

  // const debouncedFetchSuggestions = debounce(async (inputValue) => {
  //   setLoading(true);
  //   setError(null);
  //   try {

  //     const response = await axios.get(
  //       `${PROXY_URL}/?input=${inputValue}&types=(cities)&types=(regions)`
  //     );
  //     const { predictions } = response.data;
  //     const locationSuggestions = predictions.map((prediction) => prediction.description);

  //     setSuggestions(locationSuggestions);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error('Error fetching location suggestions:', error);
  //     setError('Error fetching suggestions. Please try again later.');
  //     setLoading(false);
  //   }
  // }, 300);

  // useEffect(()=>{
  //    // Debounce the API call to avoid excessive requests
  //      debouncedFetchSuggestions(searchTerm);
  // }, [searchTerm])

  const debouncedFetchSuggestions = debounce(async (inputValue) => {
    setLoading(true);
    setError(null);
    try {
      // const response = await axios.get(`${AUTOCOMPLETE_API_URL}&input=${inputValue}`);
      // const { predictions } = response.data;
      // const locationSuggestions = predictions.map((prediction) => prediction.description);

      // const response = await axios.get(`${PROXY_URL}/?input=${inputValue}`);
      // const { predictions } = response.data;
      // const locationSuggestions = predictions.map((prediction) => prediction.description);

      // setSuggestions(locationSuggestions);
      // setLoading(false);

      const response = await axios.get(`${PROXY_URL}/?input=${inputValue}`);
      const { predictions } = response.data;

      // Filter predictions to include only cities, states, or countries
      const locationSuggestions = predictions
        .filter((prediction) => {
          // You may need to adjust these types based on the actual response structure
          const relevantTypes = [
            "locality",
            "administrative_area_level_1",
            "country",
          ];
          return prediction.types.some((type) => relevantTypes.includes(type));
        })
        .map((prediction) => prediction.description);

      setSuggestions(locationSuggestions);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
      setError("Error fetching suggestions. Please try again later.");
      setLoading(false);
    }
  }, 300);

  useEffect(() => {
    // Debounce the API call to avoid excessive requests
    debouncedFetchSuggestions(searchTerm);
  }, [searchTerm]);

  //   return (
  //  <>
  //       <div className="personal-profile">

  //         <div className="body14">
  //           <div className="body15">
  //             <div className="scholarship-questions-22">
  //               <div className="quiz-question16">
  //                 <div className="name-parent14">
  //                   <div className="name17">Birthday</div>
  //                   <div className="birthdaywrapper">
  //                   <div className="inputtextboxdatemonth-parent">
  //                     <input
  //                       className="inputtextboxdatemonth"
  //                       type="date"
  //                       placeholder="DD-MM-YYYY"
  //                       name = 'birthDate'
  //                       value = {props.values['birthDate']}
  //                       onChange={props.onChange}
  //                       required='required'

  //                     />
  //                     {/* <input
  //                       className="inputtextboxdatemonth"
  //                       type="text"
  //                       placeholder="DD"
  //                       name="birthday"
  //                       value = {props.values['birthDay']}
  //                       onChange={props.onChange}

  //                     //  ref={ddRef}

  //                     />
  //                     <input
  //                       className="inputtextboxdateyear7"
  //                       type="text"
  //                       placeholder="YYYY"
  //                       name="birthYear"
  //                       value = {props.values['birthYear']}
  //                       onChange={props.onChange}

  //                     /> */}
  //                   </div>

  //                   </div>
  //                 </div>
  //               </div>
  //               <span className="errormessage">{props.formErrors.birthDay}</span>

  //               <div className="quiz-question17">
  //                 <div className="name-parent15">
  //                   <div className="name17">State of Residence</div>
  //                   <div>
  //                   <select className="multiselectdefault10" name= "stateOfResidence" required="required" onChange={props.onChange}>
  //                     <option value="">Select State</option>
  //                     <option value="texas">Texas</option>
  //                     <option value="newyork">New York</option>
  //                     <option value="alabama">Alabama</option>
  //                     <option value="florida">Florida</option>
  //                   </select>

  //                   </div>

  //                 </div>

  //               </div>
  //               <span className="errormessage">{props.formErrors.stateOfResidence}</span>
  //               <div className="quiz-question18">
  //                 <div className="name-parent16">
  //                   <div className="name19">First Generation</div>

  //                   {/* <div className="yes-no-radio-buttons">
  //                     <div className="buttonradio">
  //                       <div className="text">Yes</div>
  //                       <div className="radio-circle-empty">
  //                         <div className="outer-radio-container" />
  //                       </div>
  //                     </div>
  //                     <div className="buttonradio">
  //                       <div className="text">No</div>
  //                       <div className="radio-circle-empty">
  //                         <div className="outer-radio-container" />
  //                       </div>
  //                     </div>
  //                   </div> */}

  //                   <FormControl>

  //                   <RadioGroup
  //                     row
  //                     aria-labelledby="demo-row-radio-buttons-group-label"
  //                     //name="row-radio-buttons-group"
  //                     name = "firstGeneration" onChange={props.onChange}

  //                   >
  //                     <div className="yes-no-radio-buttons">
  //                     <div className="buttonradio">
  //                     <FormControlLabel value="Yes" control={<Radio required="required"/>} label="YES" />
  //                     </div>
  //                     <div className="buttonradio">
  //                     <FormControlLabel value="No" control={<Radio />} label="NO" />
  //                     </div>
  //                     </div>
  //                   </RadioGroup>
  //                 </FormControl>

  //                 </div>
  //               </div>

  //               <span className="errormessage">{props.formErrors.firstGeneration}</span>
  //               <div className="quiz-question19">
  //                 <div className="name-parent17">
  //                   <div className="name17">Gender</div>
  //                   <select className="multiselectdefault10" name= "gender" required="required" onChange={props.onChange}>
  //                   <option value="" >Select Gender</option>

  //                     <option value="male">Male</option>
  //                     <option value="female">Female</option>
  //                     <option value="other">Other</option>
  //                   </select>
  //                 </div>
  //               </div>
  //               <span className="errormessage">{props.formErrors.gender}</span>
  //               <div className="quiz-question20">
  //                 <div className="name-parent18">
  //                   <div className="name17">Citizenship Status</div>
  //                   <select className="multiselectdefault10" name= "citizenshipStatus" required="required" onChange={props.onChange}>
  //                   <option value="">Select Citizenship</option>
  //                     <option value="american">American</option>
  //                     <option value="canadian">Canadian</option>
  //                     <option value="british">British</option>
  //                     <option value="mexican">Mexican</option>
  //                     <option value="nigerian">Nigeria</option>

  //                   </select>
  //                 </div>
  //               </div>
  //               <span className="errormessage">{props.formErrors.citizenshipStatus}</span>
  //               <div className="quiz-question21">
  //                 <div className="name-parent19">
  //                   <div className="name17">Disability</div>
  //                   <select className="multiselectdefault10" name= "disability" required="required" onChange={props.onChange}>
  //                     <option value="">Select Disability</option>
  //                     <option value="none" >None</option>
  //                     <option value="blind">Blind</option>
  //                     <option value="deaf">Deaf</option>
  //                   </select>
  //                 </div>
  //               </div>
  //               <span className="errormessage">{props.formErrors.disability}</span>
  //               <div className="quiz-question22">
  //                 <div className="name-parent20">
  //                   <div className="name17">Ethnicity</div>
  //                   <select className="multiselectdefault10" name= "ethnicity" required="required" onChange={props.onChange}>
  //                     <option value="">Select Ethnicity</option>
  //                     <option value="african">Black/African</option>
  //                     <option value="white">White</option>
  //                     <option value="chinese">Chinese</option>
  //                     <option value="other">Other</option>
  //                   </select>
  //                 </div>
  //               </div>
  //               <span className="errormessage">{props.formErrors.ethnicity}</span>
  //               <div className="quiz-question22">
  //                 <div className="name-parent21">
  //                   <div className="name17">Religion</div>
  //                   <select className="multiselectdefault10" name= "religion" required="required" onChange={props.onChange}>
  //                     <option value="">Select Religion</option>
  //                     <option value="islam">Islam</option>
  //                     <option value="christianity">Christianity</option>
  //                     <option value="hindu">Hindu</option>
  //                   </select>
  //                 </div>
  //               </div>
  //               <span className="errormessage">{props.formErrors.religion}</span>
  //               <div className="quiz-question22">
  //                 <div className="name-parent22">
  //                   <div className="name25">Personal Attributes</div>
  //                   <select className="multiselectdefault10" name= "personalAttributes" required="required" onChange={props.onChange}>
  //                     <option value="">Select</option>
  //                     <option value="blackeyes">Black Eyes</option>
  //                     <option value="blue eyes">Blue eyes</option>
  //                   </select>
  //                 </div>
  //               </div>
  //               <span className="errormessage">{props.formErrors.personalAttributes}</span>
  //               <div className="quiz-question25">
  //                 <div className="name-parent23">
  //                   <div className="name19">Military Affiliation</div>
  //                   {/* <div className="yes-no-radio-buttons1">
  //                     <div className="buttonradio">
  //                       <div className="text">Yes</div>
  //                       <div className="radio-circle-empty">
  //                         <div className="outer-radio-container" />
  //                       </div>
  //                     </div>
  //                     <div className="buttonradio">
  //                       <div className="text">No</div>
  //                       <div className="radio-circle-empty">
  //                         <div className="outer-radio-container" />
  //                       </div>
  //                     </div>
  //                   </div> */}

  //                        <FormControl  >

  //                         <RadioGroup
  //                           row
  //                           aria-labelledby="demo-row-radio-buttons-group-label"
  //                           //name="row-radio-buttons-group"
  //                           name = "militaryAffliation" onChange={props.onChange}
  //                         >
  //                           <div className="yes-no-radio-buttons">
  //                           <div className="buttonradio">
  //                           <FormControlLabel value="Yes" control={<Radio required="required"/>} label="YES" />
  //                           </div>
  //                           <div className="buttonradio">
  //                           <FormControlLabel value="No" control={<Radio />} label="NO" />
  //                           </div>
  //                           </div>
  //                         </RadioGroup>
  //                       </FormControl>
  //                 </div>
  //               </div>
  //               <span className="errormessage">{props.formErrors.militaryAffliation}</span>
  //             </div>
  //             {/* <div className="exit-button1">
  //               <div className="frame-parent21">
  //                 <button className="exit-frame" type="Submit">
  //                   <div className="exit2">Exit</div>
  //                 </button>
  //                 <button
  //                   className="vector-wrapper1"
  //                   onClick={onFrameButton4Click}
  //                 >
  //                   <img className="vector-icon99" alt="" src="/vector34.svg" />
  //                 </button>
  //               </div>
  //             </div> */}
  //           </div>
  //         </div>
  //         {/* <div className="footer14">
  //           <div className="footer-grid-row14">
  //             <div className="footer-menu70">
  //               <img
  //                 className="footerlogo-icon14"
  //                 alt=""
  //                 src="/footerlogo7.svg"
  //               />
  //               <div className="footer-links70">
  //                 <div className="footerlinknav42">
  //                   <div className="nav-link42">info@example.com</div>
  //                 </div>
  //                 <div className="footerlinknav42">
  //                   <div className="nav-link42">+1 234 567 9830</div>
  //                 </div>
  //                 <div className="footerlinknav42">
  //                   <div className="nav-link42">Address</div>
  //                 </div>
  //               </div>
  //               <div className="icon-wrapper14">
  //                 <img
  //                   className="footericoninstagram14"
  //                   alt=""
  //                   src="/footericoninstagram.svg"
  //                 />
  //                 <img
  //                   className="footericonfacbook14"
  //                   alt=""
  //                   src="/footericonfacbook.svg"
  //                 />
  //                 <img
  //                   className="footericontwitter14"
  //                   alt=""
  //                   src="/footericontwitter.svg"
  //                 />
  //                 <img
  //                   className="footericonfacbook14"
  //                   alt=""
  //                   src="/footericonlinkedin.svg"
  //                 />
  //                 <img
  //                   className="footericonyoutube14"
  //                   alt=""
  //                   src="/footericonyoutube1.svg"
  //                 />
  //               </div>
  //             </div>
  //             <div className="footer-menu-parent12">
  //               <div className="footer-menu71">
  //                 <div className="students14">Students</div>
  //                 <div className="footer-links70">
  //                   <div className="main-footer-link182">
  //                     <div className="footer-link182">How it works</div>
  //                   </div>
  //                   <div className="main-footer-link182">
  //                     <div className="footer-link182">Scholarships</div>
  //                   </div>
  //                   <div className="main-footer-link182">
  //                     <div className="footer-link182">Community</div>
  //                   </div>
  //                   <div className="main-footer-link182">
  //                     <div className="footer-link182">Services</div>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="footer-menu71">
  //                 <div className="students14">Coaches</div>
  //                 <div className="footer-links70">
  //                   <div className="main-footer-link182">
  //                     <div className="footer-link182">How it works</div>
  //                   </div>
  //                   <div className="main-footer-link182">
  //                     <div className="footer-link182">Blog</div>
  //                   </div>
  //                   <div className="main-footer-link182">
  //                     <div className="footer-link182">Mission</div>
  //                   </div>
  //                   <div className="main-footer-link182">
  //                     <div className="footer-link182">Footer Link</div>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="footer-menu71">
  //                 <div className="students14">Company</div>
  //                 <div className="footer-links70">
  //                   <div className="main-footer-link182">
  //                     <div className="footer-link182">About Us</div>
  //                   </div>
  //                   <div className="main-footer-link182">
  //                     <div className="footer-link182">Partners</div>
  //                   </div>
  //                   <div className="main-footer-link182">
  //                     <div className="footer-link182">Careers</div>
  //                   </div>
  //                   <div className="main-footer-link182">
  //                     <div className="footer-link182">Contact</div>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="footer-menu71">
  //                 <div className="students14">Join our Email List</div>
  //                 <div className="footer-links74">
  //                   <div className="main-footer-link194">
  //                     <div className="footer-link194">
  //                       Be the first to know about new scholarships, events,
  //                       workshops and much more!
  //                     </div>
  //                   </div>
  //                   <div className="subscribe-email-text-box14">
  //                     <div className="text-box13" />
  //                     <div className="input-placeholder13">Email</div>
  //                   </div>
  //                   <div className="buttonlarge35">
  //                     <div className="button51" />
  //                     <div className="button-text86">Sign Up</div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="footer-legal14">
  //             <div className="scholist-all-rights14">
  //               © 2022 Scholist. All rights reserved.
  //             </div>
  //             <div className="scholist-all-rights14">Disclaimer Cookies</div>
  //           </div>
  //         </div>
  //         */}

  //       {isNavigationDrawerOpen && (
  //         <PortalDrawer
  //           overlayColor="rgba(113, 113, 113, 0.3)"
  //           placement="Left"
  //           onOutsideClick={closeNavigationDrawer}
  //         >
  //           <NavigationDrawer onClose={closeNavigationDrawer} />
  //         </PortalDrawer>
  //       )}
  //        </div>
  //    </>

  //   );
  //  function isOver18(dateOfBirth) {
  //   const currentDate = new Date();
  //   const dob = new Date(dateOfBirth);
  //   const ageDifference = currentDate.getFullYear() - dob.getFullYear();

  //   if (ageDifference > 18) {
  //     return true;
  //   } else if (ageDifference === 18) {
  //     // Check if the birth date has occurred in the current year or earlier
  //     if (
  //       currentDate.getMonth() > dob.getMonth() ||
  //       (currentDate.getMonth() === dob.getMonth() &&
  //         currentDate.getDate() >= dob.getDate())
  //     ) {
  //       return true;
  //     }
  //   }

  //   return false;
  // }

  function isOver18(dateOfBirth) {
    const currentDate = new Date();

    const dob = new Date(dateOfBirth);
    const ageDifference = currentDate.getFullYear() - dob.getFullYear();

    if (ageDifference > 18) {
      return true;
    } else if (ageDifference === 18) {
      // Check if the birth date has occurred in the current year or earlier
      if (
        currentDate.getMonth() > dob.getMonth() ||
        (currentDate.getMonth() === dob.getMonth() &&
          currentDate.getDate() >= dob.getDate())
      ) {
        return true;
      }
    }

    return false;
  }

  function GuardiansEmailForm() {
    const birthDate = props.values["birthDate"];
    const isUnder18 = !isOver18(birthDate);
    const isDateOfBirthEntered = birthDate !== undefined;

    if (isDateOfBirthEntered && isUnder18) {
      return (
        <>
          <div className="editprofileform6">
            <div className="editprofileformbox6">
              <div className="editprofileformboxtext6">
                Parent Email <span style={{ color: "red" }}>*</span>
              </div>
              <div className="boxerror">
                <input
                  className="editprofileformboxinputbox6"
                  type="text"
                  required="required"
                  name="guardianEmail"
                  onChange={props.onChange}
                  value={props.values.guardianEmail}
                  placeholder="Type Your Guardian's email"
                />
                <span className="errormessage">
                  {props.formErrors.guardianEmailRequired}
                </span>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return null; // Hide the parent email field
    }
  }

  //  function GuardiansEmailForm() {
  //    {
  //      if (!isOver18(props.values["birthDate"])) {
  //        return (
  //          <>
  //            <div className="editprofileform6">
  //              <div className="editprofileformbox6">
  //                <div className="editprofileformboxtext6">
  //                  Parent Email <span style={{ color: "red" }}>*</span>
  //                </div>
  //                <div className="boxerror">
  //                  <input
  //                    className="editprofileformboxinputbox6"
  //                    // placeholder="Select High School"
  //                    type="text"
  //                    required="required"
  //                    name="guardianEmail"
  //                    onChange={props.onChange}
  //                    value={props.values.guardianEmail}
  //                    placeholder="Type Your Guardians email"
  //                  />
  //                  <span className="errormessage">
  //                    {props.formErrors.guardianEmailRequired}
  //                  </span>
  //                </div>
  //              </div>
  //            </div>
  //          </>
  //        );
  //      }
  //    }
  //  }

  return (
    <div className="personal-profile">
      <div className="profileeditbodybox2">
        <div className="profileeditbodyheader2" />
        <div className="profileeditbodybox11">
          <div className="scholarship-questions-22">
            {/* <div className="birthday">
          <div className="birthdaybody">
            <div className="birthday1">Birthday</div>
            <div className="editprofileformboxinputbox16">
              <div className="editprofileformboxinputboxtext">
                Select Address
              </div>
            </div>
          </div>
        </div> */}

            <div className="birthday">
              <div className="birthdaybody">
                <div className="birthday1">
                  Birthday <span style={{ color: "red" }}>*</span>
                </div>
                <div className="boxerror">
                  <input
                    className="editprofileformboxinputbox17"
                    // placeholder="Select Date"
                    // type="text"

                    type="date"
                    placeholder="DD-MM-YYYY"
                    name="birthDate"
                    value={props.values["birthDate"]}
                    onChange={props.onChange}
                    required="required"
                  />
                  <span className="errormessage">
                    {props.formErrors.birthDate}
                  </span>
                </div>
              </div>
            </div>

            {/* <p>Selected Date: {props.values['birthDate']}</p> */}

            {/* <span className="errormessage">{props.formErrors.guardianEmail}</span> */}

            {GuardiansEmailForm()}
            <div className="birthday">
              <div className="birthdaybody">
                <div className="birthday1">
                  Location <span style={{ color: "red" }}>*</span>
                </div>
                <div className="boxerror">
                  <div className="autodrop">
                    <input
                      className="editprofileformboxinputbox17"
                      placeholder="country, state or city"
                      type="text"
                      name="stateOfResidence"
                      value={searchValue}
                      onChange={handleInputChange}
                      required="required"
                    />
                    {suggestions.length > 0 && (
                      <ul className="suggestions">
                        {suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion)}
                          >
                            {suggestion}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <span className="errormessage">
                    {props.formErrors.stateOfResidence}
                  </span>
                </div>
              </div>
            </div>

            {/* 
        <input type="text"
        value={searchTerm}
        onChange={handleInputChange}
        placeholder="Type a location..."className='filtersearch'/>


        <div className="birthday">
          <div className="birthdaybody">
            <div className="birthday1">Address</div>
            <input
              className="editprofileformboxinputbox17"
              placeholder="Select State"
              type="text"
              name= "stateOfResidence" 
              required="required" 
              onChange={props.onChange}
              value={searchTerm}
            />
          </div>
        </div> */}

            <div className="birthday">
              <div className="birthdaybody">
                <div className="birthday1">First Generation</div>

                <div className="editprofileformboxinputbox18">
                  <div className="boxerror">
                    <div>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          //name="row-radio-buttons-group"
                          name="firstGeneration"
                          onChange={props.onChange}
                        >
                          <div className="editprofileradiogroup">
                            <div className="buttonradio">
                              <FormControlLabel
                                value="Yes"
                                control={<Radio />}
                                label="Yes"
                              />
                            </div>
                            <div className="buttonradio">
                              <FormControlLabel
                                value="No"
                                control={<Radio />}
                                label="No"
                              />
                            </div>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  <span className="errormessage">
                    {props.formErrors.firstGeneration}
                  </span>

                  {/* <FormControl>
      
                       <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          //name="row-radio-buttons-group"
                          name = "firstGeneration" onChange={props.onChange}
                          
                        >
                          <div className="yes-no-radio-buttons">
                             <div className="buttonradio">
                                <FormControlLabel value="Yes" control={<Radio required="required"/>} label="YES" />
                             </div>
                             <div className="buttonradio">
                               <FormControlLabel value="No" control={<Radio />} label="NO" />
                              </div>
                          </div>
                        </RadioGroup>
                      </FormControl> */}
                </div>
              </div>
            </div>
            <div className="birthday">
              <div className="birthdaybody">
                <div className="birthday1">
                  Gender <span style={{ color: "red" }}>*</span>
                </div>
                <div className="boxerror">
                  <select
                    className="editprofileformboxinputbox19"
                    name="gender"
                    required="required"
                    onChange={props.onChange}
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Non-Binary">Non-Binary</option>
                    <option value="Transgender">Transgender</option>
                    <option value="Other">Other</option>
                  </select>
                  <span className="errormessage">
                    {props.formErrors.gender}
                  </span>
                </div>
              </div>
            </div>

            {/* 
        <div className="birthday">
          <div className="birthdaybody">
            <div className="birthday1">Citizenship Status</div>
            <input
              className="editprofileformboxinputbox17"
              placeholder="Select State"
              type="text"
              name= "citizenshipStatus"
              required="required"
              onChange={props.onChange}
            />
          </div>
        </div> */}

            <div className="editprofileform6">
              <div className="editprofileformbox6">
                <div className="editprofileformboxtext6">
                  Citizenship <span style={{ color: "red" }}>*</span>
                </div>
                <div className="boxerror">
                  <select
                    className="editprofileformboxinputbox10"
                    name="citizenshipStatus"
                    required="required"
                    onChange={props.onChange}
                  >
                    {/* <option value="">Select Education Level</option>
                <option value="High School">High School</option>
                <option value="Undergraduate">Bachelors/Undergraduate</option>
                <option value="Graduate">Masters/Graduate</option>
                <option value="Phd">Phd</option>
                <option value="Associate">Associate</option>
                <option value="Trade School">Trade School</option> */}
                    {allCitizenships.map((org) => (
                      <option key={org.name} value={org.value}>
                        {org.name}
                      </option>
                    ))}
                  </select>
                  <span className="errormessage">
                    {props.formErrors.citizenshipStatus}
                  </span>
                </div>
              </div>
            </div>

            <div className="birthday">
              <div className="birthdaybody">
                <div className="birthday1">Disability</div>
                <div className="boxerror">
                  <select
                    className="editprofileformboxinputbox19"
                    name="disability"
                    required="required"
                    onChange={props.onChange}
                  >
                    <option value="">Select Disability</option>
                    <option value="Physical disability">
                      Physical disability
                    </option>
                    <option value="Visual impairment">Visual impairment</option>
                    <option value="Hearing impairment">
                      Hearing impairment
                    </option>
                    <option value="Cognitive disability">
                      Cognitive disability
                    </option>
                    <option value="Learning disability">
                      Learning disability
                    </option>
                    <option value="Mental health condition">
                      Mental health condition
                    </option>
                    <option value="Other">Other</option>
                    <option value="None">None</option>
                  </select>
                  <span className="errormessage">
                    {props.formErrors.disability}
                  </span>
                </div>
              </div>
            </div>

            <div className="birthday">
              <div className="birthdaybody">
                <div className="birthday1">
                  Race/Ethnicity <span style={{ color: "red" }}>*</span>
                </div>
                <div className="boxerror">
                  <select
                    className="editprofileformboxinputbox19"
                    name="ethnicity"
                    required="required"
                    onChange={props.onChange}
                  >
                    <option value="">Select Ethnicity</option>
                    <option value="Asian">Asian</option>
                    <option value="Black or African American">
                      Black or African American
                    </option>
                    <option value="Hispanic or latino">
                      Hispanic or latino
                    </option>
                    <option value="Native American/American Indian or Alaskan Native">
                      Native American/American Indian or Alaskan Native
                    </option>
                    <option value="Pacific Islander, including Native Hawaiian">
                      Pacific Islander, including Native Hawaiian
                    </option>
                    <option value="White or Caucasian">
                      White or Caucasian
                    </option>
                    <option value="Prefer not to say">Prefer not to say</option>
                  </select>
                  <span className="errormessage">
                    {props.formErrors.ethnicity}
                  </span>
                </div>
              </div>
            </div>

            <div className="birthday">
              <div className="birthdaybody">
                <div className="birthday1">Religion</div>
                <div className="boxerror">
                  <select
                    className="editprofileformboxinputbox19"
                    name="religion"
                    required="required"
                    onChange={props.onChange}
                  >
                    {/* <option value="">Select Religion</option>
              <option value="Christian">Christian</option>
              <option value="Islam">Islam</option>
              <option value="Jewish">Jewish</option>
              <option value="Hindu">Hindu</option>
              <option value="Buddhist">Buddhist</option>
              <option value="Sikh">Sikh</option>
              <option value="Other">Other</option> */}
                    {religions.map((org) => (
                      <option key={org.name} value={org.value}>
                        {org.name}
                      </option>
                    ))}
                  </select>
                  <span className="errormessage">
                    {props.formErrors.religion}
                  </span>
                </div>
              </div>
            </div>

            <div className="birthday">
              <div className="birthdaybody">
                <div className="birthday1">Personal Attributes</div>
                <div className="boxerror">
                  <select
                    className="editprofileformboxinputbox19"
                    // required={true}
                    name="personalAttributes"
                    required="required"
                    onChange={props.onChange}
                  >
                    <option value="">Select Attribute</option>
                    <option value="First-generation college student">
                      First-generation college student
                    </option>
                    <option value="Veteran status">Veteran status</option>
                    <option value="Refugee or asylum seeker status">
                      Refugee or asylum seeker status
                    </option>
                    <option value="Single parent">Single parent</option>
                    <option value="Caregiver responsibilities">
                      Caregiver responsibilities
                    </option>
                    <option value="LGBTQ+">LGBTQ+</option>
                    <option value="Non traditional Student">
                      Non traditional Student
                    </option>
                    <option value="DACA (Deferred Action for Childhood Arrivals)">
                      DACA (Deferred Action for Childhood Arrivals)
                    </option>
                    <option value="Foster Care">Foster Care</option>
                    <option value="Orphan">Orphan</option>
                    <option value="Other">Other</option>
                  </select>
                  <span className="errormessage">
                    {props.formErrors.personalAttributes}
                  </span>
                </div>
              </div>
            </div>

            <div className="birthday">
              <div className="birthdaybody">
                <div className="birthday1">Military Affiliation</div>
                {/* <div className="editprofileformboxinputbox18">
              <div className="editprofileradiogroup">
                <div className="buttonradio">
                  <input className="radio-circle-empty" type="radio" />
                  <div >Yes</div>
                </div>
                <div className="buttonradio">
                  <input className="radio-circle-empty" type="radio" />
                  <div>No</div>
                </div>
              </div>
            </div> */}
                {/* 
<FormControl  >
 
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                //name="row-radio-buttons-group"
                                name = "militaryAffliation" onChange={props.onChange}
                              >
                                <div className="yes-no-radio-buttons">
                                <div className="buttonradio">
                                <FormControlLabel value="Yes" control={<Radio required="required"/>} label="YES" />
                                </div>
                                <div className="buttonradio">
                                <FormControlLabel value="No" control={<Radio />} label="NO" />
                                </div>
                                </div>
                              </RadioGroup>



                            </FormControl> */}

                <div className="editprofileformboxinputbox18">
                  <div className="boxerror">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        //name="row-radio-buttons-group"
                        name="militaryAffliation"
                        onChange={props.onChange}
                      >
                        <div className="editprofileradiogroup">
                          <div className="buttonradio">
                            {/* <input className="radio-circle-empty" type="radio" />
                  <div >Yes</div> */}
                            <FormControlLabel
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                          </div>
                          <div className="buttonradio">
                            {/* <input className="radio-circle-empty" type="radio" />
                  <div >No</div> */}
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </div>
                        </div>
                      </RadioGroup>
                    </FormControl>
                    <span className="errormessage">
                      {props.formErrors.militaryAffliation}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PERSONALPROFILE;
