//require('dotenv').config();
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./global.css";

// const container = document.getElementById("root");
// const root = createRoot(container);

// root.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>
// );

// Load environment variables

import { Provider } from "react-redux";

import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import WebFont from "webfontloader";
WebFont.load({
  google: {
    families: ["Poppins", "Poppins:400,500"],
  },
});
// const root = ReactDOM.createRoot(document.getElementById('root'));
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider clientId="473164499405-t4ekmdfesl0q2pcunddcke0u1fb9pbk3.apps.googleusercontent.com">
          <App />
        </GoogleOAuthProvider>
        {/* <BrowserRouter>
 <App />
  </BrowserRouter> */}
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
