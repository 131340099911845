import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Autocomplete } from "@react-google-maps/api";
import "./EDUCATIONPROFILE.css";
import axios from "axios";

import debounce from "lodash.debounce";
import { BASE_Server_URL } from "../requestMethods";

const EDUCATIONPROFILE = (props) => {
  const navigate = useNavigate();

  const onFrameButton2Click = useCallback(() => {
    navigate("/student-activities-profile");
  }, [navigate]);

  const onFrameButton4Click = useCallback(() => {
    navigate("/student-activities-profile");
  }, [navigate]);

  //console.log(props)

  const [searchTerm, setSearchTerm] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const PROXY_URL = `${BASE_Server_URL}proxy`; // Update this with your actual proxy server URL.

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setSearchValue(value);

    // Call the debounced function instead of making immediate requests
    //debouncedFetchSuggestions(value);
  };

  const handleSuggestionClick = (suggestion) => {
    // Set the selected suggestion as the search term
    //setSearchTerm(suggestion);
    setSearchValue(suggestion);

    // Clear the suggestion list
    setSuggestions([]);
    props.onChangeCollege(suggestion);
  };

  // const debouncedFetchSuggestions = debounce(async (inputValue) => {
  //   setLoading(true);
  //   setError(null);
  //   try {
  //     // const response = await axios.get(`${AUTOCOMPLETE_API_URL}&input=${inputValue}`);
  //     // const { predictions } = response.data;
  //     // const locationSuggestions = predictions.map((prediction) => prediction.description);

  //     //const response = await axios.get(`${PROXY_URL}/?input=${inputValue}`);
  //     const response = await axios.get(
  //       `${PROXY_URL}/?input=${inputValue}types=(university)&types=(school)`
  //     );
  //     const { predictions } = response.data;
  //     const locationSuggestions = predictions.map((prediction) => prediction.description);

  //     setSuggestions(locationSuggestions);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error('Error fetching location suggestions:', error);
  //     setError('Error fetching suggestions. Please try again later.');
  //     setLoading(false);
  //   }
  // }, 300);

  // useEffect(()=>{
  //    // Debounce the API call to avoid excessive requests
  //      debouncedFetchSuggestions(searchTerm);
  // }, [searchTerm])

  const debouncedFetchSuggestions = debounce(async (inputValue) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${PROXY_URL}/?input=${inputValue}`);
      const { predictions } = response.data;

      // Filter predictions to include only universities or colleges
      const filteredPredictions = predictions.filter(
        (prediction) =>
          prediction.types.includes("university") ||
          prediction.types.includes("school") ||
          prediction.types.includes("college")
      );

      const locationSuggestions = filteredPredictions.map(
        (prediction) => prediction.description
      );
      setSuggestions(locationSuggestions);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
      setError("Error fetching suggestions. Please try again later.");
      setLoading(false);
    }
  }, 300);

  useEffect(() => {
    // Debounce the API call to avoid excessive requests
    debouncedFetchSuggestions(searchTerm);
  }, [searchTerm]);

  // return (
  //   <div className="education-profile">
  //     {/* <div className="navbar11">
  //       <div className="info11">
  //         <div className="left-side-top-nav12">
  //           <img className="headerlogo-icon12" alt="" src="/headerlogo2.svg" />
  //           <div className="top-nav-left12">
  //             <div className="headernav-link60">
  //               <div className="nav-link-default48">Home</div>
  //             </div>
  //             <div className="headernav-link61">
  //               <div className="nav-link-default48">Scholarships</div>
  //             </div>
  //             <div className="headernav-link62">
  //               <div className="nav-link-default48">Services</div>
  //             </div>
  //             <div className="headernav-link62">
  //               <div className="nav-link-default48">{`Community `}</div>
  //             </div>
  //             <div className="headernav-link62">
  //               <div className="nav-link-default48">Resources</div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="right-side-top-nav12">
  //           <button className="menu12">
  //             <img className="vector-icon88" alt="" />
  //             <img className="vector-icon89" alt="" src="/vector31.svg" />
  //           </button>
  //           <div className="messages-parent9">
  //             <img className="messages-icon11" alt="" src="/messages8.svg" />
  //             <img
  //               className="notifications-icon11"
  //               alt=""
  //               src="/notifications9.svg"
  //             />
  //             <div className="mask-group-parent9">
  //               <img
  //                 className="mask-group-icon11"
  //                 alt=""
  //                 src="/mask-group4@2x.png"
  //               />
  //               <img className="vector-icon90" alt="" src="/vector20.svg" />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <img
  //         className="scholarships-line-icon11"
  //         alt=""
  //         src="/scholarships-line.svg"
  //       />
  //       <img className="services-line-icon11" alt="" src="/services-line.svg" />
  //       <img
  //         className="community-line-icon11"
  //         alt=""
  //         src="/community-line.svg"
  //       />
  //       <img className="resources-icon11" alt="" src="/resources.svg" />
  //     </div> */}

  //     <div className="body12">

  //       <div className="form-body">
  //         <div className="scholarship-questions-2">
  //           <div className="quiz-question">
  //             <div className="name-parent">
  //               <div className="name1">High School</div>
  //               <textarea
  //                  className="multiselectdefault"
  //                  name= "highSchool"
  //                  required="required"
  //                  onChange={props.onChange}
  //                  value = {props.values.highSchool}
  //                  placeholder="Capital Science Academy"
  //               />
  //             </div>
  //           </div>
  //           <span className="errormessage">{props.formErrors.highSchool}</span>

  //           <div className="quiz-question1">
  //             <div className="name-group">
  //               <div className="name1">{`High School  Graduation Year `}</div>
  //               <div className="inputtextboxdateyear-wrapper">
  //                 <input
  //                   className="inputtextboxdateyear"
  //                   type="text"
  //                   placeholder="2019"
  //                   required="required"
  //                   name="highSchoolGraduationYear"
  //                   onChange={props.onChange}

  //                   //value = {props.values.highSchoolGradYear}
  //                   //pattern="\b([1-9]|1[0-2])\b"

  //                 />
  //               </div>
  //             </div>
  //           </div>
  //           <span className="errormessage">{props.formErrors.highSchoolGradYear}</span>

  //           <div className="quiz-question1">
  //             <div className="name-container">
  //               <div className="name1">{`College  Graduation Year `}</div>
  //               <div className="inputtextboxdateyear-wrapper">
  //                 <input
  //                   className="inputtextboxdateyear"
  //                   type="text"
  //                   placeholder="2023"
  //                   required="required"
  //                   onChange={props.onChange}
  //                   name="collegeGraduationYear"
  //                   //value = {props.values.collegeGraduationYear}

  //                 />
  //               </div>
  //             </div>
  //           </div>
  //           <span className="errormessage">{props.formErrors.collegeGraduationYear}</span>

  //           <div className="quiz-question1">
  //             <div className="name-parent1">
  //               <div className="name1">Sat Score</div>
  //               <div className="inputtextboxdateyear-wrapper">
  //                 <input
  //                   className="inputtextboxdateyear"
  //                   type="text"
  //                   placeholder="Math"
  //                   required="required"
  //                   onChange={props.onChange}
  //                   name="math"
  //                  // value = {props.values.Math}
  //                 />
  //                 <input
  //                   className="inputtextboxdateyear3"
  //                   type="text"
  //                   placeholder="Ebrw"
  //                   required="required"
  //                   onChange={props.onChange}
  //                   name="ebrw"
  //                   //value = {props.values.ebrw}
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //           <span className="errormessage">{props.formErrors.math}</span>

  //           <div className="quiz-question1">
  //             <div className="name-parent1">
  //               <div className="name1">ACT Score</div>
  //               <div className="inputtextboxdateyear-wrapper">
  //                 <input
  //                   className="inputtextboxdateyear"
  //                   type="text"
  //                   placeholder="1-26"
  //                   required="required"
  //                   onChange={props.onChange}
  //                   name="actScore"
  //                   //value = {props.values.actScore}
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //           <span className="errormessage">{props.formErrors.actScore}</span>

  //           <div className="quiz-question1">
  //             <div className="name-parent3">
  //               <div className="name1">GED</div>
  //               <div className="inputtextboxdateyear-wrapper">
  //                 <input
  //                   className="inputtextboxdateyear"
  //                   type="text"
  //                   placeholder="200-800"
  //                   required="required"
  //                   onChange={props.onChange}
  //                   name="ged"
  //                   //value = {props.values.ged}
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //           <span className="errormessage">{props.formErrors.ged}</span>

  //           <div className="quiz-question1">
  //             <div className="name-parent3">
  //               <div className="name1">GPA</div>
  //               <div className="inputtextboxdateyear-wrapper">
  //                 <input
  //                   className="inputtextboxdateyear"
  //                   type="text"
  //                   placeholder="Overall"
  //                   required="required"
  //                   onChange={props.onChange}
  //                   name = "gpa"
  //                   //value = {props.values.gpa}
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //           <span className="errormessage">{props.formErrors.gpa}</span>

  //           <div className="quiz-question7">
  //             <div className="name-parent5">
  //               {/* <div className="name1">Class Level</div> */}

  //               <div className="name17">Class Level</div>
  //               {/* <textarea
  //                 className="multiselectdefault1"
  //                 required="required"
  //                 onChange={props.onChange}
  //                 name="classLevel"
  //                 //value = {props.values.academicHonors}
  //               /> */}
  //                  <div>
  //                 <select className="multiselectdefault10" name= "classLevel" required="required" onChange={props.onChange}>
  //                   <option value="">Select Level</option>
  //                   <option value="highSchool">High School</option>
  //                   <option value="Undergraduate">Undergraduate</option>
  //                   <option value="Graduate">Graduate</option>
  //                   <option value="Phd">Phd</option>
  //                 </select>

  //                 </div>
  //             </div>
  //           </div>
  //           <span className="errormessage">{props.formErrors.academicHonors}</span>

  //           <div className="quiz-question7">
  //             <div className="name-parent6">
  //               <div className="name1">Field Of Study</div>
  //               <textarea
  //                  className="multiselectdefault1"
  //                  required="required"
  //                  name="fieldOfStudy"
  //                  onChange={props.onChange}
  //                  placeholder="e.g Computer Engineering"
  //                  //value = {props.values.fieldOfStudy}
  //               />
  //             </div>
  //           </div>
  //           <span className="errormessage">{props.formErrors.fieldOfStudy}</span>

  //           <div className="quiz-question7">
  //             <div className="name-parent7">
  //               <div className="name10">College</div>
  //               <textarea
  //                 className="multiselectdefault1"
  //                 required="required"
  //                 onChange={props.onChange}
  //                 name="college"
  //                 placeholder="e.g. Abbeyy College"
  //                 //value = {props.values.college}
  //                 />
  //             </div>
  //           </div>
  //           <span className="errormessage">{props.formErrors.college}</span>

  //         </div>
  //         {/* <div className="form-body-inner">
  //           <div className="frame-parent17">
  //             <button className="exit-wrapper">
  //               <div className="exit">Exit</div>
  //             </button>
  //             <button className="vector-wrapper" onClick={onFrameButton4Click}>
  //               <img className="vector-icon91" alt="" src="/vector32.svg" />
  //             </button>
  //           </div>
  //         </div> */}
  //       </div>
  //     </div>

  //   </div>
  // );

  const majors = [
    "Accounting",
    "Active Paramedics",
    "Actuarial Science",
    "Administration",
    "Advertising",
    "Aeronautics",
    "Aerospace",
    "African Cultural Studies",
    "Agribusiness",
    "Agricultural Technology/Production",
    "Agriculture",
    "Agronomy",
    "Aircraft Maintenance",
    "Airline Management",
    "Allied Health",
    "Ambulatory Care",
    "American History",
    "American Sign Language",
    "American Studies",
    "Anatomy",
    "Anesthesia",
    "Animal Science",
    "Animation",
    "Anthropology",
    "Applied Geophysics",
    "Applied Science",
    "Arabic Language",
    "Archaeology",
    "Architecture",
    "Architecture",
    "Archives Management",
    "Art",
    "Art Education",
    "Art History",
    "Asian American Studies",
    "Asian Languages",
    "Astronomy",
    "Astrophysics",
    "Athletic Training",
    "Atmospheric Science",
    "Audiology",
    "Auto Body Repair and Painting",
    "Auto Mechanics",
    "Automotive",
    "Automotive Technology",
    "Aviation",
    "Aviation Technology",
    "Avionics",
    "Avionics",
    "Ballet",
    "Band",
    "Banking",
    "Barbering",
    "Behavioral Science",
    "Biblical Studies",
    "Bilingual Studies",
    "Biochemistry",
    "Biology",
    "Biometrics",
    "Biostatistics",
    "Biotechnology",
    "Botany",
    "Broadcast Meteorology",
    "Broadcasting",
    "Business",
    "Business Administration",
    "Carpentry",
    "Cartography",
    "Ceramics",
    "Chemistry",
    "Child Development",
    "Chinese Cultural Studies",
    "Chiropractic",
    "Choreography",
    "Cinematography",
    "City Planning",
    "Civics",
    "Classical Studies",
    "Classics and Letters",
    "CNA",
    "Coastal Science",
    "Commercial Art",
    "Communication Technology",
    "Communications",
    "Communicative Disorders",
    "Computer Aided Design",
    "Computer Information Systems",
    "Computer Science",
    "Conservation Science",
    "Construction",
    "Construction Management",
    "Construction Sciences",
    "Consumer Product Safety",
    "Consumer Studies",
    "Coptic Studies",
    "Cosmetics",
    "Cosmetology",
    "Counseling",
    "Counseling & Guidance",
    "Court Reporting",
    "Creative Arts",
    "Creative Writing",
    "Criminal Justice",
    "Criminology",
    "Crop Science",
    "Culinary Arts",
    "Cyber Security",
    "Dairy Science",
    "Dance",
    "Dance",
    "Dental Assistant",
    "Dental Hygiene",
    "Dental Technology",
    "Dentistry",
    "Design",
    "Design, Engineering Technology",
    "Diesel Mechanics",
    "Dietetics",
    "Digital Journalism",
    "Digital Marketing",
    "Digital Media",
    "Divinity",
    "Drafting & Design",
    "Drama",
    "Drug Research",
    "Early Childhood Education",
    "Earth Science",
    "Ecology",
    "eCommerce",
    "Economics",
    "Education",
    "Education – Music",
    "Education-Adult",
    "Education-Agriculture",
    "Education-Business",
    "Education-Early Childhood",
    "Education-Elementary",
    "Education-Engineering and Technology",
    "Education-English",
    "Education-Fine Arts",
    "Education-Health",
    "Education-History",
    "Education-Home Economics",
    "Education-Humanities",
    "Education-Industrial",
    "Education-Literature",
    "Education-Mathematics",
    "Education-Music",
    "Education-Philosophy",
    "Education-Physics",
    "Education-Religion",
    "Education-Science",
    "Education-Secondary",
    "Education-Social Science",
    "Education-Social Studies",
    "Education-Special Needs/Handicapped",
    "Education-Special/Handicapped",
    "Education-Technology",
    "Education-Vocational/Technical",
    "Educational Technology, Research, and Assessment",
    "Egyptology",
    "Electrical Technology",
    "Electronic Media",
    "Electronics",
    "Emergency Medical Technician",
    "Emergency Medical Technology",
    "Energy Management",
    "Energy Sciences",
    "Engineering",
    "Engineering – Architectural",
    "Engineering – Optical",
    "Engineering-Aerospace",
    "Engineering-Agricultural",
    "Engineering-Biological",
    "Engineering-Biomedical",
    "Engineering-Chemical",
    "Engineering-Civil",
    "Engineering-Computer",
    "Engineering-Construction",
    "Engineering-Electrical",
    "Engineering-Electronic",
    "Engineering-Energy",
    "Engineering-Environmental",
    "Engineering-Geological",
    "Engineering-Geophysical",
    "Engineering-Industrial",
    "Engineering-Manufacturing",
    "Engineering-Materials",
    "Engineering-Mechanical",
    "Engineering-Metallurgical",
    "Engineering-Mining",
    "Engineering-Naval",
    "Engineering-Nuclear",
    "Engineering-Ocean",
    "Engineering-Petroleum",
    "Engineering-Physics",
    "Engineering-Plastics",
    "Engineering-Software",
    "Engineering-Structural",
    "Engineering-Technology",
    "Engineering-Welding",
    "English",
    "English Literature",
    "Enology",
    "Entertainment Industry",
    "Entomology",
    "Entrepreneurship",
    "Environmental Chemistry",
    "Environmental Design",
    "Environmental Health",
    "Environmental Protection",
    "Environmental Science",
    "Epidemiology",
    "Equine Science",
    "Esthetics",
    "Ethnic Studies",
    "European Cultural Studies",
    "Exercise and Sport Science",
    "Facilities Planning",
    "Facility Management",
    "Family & Consumer Studies",
    "Family and Consumer Science",
    "Family Studies",
    "Fashion Design/Merchandising",
    "Fiber Arts",
    "Film/Video Studies",
    "Finance",
    "Financial Planning",
    "Fine Arts",
    "Fire Science",
    "Fire Service Management; Public Safety Administration",
    "Fish & Wildlife Science",
    "Floriculture",
    "Floriculture",
    "Food & Nutritional Science",
    "Food Safety",
    "Food Service",
    "Food Service Management",
    "Food Technology",
    "Foreign Cultural Studies",
    "Foreign Language",
    "Foreign Studies",
    "Forensics",
    "Forestry",
    "Franchise Management",
    "French Cultural Studies",
    "French Language",
    "Funeral Services",
    "General Studies",
    "Geochemistry",
    "Geography",
    "Geology",
    "Geophysics",
    "Geoscience",
    "Geospatial Information and Technology",
    "Geotechnology",
    "Geriatrics",
    "German",
    "Gerontology",
    "Government",
    "Graphic Arts",
    "Graphic Design",
    "Hairdressing",
    "Hawaiian Studies/Language",
    "Health",
    "Health Administration",
    "Health Care",
    "Health Informatics",
    "Health Outcomes",
    "Health Professions",
    "Health Promotion and Education",
    "Health Sciences",
    "Heating, Refrigeration & Air Conditioning",
    "Historic Preservation",
    "History",
    "Home Economics",
    "Horticulture",
    "Hospital ,Long Term Care",
    "Hospitality",
    "Hotel/Restaurant Management",
    "Human Development",
    "Human Relations",
    "Human Resource Development",
    "Human Resources",
    "Human Sciences",
    "Human Services",
    "Human Sexuality Studies",
    "Humanities",
    "Hydrology",
    "Illustration",
    "Industrial Arts",
    "Industrial Design",
    "Industrial Technology",
    "Industry",
    "Informatics",
    "Information Studies",
    "Information Systems Technology",
    "Information Technology",
    "Instructional Technology",
    "Insurance",
    "Interactive Entertainment",
    "Interdisciplinary Studies",
    "Interfaith Relations",
    "Interior Architecture",
    "Interior Design",
    "International Business.",
    "International Law",
    "International Relations",
    "International Studies",
    "Islamic Studies",
    "Italian Cultural Studies",
    "Italian Language",
    "Japanese",
    "Japanese Cultural Studies",
    "Jewelry Design/Repair",
    "Jewish Cultural Studies",
    "Journalism",
    "Justice",
    "Kinesiology",
    "Labor Relations",
    "Laboratory Science",
    "Laboratory Technology",
    "Land Economics",
    "Landscape Architecture",
    "Language Arts",
    "Languages and Literature",
    "Latin",
    "Law",
    "Law Enforcement",
    "Legal",
    "Legal Administration",
    "Legal Interpreter",
    "Liberal Arts",
    "Library Media",
    "Library Science",
    "Life Science",
    "Linguistics",
    "Literature",
    "Logistics",
    "Long Term Care Administration",
    "Machining",
    "Management",
    "Management Information Systems",
    "Manufacturing Technology",
    "Marine Science",
    "Marketing",
    "Marriage and Family Therapy",
    "Masonry",
    "Mass Communications",
    "Mass Media",
    "Massage Therapy",
    "Material Science",
    "Mathematics",
    "MBA",
    "Mechanical Technology",
    "Medical Administration",
    "Medical Assistant",
    "Medical Field",
    "Medical Research",
    "Medical Sciences",
    "Medical Technology",
    "Medical-clinical",
    "Medicine",
    "Medieval Studies",
    "Mental Health",
    "Metallurgical Engineering",
    "Metalsmithing",
    "Meteorology",
    "Microbiology",
    "Military Science",
    "Mineral Economics",
    "Mineral Science",
    "Mines and Earth Sciences",
    "Mining",
    "Mining Technology",
    "Ministry",
    "Modern Dance",
    "Modern European History",
    "Molecular Biology",
    "Morphology",
    "Mortuary Science",
    "Museum Studies",
    "Music",
    "Music-Composition",
    "Music-Instrumental",
    "Music-Piano",
    "Music-Voice",
    "National Security",
    "Natural Resources",
    "Natural Science",
    "Naval Science",
    "Navigation",
    "Nondestructive Testing",
    "Nuclear Science",
    "Nursing",
    "Nutrition",
    "Nutrition Science",
    "Occupational Safety",
    "Occupational Therapy",
    "Oceanography",
    "Office Administration",
    "Office Technology",
    "Operations and Information Management",
    "Ophthalmology",
    "Optical Design",
    "Optometry",
    "Orthotics",
    "Osteopathic Medicine",
    "Painting",
    "Paleontology",
    "Paralegal Studies",
    "Paramedics",
    "Parks & Recreation Management",
    "Pastoral Music",
    "Pastry Arts",
    "Pathology",
    "Performing Arts",
    "Performing Arts",
    "Pharmaceutics",
    "Pharmacology",
    "Philanthropy",
    "Philosophy",
    "Phlebotomy",
    "Photography",
    "Photojournalism",
    "Physical Education",
    "Physical Science",
    "Physical Therapy",
    "Physician Assistant",
    "Physics",
    "Physiology",
    "Planetary Geology",
    "Plant/Soil Science",
    "Plastics Technology",
    "Playwriting",
    "Plumbing",
    "Podiatry",
    "Police Science",
    "Polish Language",
    "Political Science",
    "Polymers & Coatings",
    "Polytechnic",
    "Poultry Science",
    "Pre-Dental",
    "Pre-Law",
    "Pre-Medicine",
    "Print Journalism",
    "Printing",
    "Product Design",
    "Professional Golf Management Program",
    "Professional Pilot Program",
    "Psychiatry",
    "Psychology",
    "Public Administration",
    "Public Health",
    "Public Policy",
    "Public Relations",
    "Public Services",
    "Radio",
    "Radiologic Science",
    "Radiology",
    "Real Estate",
    "Recording Technology",
    "Records Management",
    "Recreation",
    "Rehabilitation",
    "Religious Studies",
    "Religious Vocation",
    "Respiratory Care",
    "Restaurant Management",
    "Retail",
    "Risk Management",
    "Russian",
    "Safety",
    "Sales",
    "Science",
    "Scientific Research",
    "Secretarial Sciences",
    "Security Sciences",
    "Shipping and Packaging",
    "Social Sciences",
    "Social Services",
    "Social Work",
    "Sociology",
    "Software Development",
    "Soil Science",
    "Southeast Asian Studies",
    "Space History",
    "Space Technology",
    "Spanish",
    "Spanish Cultural Studies",
    "Special Education",
    "Speech Communication",
    "Speech Therapy",
    "Speech/Drama",
    "Sport and Leisure Studies",
    "Sports Management",
    "Sports Medicine",
    "Statistics",
    "STEM",
    "Student teaching",
    "Supply Chain Management",
    "Surgical Technology and Assisting",
    "Surveying",
    "Swedish Studies",
    "Swine Production",
    "Teacher in Inner-City Schools",
    "Teaching",
    "Teaching and Learning",
    "Technical Science",
    "Technology",
    "Telecommunications",
    "Television",
    "Textile Science",
    "Theatre Arts",
    "Theology",
    "Toxicology",
    "Trades",
    "Traffic Control",
    "Transportation",
    "Travel & Tourism",
    "Turf Management",
    "Turf Management",
    "Turkish Language",
    "Urban Design",
    "Urban Planning",
    "Vacuum Science",
    "Veterinary Medicine",
    "Video Game Arts",
    "Visual and Performing Arts",
    "Visual Arts",
    "Visual Communication",
    "Viticulture",
    "Vocal",
    "Vocational/Technical",
    "Waste Management",
    "Water Resources",
    "Water Technology",
    "Web Design",
    "Welding Technology",
    "Wetlands Science",
    "Wildlife Biology",
    "Wildlife Management",
    "Women’s History",
    "Women’s Studies",
    "Wood Science",
    "Wood Technology",
    "Writing",
    "Zoology",
  ];

  function formatMajors(array) {
    const formattedArray = [
      { name: "Select Major", value: "" },
      ...array.map((organization) => ({
        name: organization,
        value: organization,
      })),
    ];

    return formattedArray;
  }

  const formattedMajorsArray = formatMajors(majors);
  //console.log(formattedStudentOrganizationsArray);

  const gpa = [
    "0",
    "0.1",
    "0.2",
    "0.3",
    "0.4",
    "0.5",
    "0.6",
    "0.7",
    "0.8",
    "0.9",
    "1",
    "1.1",
    "1.2",
    "1.3",
    "1.4",
    "1.5",
    "1.6",
    "1.7",
    "1.8",
    "1.9",
    "2",
    "2.1",
    "2.2",
    "2.3",
    "2.4",
    "2.5",
    "2.6",
    "2.7",
    "2.8",
    "2.9",
    "3",
    "3.1",
    "3.2",
    "3.3",
    "3.4",
    "3.5",
    "3.6",
    "3.7",
    "3.8",
    "3.9",
    "4",
    "4.1",
    "4.2",
    "4.3",
    "4.4",
    "4.5",
    "4.6",
    "4.7",
    "4.8",
    "4.9",
    "5.0",
  ];

  return (
    <div className="personal-profile">
      <div className="profileeditbodybox2">
        <div className="profileeditbodyheader2" />
        <div className="profileeditbodybox11">
          <div className="scholarship-questions-22">
            <div className="editprofileform6">
              <div className="editprofileformbox6">
                <div className="editprofileformboxtext6">
                  Education/Grade Level <span style={{ color: "red" }}>*</span>
                </div>
                <div className="boxerror">
                  <select
                    className="editprofileformboxinputbox10"
                    name="classLevel"
                    required="required"
                    onChange={props.onChange}
                  >
                    <option value="">Select Education Level</option>
                    <option value="High School">High School</option>
                    <option value="Undergraduate">
                      Bachelors/Undergraduate
                    </option>
                    <option value="Graduate">Masters/Graduate</option>
                    <option value="Phd">Phd</option>
                    <option value="Associate">Associate</option>
                    <option value="Trade School">Trade School</option>
                  </select>
                  <span className="errormessage">
                    {props.formErrors.classLevel}
                  </span>
                </div>
              </div>
            </div>

            <div className="editprofileform6">
              <div className="editprofileformbox6">
                <div className="editprofileformboxtext6">High School</div>
                <div className="boxerror">
                  <input
                    className="editprofileformboxinputbox6"
                    // placeholder="Select High School"
                    type="text"
                    required="required"
                    name="highSchool"
                    onChange={props.onChange}
                    value={props.values.highSchool}
                    placeholder="Type High Shool"
                  />
                  <span className="errormessage">
                    {props.formErrors.highSchool}
                  </span>
                </div>
              </div>
            </div>

            <div className="editprofileform6">
              <div className="editprofileformbox6">
                <div className="editprofileformboxtext6">
                  High School Grad Year
                </div>
                <div className="boxerror">
                  <div className="editprofileformboxinputbox7">
                    {/* <input
                  className="inputtextboxdateyear"
                  placeholder="YYYY"
                  type="text"
                  //placeholder="2019"
                  required="required"
                  name="highSchoolGraduationYear"
                  onChange={props.onChange}
                                    
                /> */}

                    <select
                      className="inputtextboxdateyear"
                      required="required"
                      onChange={props.onChange}
                      name="highSchoolGraduationYear"
                    >
                      <option value="">Select year</option>
                      {Array.from({ length: 108 }, (_, index) => {
                        const year = 1923 + index;
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <span className="errormessage">
                    {props.formErrors.highSchoolGraduationYear}
                  </span>
                </div>
              </div>
            </div>

            <div className="editprofileform6">
              <div className="editprofileformbox6">
                <div className="editprofileformboxtext6">
                  Intended/Current College
                </div>
                <div className="boxerror">
                  {/* <input
                className="editprofileformboxinputbox6"
                placeholder="Search College"
                required="required"
                name="college"
                onChange={props.onChange}
              /> */}
                  <div className="autodrop">
                    <input
                      className="editprofileformboxinputbox17"
                      placeholder="Type College Name"
                      type="text"
                      name="college"
                      value={searchValue}
                      onChange={handleInputChange}
                      required="required"
                    />
                    {suggestions.length > 0 && (
                      <ul className="suggestions">
                        {suggestions.map((suggestion, index) => (
                          <li onClick={() => handleSuggestionClick(suggestion)}>
                            {suggestion}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <span className="errormessage">
                    {props.formErrors.college}
                  </span>
                </div>
              </div>
            </div>

            <div>
              {/* <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Search for a college..."
      /> */}
            </div>
            <div className="editprofileform6">
              <div className="editprofileformbox6">
                <div className="editprofileformboxtext6">College Grad Year</div>
                <div className="boxerror">
                  <div className="editprofileformboxinputbox7">
                    {/* <input
                  className="inputtextboxdateyear"
              
                  type="text"
                  placeholder="2023"
                  required="required" 
                  onChange={props.onChange}
                  name="collegeGraduationYear"
                /> */}

                    <select
                      className="inputtextboxdateyear"
                      required="required"
                      onChange={props.onChange}
                      name="collegeGraduationYear"
                    >
                      <option value="">Select year</option>
                      {Array.from({ length: 108 }, (_, index) => {
                        const year = 1923 + index;
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <span className="errormessage">
                    {props.formErrors.collegeGraduationYear}
                  </span>
                </div>
              </div>
            </div>

            <div className="editprofileform6">
              <div className="editprofileformbox6">
                <div className="editprofileformboxtext6">
                  Intended/Current Field Of Study{" "}
                  <span style={{ color: "red" }}>*</span>
                </div>
                {/* <input
                className="editprofileformboxinputbox6"
                required="required"
                name="fieldOfStudy" 
                onChange={props.onChange}
                placeholder="e.g Computer Engineering"
              /> */}
                <div className="boxerror">
                  <select
                    className="editprofileformboxinputbox10"
                    name="fieldOfStudy"
                    required="required"
                    onChange={props.onChange}
                  >
                    {/* <option value="">Select Education Level</option>
                <option value="High School">High School</option>
                <option value="Undergraduate">Bachelors/Undergraduate</option>
                <option value="Graduate">Masters/Graduate</option>
                <option value="Phd">Phd</option>
                <option value="Associate">Associate</option>
                <option value="Trade School">Trade School</option> */}

                    {formattedMajorsArray.map((org) => (
                      <option key={org.name} value={org.value}>
                        {org.name}
                      </option>
                    ))}
                  </select>
                  <span className="errormessage">
                    {props.formErrors.fieldOfStudy}
                  </span>
                </div>
              </div>
            </div>

            <div className="editprofileform6">
              <div className="editprofileformbox6">
                <div className="editprofileformboxtext6">Sat Score</div>
                <div className="boxerror">
                  <div className="editprofileformboxinputbox7">
                    <div className="inputtextboxdateyear-parent">
                      <input
                        className="inputtextboxdateyear2"
                        placeholder="Math"
                        type="text"
                        required="required"
                        onChange={props.onChange}
                        name="math"
                      />
                      <input
                        className="inputtextboxdateyear3"
                        type="text"
                        placeholder="Ebrw"
                        required="required"
                        onChange={props.onChange}
                        name="ebrw"
                      />
                    </div>
                  </div>
                  <span className="errormessage">{props.formErrors.math}</span>
                </div>
              </div>
            </div>

            <div className="editprofileform6">
              <div className="editprofileformbox6">
                <div className="editprofileformboxtext6">ACT Score</div>
                <div className="boxerror">
                  <div className="editprofileformboxinputbox7">
                    <input
                      className="inputtextboxdateyear"
                      type="text"
                      placeholder="e.g 26"
                      required="required"
                      onChange={props.onChange}
                      name="actScore"
                    />
                  </div>
                  {/* <span className="errormessage">
                    {props.formErrors.actScore}
                  </span> */}
                </div>
              </div>
            </div>

            <div className="editprofileform6">
              <div className="editprofileformbox6">
                <div className="editprofileformboxtext6">GED</div>
                <div className="boxerror">
                  <div className="editprofileformboxinputbox7">
                    <input
                      className="inputtextboxdateyear"
                      type="text"
                      placeholder="200-800"
                      required="required"
                      onChange={props.onChange}
                      name="ged"
                    />
                  </div>
                  <span className="errormessage">{props.formErrors.ged}</span>
                </div>
              </div>
            </div>

            <div className="editprofileform6">
              <div className="editprofileformbox6">
                <div className="editprofileformboxtext6">
                  GPA <span style={{ color: "red" }}>*</span>
                </div>
                <div className="boxerror">
                  <div className="editprofileformboxinputbox7">
                    {/* <input
                  className="inputtextboxdateyear"
                  type="text"
                  placeholder="Overall"
                  required="required" 
                  onChange={props.onChange}
                  name = "gpa"
                /> */}

                    <select
                      className="inputtextboxdateyear"
                      required="required"
                      onChange={props.onChange}
                      name="gpa"
                    >
                      <option value="">Select GPA</option>
                      {gpa.map((org) => (
                        <option key={org} value={org}>
                          {org}
                        </option>
                      ))}
                      {/* <option value="4.0">4.0</option>
  <option value="3.7">3.7 </option>
  <option value="3.3">3.3 </option>
  <option value="3.0">3.0 </option>
  <option value="2.7">2.7 </option>
  <option value="2.3">2.3 </option>
  <option value="2.0">2.0 </option>
  <option value="1.7">1.7 </option>
  <option value="1.3">1.3 </option>
  <option value="1.0">1.0 </option>
  <option value="0.7">0.7 </option>
  <option value="0.0">0.0 </option> */}
                    </select>
                  </div>
                  <span className="errormessage">{props.formErrors.gpa}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EDUCATIONPROFILE;
