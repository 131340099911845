import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import "./Login.css";
import "./WelcomeSignUp.css";

import NavBar from "../components/NavBar";
import { useDispatch, useSelector } from "react-redux";

//import { login, viewUserProfile } from "../../redux/apiCalls";
import {
  clearErrorAction,
  login,
  signUp,
  viewUserProfile,
} from "../redux/apiCalls";
import { loginFailure, loginStart, loginSuccess } from "../redux/userRedux";
const Login = () => {
  const navigate = useNavigate();

  const onRightOnBoardingLoginButtonClick = useCallback(() => {
    // Please sync "STUDENT ACTIVITIES PROFILE" to the project
  }, []);
  const onRightOnBoardingBoxAlreadyCreatClick = useCallback(() => {
    navigate("/welcomepath");
  }, [navigate]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [values, setValues] = useState({
    // birthYear: "",
    // highSchool: ""
  });
  const [formErrors, setFormErrors] = useState({});

  const dispatch = useDispatch();

  const error = useSelector((state) => state.user.error);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    // Check if there is an error message
    if (error.message) {
      console.log(error);
      return () => {
        console.log("error clearing xsnb");
        clearErrorAction()(dispatch);
      };
    }
  }, [error.message, dispatch]);

  function isValidEmail(email) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  const validateForm = (values) => {
    // console.log(values)
    const errorsF = {};

    if (!values.email) {
      errorsF.email = "Please Fill in the form!";
    }

    if (values.email) {
      if (!isValidEmail(values.email)) {
        errorsF.email = "Not a Valid Email!";
      }
    }

    if (!values.password) {
      errorsF.password = "Please Fill in the form!";
    }

    return errorsF;
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setFormErrors(validateForm(values));
  };

  useEffect(() => {
    console.log("values", values);
  }, [values]);

  useEffect(() => {
    if (isSubmitting) {
      console.log("errors", formErrors);

      if (Object.keys(formErrors).length === 0) {
        console.log("yes no error");
        dispatch(loginStart());

        const { email, password } = values;

        const user = {
          email,
          password,
        };

        const loginUser = async () => {
          try {
            const data = await login(user);
            dispatch(loginSuccess(data));
            setIsSubmitting(false);
            navigate("/");
          } catch (error) {
            console.log("Error:", error);
            setIsSubmitting(false);
            if (error.response.data.message) {
              dispatch(
                loginFailure({
                  message: error.response.data.message,
                  code: error.response.status,
                })
              );
            }
          }
        };

        loginUser();
      } else {
        setIsSubmitting(false);
      }
    }
  }, [formErrors, dispatch, navigate, signUp]);

  const handleLoginSuccess = async (tokenResponse) => {
    console.log("Login Success:", tokenResponse);

    try {
      const userInfoResponse = await fetch(
        "https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        }
      );

      const userInfo = await userInfoResponse.json();
      //console.log("User Info:", userInfo);
      const securePassword = userInfo.id + "A";

      const user = {
        email: userInfo.email,
        password: securePassword,
      };

      console.log("User:", user);

      dispatch(loginStart());
      try {
        const data = await login(user);
        dispatch(loginSuccess(data));
        navigate("/");
      } catch (error) {
        console.log("Error:", error);
        if (error.response) {
          dispatch(
            loginFailure({
              message: error.response.data.message,
              code: error.response.status,
            })
          );
        } else {
          dispatch(loginFailure({ message: "An error occurred", code: 500 }));
        }
      }
    } catch (error) {
      console.error("Failed to fetch user info:", error);
    }
  };

  const handleLoginFailure = (error) => {
    console.error("Login Failed:", error);
  };

  const google_login = useGoogleLogin({
    onSuccess: handleLoginSuccess,
    onError: handleLoginFailure,
  });

  return (
    <div className="welcomesignup">
      <div className="onboardingbox12">
        <div className="rightonboarding">
          <div className="leftonboarding">
            <div className="leftonboadingbox">
              <div className="letfonboardingimagebox">
                <div className="letfonboardingimagebox1">
                  <img
                    className="letfonboardingimage1-icon"
                    alt=""
                    src="/LetfOnboardingImage1.png"
                  />
                </div>
                <div className="letfonboardingimagebox2">
                  <img
                    className="letfonboardingimage2-icon"
                    alt=""
                    src="/LetfOnboardingImage2.png"
                  />
                </div>
              </div>
              <b className="letfonboardingwelcome">
                <p className="welcome">Welcome</p>
                <p className="welcome">To Scholist!</p>
              </b>
              <div className="letfonboardingapplying">
                Applying to scholarships made easy
              </div>
              <div className="letfonboardingrectanglebox">
                <div className="letfonboardingrectanglered" />
              </div>
              <div className="lowerboxonboarding">
                <div className="leftrectangleyellow" />
                <img
                  className="leftonboardingimage-icon"
                  alt=""
                  src="/LeftOnBoardingImage.png"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="rightonboarding12">
          <div className="rightonboardingbox1">
            <div className="rightonboardingboxsignup">
              <div className="rightonboarding1login">Login</div>
            </div>
            <form onSubmit={handleClick}>
              <div className="rightonboardingemail">
                <input
                  className="rightonboardingbox"
                  type="text"
                  placeholder="Email"
                  required="required"
                  name="email"
                  onChange={onChange}
                  value={values.email}
                />
                <span className="errormessage">{formErrors.email}</span>
              </div>
              <div className="rightonboardingpassword">
                <input
                  className="rightonboardingbox"
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={onChange}
                  value={values.password}
                />
                <span className="errormessage">{formErrors.password}</span>
              </div>
              <div className="rightonboardingboxloginbutton">
                <button className="rightonboardingloginbutton" type="submit">
                  <div className="rightonboardingloginbuttontext"> Login</div>
                </button>
              </div>
              <div className="errorLoginBox">
                {error && <b className="signuperror">{error.message}</b>}
              </div>

              <div className="rightonboardingsignupbox1">
                <div className="rightonboardingsignupbox">
                  <button
                    className="rightonboardingboxbutton1"
                    onClick={() => google_login()}
                  >
                    <div className="rightonboardingboxgoogle">
                      <svg
                        className="onboardingicongoogle"
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="18"
                        viewBox="0 0 17 18"
                        fill="none"
                      >
                        <path
                          d="M16.0205 9.49775C16.0205 8.79799 15.9649 8.28736 15.8446 7.75781H8.02051V10.9162H12.6131C12.5205 11.7011 12.0205 12.8831 10.9094 13.6774L10.8938 13.7831L13.3676 15.7403L13.539 15.7578C15.1131 14.2732 16.0205 12.0888 16.0205 9.49775"
                          fill="#4A93CF"
                        />
                        <path
                          d="M8.48624 17.7578C10.7426 17.7578 12.6369 16.9822 14.0205 15.6442L11.3834 13.5112C10.6777 14.0251 9.73049 14.3838 8.48624 14.3838C6.27625 14.3838 4.40055 12.8617 3.73192 10.7578L3.63391 10.7665L1.05424 12.851L1.02051 12.9489C2.39478 15.7993 5.21764 17.7578 8.48624 17.7578Z"
                          fill="#34A853"
                        />
                        <path
                          d="M4.02051 11.2541C3.82463 10.6337 3.71127 9.96894 3.71127 9.2821C3.71127 8.59519 3.82463 7.9305 4.0102 7.3101L4.00501 7.17798L1.10507 4.75781L1.01018 4.80631C0.38134 6.15791 0.0205078 7.67571 0.0205078 9.2821C0.0205078 10.8885 0.38134 12.4062 1.01018 13.7578L4.02051 11.2541"
                          fill="#DEB00F"
                        />
                        <path
                          d="M8.45439 4.13175C10.017 4.13175 11.071 4.83951 11.672 5.43096L14.0205 3.02651C12.5782 1.6207 10.7012 0.757812 8.45439 0.757812C5.19973 0.757812 2.38892 2.71625 1.02051 5.56664L3.71111 7.75781C4.38614 5.65394 6.25383 4.13175 8.45439 4.13175"
                          fill="#EB4335"
                        />
                      </svg>
                      <div className="rightonboardingboxbuttongoogle">
                        Sign In with Google
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </form>
            <button
              className="rightonboardingboxalreadycreat"
              onClick={onRightOnBoardingBoxAlreadyCreatClick}
            >
              <span className="already-have-an">Don't have an account? </span>
              <span className="log-in"> Sign up</span>
            </button>
            {/* <div className="terms-and-condition">
              <div className="by-submitting-this-form-you-c-wrapper">
                <div className="by-submitting-this-container">
                  <button
                    className="rightonboardingboxalreadycreat"
                    onClick={onRightOnBoardingBoxAlreadyCreatClick}
                  >
                    <span className="already-have-an">
                      Don't have an account?{" "}
                    </span>
                    <span className="log-in"> Sign up</span>
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
