// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import jsonp from "jsonp";

// const MailchimpSubscribe = ({ email }) => {
//   const [status, setStatus] = useState(null);
//   const mailchimpUrl =
//     //"https://scholistapp.us17.list-manage.com/subscribe/post-json?u=a80c9d8113a5f1fa89ec93f8e&id=914f080c97";
//     "https://scholistapp.us7.list-manage.com/subscribe/post?u=00fe0e96911423530b9f1b092&id=46d27be03c&f_id=004ad8e4f0";

//   const subscribe = (email) => {
//     const url = `${mailchimpUrl}&EMAIL=${encodeURIComponent(email)}&c=callback`;

//     console.log("Attempting to subscribe with email:", email);
//     console.log("Mailchimp API URL:", url);

//     jsonp(url, { param: "c" }, (err, data) => {
//       if (err) {
//         console.error("Mailchimp subscription error:", err);
//         setStatus("error");
//       } else if (data.result === "success") {
//         console.log("Mailchimp response:", data);
//         setStatus("success");
//       } else {
//         console.log("Mailchimp response:", data);
//         setStatus("error");
//       }
//     });
//   };

//   useEffect(() => {
//     if (email) {
//       subscribe(email);
//     }
//   }, [email]);

//   return (
//     <div>
//       {status === "success" && (
//         <p style={{ color: "green", fontSize: "1rem" }}>
//           Subscription successful!
//         </p>
//       )}
//       {status === "error" && (
//         <p style={{ color: "red", fontSize: "1rem" }}>
//           Subscription failed. Please try again.
//         </p>
//       )}
//     </div>
//   );
// };

// export default MailchimpSubscribe;

import React, { useState, useEffect } from "react";
import jsonp from "jsonp";

const MailchimpSubscribe = ({ email, firstName, lastName }) => {
  const [status, setStatus] = useState(null);
  const mailchimpUrl =
    "https://scholistapp.us7.list-manage.com/subscribe/post?u=00fe0e96911423530b9f1b092&id=46d27be03c&f_id=004ad8e4f0";

  const subscribe = (email, firstName, lastName) => {
    let url = `${mailchimpUrl}&EMAIL=${encodeURIComponent(email)}&c=callback`;

    if (firstName) {
      url += `&FNAME=${encodeURIComponent(firstName)}`;
    }
    if (lastName) {
      url += `&LNAME=${encodeURIComponent(lastName)}`;
    }

    console.log("Attempting to subscribe with email:", email);
    console.log("Mailchimp API URL:", url);

    jsonp(url, { param: "c" }, (err, data) => {
      if (err) {
        console.error("Mailchimp subscription error:", err);
        setStatus("error");
      } else if (data.result === "success") {
        console.log("Mailchimp response:", data);
        setStatus("success");
      } else {
        console.log("Mailchimp response:", data);
        setStatus("error");
      }
    });
  };

  useEffect(() => {
    if (email) {
      subscribe(email, firstName, lastName);
    }
  }, [email, firstName, lastName]);

  return (
    <div>
      {/* {status === "success" && (
        <p style={{ color: "green", fontSize: "1rem" }}>
          Subscription successful!
        </p>
      )} */}
      {status === "error" && (
        <p style={{ color: "red", fontSize: "1rem" }}>
          Subscription failed. Please try again.
        </p>
      )}
    </div>
  );
};

export default MailchimpSubscribe;
