import "./TicketSubmitted.css";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
const TicketSubmitted = ({ onClose, ticketNo }) => {
  return (
    <div className="ticketsubmitted">
      <div className="tickedsubmittedbody">
        <div className="ticketsubmittedheader">Ticket Submitted</div>
        <div className="ticketsubmittednumber">#{ticketNo}</div>
        <div className="ticketsubmittedmessagebody">
          
          <QuestionAnswerIcon 
          className="ticketsubmittedquestion-icon"
          />
        </div>
        <div className="ticketsubmiitedwillbe">
          We will get back to you immediately
        </div>
      </div>
    </div>
  );
};

export default TicketSubmitted;
