import { createSlice } from "@reduxjs/toolkit";


const bookmarkSlice = createSlice({
    name: "bookmark",
    initialState: {
      currentCoachBookmarks: null,
      isFetching: false,
      error: false

    },
    reducers:{
        getCoachBookmarkStart: (state) => {
            state.isFetching = true
        },
        getCoachBookmarkSuccess: (state, action) => {
            state.isFetching = false;
            state.currentCoachBookmarks = action.payload

        },
        getCoachBookmarkFailure: (state) => {
            state.isFetching = false;
            state.error = true;
            state.currentCoachBookmarks = null;
        },

        createProfileStart: (state) => {
            state.isCreating = true
        },
        createProfileSuccess: (state, action) => {
            state.isCreating = false;
            state.currentUserProfile = action.payload

        },
        createProfileFailure: (state) => {
            state.isCreating = false;
            state.error = true;
            state.currentUserProfile = null;
        },

        
        updateProfileStart: (state) => {
            state.isUpdating = true
        },
        updateProfileSuccess: (state, action) => {
            state.isUpdating = false;
            state.currentUserProfile = action.payload

        },
        updateProfileFailure: (state) => {
            state.isUpdating = false;
            state.error = true;
            //state.currentUserProfile = null;
        },

         
        clearProfileStart: (state) => {
            state.isClearing = true
        },
        clearProfileSuccess: (state) => {
            state.isClearing = false;
            state.currentUserProfile = null

        },
        clearProfileFailure: (state) => {
            state.isClearing = false;
            state.error = true;
            //state.currentUserProfile = null;
        },







    }
})



export const {
    getCoachBookmarkStart,
    getCoachBookmarkSuccess,
    getCoachBookmarkFailure,

} = bookmarkSlice.actions;

export default bookmarkSlice.reducer;