import { createSlice } from "@reduxjs/toolkit";


const userSlice = createSlice({
    name: "user",
    initialState: {
      currentUser: null,
      isFetching: false,
      error: false

    },
    reducers:{
        loginStart: (state) => {
            state.isFetching = true
        },
        loginSuccess: (state, action) => {
            state.isFetching = false;
            state.currentUser = action.payload
            localStorage.setItem('currentUser', JSON.stringify(action.payload));

        },
        // loginFailure: (state) => {
        //     state.isFetching = false;
        //     state.error = true;
        // },
        loginFailure: (state, action) => {
           
            state.isFetching = false;
            const { message, code } = action.payload; // Extract relevant information from AxiosError object
            state.error = { message, code };
        },


        signUpStart: (state) => {
            state.isFetching = true
        },
        signUpSuccess: (state, action) => {
            state.isFetching = false;
            state.currentUser = action.payload

        },
        // signUpFailure: (state) => {
        //     state.isFetching = false;
        //     state.error = true;
        // },
        signUpFailure: (state, action) => {
            state.isFetching = false;
            const { message, code } = action.payload; // Extract relevant information from AxiosError object
            state.error = { message, code }; // Store only the serializable properties in state
          },

        logoutStart: (state) => {
            state.isFetching = true
        },
        logoutSuccess: (state) => {
            state.isFetching = false;
            state.currentUser =  null

        },
        logoutFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        
        clearErrorStart: (state) => {
            state.isFetching = true
        },
        clearErrorSuccess: (state, action) => {
            state.isFetching = false;
            const { message, code } = action.payload; // Extract relevant information from AxiosError object
            state.error = { message, code }; // Store only the serializable properties in state

        },
 
        clearErrorFailure: (state, action) => {
            state.isFetching = false;
            const { message, code } = action.payload; // Extract relevant information from AxiosError object
            state.error = { message, code }; // Store only the serializable properties in state
          },

        resetIsFetching: (state) => {
            state.isFetching = false
        },





    }
})



export const {loginStart, loginSuccess, loginFailure , signUpStart, signUpFailure, signUpSuccess, logoutStart, logoutSuccess, logoutFailure, clearErrorStart, clearErrorSuccess, clearErrorFailure, resetIsFetching} = userSlice.actions;
export default userSlice.reducer;