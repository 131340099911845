import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { BASE_Server_URL, publicRequest } from '../requestMethods';



const Conversation = ({conversation, currentUser}) => {
  const [user, setUser] = useState({});
const PF = process.env.React_App_Public;

const navigate = useNavigate();
const [messages, setMessages] = useState([]);

const onMyMessagesListBox1ContainerClick = useCallback(() => {
  navigate("/messageview");
}, [navigate]);


// useEffect(() => {
//   const friendId = conversation.members.find((m) => m !== currentUser._id);

//   const getUser = async () => {
//     try {
      

//       const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
//       const currentUser = user && JSON.parse(user).currentUser;
//       const TOKEN = currentUser?.tokens.access.token;
      
//             const client = axios.create({
//               baseURL: "http://localhost:3000/v1/",
//               headers: { Authorization: `Bearer ${TOKEN}` },
//               });

//               console.log('friendId', friendId)
//               const res = await client.get("/users/" + friendId);

               
//       console.log(res)
//       setUser(res.data);
//     } catch (err) {
//       console.log(err);
//     }
//   };
//   getUser();
// }, [currentUser, conversation]);
useEffect(() => {
  console.log('cghdchnfvbnxm', conversation)
}, [])

useEffect(()=>{

  const getMessages = async () => {
    console.log('called')
    try {


      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
        });


      const res = await client.get("/message/" + conversation?.id);
      // const res = await axios.get("/messages/" + currentChat?._id);

      console.log('messages conversation',res.data )

      setMessages(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  getMessages();

  // const friendId = conversation.members.find((m) => m !== currentUser.id);
//console.log('current user',currentUser)
  const friendId  =  conversation.members.find(member => member !== currentUser.user.id)


  console.log('current friend',friendId)
  // console.log(conversation.members)

  const getCoach = async ()=>{
    try{
      const res = await publicRequest.get("/coach/coachProfileOwner/"+friendId)
      setUser(res.data);
      console.log('mxd c,dx',res)
    }catch (err) {
            console.log(err);
          }
  }

  getCoach()

  
}, [currentUser, conversation])



function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
}


  return (
   
  //   <div className="writemessagebodysendermessage2">
  //   <div className="writemessagebodysendermessage11">
  //     <div className="writebodysendermessagetext1">{`hvnsxd vgfgh gvbgvgvfcfv `}</div>
  //   </div>
  // </div>
  <div
  className="mymessageslistbox1"
  // onClick={onMyMessagesListBox1ContainerClick}
>
  <div className="mymessageslistbox2">
    <img
      className="mymessageslistprofile-icon"
      alt=""
      //  src={user.img}
       src={
        user?.img
          ? user.img
          : PF + "noprofile.png"
      }
    />
    

    <div className="mymessageslistbox3">
      <div className="mymessageslistheader">
        <div className="mymessageslistname">
          {user.name}
        </div>
        <div className="mymessageslistdate"> {messages.length > 0 ? formatDate(messages[messages.length - 1].createdAt) : formatDate(conversation.createdAt)}</div>
      </div>
      <div className="mymessageslistsummary">
        <span className="mymessageslistsummary-txt">
          <p className="coca-cola-scholarship-form">{messages.length > 0 ? messages[messages.length - 1].text : 'No Messages '}</p>
          {/* <p className="coca-cola-scholarship-form">{`Mode of app... `}</p> */}
        </span>
      </div>
    </div>

  </div>
</div>
  )
}

export default Conversation