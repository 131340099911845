import { createSlice } from "@reduxjs/toolkit";


const communitySlice = createSlice({
    name: "community",
    initialState: {
        myCommunities: []

    },
    reducers:{
        createCommunity: (state, action) => {
            state.myCommunities.push(action.payload.community)

        }
    }
})



export const {createCommunity} = communitySlice.actions;
export default communitySlice.reducer;