import "./MyNotifications.css";
const MyNotifications = () => {
  return (
    <div className="mynotifications">
      <div className="notificationheader">
        <div className="notificationheadertext">Notification</div>
      </div>
      <div className="notificationbox">
        <div className="notificationbox1">
          <div className="notificationbox2">
            {/* <img className="notificationboxicon" alt="" src="/group-87.svg" /> */}
            <div className="notificationCircle">
                    {/* <b className="b1">20</b> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M17.7846 8.14118C17.7846 6.60672 17.175 5.1351 16.09 4.05007C15.005 2.96503 13.5334 2.35547 11.9989 2.35547C10.4644 2.35547 8.9928 2.96503 7.90777 4.05007C6.82273 5.1351 6.21317 6.60672 6.21317 8.14118C6.21317 14.8912 3.32031 16.8198 3.32031 16.8198H20.6775C20.6775 16.8198 17.7846 14.8912 17.7846 8.14118Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.6665 20.6777C13.497 20.97 13.2536 21.2126 12.9609 21.3812C12.6681 21.5498 12.3362 21.6386 11.9983 21.6386C11.6604 21.6386 11.3285 21.5498 11.0357 21.3812C10.7429 21.2126 10.4996 20.97 10.3301 20.6777" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                  </div>
            <div className="notificationupdatebox">
              <div className="notificationupdateboxtext">
                <span className="update">{`Update:  `}</span>
                <span>$2,000 Nitro Scholarship Sweepstakes</span>
                <span className="update">{`  `}</span>
              </div>
              <div className="notificationupdateboxreadmore">read more</div>
            </div>
          </div>
          <div className="notificationline1" />
        </div>
        <div className="notificationbox1">
          <div className="notificationbox2">
            {/* <img className="notificationboxicon" alt="" src="/group-87.svg" /> */}
            <div className="notificationCircle">
                    {/* <b className="b1">20</b> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M17.7846 8.14118C17.7846 6.60672 17.175 5.1351 16.09 4.05007C15.005 2.96503 13.5334 2.35547 11.9989 2.35547C10.4644 2.35547 8.9928 2.96503 7.90777 4.05007C6.82273 5.1351 6.21317 6.60672 6.21317 8.14118C6.21317 14.8912 3.32031 16.8198 3.32031 16.8198H20.6775C20.6775 16.8198 17.7846 14.8912 17.7846 8.14118Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.6665 20.6777C13.497 20.97 13.2536 21.2126 12.9609 21.3812C12.6681 21.5498 12.3362 21.6386 11.9983 21.6386C11.6604 21.6386 11.3285 21.5498 11.0357 21.3812C10.7429 21.2126 10.4996 20.97 10.3301 20.6777" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                  </div>
            <div className="notificationupdatebox">
              <div className="notificationupdateboxtext">
                <span className="update">{`Update:  `}</span>
                <span>$2,000 Nitro Scholarship Sweepstakes</span>
                <span className="update">{`  `}</span>
              </div>
              <div className="notificationupdateboxreadmore">read more</div>
            </div>
          </div>
          <div className="notificationline1" />
        </div>
        <div className="notificationbox1">
          <div className="notificationbox2">
            {/* <img className="notificationboxicon" alt="" src="/group-87.svg" /> */}
            <div className="notificationCircle">
                    {/* <b className="b1">20</b> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M17.7846 8.14118C17.7846 6.60672 17.175 5.1351 16.09 4.05007C15.005 2.96503 13.5334 2.35547 11.9989 2.35547C10.4644 2.35547 8.9928 2.96503 7.90777 4.05007C6.82273 5.1351 6.21317 6.60672 6.21317 8.14118C6.21317 14.8912 3.32031 16.8198 3.32031 16.8198H20.6775C20.6775 16.8198 17.7846 14.8912 17.7846 8.14118Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.6665 20.6777C13.497 20.97 13.2536 21.2126 12.9609 21.3812C12.6681 21.5498 12.3362 21.6386 11.9983 21.6386C11.6604 21.6386 11.3285 21.5498 11.0357 21.3812C10.7429 21.2126 10.4996 20.97 10.3301 20.6777" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                  </div>
            <div className="notificationupdatebox">
              <div className="notificationupdateboxtext">
                <span className="update">{`Update:  `}</span>
                <span>$2,000 Nitro Scholarship Sweepstakes</span>
                <span className="update">{`  `}</span>
              </div>
              <div className="notificationupdateboxreadmore">read more</div>
            </div>
          </div>
          <div className="notificationline1" />
        </div>
        <div className="notificationbox1">
          <div className="notificationbox2">
            {/* <img className="notificationboxicon" alt="" src="/group-87.svg" /> */}
            <div className="notificationCircle">
                    {/* <b className="b1">20</b> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M17.7846 8.14118C17.7846 6.60672 17.175 5.1351 16.09 4.05007C15.005 2.96503 13.5334 2.35547 11.9989 2.35547C10.4644 2.35547 8.9928 2.96503 7.90777 4.05007C6.82273 5.1351 6.21317 6.60672 6.21317 8.14118C6.21317 14.8912 3.32031 16.8198 3.32031 16.8198H20.6775C20.6775 16.8198 17.7846 14.8912 17.7846 8.14118Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.6665 20.6777C13.497 20.97 13.2536 21.2126 12.9609 21.3812C12.6681 21.5498 12.3362 21.6386 11.9983 21.6386C11.6604 21.6386 11.3285 21.5498 11.0357 21.3812C10.7429 21.2126 10.4996 20.97 10.3301 20.6777" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                  </div>
            <div className="notificationupdatebox">
              <div className="notificationupdateboxtext">
                <span className="update">{`Update:  `}</span>
                <span>$2,000 Nitro Scholarship Sweepstakes</span>
                <span className="update">{`  `}</span>
              </div>
              <div className="notificationupdateboxreadmore">read more</div>
            </div>
          </div>
          <div className="notificationline1" />
        </div>
        <div className="notificationbox1">
          <div className="notificationbox2">
            {/* <img className="notificationboxicon" alt="" src="/group-87.svg" /> */}
            <div className="notificationCircle">
                    {/* <b className="b1">20</b> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M17.7846 8.14118C17.7846 6.60672 17.175 5.1351 16.09 4.05007C15.005 2.96503 13.5334 2.35547 11.9989 2.35547C10.4644 2.35547 8.9928 2.96503 7.90777 4.05007C6.82273 5.1351 6.21317 6.60672 6.21317 8.14118C6.21317 14.8912 3.32031 16.8198 3.32031 16.8198H20.6775C20.6775 16.8198 17.7846 14.8912 17.7846 8.14118Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.6665 20.6777C13.497 20.97 13.2536 21.2126 12.9609 21.3812C12.6681 21.5498 12.3362 21.6386 11.9983 21.6386C11.6604 21.6386 11.3285 21.5498 11.0357 21.3812C10.7429 21.2126 10.4996 20.97 10.3301 20.6777" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                  </div>
            <div className="notificationupdatebox">
              <div className="notificationupdateboxtext">
                <span className="update">{`Update:  `}</span>
                <span>$2,000 Nitro Scholarship Sweepstakes</span>
                <span className="update">{`  `}</span>
              </div>
              <div className="notificationupdateboxreadmore">read more</div>
            </div>
          </div>
          <div className="notificationline1" />
        </div>
        <div className="notificationbox1">
          <div className="notificationbox2">
            {/* <img className="notificationboxicon" alt="" src="/group-87.svg" /> */}
            <div className="notificationCircle">
                    {/* <b className="b1">20</b> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M17.7846 8.14118C17.7846 6.60672 17.175 5.1351 16.09 4.05007C15.005 2.96503 13.5334 2.35547 11.9989 2.35547C10.4644 2.35547 8.9928 2.96503 7.90777 4.05007C6.82273 5.1351 6.21317 6.60672 6.21317 8.14118C6.21317 14.8912 3.32031 16.8198 3.32031 16.8198H20.6775C20.6775 16.8198 17.7846 14.8912 17.7846 8.14118Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.6665 20.6777C13.497 20.97 13.2536 21.2126 12.9609 21.3812C12.6681 21.5498 12.3362 21.6386 11.9983 21.6386C11.6604 21.6386 11.3285 21.5498 11.0357 21.3812C10.7429 21.2126 10.4996 20.97 10.3301 20.6777" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                  </div>
            <div className="notificationupdatebox">
              <div className="notificationupdateboxtext">
                <span className="update">{`Update:  `}</span>
                <span>$2,000 Nitro Scholarship Sweepstakes</span>
                <span className="update">{`  `}</span>
              </div>
              <div className="notificationupdateboxreadmore">read more</div>
            </div>
          </div>
          <div className="notificationline1" />
        </div>
        <div className="notificationbox1">
          <div className="notificationbox2">
            {/* <img className="notificationboxicon" alt="" src="/group-87.svg" /> */}
            <div className="notificationCircle">
                    {/* <b className="b1">20</b> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M17.7846 8.14118C17.7846 6.60672 17.175 5.1351 16.09 4.05007C15.005 2.96503 13.5334 2.35547 11.9989 2.35547C10.4644 2.35547 8.9928 2.96503 7.90777 4.05007C6.82273 5.1351 6.21317 6.60672 6.21317 8.14118C6.21317 14.8912 3.32031 16.8198 3.32031 16.8198H20.6775C20.6775 16.8198 17.7846 14.8912 17.7846 8.14118Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.6665 20.6777C13.497 20.97 13.2536 21.2126 12.9609 21.3812C12.6681 21.5498 12.3362 21.6386 11.9983 21.6386C11.6604 21.6386 11.3285 21.5498 11.0357 21.3812C10.7429 21.2126 10.4996 20.97 10.3301 20.6777" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                  </div>
            <div className="notificationupdatebox">
              <div className="notificationupdateboxtext">
                <span className="update">{`Update:  `}</span>
                <span>$2,000 Nitro Scholarship Sweepstakes</span>
                <span className="update">{`  `}</span>
              </div>
              <div className="notificationupdateboxreadmore">read more</div>
            </div>
          </div>
          <div className="notificationline1" />
        </div>
        <div className="notificationbox1">
          <div className="notificationbox2">
            {/* <img className="notificationboxicon" alt="" src="/group-87.svg" /> */}
            <div className="notificationCircle">
                    {/* <b className="b1">20</b> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M17.7846 8.14118C17.7846 6.60672 17.175 5.1351 16.09 4.05007C15.005 2.96503 13.5334 2.35547 11.9989 2.35547C10.4644 2.35547 8.9928 2.96503 7.90777 4.05007C6.82273 5.1351 6.21317 6.60672 6.21317 8.14118C6.21317 14.8912 3.32031 16.8198 3.32031 16.8198H20.6775C20.6775 16.8198 17.7846 14.8912 17.7846 8.14118Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.6665 20.6777C13.497 20.97 13.2536 21.2126 12.9609 21.3812C12.6681 21.5498 12.3362 21.6386 11.9983 21.6386C11.6604 21.6386 11.3285 21.5498 11.0357 21.3812C10.7429 21.2126 10.4996 20.97 10.3301 20.6777" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                  </div>
            <div className="notificationupdatebox">
              <div className="notificationupdateboxtext">
                <span className="update">{`Update:  `}</span>
                <span>$2,000 Nitro Scholarship Sweepstakes</span>
                <span className="update">{`  `}</span>
              </div>
              <div className="notificationupdateboxreadmore">read more</div>
            </div>
          </div>
          <div className="notificationline1" />
        </div>
      </div>
      <div className="notificaticationviewmore">
        <div className="notificationviewmorebutton">
          <div className="notificationviewmorebuttontext">View More</div>
        </div>
      </div>
    </div>
  );
};

export default MyNotifications;
