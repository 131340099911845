import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./CoachSidebar.css";

const Sidebar = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("Home");

  // Update active link based on current location
  React.useEffect(() => {
    const currentPath = location.pathname;
    const linkName = currentPath.includes("calendar")
      ? "Calendar"
      : currentPath.includes("bookings")
      ? "Bookings"
      : currentPath.includes("messages")
      ? "Messages"
      : currentPath.includes("payments")
      ? "Payments"
      : currentPath.includes("resources")
      ? "Resources"
      : currentPath.includes("scholarships")
      ? "Scholarships"
      : currentPath.includes("settings")
      ? "Settings"
      : "Home"; // Default to "Home"
    setActiveLink(linkName);
  }, [location.pathname]);

  return (
    <div className="sidebar">
      <nav>
        <Link
          to="/welcome"
          className={`first-one ${activeLink === "Home" ? "active" : ""}`}
          onClick={() => setActiveLink("Home")}
        >
          <img src="/homeIconCoach.svg" alt="Home Icon" className="home-icon" />
          Home
        </Link>
        <Link
          to="/calendar/recurring"
          className={`other-one ${activeLink === "Calendar" ? "active" : ""}`}
          onClick={() => setActiveLink("Calendar")}
        >
          <img
            src="/calendarIconCoach.svg"
            alt="Calendar Icon"
            className="calendar-icon"
          />
          Calendar
        </Link>
        <Link
          to="/bookings"
          className={`other-one ${activeLink === "Bookings" ? "active" : ""}`}
          onClick={() => setActiveLink("Bookings")}
        >
          <img
            src="/bookingsIconCoach.svg"
            alt="Bookings Icon"
            className="bookings-icon"
          />
          Bookings
        </Link>
        <Link
          to="/messages"
          className={`other-one ${activeLink === "Messages" ? "active" : ""}`}
          onClick={() => setActiveLink("Messages")}
        >
          <img
            src="/messagesIconCoach.svg"
            alt="Messages Icon"
            className="messages-icon"
          />
          Messages
        </Link>
        <Link
          to="/payments"
          className={`other-one ${activeLink === "Payments" ? "active" : ""}`}
          onClick={() => setActiveLink("Payments")}
        >
          <img
            src="/paymentIconCoach.svg"
            alt="Payment Icon"
            className="payment-icon"
          />
          Payments
        </Link>
        <Link
          to="/resources"
          className={`other-one ${activeLink === "Resources" ? "active" : ""}`}
          onClick={() => setActiveLink("Resources")}
        >
          <img
            src="/resourcesIconCoach.svg"
            alt="Resources Icon"
            className="resources-icon"
          />
          Resources
        </Link>
        <Link
          to="/scholarships"
          className={`other-one ${
            activeLink === "Scholarships" ? "active" : ""
          }`}
          onClick={() => setActiveLink("Scholarships")}
        >
          <img
            src="/scholarshipsIconCoach.svg"
            alt="Scholarships Icon"
            className="scholarships-icon"
          />
          Scholarships
        </Link>
        <Link
          to="/settings"
          className={`last-one ${activeLink === "Settings" ? "active" : ""}`}
          onClick={() => setActiveLink("Settings")}
        >
          <img
            src="/settingsIconCoach.svg"
            alt="Settings Icon"
            className="settings-icon"
          />
          Settings
        </Link>
      </nav>
    </div>
  );
};

export default Sidebar;
