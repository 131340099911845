import "./SavedCoachNotification.css";
const SavedCoachNotification = (props) => {
  return (
    <div className="savedcoachnotification">
      <div className="savedcoachnotificationbox">
        <div className="leftsavedcoachnotificationbox">
          <img
            className="leftsavedcoachnotificationbox-icon"
            alt=""
            //src="/cardcoachprofile-imgsmall@2x.png"
            src={props.coach.img}
          />
          <div className="rightsavedcoachnotbox">
            <b className="rightsavedcoachnotboxsavedtext">Coach</b>
            <div className="rightsavedcoachnotboxsavedtext1">{props.coach.name}</div>
          </div>
        </div>
        <div className="rightsavedcoachnoticonbox">
          <b className="rightsavedcoachnoticonboxtext">Bookmarked</b>
          {/* <img
            className="rightsavedcoachnotbookmarkicon"
            alt=""
            src="/vector.svg"
          /> */}
          <svg  className="rightsavedcoachnotbookmarkicon" xmlns="http://www.w3.org/2000/svg" width="21" height="29" viewBox="0 0 21 29" fill="none">
  <path d="M17.7706 -0.000976562H2.69252C1.97842 -0.000976562 1.29357 0.282699 0.78862 0.787644C0.283675 1.29259 9.10724e-08 1.97744 9.10723e-08 2.69154V26.3857C-9.67402e-05 26.6741 0.0770238 26.9573 0.223353 27.2059C0.369682 27.4544 0.579882 27.6592 0.832126 27.7991C1.08437 27.939 1.36946 28.0087 1.65778 28.0012C1.9461 27.9937 2.22715 27.9091 2.47173 27.7562L10.2302 22.9097L17.9914 27.7562C18.236 27.9091 18.517 27.9937 18.8054 28.0012C19.0937 28.0087 19.3788 27.939 19.631 27.7991C19.8833 27.6592 20.0935 27.4544 20.2398 27.2059C20.3861 26.9573 20.4632 26.6741 20.4631 26.3857V2.69154C20.4631 1.97744 20.1795 1.29259 19.6745 0.787644C19.1696 0.282699 18.4847 -0.000976562 17.7706 -0.000976562ZM11.6433 13.5162L17.2321 17.009L11.0864 13.1681C10.8297 13.0077 10.533 12.9226 10.2302 12.9226C9.92744 12.9226 9.63076 13.0077 9.374 13.1681L3.23102 17.009L7.86701 14.1104L10.6958 13.5162L11.6433 13.5162ZM11.0864 19.6302C10.8297 19.4697 10.533 19.3846 10.2302 19.3846C9.92744 19.3846 9.63076 19.4697 9.374 19.6302L3.23102 23.471L6.30251 21.5506L10.2302 20.8189L14.2611 21.6142L17.2321 23.471L11.0864 19.6302Z" fill="#FE4A49"/>
</svg>
        </div>
      </div>
    </div>
  );
};

export default SavedCoachNotification;
