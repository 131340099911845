import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { Server_URL, publicRequest } from "../requestMethods";


function getServiceById(serviceId, services) {

  //console.log('xcfgvhbnm,', services)

  //console.log('dfgfhghxcfgvhbnm,', serviceId)
  return services.find(service => service._id === serviceId);
}

export default function CheckoutForm({coachId, serviceId}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [coach, setCoach] = useState(null)
  const [service, setService] = useState(null)

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
       
        return_url: Server_URL + "success",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    //setIsLoading(false);
  };


  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  
  //   if (!stripe || !elements) {
  //     // Stripe.js hasn't yet loaded.
  //     // Make sure to disable form submission until Stripe.js has loaded.
  //     return;
  //   }
  
  //   setIsLoading(true);
  
  //   // Ensure Server_URL is defined and available here
  //   let Server_URL = "http://localhost:3001/"; // Define Server_URL here
  
  //   try {
  //     const { error } = await stripe.confirmPayment({
  //       elements,
  //       confirmParams: {
  //         return_url: Server_URL + "success",
  //       },
  //     });
  
  //     // This point will only be reached if there is an immediate error when
  //     // confirming the payment. Otherwise, your customer will be redirected to
  //     // your `return_url`. For some payment methods like iDEAL, your customer will
  //     // be redirected to an intermediate site first to authorize the payment, then
  //     // redirected to the `return_url`.
  //     if (error.type === "card_error" || error.type === "validation_error") {
  //       setMessage(error.message);
  //     } else {
  //       setMessage("An unexpected error occurred.");
  //     }
  //   } catch (error) {
  //     console.error("Error confirming payment:", error);
  //     setMessage("An unexpected error occurred.");
  //   }
  
  //   setIsLoading(false);
  // };
  

  const paymentElementOptions = {
    layout: "tabs"
  }


  // useEffect(()=> {
  //   console.log('coachId', coachId)
  //   console.log('serviceId', serviceId)
  // }, [])



  
  const getCoach = async ()=>{

    // console.log('gbhnmk,l.', order.coachId)
     try{
       const res = await publicRequest.get("/coach/coachProfileOwner/"+coachId)
       setCoach(res.data);
       console.log('mxd c,dx',res.data)


     




     }catch(err){
       console.log(err)
     }
   }
 
 useEffect(() => {
    
    
       getCoach()
      
     
     // else{
     //   console.log('you cannot make a book a session without payment')
     //   navigate('/services')
     // }
 }, [])


 useEffect(() => {
  if(coach) {
    const service=  getServiceById(serviceId, coach.services)

    setService(service)

  console.log('services', service)
    
  }
 


}, [coach])


useEffect(() => {
  console.log('services', service)

  setIsLoading(false)

}, [service])



  return (
    <>
    
  
    <form id="payment-form" className = 'pay-form' onSubmit={handleSubmit}>
      <div className="otherDetails">
        <div>

          <div className="paycoachdetails">

        
           <img
             className="avatar-pay"
              alt=""
              src={coach?.img}
            />
          </div>
            <div className="paycoachdetails">

              <h4 className="paycoachname">{coach?.name}</h4>
             
            </div>
            <div className="paycoachdetails">


<h5 className="payservicename">{service?.name}</h5>
</div>
<div className="paycoachdetails">


<h5 className="payservicename">{service?.duration} min 1:1</h5>
</div>

            </div>
           

           
            

      </div>

      <PaymentElement id="payment-element" options={paymentElementOptions} />
      {/* <button className = "payment-submit" disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now $"{service.price}}
        </span>
      </button> */}

<button className="payment-submit" disabled={isLoading || !stripe || !elements} id="submit">
  <span id="button-text">
    {isLoading ? <div className="spinner" id="spinner"></div> : `Pay now $${service?.price}`}
  </span>
</button>

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}

    </form>

    </>
  );
}