import { useEffect, useState } from "react";
import "./NewMessages.css";
import axios from "axios";
const NewMessages = ({ onClose, selectedCoach, setSelectedCoach, coaches, StartConversation }) => {

  const [convos, setConvos] = useState(coaches)

  const handleCoachClick = (coach) => {
    setSelectedCoach((prevSelectedCoach) => {
      // Toggle selection: if the clicked coach is already selected, unselect it; otherwise, select it.
      return prevSelectedCoach === coach ? null : coach;
    });
  };

  const [searchTerm, setSearchTerm] = useState('');

  

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };


  useEffect(() => {



    if(searchTerm !== ''){
      console.log('search term', searchTerm)

      console.log('coaches nbn', coaches)
      const filteredConversations = convos.filter(coach =>
        coach.coachName?.toLowerCase().includes(searchTerm.toLowerCase())
      );


      
      
  
      setConvos(filteredConversations)
    }

  }, [searchTerm])


  return(
    <div className="newmessage">
      <div className="newmessageboxcloseicon-wrapper">
        <button className="newmessageboxcloseicon"  onClick={onClose}>
          <div className="newmessageboxcloseicon2" />
          <div className="newmessageboxcloseicon1">+</div>
        </button>
      </div>
      <div className="newmessageboxheadertext-wrapper">
        <div className="newmessageboxheadertext">New Message</div>
      </div>
      <div className="newmessagetoheaderbox">
        <div className="newmessageboxheadertext">To:</div>
      </div>
      <div className="newmessageboxsearchnext">
        <div className="newmessageboxsearchnext1">
          <input
            className="newmessageboxheaderinputbox1"
            placeholder="H"
            type="text"
            value={searchTerm}
            onChange={handleInputChange}

          />
          <div className="newmessageboxinputbutton" onClick= { () => StartConversation(selectedCoach.owner, selectedCoach.name)}>
            <div className="newmessageboxinputbuttontext">Next</div>
          </div>
        </div>
      </div>


      

      


      {
      convos.map((coach) => (
        
      <div 
        className="newmessagelistbox" 
        key={coach.id} // Make sure each element has a unique key
          //className={`newmessagelistbox ${selectedCoach === coach ? 'selected' : ''}`}
          onClick={() => handleCoachClick(coach)}
      > 
      
       <div className="newmessagelistbox1">
        <div className="newmessagelistbox2">
            <img
             className="newmessageboxcontactprofile-icon"
            alt=""
            src={coach.img}
          />
          <div className="newmessageboxcontactprofile1">
            <div className="newmessageboxcontactprofilenam">
             {coach.name}
            </div>
            <div className="newmessagecontactpnamesummary">
               Bio: {coach.bio.split(' ').slice(0, 7).join(' ')}
            </div>
          </div>

          {
            selectedCoach === coach ?   <svg   className="newmessageboxcheck-icon" xmlns="http://www.w3.org/2000/svg" width="131" height="131" viewBox="0 0 131 131" fill="none">
            <path d="M57.3125 87.6636L36.8438 67.1907L42.6282 61.4062L57.3125 76.0864L88.3636 45.0312L94.1562 50.8239L57.3125 87.6636Z" fill="#3DD598"/>
            <path d="M65.5 8.1875C54.1647 8.1875 43.0839 11.5488 33.6589 17.8464C24.2339 24.144 16.888 33.095 12.5502 43.5675C8.21233 54.0399 7.07735 65.5636 9.28877 76.6811C11.5002 87.7986 16.9587 98.0108 24.974 106.026C32.9893 114.041 43.2014 119.5 54.3189 121.711C65.4365 123.923 76.9601 122.788 87.4326 118.45C97.9051 114.112 106.856 106.766 113.154 97.3411C119.451 87.9161 122.813 76.8353 122.813 65.5C122.813 50.2998 116.774 35.7221 106.026 24.9739C95.2779 14.2258 80.7002 8.1875 65.5 8.1875ZM65.5 114.625C55.784 114.625 46.2862 111.744 38.2076 106.346C30.1291 100.948 23.8326 93.2757 20.1144 84.2993C16.3963 75.3229 15.4234 65.4455 17.3189 55.9162C19.2144 46.3869 23.8931 37.6336 30.7634 30.7634C37.6337 23.8931 46.3869 19.2144 55.9162 17.3189C65.4455 15.4234 75.3229 16.3963 84.2993 20.1144C93.2758 23.8326 100.948 30.129 106.346 38.2076C111.744 46.2862 114.625 55.784 114.625 65.5C114.625 78.5288 109.449 91.0239 100.237 100.237C91.0239 109.449 78.5288 114.625 65.5 114.625Z" fill="#3DD598"/>
          </svg> :
                    
        <svg xmlns="http://www.w3.org/2000/svg"   className="newmessageboxcheck-icon"  src="/newmessageboxcheck.svg" width="131" height="132" viewBox="0 0 131 132" fill="none">
          <path d="M33.9367 18.7621C43.2795 12.5195 54.2636 9.1875 65.5 9.1875C80.5676 9.1875 95.0181 15.1731 105.673 25.8275C116.327 36.4819 122.313 50.9324 122.313 66C122.313 77.2365 118.981 88.2206 112.738 97.5633C106.495 106.906 97.6224 114.188 87.2412 118.488C76.8601 122.788 65.437 123.913 54.4165 121.721C43.3959 119.529 33.2729 114.118 25.3275 106.173C17.3821 98.2271 11.9713 88.1041 9.77916 77.0836C7.58704 66.063 8.71212 54.6399 13.0121 44.2588C17.3121 33.8777 24.5939 25.0048 33.9367 18.7621ZM37.9299 107.262C46.0906 112.715 55.6851 115.625 65.5 115.625C78.6614 115.625 91.2837 110.397 100.59 101.09C109.897 91.7837 115.125 79.1614 115.125 66C115.125 56.1851 112.215 46.5906 106.762 38.4298C101.309 30.269 93.5585 23.9085 84.4907 20.1525C75.4229 16.3965 65.445 15.4137 55.8187 17.3285C46.1924 19.2433 37.35 23.9696 30.4099 30.9098C23.4697 37.85 18.7433 46.6923 16.8286 56.3186C14.9138 65.945 15.8965 75.9229 19.6525 84.9907C23.4085 94.0584 29.7691 101.809 37.9299 107.262Z" fill="white" stroke="#A6A3A3"/>
        </svg>

          }
        

        </div>
      </div>
      </div>

      ))}




    </div>
  );
};

export default NewMessages;
