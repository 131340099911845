import React, { useEffect } from 'react'
import { format } from "timeago.js";
const Message = ({message, own}) => {

  
  useEffect(() => {
    
    console.log('own tv show',own)
  }, [])


  return (
    <>
<div   className= {own ? "writemessagebodysendermessage2" : "writemessagebodyreceivermessag2"}>
              <div className={own ? "writemessagebodysendermessage11" : "writemessagebodyreceivermessag3"}>
                <div className="writebodysendermessagetext1">{message.text}</div>
              </div>
              <div className="messageBottom">{format(message.createdAt)}</div>
            </div> 
             
             </>
  )
}

export default Message