import "./CoachPage.css";
import React, { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_Server_URL, publicRequest } from "../requestMethods";
import { useState } from "react";
import { Avatar } from "@mui/material";

import Rating from "@mui/material/Rating";
import { Link } from "react-router-dom";

import { useCallback } from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
} from "@mui/material";
import WriteMessagesPopUp from "../components/WriteMessagesPopUp";
import PortalPopup from "../components/PortalPopup";
import BookCoach from "../components/BookCoach";
import PortalPopupNot from "../components/PortalPopupNot";
import SavedCoachNotification from "../components/SavedCoachNotification";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { ElevatorSharp } from "@mui/icons-material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import StripeCheckout from "react-stripe-checkout";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SKey = process.env.REACT_APP_STRIPE;

const CoachPage = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openPaidPopUp, setOpenPaidPopUp] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpenPaidPopUp = () => {
    setOpenPaidPopUp(true);
  };

  const handleClosePaidPopUp = () => {
    setOpenPaidPopUp(false);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split("/")[2];

  const user = useSelector((state) => state.user.currentUser);

  const [value, setValue] = React.useState({
    rating: 0,
    review: "",
  });

  const handleChange = (event, newValue) => {
    setValue({
      ...value,
      rating: newValue,
    });
  };

  const handleReviewChange = (event) => {
    setValue({
      ...value,
      review: event.target.value,
    });
  };

  const [isWriteMessagesPopUpOpen, setWriteMessagesPopUpOpen] = useState(false);

  const openWriteMessagesPopUp = useCallback(() => {
    setWriteMessagesPopUpOpen(true);
  }, []);

  const closeWriteMessagesPopUp = useCallback(() => {
    setWriteMessagesPopUpOpen(false);
  }, []);

  const [isBookCoachPopupOpen, setBookCoachPopupOpen] = useState(false);

  const openBookCoachPopup = useCallback(() => {
    setBookCoachPopupOpen(true);
  }, []);

  const closeBookCoachPopup = useCallback(() => {
    setBookCoachPopupOpen(false);
  }, []);

  //console.log(location.pathname.split(""))

  const [coach, setCoach] = useState({
    //rating: 0,
    education: [],
    services: [],
  });

  console.log(id);

  const getCoach = async () => {
    try {
      const res = await publicRequest.get("/coach/" + id);
      setCoach(res.data);
      console.log("mxd c,dx", res.data);
    } catch {}
  };

  useEffect(() => {
    const getCoach = async () => {
      try {
        const res = await publicRequest.get("/coach/" + id);
        setCoach(res.data);
        console.log("mxd c,dx", res.data);
      } catch {}
    };
    getCoach();
  }, [id]);

  const [serviceType, setServiceType] = useState("");
  const [selectedService, setSelectedService] = useState(null);
  const [hasAttemptedBooking, setHasAttemptedBooking] = useState(false);

  const handleServiceType = (event, services) => {
    setServiceType(event.target.value);

    if (event.target.value == "") {
      setSelectedService(null);
    } else {
      const serviceob = (serviceName) => {
        return services.find((service) => service.name === serviceName);
      };

      setSelectedService(serviceob(event.target.value));

      console.log("service type hdnsmhn4edkmw", serviceob(event.target.value));
      // console.log('service mhn4edkmw' , services)
    }
  };

  const onBooknowClick = () => {
    if (serviceType == "") {
      // If serviceType is empty, set hasAttemptedBooking to true
      setHasAttemptedBooking(true);
      console.log("Select service type");
    } else {
      console.log("Navigate to coach availability");
      // ... (rest of your logic for navigation)
      navigate("/coachavailability");
      openBookCoachPopup();
    }
  };

  const [coachBookmarks, setCoachBookmarks] = useState([]);

  const handleCoachBookmark = async (event, data) => {
    //console.log(data)
    event.preventDefault();
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      //console.log(currentUser)
      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.post("/coachbookmark", {
        img: data.img,
        coachId: data.id,
        name: data.name,
        services: data.services,
        rating: data.rating.$numberDecimal,
      });
      console.log(res);
      getCoachBookmarks();
      //openBookCoachPopup()

      setCoachNotDetail({
        img: data.img,
        coachId: data.id,
        name: data.name,
        services: data.services,
        rating: data.rating.$numberDecimal,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCoachBookmarks = async () => {
    try {
      console.log("get coach bookmarks");
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      //console.log(currentUser)
      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.get("/coachbookmark");
      console.log("coach bookmarks", res.data);
      setCoachBookmarks(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCoachBookmarks();
  }, []);

  const deleteCoachBookmark = async (event, id) => {
    console.log("delete", event);
    console.log(id);
    event.preventDefault();

    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      //console.log(currentUser)
      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.delete("/coachbookmark/" + id);
      console.log("delete", res);

      getCoachBookmarks();
    } catch (error) {
      console.log(error);
    }
  };

  function BookmarkCheckerCoach(Id, elem) {
    const isObjectPresent = coachBookmarks.find((o) => o.coachId === Id);
    if (isObjectPresent) {
      //console.log(isObjectPresent)
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="24"
          viewBox="0 0 27 24"
          fill="none"
          onClick={(e) => deleteCoachBookmark(e, elem.id)}
        >
          <path
            d="M13.5007 22.6358C13.7863 22.6358 14.203 22.4185 14.5462 22.2064C20.7999 18.174 24.8148 13.4485 24.8148 8.66272C24.8148 4.54232 21.97 1.68359 18.4005 1.68359C16.1779 1.68359 14.5079 2.91145 13.5007 4.75156C12.5119 2.92063 10.8214 1.68359 8.6009 1.68359C5.03135 1.68359 2.18652 4.54232 2.18652 8.66272C2.18652 13.4485 6.20142 18.174 12.4644 22.2064C12.7983 22.4185 13.2131 22.6358 13.5007 22.6358Z"
            fill="red"
            stroke="red"
            stroke-width="2.5"
            stroke-linejoin="round"
          />
        </svg>
      );
    } else {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="24"
          viewBox="0 0 27 24"
          fill="none"
          onClick={(e) => handleCoachBookmark(e, elem)}
        >
          <rect
            width="22.6283"
            height="21.6355"
            transform="translate(2.18652 1)"
            fill="white"
          />
          <path
            d="M13.5007 22.6358C13.7863 22.6358 14.203 22.4185 14.5462 22.2064C20.7999 18.174 24.8148 13.4485 24.8148 8.66272C24.8148 4.54232 21.97 1.68359 18.4005 1.68359C16.1779 1.68359 14.5079 2.91145 13.5007 4.75156C12.5119 2.92063 10.8214 1.68359 8.6009 1.68359C5.03135 1.68359 2.18652 4.54232 2.18652 8.66272C2.18652 13.4485 6.20142 18.174 12.4644 22.2064C12.7983 22.4185 13.2131 22.6358 13.5007 22.6358Z"
            fill="white"
            stroke="#010101"
            stroke-width="2.5"
            stroke-linejoin="round"
          />
        </svg>
      );
    }
  }

  const [
    isSavedCoachNotificationPopupOpen,
    setSavedCoachNotificationPopupOpen,
  ] = useState(false);

  const openSavedCoachNotificationPopup = useCallback(() => {
    setSavedCoachNotificationPopupOpen(true);
  }, []);

  const closeSavedCoachNotificationPopup = useCallback(() => {
    setSavedCoachNotificationPopupOpen(false);
  }, []);

  const [coachNotDetail, setCoachNotDetail] = useState("");

  useEffect(() => {
    // console.log('coach details',coachNotDetail)
    if (coachNotDetail) {
      openSavedCoachNotificationPopup();
      setTimeout(() => {
        closeSavedCoachNotificationPopup();
      }, 5000); // 5000 milliseconds (5 seconds)
    }
  }, [coachNotDetail]);

  async function SubmitReview(e) {
    e.preventDefault();

    const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
    const currentUser = user && JSON.parse(user).currentUser;

    if (!currentUser) {
      console.log("Sign up to submit a review");
      return;
    }

    if (!profile.name) {
      console.log("Profile is not complete");
      return;
    }

    if (!value.rating || !value.review) {
      console.log("Fill in everything to submit a review");
      return;
    }
    const totalRatings = review.length;

    const data = {
      coachId: coach.id,
      name: profile.name,
       img: profile.profilePic,
      calcRating: updateRating(
        coach.rating.$numberDecimal,
        value.rating,
        totalRatings
      ),
      rating: value.rating,
      review: value.review,
      reviewer: profile.id,
      owner: coach.owner,
    };

    try {
      const TOKEN = currentUser.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL, // Use an environment variable
        headers: { Authorization: `Bearer ${TOKEN}` },
      });

      const res = await client.post("/review", data);
      console.log(res);

      handleClose();

      getReviews();

      getCoach();

      // Handle success or show a success message to the user
    } catch (error) {
      // Handle specific error types and show appropriate messages
      console.log(error);
    }
  }

  const [profile, setProfile] = useState({});

  const [review, setReview] = useState([]);

  const viewUserProfile = () => {
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });

      const res = client.get("/profile");
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    viewUserProfile()
      .then((result) => {
        setProfile(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    console.log(profile);
  }, [profile]);

  const getReviews = async () => {
    try {
      console.log("get reviews");
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      //console.log(currentUser)
      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.get("/review/" + coach.id);
      console.log("coach", coach);
      console.log("reviews", res.data);
      setReview(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (coach.id) {
      getReviews();
    }
  }, [coach]);

  function formatDate(dateString) {
    console.log("date", dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  function capitalizeEachWord(inputString) {
    // Split the string into an array of words
    const words = inputString.split(" ");

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the words back into a string
    const resultString = capitalizedWords.join(" ");

    return resultString;
  }

  function CheckRatingCount(reviews, targetRating) {
    const filteredReviews = reviews.filter((review) => {
      const rating = parseFloat(review.rating.$numberDecimal);

      return rating === targetRating;
    });

    return filteredReviews.length;
  }

  function updateRating(existingRating, newRating, totalRatings) {
    // Parse existing and new ratings as numbers
    console.log("existingRating", existingRating);
    console.log("newRating", newRating);
    console.log("totalRatings", totalRatings);

    const existing = parseFloat(existingRating);
    const updated = parseFloat(newRating);

    // Calculate the new average rating
    const newAverage = (existing * totalRatings + updated) / (totalRatings + 1);

    // Return the new average rating
    return newAverage;
  }

  // Example usage

  function sortReviewsByRatingDescending(reviews) {
    return reviews.sort((a, b) => {
      const ratingA = parseFloat(a.rating["$numberDecimal"]);
      const ratingB = parseFloat(b.rating["$numberDecimal"]);

      return ratingB - ratingA;
    });
  }

  const [conversations, setConversations] = useState([]);

  const getConversations = async () => {
    try {
      // ... (rest of your code)
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.get("/conversation");
      console.log("concos", res);
      setConversations(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (user) {
      console.log(user.id);

      getConversations();
    }

    //console.log('users ', user.id)
  }, [user]);

  useEffect(() => {
    console.log("service type", serviceType);
  }, [serviceType]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.Calendy_src;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const openCalendlyPopup = () => {
    if (selectedService) {
      if (window.Calendly) {
        window.Calendly.initPopupWidget({ url: selectedService.bookingLink });
      }
    } else {
      console.log("pls select a service to view its availability");
    }
  };

  useEffect(() => {
    const handleCalendlyEvent = (event) => {
      if (event.data.event === "calendly.date_and_time_selected") {
        console.log(window.Calendly);

        window.Calendly.closePopupWidget();
        handleClickOpenPaidPopUp();
        // Perform additional actions based on the event payload
      }
    };

    // Add an event listener to the window object
    window.addEventListener("message", handleCalendlyEvent);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("message", handleCalendlyEvent);
    };
  }, []); // Empty dependency array ensures that the effect runs only once (on mount)

  const redirectToAnotherPage = () => {
    // Replace '/target-page' with the path of the page you want to redirect to
    // window.location.href = '/';
    alert("You have to make payme nt before yo can book a meeting");
  };

  return (
    <>
      <div className="view-coach-profile">
        <div className="viewcoachdetailsheader">
          <button
            className="viewcoachbachheader"
            onClick={() => navigate("/dashboard")}
          >{`< Dashboard     `}</button>
        </div>
        <div className="coachdetailsprofile">
          <div className="coach-details-box">
            <div className="coachdetailsprofile-img1">
              <b className="coach-details1">Coach Details</b>
              <Avatar
                alt="Remy Sharp"
                src={coach.img}
                sx={{ width: 254, height: 243 }}
              />
            </div>
            <div className="detailsright">
              <div className="detailsname">
                <div className="coach-details2">
                  <b className="hailey-johnson">{coach.name}</b>
                  <div className="scholarship-essay-expertise">
                    {coach.expertise} Expertise
                  </div>
                  <div className="coach-review-stars">
                    {/* <img className="stars-icon3" alt="" src="/stars3.svg" /> */}
                    <div>
                      <Rating
                        name="read-only"
                        value={coach.rating ? coach.rating.$numberDecimal : 0}
                        size="small"
                        className="stars-icon3"
                        readOnly
                      />
                    </div>
                    <div className="members1">{review.length}</div>
                  </div>
                </div>
                <div className="envelopebadge-parent">
                  <button
                    className="envelopebadge"
                    onClick={openWriteMessagesPopUp}
                  >
                    {/* <img className="group-icon" alt="" src="/group.svg" /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="37"
                      height="25"
                      viewBox="0 0 37 25"
                      fill="none"
                    >
                      <path
                        d="M5.07504 22.9581H29.1121C31.97 22.9581 33.6304 21.6312 33.6304 19.0893V9.51649C32.7753 9.80597 31.854 9.9282 30.8907 9.89294V19.024C30.8907 20.1781 30.1106 20.7494 28.7436 20.7494H5.06878C3.67993 20.7494 2.92438 20.1781 2.92438 19.0142V5.99955C2.92438 4.84757 3.67993 4.27408 5.06878 4.27408H23.8189C23.741 3.51713 23.9035 2.74113 24.2604 2.06543H4.70025C1.84504 2.06543 0.18457 3.38253 0.18457 5.9245V19.0893C0.18457 21.641 1.85726 22.9581 5.07504 22.9581ZM16.9306 15.752C18.0332 15.752 19.0873 15.3828 20.1577 14.5416L27.1752 9.02496C26.4594 8.67449 25.8299 8.19654 25.3302 7.65808L18.638 12.9365C18.0156 13.4315 17.4798 13.6472 16.9306 13.6472C16.3692 13.6472 15.8482 13.4315 15.2234 12.9365L3.44974 3.64318L1.68045 5.0944L13.701 14.5416C14.774 15.3851 15.8158 15.752 16.9306 15.752ZM30.3582 21.3225L32.1555 19.8893L21.7728 11.673L19.9969 13.1113L30.3582 21.3225ZM3.67009 21.1814L13.5993 13.3134L11.81 11.8879L1.87962 19.7526L3.67009 21.1814Z"
                        fill="black"
                      />
                      <path
                        d="M31.0351 8.23326C33.8542 8.23326 36.1867 6.36937 36.1867 4.11663C36.1867 1.8639 33.8542 0 31.0351 0C28.2158 0 25.8955 1.8639 25.8955 4.11663C25.8955 6.36937 28.2158 8.23326 31.0351 8.23326Z"
                        fill="black"
                      />
                    </svg>
                  </button>

                  {BookmarkCheckerCoach(coach.id, coach)}
                </div>
              </div>

              <select
                style={{
                  width: "324px",
                  height: "56px",
                  border:
                    hasAttemptedBooking && serviceType === ""
                      ? "2px solid red"
                      : "1px solid #ced4da",
                }}
                value={serviceType}
                onChange={(e) => handleServiceType(e, coach.services)}
              >
                <option value="">Select Services Type</option>
                {coach.services.map((service) => (
                  <option key={service.name} value={service.name}>
                    {service.name} - ${service.price}
                  </option>
                ))}
              </select>

              {/* <span className="coach-page-error">Select Service Type</span> */}

              <div style={{ display: "flex", gap: "10px" }}>
                <button className="booknow" onClick={onBooknowClick}>
                  <div className="buttonlarge1">
                    <div className="button2" />
                    <b className="button-text2" style={{ color: "white" }}>
                      Book Now
                    </b>
                  </div>
                </button>
                <div>
                  {/* Add a button or text link to open the Calendly popup */}
                  <button
                    style={{
                      borderRadius: "6px",
                      border: "none",
                      padding: "2px 6px",
                      border: "1px solid black",
                    }}
                    onClick={openCalendlyPopup}
                  >
                    Preview Availability
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="icround-plus" />
        </div>
        <b className="about-the-coach">About the coach</b>
        <div className="detailsbogbox">
          <div className="biobox">
            <div className="bio">Bio</div>
            <div className="lorem-ipsum-dolor">{coach.bio}</div>
          </div>
          <div className="educationbox">
            <div className="bio">Education</div>
            <div>
              {coach.education.map((edu, index) => (
                <>
                  <div className="university-of-prestige">
                    {edu.abbreviation} - {edu.course}
                  </div>

                  <div key={edu._id} className="university-of-prestige">
                    {edu.universityName}
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="educationbox">
            <div className="bio">Policies</div>
            <div className="policiesbox1">
              {coach.policies?.map((pol, index) => (
                <div key={index} className="reviewcoachimgheader">
                  {/* Uncomment the next line if you want to include an image */}
                  {/* <img className="bgcheckicon" alt="" src="/bgcheckicon.svg" /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none"
                  >
                    <path
                      d="M10.202 0.128058L17.202 2.76974C17.5833 2.91358 17.9119 3.17113 18.1438 3.50796C18.3758 3.84478 18.5001 4.24484 18.5 4.65464V10.0517C18.5 11.7338 18.0346 13.3826 17.1559 14.8135C16.2772 16.2443 15.02 17.4006 13.525 18.1529L10.171 19.8405C9.96267 19.9454 9.73294 20 9.5 20C9.26706 20 9.03733 19.9454 8.829 19.8405L5.475 18.1529C3.98004 17.4006 2.72277 16.2443 1.84407 14.8135C0.965371 13.3826 0.499964 11.7338 0.5 10.0517V4.65464C0.499949 4.24484 0.624204 3.84478 0.856153 3.50796C1.0881 3.17113 1.41669 2.91358 1.798 2.76974L8.798 0.128058C9.25062 -0.0426859 9.74938 -0.0426859 10.202 0.128058ZM9.5 2.01296L2.5 4.65464V10.0517C2.50023 11.3599 2.86239 12.6421 3.54592 13.7548C4.22944 14.8674 5.20732 15.7666 6.37 16.3515L9.5 17.9285L12.63 16.3535C13.793 15.7685 14.7711 14.869 15.4546 13.7559C16.1382 12.6428 16.5001 11.3602 16.5 10.0517V4.65464L9.5 2.01296ZM9.5 12.0081C9.76522 12.0081 10.0196 12.1141 10.2071 12.3028C10.3946 12.4916 10.5 12.7475 10.5 13.0144C10.5 13.2813 10.3946 13.5373 10.2071 13.726C10.0196 13.9148 9.76522 14.0208 9.5 14.0208C9.23478 14.0208 8.98043 13.9148 8.79289 13.726C8.60536 13.5373 8.5 13.2813 8.5 13.0144C8.5 12.7475 8.60536 12.4916 8.79289 12.3028C8.98043 12.1141 9.23478 12.0081 9.5 12.0081ZM9.5 4.96359C9.76522 4.96359 10.0196 5.06962 10.2071 5.25835C10.3946 5.44708 10.5 5.70305 10.5 5.96995V9.99537C10.5 10.2623 10.3946 10.5182 10.2071 10.707C10.0196 10.8957 9.76522 11.0017 9.5 11.0017C9.23478 11.0017 8.98043 10.8957 8.79289 10.707C8.60536 10.5182 8.5 10.2623 8.5 9.99537V5.96995C8.5 5.70305 8.60536 5.44708 8.79289 5.25835C8.98043 5.06962 9.23478 4.96359 9.5 4.96359Z"
                      fill="#010101"
                    />
                  </svg>
                  <div className="background-check-last">{pol}</div>
                </div>
              ))}
            </div>
          </div>

          <div className="educationbox">
            <div className="bio">Languages</div>
            <div className="languagebox1">
              {coach.languages?.map((language, index) => (
                <div key={index}>
                  <div className="background-check-last">
                    {language}
                    {index < coach.languages.length - 1 && ","}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="servicetypecoachbox">
            <div className="servicetypetitle">
              <div className="servicetypetitle1">
                <div className="service-type">Service Type</div>
              </div>
            </div>
            <div className="rightservicetype">
              {coach.services.map((service) => (
                <div className="rightservervicetype1">
                  <div className="innerrightservicetype2">
                    <div className="innerrightservicetitle">
                      <div className="essay-review-wrapper">
                        <b className="essay-review">{service.name}</b>
                      </div>
                    </div>
                    <b className="rightservicetypeprice">${service.price}</b>
                  </div>
                  {/* <div className="hr-mock-interview">
                 {service.details.slice(0, 120)}
                </div> */}
                  <div className="hr-mock-interview">
                    {service.details.split(" ").slice(0, 25).join(" ")}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="page-divider" />
        <div className="reviews">
          <div className="coach-rating">
            <div className="reviewcoachuser-prompt-parent">
              <div className="reviewcoachuser-prompt1">
                <div className="write-review-text1">
                  <div className="write-a-review1">Write a review</div>
                  <div className="have-you-worked1">
                    Have you worked with{" "}
                    {coach && coach.name
                      ? coach.name.split(" ")[0]
                      : "loading.."}
                  </div>
                  <div className="let-us-know1">Let us know your thoughts</div>
                </div>

                <div className="buttonlarge2" onClick={handleOpen}>
                  <div className="button3" />
                  <div className="button-text3">Write Review</div>
                </div>
              </div>

              <div className="reviewcoachstar-graphicsmal1">
                <div className="reviewcoachstars1">
                  {/* <img className="stars-icon4" alt="" src="/stars4.svg" /> */}
                  <Rating
                    name="read-only"
                    value={coach.rating ? coach.rating.$numberDecimal : 0}
                    size="small"
                    readOnly
                  />
                  <div className="ratings2">{review.length} ratings</div>
                </div>
                <div className="rating-bars1">
                  <div className="star-parent3">
                    <div className="star5">5 star</div>
                    <div className="rating-bar5">
                      <div className="rating-bar-inner1">
                        <div
                          className="frame-child1"
                          style={{
                            width:
                              (((CheckRatingCount(review, 5) / review.length) *
                                100) /
                                100) *
                              148,
                          }}
                        />
                      </div>
                    </div>
                    <div className="div6">
                      {Math.round(
                        (CheckRatingCount(review, 5) / review.length) * 100
                      )}
                      %
                    </div>
                  </div>
                  <div className="star-parent3">
                    <div className="star6">4 star</div>
                    <div className="rating-bar5">
                      <div className="rating-bar-inner1">
                        <div
                          className="frame-child1"
                          style={{
                            width:
                              (((CheckRatingCount(review, 4) / review.length) *
                                100) /
                                100) *
                              148,
                          }}
                        />
                      </div>
                    </div>
                    <div className="div6">
                      {Math.round(
                        (CheckRatingCount(review, 4) / review.length) * 100
                      )}
                      %
                    </div>
                  </div>

                  <div className="star-parent3">
                    <div className="star6">3 star</div>
                    <div className="rating-bar5">
                      <div className="rating-bar-inner1">
                        <div
                          className="frame-child1"
                          style={{
                            width:
                              (((CheckRatingCount(review, 3) / review.length) *
                                100) /
                                100) *
                              148,
                          }}
                        />
                      </div>
                    </div>
                    <div className="div6">
                      {Math.round(
                        (CheckRatingCount(review, 3) / review.length) * 100
                      )}
                      %
                    </div>
                  </div>

                  <div className="star-parent3">
                    <div className="star6">2 star</div>
                    <div className="rating-bar5">
                      <div className="rating-bar-inner1">
                        <div
                          className="frame-child1"
                          style={{
                            width:
                              (((CheckRatingCount(review, 2) / review.length) *
                                100) /
                                100) *
                              148,
                          }}
                        />
                      </div>
                    </div>
                    <div className="div6">
                      {Math.round(
                        (CheckRatingCount(review, 2) / review.length) * 100
                      )}
                    </div>
                  </div>

                  <div className="star-parent3">
                    <div className="star6">1 star</div>
                    <div className="rating-bar5">
                      <div className="rating-bar-inner1">
                        <div
                          className="frame-child1"
                          style={{
                            width:
                              (((CheckRatingCount(review, 1) / review.length) *
                                100) /
                                100) *
                              148,
                          }}
                        />
                      </div>
                    </div>
                    <div className="div6">
                      {Math.round(
                        (CheckRatingCount(review, 1) / review.length) * 100
                      )}
                      %
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="top-reviews">
            <div className="coachtopreview-wrapper">
              <div className="coachtopreview">
                <div className="top-review1">Top Reviews</div>
                <img
                  className="vector-review-icon"
                  alt=""
                  src="/vector-review.svg"
                />
              </div>
            </div>
            <div className="top-reviews-header-wrapper">
              <div className="top-reviews-header">Top Reviews</div>
            </div>

            {sortReviewsByRatingDescending(review)
              .slice(0, 2)
              .map((data) => (
                <div key={data.id} className="reviewcoachreviewer-full">
                  <div className="reviewcoachimgheader">
                    <img
                      className="reviewcoachreviewer-img-icon"
                      alt=""
                      src={`${BASE_Server_URL}files/${data.img}`}
                    />
                    <div className="reviewcoachreviewer-full">
                      <div className="viewcoachbachheader">
                        {capitalizeEachWord(data.name)}
                      </div>

                      <Rating
                        name="read-only"
                        value={data.rating.$numberDecimal}
                        size="small"
                        readOnly
                      />
                      {/* <img
            className="reviewcoachstarssmall-icon"
            alt=""
            src="/reviewcoachstarssmall.svg"
          /> */}
                      <div className="reviewed-on-august">
                        Reviewed on {formatDate(data.reviewedOn)}
                      </div>
                    </div>
                  </div>
                  <div className="reviewcoachimg">
                    <div className="lorem-ipsum-dolor1">{data.review}</div>
                  </div>
                </div>
              ))}
          </div>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="writereviewbox">
                <h4>Rate Coach</h4>
                <Rating
                  name="simple-controlled"
                  value={value.rating}
                  onChange={handleChange}
                />

                <h4>Write a Review</h4>
                {/* <textarea onChange={handleReviewChange} value={value.review} /> */}
                <div>
                  <textarea
                    onChange={handleReviewChange}
                    value={value.review}
                  />
                </div>

                <button
                  className="writereviewbutton"
                  onClick={(e) => SubmitReview(e)}
                >
                  Submit
                </button>
              </div>
            </Box>
          </Modal>
        </div>
      </div>

      <Dialog
        fullScreen={fullScreen}
        open={openPaidPopUp}
        // onClose={handleClosePaidPopUp}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Book now to reserve this space"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To continue booking your coaching session, please go back and
            purchase sessions first. You’ll be able to finish scheduling after
            payment.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePaidPopUp} autoFocus>
            Continue Booking
          </Button>
        </DialogActions>
      </Dialog>
      {isWriteMessagesPopUpOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeWriteMessagesPopUp}
        >
          <WriteMessagesPopUp
            onClose={closeWriteMessagesPopUp}
            coach={coach}
            user={user}
            conversations={conversations}
          />
        </PortalPopup>
      )}

      {isBookCoachPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeBookCoachPopup}
        >
          <BookCoach
            onClose={closeBookCoachPopup}
            coach={coach}
            review={review}
            selectedService={selectedService}
          />
        </PortalPopup>
      )}

      {isSavedCoachNotificationPopupOpen && (
        <PortalPopupNot
          overlayColor="rgba(255, 255, 255, 0)"
          placement="Top right"
          onOutsideClick={closeSavedCoachNotificationPopup}
        >
          <SavedCoachNotification
            onClose={closeSavedCoachNotificationPopup}
            coach={coachNotDetail}
          />
        </PortalPopupNot>
      )}
    </>
  );
};

export default CoachPage;