import React, { useEffect, useState } from "react";


import "./Pay.css";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { useParams } from "react-router-dom";

import axios from "axios";
import CheckoutForm from "../components/CheckoutForm";
import { BASE_Server_URL } from "../requestMethods";

const stripePromise = loadStripe(
   process.env.REACT_APP_STRIPE 
);

const Pay = () => {
  const [clientSecret, setClientSecret] = useState("");

  const { coachId, serviceId} = useParams();

  useEffect(() => {
    console.log('stripe promise',  process.env.REACT_APP_STRIPE )
  }, [])

  useEffect(() => {


    console.log('coachid', coachId)
    console.log('serviceId', serviceId)
    const makeRequest = async () => {
      try {
        // const res = await newRequest.post(
        //   `/orders/create-payment-intent/${id}`
        // );

        const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
        const currentUser = user && JSON.parse(user).currentUser;
        const TOKEN = currentUser?.tokens.access.token;
    
    //console.log(currentUser)
         const client = axios.create({
          baseURL: BASE_Server_URL,
          headers: { Authorization: `Bearer ${TOKEN}` },
      });


      const res =  await client.post( `/booking/order/${coachId}/${serviceId}`)





        setClientSecret(res.data.clientSecret);



      } catch (err) {
        console.log(err);
      }
    };
    makeRequest();
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return <div className="pay">
    
    {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm coachId={coachId} serviceId={serviceId}  />
        </Elements>
      )}
  </div>;
};

export default Pay;