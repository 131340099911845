import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./STUDENTACTIVITIESPROFILE.css";
const STUDENTACTIVITIESPROFILE = (props) => {
  const navigate = useNavigate();

  const onFrameButton4Click = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  // return (
  //   <div className="student-activities-profile">
  //     {/* <div className="navbar12">
  //       <div className="info12">
  //         <div className="left-side-top-nav13">
  //           <img className="headerlogo-icon13" alt="" src="/headerlogo2.svg" />
  //           <div className="top-nav-left13">
  //             <div className="headernav-link65">
  //               <div className="nav-link-default52">Home</div>
  //             </div>
  //             <div className="headernav-link66">
  //               <div className="nav-link-default52">Scholarships</div>
  //             </div>
  //             <div className="headernav-link67">
  //               <div className="nav-link-default52">Services</div>
  //             </div>
  //             <div className="headernav-link67">
  //               <div className="nav-link-default52">{`Community `}</div>
  //             </div>
  //             <div className="headernav-link67">
  //               <div className="nav-link-default52">Resources</div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="right-side-top-nav13">
  //           <button className="menu13">
  //             <img className="vector-icon92" alt="" />
  //             <img className="vector-icon93" alt="" src="/vector31.svg" />
  //           </button>
  //           <div className="messages-parent10">
  //             <img className="messages-icon12" alt="" src="/messages9.svg" />
  //             <img
  //               className="notifications-icon12"
  //               alt=""
  //               src="/notifications9.svg"
  //             />
  //             <div className="mask-group-parent10">
  //               <img
  //                 className="mask-group-icon12"
  //                 alt=""
  //                 src="/mask-group4@2x.png"
  //               />
  //               <img className="vector-icon94" alt="" src="/vector20.svg" />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <img
  //         className="scholarships-line-icon12"
  //         alt=""
  //         src="/scholarships-line.svg"
  //       />
  //       <img className="services-line-icon12" alt="" src="/services-line.svg" />
  //       <img
  //         className="community-line-icon12"
  //         alt=""
  //         src="/community-line.svg"
  //       />
  //       <img className="resources-icon12" alt="" src="/resources.svg" />
  //     </div> */}
  //     <div className="body13">
       

  //       <div className="scholarship-questions-21">
  //         <div className="quiz-question10">
  //           <div className="name-parent8">
  //             <div className="name11">Student Organizations</div>
  //             <select className="multiselectdefault4"  name= "studentOrganization" required="required" onChange={props.onChange}>
  //               <option value="">Select</option>
  //               <option value="TSU Organization">TSU Organization</option>
  //               <option value="Alington Students">Alington Students</option>
  //               <option value="Alabama Students">Alabama Students</option>
  //               <option value="Florida Students">Florida Students</option>
  //             </select>
  //           </div>

  //         </div>
  //         <span className="errormessage">{props.formErrors.studentOrganization}</span>
             
  //         <div className="quiz-question10">
  //           <div className="name-parent9">
  //             <div className="name11">Honor Societies</div>
  //             <select className="multiselectdefault4"  name= "honorSocieties" required="required" onChange={props.onChange}>
  //             <option value="">Select</option>
  //               <option value="honor 1">Honor 1</option>
  //               <option value="honor 2">Honor 2</option>
                
  //             </select>
  //           </div>
  //         </div>
  //         <span className="errormessage">{props.formErrors.honorSocieties}</span>
             
  //         <div className="quiz-question12">
  //           <div className="name-parent10">
  //             <div className="name11">Fraternity</div>
  //             <textarea 
  //               className="multiselectdefault6"
  //               name= "fraternity" 
  //               required="required" 
  //               onChange={props.onChange} 
                
  //               />
  //           </div>
  //         </div>
  //         <span className="errormessage">{props.formErrors.fraternity}</span>
             
  //         <div className="quiz-question13">
  //           <div className="name-parent11">
  //             <div className="name11">Sorority</div>
  //             <textarea 
  //                className="multiselectdefault6" 
  //                name= "sorority" 
  //                required="required" 
  //                onChange={props.onChange}
  //               />
  //           </div>
  //         </div>
  //         <span className="errormessage">{props.formErrors.sorority}</span>
             
  //         <div className="quiz-question14">
  //           <div className="name-parent12">
  //             <div className="name11">Sports</div>
  //             <select 
  //                className="multiselectdefault4"
  //                name= "sports" 
  //                required="required" onChange={props.onChange}>
  //             <option value="">Select</option>
  //               <option value="football">Football</option>
  //               <option value="basketball">BasketBall</option>
  //               <option value="tennis">Tennis</option>
  //               <option value="kayaking">Kayaking</option>
  //             </select>
  //           </div>
  //         </div>
  //         <span className="errormessage">{props.formErrors.sports}</span>
             
  //         <div className="quiz-question15">
  //           <div className="name-parent13">
  //             <div className="name11">Perfoming Arts</div>
  //             <select className="multiselectdefault4"  name= "perfomingArts" required="required" onChange={props.onChange}>
  //             <option value="">Select</option>
  //               <option value="dance">Dance</option>
  //               <option value="music">Music</option>
  //               <option value="magic">Magic</option>
  //               <option value="comedy">Comedy</option>
  //             </select>
  //           </div>
  //         </div>
  //         <span className="errormessage">{props.formErrors.perfomingArts}</span>
             
  //       </div>
  //       {/* <div className="form-body1">
  //         <div className="exit-button">
  //           <div className="frame-parent19">
  //             <button className="exit-container">
  //               <div className="exit1">Exit</div>
  //             </button>
  //             <button className="vector-frame" onClick={onFrameButton4Click}>
  //               <img className="vector-icon95" alt="" src="/vector33.svg" />
  //             </button>
  //           </div>
  //         </div>
  //       </div> */}
  //     </div>
      
  //   </div>
  // );

  const studentorganizations = [
    "4-H Club",
    "AACN",
    "Academy of Medical-Surgical Nurses",
    "ACFEF registered apprentice",
    "AFL-CIO",
    "AGU",
    "Air and Waste Management Association",
    "Aircraft Electronics Association",
    "Alabama Farmers Federation",
    "Alpha Beta Gamma",
    "Alpha Chi Omega",
    "Alpha Chi Rho",
    "Alpha Delta Kappa",
    "Alpha Epsilon Delta",
    "Alpha Epsilon Phi",
    "Alpha Eta Rho",
    "Alpha Gamma Delta",
    "Alpha Gamma Rho",
    "Alpha Gamma Sigma",
    "Alpha Kappa Alpha",
    "Alpha Lambda Delta Member",
    "Alpha Mu Gamma",
    "Alpha Omega Epsilon",
    "Alpha Phi Omega",
    "Alpha Psi Omega",
    "Alpha Sigma Lambda",
    "Alpha Sigma Tau",
    "Alpha Zeta",
    "Amalgamated Transit Union (ATC)",
    "Amarillo Young American Bowling Association",
    "American Academy of Family Physicians",
    "American Art Therapy Association (AATA)",
    "American Association for Geodetic Surveying (AAGS)",
    "American Association of Blacks in Energy",
    "American Association of Bovine Practitioners",
    "American Association of Critical Care Nurses(AACN)",
    "American Association of Family & Consumer Sciences",
    "American Association of Professional Landmen (AAPL)",
    "American Association of Law Libraries",
    "American Business Women’s Association (ABWA)",
    "American Chemical Society (ACS)",
    "American College of Health Care Executives (ACHE)",
    "American College of Medical Practice Executives (ACMPE)",
    "American College of Nurse-Midwives (ACNM)",
    "American College Theater Festival (ACTF)",
    "American Congress on Surveying and Mapping (ACSM)",
    "American Contract Bridge League",
    "American Counseling Assocation",
    "American Culinary Federation",
    "American Dental Assistants Association (ADAA)",
    "American Dental Hygienists Association",
    "American Dietetic Association",
    "American Federation of Teachers",
    "American Federation of Television and Radio Artists (AFTRA)",
    "American Goat Society (AGS)",
    "American Guild of Musical Artists (AGMA)",
    "American Guild of Organists (AGO)",
    "American Health Information Management Assn. AHIMA",
    "American Heart Association",
    "American Holistic Nurses’ Association",
    "American Indian Science & Engineering Society",
    "American Institute of Aeronautics & Astronautics",
    "American Institute of Architecture Students",
    "American Institute of Certified Public Accountants",
    "American Institute of Chemical Engineers (AIChE)",
    "American Institute of Constructors (AIC)",
    "American Institute of Professional Geologists",
    "American Legion",
    "American Legion Auxiliary",
    "American Legion Junior Auxiliary",
    "American Legion Oratorical Contest",
    "American Library Association (ALA)",
    "American Marketing Association",
    "American Maine-Anjou Association (AMAA)",
    "American Medical Tecnhologists",
    "American Medical Women’s Association (AMWA)",
    "American Meterological society",
    "American Nephrology Nurses Association",
    "American Nephrology Nurses’ Association (ANNA)",
    "American Nuclear Society",
    "American Nurses Association (ANA)",
    "American Optometric Association (AOA)",
    "American Orff-Schulwerk Association",
    "American Paint Horse Association (APHA)",
    "American Pharmacists Association",
    "American Physics Society",
    "American Physical Therapy Association",
    "American Planning Association (APA)",
    "American Postal Workers Union (APWU)",
    "American Psychological Association",
    "American Production and Inventory Control Society",
    "American Quarter Horse Foundation",
    "American Radio Relay League (ARRL)",
    "American School Food Service Association (ASFSA)",
    "American Society for Microbiology",
    "American Society for Photogrammetry and Remote Sensing",
    "American Society for Testing and Materials",
    "American Society of Agricultural and Biological Engineers",
    "American Society of Agricultural Engineers (ASAE)",
    "American Society of Arms Collectors",
    "American Society of Civil Engineers (ASCE)",
    "American Society of Mechanical Engineers (ASME)",
    "American Society of Metals (ASM)",
    "American Society of PeriAnesthesia Nurses (ASPAN)",
    "American Society of Plumbing Engineers (ASPE)",
    "American Society of Safety Engineers",
    "American Society of Safety Professionals",
    "American Society of Travel Agents",
    "American Society of Women Accountants (ASWA)",
    "American Society-Photogrammetry & Remote Sensing",
    "American Veterinary Medical Association",
    "American Water Resources Association (AWRA)",
    "American Welding Society",
    "AmeriCorps",
    "AMVETS Auxiliary",
    "Angel Flight",
    "Appaloosa Horse Club",
    "Appaloosa Youth Association",
    "Archaeological Institute of America",
    "Archaeological Institute of America",
    "Arnold Air Society",
    "ASHA",
    "Asian Pacific American Heritage Association",
    "Asian Pacific Islanders (APIA)",
    "Asian Pacific fund",
    "Aspen Institute Intern",
    "Assn. for Educational Communications & Technology",
    "Associated General Contractors",
    "Association for Nursing Professional Development (ANPD)",
    "Association for Women in Communications (AWC)",
    "Association of American Geographers (AAG)",
    "Association of International Students",
    "Association of Operating Room Nurses (AORN)",
    "Association of periOperative Registered Nurses (AORN)",
    "Association of the Sons of Poland",
    "Association of Women’s Health, Obstetric and Neonatal Nurses (AWHONN)",
    "Baptist Health Foundation’s Volunteer Program",
    "Baptist Life Association",
    "Beta Alpha Psi",
    "Beta Gamma Sigma",
    "Beta Sigma Phi",
    "Beta Sigma Psi",
    "Beta Theta Pi",
    "Big Brothers/Big Sisters of America",
    "Black Girls Code",
    "Black Girls in Cyber",
    "Boy Scouts of America",
    "Boys and Girls Clubs of America",
    "Boys’ State",
    "Broadcast Cable Financial Management Association",
    "Broadcast Education Association (BEA)",
    "Bulwark Pest Control Services",
    "Business Owner",
    "Cadet Patrol Academy",
    "California Conservation Corps",
    "California Correctional Peace Officers Association (CCPOA)",
    "California School Library Association",
    "California Society of Professional Engineers",
    "California Teachers Association (CTA)",
    "Camden Clark Medical Center",
    "Camp Fire Boys and Girls",
    "Catholic Aid Association",
    "Catholic Kolping Society of America",
    "Catholic Library Association",
    "Children of the Confederacy",
    "Civil Air Patrol (CAP)",
    "Colorstack Member",
    "Computer Club",
    "Costume Society of America",
    "Council of the Great City Schools",
    "Credit Union member",
    "Dakota Alliance Soccer Club",
    "Danish Sisterhood of America",
    "Defense Eligibility Enrollment Reporting System (DEERS)",
    "Degree of Honor Protective Association",
    "Delta Epsilon Chi",
    "Delta Gamma",
    "Delta Kappa",
    "Delta Phi Epsilon",
    "Delta Sigma Pi",
    "Delta Sigma Theta",
    "DeMolay International",
    "Descendants of the Signers of the Declaration of Independence (DSDI)",
    "Disabled American Veterans Auxiliary",
    "Distinguished Young Women",
    "Distributive Education Clubs of America (DECA)",
    "Dollars for Scholars",
    "Eagle Scout",
    "Emergency Nurses Association (ENA)",
    "Energy Management Student Association (EMSA)",
    "Engineering Society",
    "Equals6",
    "Eta Sigma Gamma",
    "Eta Sigma Phi",
    "Family, Career and Community Leaders of America (FCCLA)",
    "Farm Bureau Member",
    "Federation Life Insurance of America",
    "Federation of American Consumers and Travelers (FACT)",
    "First Catholic Slovak Ladies Association",
    "First Command Educational Foundation",
    "Florida Roofing and Sheet Metal Contractors Association",
    "Florida School Nutrition Association",
    "Forensics Squad",
    "Foundry Educational Foundation (FEF)",
    "Future Business Leaders of America (FBLA)",
    "Future Educators Association",
    "Future Farmers of America (FFA)",
    "Future Homemakers of America (FHA)",
    "Girl Scouts of America",
    "Girls Incorporated",
    "Girls’ State",
    "Girls Who Code",
    "Gleaner Life Insurance Society",
    "Globe-Trotters",
    "Golden Key Honor Society",
    "Golf Course Superintendents Association of America (GCSAA)",
    "Gridiron Club",
    "Hamilton Leaders Academy",
    "Hawaiian Civic Club",
    "HBCU Connect",
    "Healthcare Information and Management Systems Society (HIMSS)",
    "Hispanic Student Dental Association",
    "Hispanic Student Society",
    "Honeywell Retirees Club",
    "Honpa or Higashi Hongwanji",
    "Horatio Alger Association",
    "Human Resources Association of the Midlands",
    "HVACR",
    "Ice Skating Institute of America (ISIA)",
    "IDDBA-zinternational Dairy-Deli-Bakery Association",
    "IEEE Computer Society",
    "IIE",
    "Illinois Farm Bureau",
    "Institute of Food Technologists",
    "Institute of Industrial Engineers (IIE)",
    "Institute of International Education",
    "Institute of Electrical/Electronic Engineers",
    "Instrument Society of America",
    "International Brotherhood of Electrical Workers",
    "International Business Student Organization",
    "International Dairy-Deli-Bakery Association",
    "International Executive Housekeepers Association",
    "International Furnishings and Design Association (IFDA)",
    "International Order of Job’s Daughters",
    "International Students Organization",
    "International Technology and Engineering Educators Association",
    "International Technology Education Association",
    "International Thespian Society",
    "Iota Sigma Pi",
    "Italian Catholic Federation",
    "Japanese American Citizens League (JACL)",
    "Job Corps",
    "JROTC",
    "Junior Achievement",
    "Kappa Alpha Psi",
    "Kappa Delta Pi",
    "Kappa Kappa Gamma",
    "Kappa Kappa Psi",
    "Kappa Omicron Nu",
    "Kappa Sigma",
    "Key Club International",
    "Knights of Columbus",
    "Knights of Lithuania",
    "La Leche League",
    "Lambda Chi Alpha",
    "Lambda Iota Tau",
    "Lambda Kappa Sigma",
    "Latin American Club",
    "leadership",
    "League for Nursing",
    "LGBTQ",
    "Library Association",
    "Lions Club",
    "Luso-American Fraternal Federation",
    "Lutheran Volunteer Corps (LVC)",
    "Macedonian Patriotic Organization",
    "MAES",
    "Maids of Athena",
    "Medical Group Management Association (MGMA)",
    "MENSA Education & Research Foundation",
    "Mexican American Women’s National Association-MANA",
    "Michigan Society of Professional Engineers (MSPE)",
    "Minnesota Association of Law Libraries",
    "Mortar Board",
    "Muslim Students Association",
    "NABF-National Amateur Baseball Federation",
    "Nat’l Assn. for the Advancement of Colored People",
    "Nat’l Society Daughters of the American Revolution",
    "National Academic Advising Association",
    "National Action Council for Minorities in Engineering ( NACME)",
    "National Achievement Scholar",
    "National Art Honor Society",
    "National Association for the Advancement of Colored People (NAACP)",
    "National Association of Black Accountants (NABA)",
    "National Association of Black County Officials (NABCO)",
    "National Association of Black Journalists (NABJ)",
    "National Association of Congregational Christian Churches (NACCC)",
    "National Association of Directors of Nursing Administration in Long Term Care (NADONA/LTC)",
    "National Association of Health Services Executives (NAHSE)",
    "National Association of Hispanic Nurses",
    "National Association of Home Builders",
    "National Association of Orthopaedic Nurses (NAON)",
    "National Association of Pastoral Musicians",
    "National Association of pediatric Nurse practitioners",
    "National Association of Railway Business Women (NARBW)",
    "National Beta Club",
    "National Black Law Students Association",
    "National Black MBA Association",
    "National Black Nurses’ Association (NBNA)",
    "National Broadcasting Society (NBS)",
    "National Court Reporters Association",
    "National Defense Transportation Association (NDTA)",
    "National Federation of Music Clubs (NFMC)",
    "National Fraternal Society of the Deaf",
    "National Gay and Lesbian Task Force (NGLTF)",
    "National Gerontological Nurses Association (NGNA)",
    "National Honor Society",
    "National Hook-Up of Black Women (NHBW)",
    "National Ice Cream Retailers Association",
    "National Junior Angus Association (NJAA)",
    "National Junior Hereford Association (NJHA)",
    "National Rifle Association (NRA)",
    "National Scholars Honor Society",
    "National Scholastic Press Association (NSPA)",
    "National Scholastic Surfing Association (NSSA)",
    "National Science Olympiad",
    "National Society of Black Engineers",
    "National Society of Collegiate Scholars(NSCS)",
    "National Society of High School Scholars (NSHSS)",
    "National Society of Leadership and Success",
    "National Society of Minorities in Hospitality",
    "National Society of Professional Engineers (NSPE)",
    "National Society of the Sons of the American Revolution (NSSAR)",
    "National Strength & Conditioning Association-NSCA",
    "National Student Nurses Association (NSNA)",
    "National Urban League",
    "National Women’s Studies Association (NWSA)",
    "Native American Journalists Association (NAJA)",
    "Native American Journalists Association (NAJA)",
    "Neighbors of Woodcraft",
    "Nevada State FireFighter’s Association (NSFA)",
    "New England Hemophilia Association",
    "New England Water Works Association",
    "New Members of the Round Table (NMRT)",
    "NIAF",
    "NLN (National League for Nursing)",
    "Nonprofit Organization",
    "NSAA",
    "Nurses Association",
    "Nursing Student Association",
    "Ohio GFOA",
    "Oklahoma Cattlemen’s Association",
    "Olivet Boys & Girls Club",
    "Omega Boys Club",
    "Omega Psi Phi",
    "Omicron Delta Kappa",
    "Order of De Molay",
    "Order of the Arrow",
    "Oregon Independent College Foundation (OICF)",
    "Palomino Horse Breeders of America (PHBA-Y)",
    "Panhellenic/Greek Student Organizations",
    "Parent Teacher Association/Parent Teacher Student Association (PTA/PTSA)",
    "PDCA",
    "PEO",
    "Phi Alpha Delta",
    "Phi Alpha Theta",
    "Phi Beta Kappa",
    "Phi Beta Kappa",
    "Phi Delta Gamma",
    "Phi Delta Kappa International",
    "Phi Eta Sigma",
    "Phi Gamma Delta",
    "Phi Gamma Nu",
    "Phi Kappa Lambda",
    "Phi Kappa Phi",
    "Phi Kappa Sigma",
    "Phi Mu Alpha",
    "Phi Sigma Iota",
    "Phi Sigma Kappa",
    "Phi Theta Kappa",
    "Phi Upsilon Omicron",
    "Philippine Nurses Association of America",
    "Pi Kappa Alpha",
    "Pi Lambda Theta",
    "Pi Sigma Alpha",
    "Pilot International Foundation",
    "Political Animals Club",
    "Pony Club",
    "Pork Producers Association",
    "Portuguese Continental Union of the USA",
    "Prospective Gents Club-Bridge Builders",
    "Public Relations Student Society of America (PRSSA)",
    "Quill and Scroll Society",
    "Recording for the Blind and Dyslexic",
    "Religious Group",
    "Reno Pops Orchestra",
    "Rotary Club",
    "ROTC",
    "Royal Neighbors of America",
    "San Antonio Music Teachers Association",
    "School Nutrition Association",
    "Science Club",
    "Screen Actors Guild (SAG)",
    "Serteen Club",
    "Sertoma Collegiate Club",
    "Sheriffs Boys & Girls Youth Ranches",
    "Sigma Alpha Epsilon",
    "Sigma Alpha Iota",
    "Sigma Chi",
    "Sigma Delta Chi",
    "Sigma Gamma Rho",
    "Sigma Phi Alpha",
    "Sigma Pi",
    "Sigma Pi Epsilon",
    "Sigma Xi",
    "Silicon Valley Community Foundation",
    "SkillsUSA",
    "Slovene National Benefit Society",
    "Slovenian Women’s Union of America",
    "Soaring Society of America (SSA)",
    "Society Farsarotul",
    "Society for Mining, Metallurgy, and Exploration (SME)",
    "Society for Signers of Declaration of Independence",
    "Society for the Advancement of Scandinavian Study",
    "Society of Automative Engineers",
    "Society of Broadcast Engineers (SBE)",
    "Society of Certified Public Accountants",
    "Society of Hispanic Professional Engineers",
    "Society of Human Resource Management",
    "Society of Manufacturing Engineers (SME)",
    "Society of Mexican American Engineers & Scientists",
    "Society of Mining Engineers",
    "Society of Motion Picture and Television Engineers (SMPTE)",
    "Society of Otorhinolaryngology and Head-Neck Nurses",
    "Society of Petroleum Engineers",
    "Society of Physics Students",
    "Society of Plastic Engineers",
    "Society of Women Engineers (SWE)",
    "Soil and Water Conservation Society (SWCS)",
    "Sons of Italy",
    "Sons of Norway",
    "Sons of Pericles",
    "Special Libraries Association",
    "Spina Bifida Association of America",
    "State Grange Club",
    "Student Dietetic Association",
    "Student Educatuon Association (SEA)",
    "Student Fashion Association",
    "Tau Beta Pi",
    "Tau Kappa Epsilon Fraternity",
    "Teachers of Tomorrow Academy",
    "Technology Student Association",
    "Texas Medical Association",
    "The Minerals, Metals & Materials Society (TMS)",
    "Theta Delta Chi",
    "Theta Xi",
    "Tile Contractors’ Association of America (TCAA)",
    "Tri Delta",
    "UNICO National",
    "United Commercial Travelers of America",
    "United Daughters of the Confederacy",
    "United Negro College Fund (UNCF)",
    "United Paperworkers International Union (UPIU)",
    "United States Association for Blind Athletes USABA",
    "United States Bowling Congress (USBC)",
    "United States Chess Federation",
    "United States Junior Chamber (Jaycees)",
    "United States Navy Radioman Association (USNRMA)",
    "United States Pony Club Member",
    "University Film and Video Association",
    "Upward Bound",
    "Urban Servant Corps",
    "USA Water Ski",
    "USAA Mentor Program",
    "Vasa Order of America",
    "Veterans of the Vietnam War (VVnW)",
    "VICA",
    "Washington Correctional Association (WCA)",
    "Washington Osteopathic Medical Association",
    "Western Fraternal Life Association (WFLA)",
    "Western Undergraduate Exchange (WUE)",
    "WHCC Junior Golf Program",
    "Women in Aviation, International",
    "Women in CyberSecurity",
    "Women’s Association",
    "Wound, Ostomy & Continence Nurses Society",
    "YAC",
    "Young Adults Global Mission (YAGM)",
    "Young Mens Christian Association (YMCA)",
    "Youth Education Summit (Y.E.S.)",
    "Zeta Phi Beta Sorority",
    "Zonta Club"
]

function formatStudentOrganizations(studentOrganizationsArray) {
  const formattedArray = [
    { name: "Select Organization", value: "" },
    ...studentOrganizationsArray.map((organization) => ({
      name: organization,
      value: organization,
    })),
  ];

  return formattedArray;
}

const formattedStudentOrganizationsArray = formatStudentOrganizations(studentorganizations);
//console.log(formattedStudentOrganizationsArray);


const honorSocietiesArray = [
  { name: "Select Society", value: "" },
  { name: "Alpha Beta Gamma", value: "alpha-beta-gamma" },
  { name: "Alpha Chi", value: "alpha-chi" },
  { name: "Alpha Chi Sigma", value: "alpha-chi-sigma" },
  { name: "Alpha Delta Mu", value: "alpha-delta-mu" },
  { name: "Alpha Epsilon", value: "alpha-epsilon" },
  { name: "Alpha Epsilon Delta", value: "alpha-epsilon-delta" },
  { name: "Alpha Eta Rho", value: "alpha-eta-rho" },
  { name: "Alpha Kappa Delta", value: "alpha-kappa-delta" },
  { name: "Alpha Kappa Psi", value: "alpha-kappa-psi" },
  { name: "Alpha Lambda Delta", value: "alpha-lambda-delta" },
  { name: "Alpha Mu Gamma", value: "alpha-mu-gamma" },
  { name: "Alpha Omega Epsilon", value: "alpha-omega-epsilon" },
  { name: "Alpha Phi Mu", value: "alpha-phi-mu" },
  { name: "Alpha Phi Sigma", value: "alpha-phi-sigma" },
  { name: "Alpha Psi Omega", value: "alpha-psi-omega" },
  { name: "Alpha Rho Chi", value: "alpha-rho-chi" },
  { name: "Alpha Sigma Kappa", value: "alpha-sigma-kappa" },
  { name: "Alpha Sigma Mu", value: "alpha-sigma-mu" },
  { name: "Alpha Sigma Nu", value: "alpha-sigma-nu" },
  { name: "Alpha Zeta", value: "alpha-zeta" },
  { name: "Beta Alpha Psi", value: "beta-alpha-psi" },
  { name: "Beta Beta Beta", value: "beta-beta-beta" },
  { name: "Beta Gamma Sigma", value: "beta-gamma-sigma" },
  { name: "Beta Kappa Chi", value: "beta-kappa-chi" },
  { name: "Beta Phi Mu", value: "beta-phi-mu" },
  { name: "Chi Epsilon", value: "chi-epsilon" },
  { name: "Chi Eta Phi", value: "chi-eta-phi" },
  { name: "Chi Sigma Iota", value: "chi-sigma-iota" },
  { name: "College Board National Recognition Program", value: "college-board-national-recognition-program" },
  { name: "Delta Alpha Pi", value: "delta-alpha-pi" },
  { name: "Delta EPSILON cHI", value: "delta-epsilon-chi" },
  { name: "Delta Epsilon Sigma", value: "delta-epsilon-sigma" },
  { name: "Delta Mu Delta", value: "delta-mu-delta" },
  { name: "Delta Omicron", value: "delta-omicron" },
  { name: "Delta Omicron Sigma", value: "delta-omicron-sigma" },
  { name: "Delta Rho", value: "delta-rho" },
  { name: "Delta Sigma Pi", value: "delta-sigma-pi" },
  { name: "Delta tau Alpha", value: "delta-tau-alpha" },
  { name: "Eta Kappa Nu", value: "eta-kappa-nu" },
  { name: "Gamma Beta Phi", value: "gamma-beta-phi" },
  { name: "Gamma Iota Sigma", value: "gamma-iota-sigma" },
  { name: "Gamma Theta Upsilon", value: "gamma-theta-upsilon" },
  { name: "Golden Key International Hour Society", value: "golden-key-international-hour-society" },
  { name: "Honors Program", value: "honors-program" },
  { name: "Kappa Delta pi", value: "kappa-delta-pi" },
  { name: "Kappa Epsilon", value: "kappa-epsilon" },
  { name: "Kappa kappa psi", value: "kappa-kappa-psi" },
  { name: "Kappa Mu EPSILON", value: "kappa-mu-epsilon" },
  { name: "Kappa Omicron Nu", value: "kappa-omicron-nu" },
  { name: "Kappa Psi", value: "kappa-psi" },
  { name: "Kappa Tau Alpha", value: "kappa-tau-alpha" },
  { name: "Lambda Alpha Upsilon", value: "lambda-alpha-upsilon" },
  { name: "Lambda Iota Tau", value: "lambda-iota-tau" },
  { name: "Lambda Pi Eta", value: "lambda-pi-eta" },
  { name: "Lambda Sigma", value: "lambda-sigma" },
  { name: "Mortar Board", value: "mortar-board" },
  { name: "Mu Alpha Theta", value: "mu-alpha-theta" },
  { name: "Mu Kappa Tau", value: "mu-kappa-tau" },
  { name: "Mu Phi Epsilon", value: "mu-phi-epsilon" },
  { name: "National Achievement Finalist", value: "national-achievement-finalist" },
  { name: "National Achievement Scholar", value: "national-achievement-scholar" },
  { name: "National Achievement Semifinalist", value: "national-achievement-semifinalist" },
  { name: "National Hispanic Honorable mention", value: "national-hispanic-honorable-mention" },
  { name: "National Honor society", value: "national-honor-society" },
  { name: "National merit finalist", value: "national-merit-finalist" },
  { name: "National society of collegiate scholars", value: "national-society-of-collegiate-scholars" },
  { name: "National society of high school scholars", value: "national-society-of-high-school-scholars" },
  { name: "Omega Chi Epsilon", value: "omega-chi-epsilon" },
  { name: "Omega Rho", value: "omega-rho" },
  { name: "Omicron Delta Epsilon", value: "omicron-delta-epsilon" },
  { name: "Omicron Delta Kappa", value: "omicron-delta-kappa" },
  { name: "Order of Omega", value: "order-of-omega" },
  { name: "Phi Alpha Honor Society", value: "phi-alpha-honor-society" },
  { name: "Phi Alpha Theta", value: "phi-alpha-theta" },
  { name: "Phi Beta", value: "phi-beta" },
  { name: "Phi Beta Kappa", value: "phi-beta-kappa" },
  { name: "Phi Chi Sigma", value: "phi-chi-sigma" },
  { name: "Phi Chi Theta", value: "phi-chi-theta" },
  { name: "Phi Delta Chi", value: "phi-delta-chi" },
  { name: "Phi Eta Sigma", value: "phi-eta-sigma" }
  // Add more societies here...
];
const fraternitiesArray = [
  { name: "Select Fraternity", value: "" },
  { name: "Acacia", value: "acacia" },
  { name: "Alpha Chi Rho", value: "alpha-chi-rho" },
  { name: "Alpha Delta Gamma", value: "alpha-delta-gamma" },
  { name: "Alpha Delta Phi", value: "alpha-delta-phi" },
  { name: "Alpha Epsilon Pi", value: "alpha-epsilon-pi" },
  { name: "Alpha Gamma Rho", value: "alpha-gamma-rho" },
  { name: "Alpha Gamma Sigma", value: "alpha-gamma-sigma" },
  { name: "Alpha Kappa Lambda", value: "alpha-kappa-lambda" },
  { name: "Alpha Nu Omega", value: "alpha-nu-omega" },
  { name: "Alpha Phi Delta", value: "alpha-phi-delta" },
  { name: "Alpha Phi Omega", value: "alpha-phi-omega" },
  { name: "Alpha Sigma Phi", value: "alpha-sigma-phi" },
  { name: "Alpha Tau Omega", value: "alpha-tau-omega" },
  { name: "Beta Sigma Psi", value: "beta-sigma-psi" },
  { name: "Beta Theta Pi", value: "beta-theta-pi" },
  { name: "Chi Phi", value: "chi-phi" },
  { name: "Chi Psi", value: "chi-psi" },
  { name: "Delta Chi", value: "delta-chi" },
  { name: "Delta Kappa Epsilon", value: "delta-kappa-epsilon" },
  { name: "Delta Lambda Phi", value: "delta-lambda-phi" },
  { name: "Delta Phi", value: "delta-phi" },
  { name: "Delta Sigma Phi", value: "delta-sigma-phi" },
  { name: "Delta Tau Delta", value: "delta-tau-delta" },
  { name: "Delta Upsilon", value: "delta-upsilon" },
  { name: "FarmHouse Fraternity", value: "farmhouse-fraternity" },
  { name: "Gamma Phi Sigma", value: "gamma-phi-sigma" },
  { name: "Gamma Zeta Alpha", value: "gamma-zeta-alpha" },
  { name: "Iota Phi Theta", value: "iota-phi-theta" },
  { name: "Kappa Alpha Order", value: "kappa-alpha-order" },
  { name: "Kappa Alpha Psi", value: "kappa-alpha-psi" },
  { name: "Kappa Alpha Society", value: "kappa-alpha-society" },
  { name: "Kappa Delta Phi", value: "kappa-delta-phi" },
  { name: "Kappa Delta Rho", value: "kappa-delta-rho" },
  { name: "Kappa Sigma", value: "kappa-sigma" },
  { name: "Lambda Chi Alpha", value: "lambda-chi-alpha" },
  { name: "Lambda Sigma Upsilon", value: "lambda-sigma-upsilon" },
  { name: "Lambda Theta Phi", value: "lambda-theta-phi" },
  { name: "Lambda Upsilon Lambda", value: "lambda-upsilon-lambda" },
  { name: "Nu Alpha Kappa", value: "nu-alpha-kappa" },
  { name: "Phi Beta Sigma", value: "phi-beta-sigma" },
  { name: "Phi Delta Theta", value: "phi-delta-theta" },
  { name: "Phi Eta Psi", value: "phi-eta-psi" },
  { name: "Phi Gamma Delta", value: "phi-gamma-delta" },
  { name: "Phi Iota Alpha", value: "phi-iota-alpha" },
  { name: "Phi Kappa Psi", value: "phi-kappa-psi" },
  { name: "Phi Kappa Sigma", value: "phi-kappa-sigma" },
  { name: "Phi Kappa Tau", value: "phi-kappa-tau" },
  { name: "Phi Kappa Theta", value: "phi-kappa-theta" },
  { name: "Phi Lambda Chi", value: "phi-lambda-chi" },
  { name: "Phi Mu Delta", value: "phi-mu-delta" },
  { name: "Phi Sigma Kappa", value: "phi-sigma-kappa" },
  { name: "Phi Sigma Phi", value: "phi-sigma-phi" },
  { name: "Pi Kappa Alpha", value: "pi-kappa-alpha" },
  { name: "Pi Kappa Phi", value: "pi-kappa-phi" },
  { name: "Pi Lambda Phi", value: "pi-lambda-phi" },
  { name: "Psi Upsilon", value: "psi-upsilon" },
  { name: "Sigma Alpha Epsilon", value: "sigma-alpha-epsilon" },
  { name: "Sigma Alpha Mu", value: "sigma-alpha-mu" },
  { name: "Sigma Chi", value: "sigma-chi" },
  { name: "Sigma Lambda Beta", value: "sigma-lambda-beta" },
  { name: "Sigma Nu", value: "sigma-nu" },
  { name: "Sigma Phi Epsilon", value: "sigma-phi-epsilon" },
  { name: "Sigma Phi Society", value: "sigma-phi-society" },
  { name: "Sigma Pi", value: "sigma-pi" },
  { name: "Sigma Tau Gamma", value: "sigma-tau-gamma" },
  { name: "Tau Delta Phi", value: "tau-delta-phi" },
  { name: "Tau Epsilon Phi", value: "tau-epsilon-phi" },
  { name: "Tau Kappa Epsilon", value: "tau-kappa-epsilon" },
  { name: "Theta Chi", value: "theta-chi" },
  { name: "Theta Delta Chi", value: "theta-delta-chi" },
  { name: "Theta Xi", value: "theta-xi" },
  { name: "Triangle Fraternity", value: "triangle-fraternity" },
  { name: "Zeta Beta Tau", value: "zeta-beta-tau" },
  { name: "Zeta Psi", value: "zeta-psi" }
];
const sororitiesArray =[
  { name: "Select Sorority", value: "" },
  { name: "Alpha Chi Omega", value: "alpha-chi-omega" },
  { name: "Alpha Delta Pi", value: "alpha-delta-pi" },
  { name: "Alpha Epsilon Phi", value: "alpha-epsilon-phi" },
  { name: "Alpha Gamma Delta", value: "alpha-gamma-delta" },
  { name: "Alpha Kappa Alpha", value: "alpha-kappa-alpha" },
  { name: "Alpha Kappa Delta Phi", value: "alpha-kappa-delta-phi" },
  { name: "Alpha Omicron Pi", value: "alpha-omicron-pi" },
  { name: "Alpha Phi", value: "alpha-phi" },
  { name: "Alpha Sigma Alpha", value: "alpha-sigma-alpha" },
  { name: "Alpha XI Delta", value: "alpha-xi-delta" },
  { name: "Chi Omega", value: "chi-omega" },
  { name: "Chi Upsilon Sigma", value: "chi-upsilon-sigma" },
  { name: "Delta Delta Delta", value: "delta-delta-delta" },
  { name: "Delta Gamma", value: "delta-gamma" },
  { name: "Delta Phi Epsilon", value: "delta-phi-epsilon" },
  { name: "Delta Phi Lambda", value: "delta-phi-lambda" },
  { name: "Delta Sigma Theta", value: "delta-sigma-theta" },
  { name: "Delta Zeta", value: "delta-zeta" },
  { name: "Gamma Phi Beta", value: "gamma-phi-beta" },
  { name: "Kappa Alpha Theta", value: "kappa-alpha-theta" },
  { name: "Kappa Delta", value: "kappa-delta" },
  { name: "Kappa Delta Chi", value: "kappa-delta-chi" },
  { name: "Kappa Kappa Gamma", value: "kappa-kappa-gamma" },
  { name: "Lambda Pi Chi", value: "lambda-pi-chi" },
  { name: "Lambda Theta Nu", value: "lambda-theta-nu" },
  { name: "Phi Mu", value: "phi-mu" },
  { name: "Phi Sigma Sigma", value: "phi-sigma-sigma" },
  { name: "Pi Beta Phi", value: "pi-beta-phi" },
  { name: "Sigma Alpha", value: "sigma-alpha" },
  { name: "Sigma Delta Tau", value: "sigma-delta-tau" },
  { name: "Sigma Gamma Rho", value: "sigma-gamma-rho" },
  { name: "Sigma Kappa", value: "sigma-kappa" },
  { name: "Sigma Sigma Sigma", value: "sigma-sigma-sigma" },
  { name: "Theta Phi Alpha", value: "theta-phi-alpha" },
  { name: "Zeta Phi Beta", value: "zeta-phi-beta" },
  { name: "Zeta Tau Alpha", value: "zeta-tau-alpha" }
]

const sportsArray = [
  { name: "Select Sports", value: "" },
  { name: "Aerobics", value: "aerobics" },
  { name: "Archery", value: "archery" },
  { name: "Athletics", value: "athletics" },
  { name: "Badminton", value: "badminton" },
  { name: "Baseball", value: "baseball" },
  { name: "Basketball", value: "basketball" },
  { name: "Baton Twirling", value: "baton-twirling" },
  { name: "Bowling", value: "bowling" },
  { name: "Cheerleader", value: "cheerleader" },
  { name: "Cricket", value: "cricket" },
  { name: "Cross country", value: "cross-country" },
  { name: "Cycling", value: "cycling" },
  { name: "Dance/Drill team", value: "dance/drill-team" },
  { name: "Fencing", value: "fencing" },
  { name: "Field Hockey", value: "field-hockey" },
  { name: "Fishing", value: "fishing" },
  { name: "Flag Football", value: "flag-football" },
  { name: "Football", value: "football" },
  { name: "Frisbee", value: "frisbee" },
  { name: "Golf", value: "golf" },
  { name: "Gymnastics", value: "gymnastics" },
  { name: "Handball", value: "handball" },
  { name: "Horseback riding/Equestrian", value: "horseback-riding/equestrian" },
  { name: "Ice Hockey", value: "ice-hockey" },
  { name: "Ice/Figure Skating", value: "ice/figure-skating" },
  { name: "Lacrosse", value: "lacrosse" },
  { name: "Martial Arts", value: "martial-arts" },
  { name: "Mountain Biking", value: "mountain-biking" },
  { name: "Racquetball", value: "racquetball" },
  { name: "Rodeo", value: "rodeo" },
  { name: "Rowing", value: "rowing" },
  { name: "Rugby", value: "rugby" },
  { name: "Running", value: "running" },
  { name: "Sailing", value: "sailing" },
  { name: "Skateboarding", value: "skateboarding" },
  { name: "Skiing", value: "skiing" },
  { name: "Snowboarding", value: "snowboarding" },
  { name: "Soccer", value: "soccer" },
  { name: "Softball", value: "softball" },
  { name: "Surfing", value: "surfing" },
  { name: "Swimming", value: "swimming" },
  { name: "Table Tennis", value: "table-tennis" },
  { name: "Track and Field", value: "track-and-field" },
  { name: "Volleyball", value: "volleyball" },
  { name: "Water Polo", value: "water-polo" },
  { name: "Weight Lifting", value: "weight-lifting" },
  { name: "Wrestling", value: "wrestling" },
  { name: "Other", value: "other" }
];


// function formatStudent(studentOrganizationss) {
//   const formattedArray = [
//     { name: "Select Organization", value: "" },
//     ...studentorganizations.map((soc) => ({
//       name: soc,
//       value: soc.toLowerCase().replace(/\s+/g, '-'),
//     })),
//   ];

//   return formattedArray;
// }


// const formattedStudent = formatStudent(studentorganizations);
// console.log(formattedStudent);

  return(
    <div className="personal-profile">
    <div className="profileeditbodyheader" />
    <div className="profileeditbodybox2">
    <div className="scholarship-questions-22">
        <div className="editprofileform">
          <div className="editprofileformbox">
            <div className="editprofileformboxtext">
              Student Organizations
            </div>
            <div className="boxerror">
            <select className="editprofileformboxinputbox"  name= " studentOrganizations" required="required" onChange={props.onChange}>
              {/* <option value="4-H Club">4-H Club</option>
              <option value="AACN">AACN</option> */}

              
{
  formattedStudentOrganizationsArray.map((org) => (
    <option key={org.name} value={org.value}>
      {org.name}
    </option>
  ))
}
            </select>
            <span className="errormessage">{props.formErrors.studentOrganization}</span>
            </div>
          </div>
        </div>
        <div className="editprofileform">
          <div className="editprofileformbox">
            <div className="editprofileformboxtext">Honor Societies</div>
            <div className="boxerror">
            <select className="editprofileformboxinputbox"  name= "honorSocieties" required="required" onChange={props.onChange}>
              {/* <option value="Alpha Beta Gamma">Alpha Beta Gamma</option>
              <option value="Alpha Chi">Alpha Chi</option>
              <option value="Alpha Chi Sigma’Alpha Delta Mu">
                Alpha Chi Sigma’Alpha Delta Mu
              </option> */}
           {
  honorSocietiesArray.map((org) => (
    <option key={org.name} value={org.value}>
      {org.name}
    </option>
  ))
}
            </select>
            <span className="errormessage">{props.formErrors.honorSocieties}</span>
            </div>
          </div>
        </div>
        <div className="editprofileform">
          <div className="editprofileformbox">
            <div className="editprofileformboxtext">Fraternity</div>
            <div className="boxerror">
            <select className="editprofileformboxinputbox"  name= "fraternity" 
                required="required" 
                onChange={props.onChange} >
              {/* <option value="Acacia">Acacia</option>
              <option value="Alpha chi rho">Alpha chi rho</option> */}

{
 fraternitiesArray.map((org) => (
    <option key={org.name} value={org.value}>
      {org.name}
    </option>
  ))
}
    
            </select>
            <span className="errormessage">{props.formErrors.fraternity}</span>
            </div>
          </div>
        </div>
        <div className="editprofileform">
          <div className="editprofileformbox">
            <div className="editprofileformboxtext">Sorority</div>
            <div className="boxerror">
            <select className="editprofileformboxinputbox" name= "sorority" required="required" onChange={props.onChange}>
              {/* <option value="Acacia">Acacia</option>
              <option value="Alpha chi rho">Alpha chi rho</option> */}
              
{
 sororitiesArray.map((org) => (
    <option key={org.name} value={org.value}>
      {org.name}
    </option>
  ))
}
            </select>
            <span className="errormessage">{props.formErrors.sorority}</span>
            </div>
          </div>
        </div>
        <div className="editprofileform">
          <div className="editprofileformbox">
            <div className="editprofileformboxtext">Sports</div>
            <div className="boxerror">
            <select 
            className="editprofileformboxinputbox" 
            name= "sports" 
            required="required" onChange={props.onChange}
            >
              {/* <option value="Archery">Archery</option>
              <option value="Athletics">Athletics</option>
              <option value="Football">Football</option>
              <option value="Basketball">Basketball</option>
              <option value="Golf">Golf</option> */}
              {
 sportsArray.map((org) => (
    <option key={org.name} value={org.value}>
      {org.name}
    </option>
  ))
}
   
            </select>
            <span className="errormessage">{props.formErrors.sports}</span>
            </div>
          </div>
        </div>
        <div className="editprofileform">
          <div className="editprofileformbox">
            <div className="editprofileformboxtext">Performing Arts</div>
            <div className="boxerror"> 
            <select className="editprofileformboxinputbox"   name= "perfomingArts" required="required" onChange={props.onChange}>
            <option value="">Select Arts</option>
              <option value="Dance">Dance</option>
              <option value="Music">Music</option>
              <option value="Drama">Drama</option>
            </select>
            <span className="errormessage">{props.formErrors.perfomingArts}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default STUDENTACTIVITIESPROFILE;
