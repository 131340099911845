
import communityReducer from "./communityRedux"

import userReducer from './userRedux'
import userProfileReducer from "./userProfileRedux";
import { configureStore, combineReducers } from "@reduxjs/toolkit";

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from "redux-persist";
  import storage from "redux-persist/lib/storage";
import bookmarkReducer from "./bookmarkRedux";
import createMigrate from "redux-persist/es/createMigrate";
  
  // const persistConfig = {
  //   key: "root",
  //   version: 1,
  //   storage,
  // };

  
const migrations = {
  0: (state) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        moodColorCategory: {
          colorPalette: 'DEFAULT',
        }
      }
    }
  },
};

const persistConfig = {
  key: 'root',
  storage,
  version: 0,
  timeout: 0,
  migrate: createMigrate(migrations, { debug: true }),
};


const rootReducer = combineReducers({ userProfile: userProfileReducer, user: userReducer,  bookmark: bookmarkReducer });
const persistedReducer = persistReducer(persistConfig, rootReducer);


export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });
  
  export let persistor = persistStore(store);