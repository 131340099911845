import React, { useEffect, useRef } from "react";
import { useState, useCallback } from "react";

import { useNavigate } from "react-router-dom";
import "./Profile.css";
import PERSONALPROFILE from "../components/PERSONALPROFILE";
import EDUCATIONPROFILE from "../components/EDUCATIONPROFILE";
import STUDENTACTIVITIESPROFILE from "../components/STUDENTACTIVITIESPROFILE";
import { BASE_Server_URL, userRequest } from "../requestMethods";
import { viewUserProfile } from "../redux/apiCalls";
import axios from "axios";

const Profile = () => {
  const navigate = useNavigate();

  const onFrameButton1Click = useCallback(() => {
    navigate("/education-profile");
  }, [navigate]);

  const onFrameButton2Click = useCallback(() => {
    navigate("/student-activities-profile");
  }, [navigate]);

  const onFrameButton4Click = useCallback(() => {
    navigate("/education-profile");
  }, [navigate]);

  const [isNavigationDrawerOpen, setNavigationDrawerOpen] = useState(false);
  const [formType, setFormType] = useState("personalprofileform");

  const [profile, setProfile] = useState({
    birthDate: "",
    highschool: "",
    studentOrganizations: "",
  });

  const viewUserProfileStart = () => {
    //console.log('view User Profile', userRequest)
    // viewUserProfile()
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });

      console.log(currentUser);
      // console.log(userRequest)

      const res = client.get("profile");
      console.log("res", res);
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const createUserProfile = async () => {
    //console.log('view User Profile', userRequest)
    // viewUserProfile()
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });

      // console.log(currentUser)
      // console.log(userRequest)

      const res = await client.post("/profile");
      //console.log('res',res)
      return "create user profiloe", res;
    } catch (error) {
      console.log(error);
    }
  };

  //check if profile completed
  useEffect(() => {
    //const profile = viewUserProfileStart()
    // console.log(profile)

    viewUserProfileStart()
      .then((profile) => {
        console.log("profile", profile.data);
        if (profile.data == "") {
          console.log("create data profile");
          createUserProfile().then((data) => {
            console.log("data", data);
            setProfile(data);
          });
        }
        if (profile.data.profileCompleted) {
          navigate("/dashboard");
        } else {
          setProfile(profile);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  //const ddRef = useRef()
  //console.log(ddRef)

  const [values, setValues] = useState({
    // birthYear: "",
    // highSchool: ""
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [errorsAvailable, setErrorsAvailable] = useState(false);

  // const [educationProfileValues, setEducationProfileValues] = useState({
  //   highschool: ""
  // })

  const handleSubmit = (e) => {
    e.preventDefault();
    //const data = new FormData(e.target)

    //console.log(Object.fromEntries(data.entries()))educa
    //console.log(educationProfileValues)
    if (formType == "personalprofileform") {
      console.log(values);
      setFormErrors(validate(values));
      //setErrorsAvailable(true)
      setIsNext(true);
    }

    if (formType == "educationform") {
      console.log(values);
      setFormErrors(validateEduForm(values));
      setIsNext(true);
    }

    if (formType == "studentactivitiesform") {
      console.log(values);
      setFormErrors(validateStudentForm(values));
      setIsNext(true);
    }
  };

  const onChange = (e) => {
    //console.log('calledc hnskm', e)
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeAddress = (val) => {
    // console.log('calledc hnskm', val)
    setValues({ ...values, stateOfResidence: val });
  };

  const onChangeCollege = (val) => {
    console.log("calledc hnskm", val);
    setValues({ ...values, college: val });
  };

  // const onChangeEducationForm = (e) => {
  //   setEducationProfileValues({...educationProfileValues, [e.target.name]: e.target.value });
  // }

  const validate = (values) => {
    // console.log(values)
    const errors = {};

    if (!values.birthDate) {
      errors.birthDate = "Birthday is Required!";
    }

    function isOver18(dateOfBirth) {
      const currentDate = new Date();
      const dob = new Date(dateOfBirth);
      const ageDifference = currentDate.getFullYear() - dob.getFullYear();

      if (ageDifference > 18) {
        return true;
      } else if (ageDifference === 18) {
        // Check if the birth date has occurred in the current year or earlier
        if (
          currentDate.getMonth() > dob.getMonth() ||
          (currentDate.getMonth() === dob.getMonth() &&
            currentDate.getDate() >= dob.getDate())
        ) {
          return true;
        }
      }

      return false;
    }

    function isValidEmail(email) {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailPattern.test(email);
    }
    //  if(values.birthDate){
    //   //errors.birthDate= 'Birthday is Required!'

    //   if(!isOver18(values.birthDate  )){
    //     errors.guardianEmail = 'Guardian Email is required'
    //   }
    //  }

    if (!isOver18(values.birthDate) && !values.guardianEmail) {
      errors.guardianEmailRequired = "Please Enter Your Guardians Email!";
    }

    if (
      !isOver18(values.birthDate) &&
      values.guardianEmail &&
      !isValidEmail(values.guardianEmail)
    ) {
      errors.guardianEmailRequired = "Not a Valid Email!";
    }

    if (!values.stateOfResidence) {
      errors.stateOfResidence = "Please Select an item in the list";
    }

    //  if(!values.firstGeneration){
    //   errors.firstGeneration = 'Please choose one of the options'
    //  }

    if (!values.gender) {
      errors.gender = "Please Select an item in the list";
    }

    if (!values.citizenshipStatus) {
      errors.citizenshipStatus = "Please Select an item in the list";
    }

    //  if(!values.disability){
    //   errors.disability = 'Please Select an item in the list'
    //  }

    if (!values.ethnicity) {
      errors.ethnicity = "Please Select an item in the list";
    }

    //  if(!values.religion){
    //   errors.religion = 'Please Select an item in the list'
    //  }

    //  if(!values.personalAttributes){
    //   errors.personalAttributes = 'Please Select an item in the list'
    //  }

    //  if(!values.militaryAffliation){
    //   errors.militaryAffliation   = 'Please choose one of the options'
    //  }

    return errors;
  };

  const validateEduForm = (values) => {
    // console.log(values)
    const errors = {};

    const actRegex = /\b(0?[1-9]|1[0-9]|2[0-6])\b/g;
    const yearRegex = /(?:(?:19|20)[0-9]{2})/;
    const scoreRegex = /^([2-7][0-9]0|800)$/;
    const gpaRegex = /[0-4]/;

    // if(!values.highSchool){
    //  errors.highSchool = 'Please Fill in the form!'
    // }

    if (values.highSchoolGraduationYear) {
      //errors.highSchoolGraduationYear = 'Please Fill in the form!'
      if (!yearRegex.test(values.highSchoolGraduationYear)) {
        errors.highSchoolGraduationYear = "Should be a valid year";
      }
    }

    if (values.collegeGraduationYear) {
      //errors.collegeGraduationYear = 'Please Fill in the form!'
      if (!yearRegex.test(values.collegeGraduationYear)) {
        errors.collegeGraduationYear = "Should be a valid year";
      }
    }

    if (values.math || values.ebrw) {
      if (!scoreRegex.test(values.math) || !scoreRegex.test(values.ebrw)) {
        errors.math = "Scores should be between 200 - 800";
      }
    }

    //  if(!values.ebrw){
    //   errors.ebrw = 'Please Fill in the form!'
    //  }
    //  if(values.actScore){
    //   //errors.actScore = 'Please Fill in the form!'
    //   if(!actRegex.test(values.actScore)){
    //     errors.actScore = 'Act Score should be between 1- 26'
    //    }
    //  }

    if (values.ged) {
      //errors.ged = 'Please Fill in the form!'
      if (!scoreRegex.test(values.ged)) {
        errors.ged = "Scores should be between 200 - 800";
      }
    }

    if (!values.gpa) {
      errors.gpa = "Please Fill in the form!";
    } else if (!gpaRegex.test(values.gpa)) {
      errors.gpa = "gpa should be beteen 1 and 4";
    }
    // if(values.highSchoolGraduationYear){
    //   errors.highSchoolGraduationYear = 'Please Fill in the form!'
    //  }
    //  else if(!yearRegex.test(values.highSchoolGraduationYear)){
    //   errors.highSchoolGraduationYear = 'Should be a valid year'
    //  }
    //  if(!values.collegeGraduationYear){

    //   errors.collegeGraduationYear = 'Please Fill in the form!'
    //  }
    //  else if(!yearRegex.test(values.collegeGraduationYear)){
    //   errors.collegeGraduationYear = 'Should be a valid year'
    //  }
    //  if(!values.math || !values.ebrw){
    //   errors.math = 'Please Fill in the form!'
    //  }
    //  else if(!scoreRegex.test(values.math) || !scoreRegex.test(values.ebrw) ){
    //   errors.math = 'Scores should be between 200 - 800'
    //  }
    // //  if(!values.ebrw){
    // //   errors.ebrw = 'Please Fill in the form!'
    // //  }
    //  if(!values.actScore){
    //   errors.actScore = 'Please Fill in the form!'
    //  }
    //  else if(!actRegex.test(values.actScore)){
    //   errors.actScore = 'Act Score should be between 1- 26'
    //  }
    //  if(!values.ged){
    //   errors.ged = 'Please Fill in the form!'
    //  }
    //  else if(!scoreRegex.test(values.ged) ){
    //   errors.ged = 'Scores should be between 200 - 800'
    //  }

    //  if(!values.gpa){
    //   errors.gpa = 'Please Fill in the form!'
    //  }
    //  else if(!gpaRegex.test(values.gpa) ){
    //   errors.gpa = 'gpa should be beteen 1 and 4'
    //  }

    //  if(!values.classLevel){
    //   errors.classLevel= 'Please Fill in the form!'
    //  }
    if (!values.fieldOfStudy) {
      errors.fieldOfStudy = "Please Fill in the form!";
    }

    //  if(!values.college){
    //   errors.college = 'Please Fill in the form!'
    //  }

    //  if(!values.collegeGraduationYear){
    //   errors.collegeGraduationYear = 'Please Fill !'
    //  }
    if (!values.classLevel) {
      errors.classLevel = "Please Choose from the list!";
    }

    return errors;
  };

  const validateStudentForm = (values) => {
    // console.log(values)
    const errors = {};
    // if(!values.studentOrganization){
    //  errors.studentOrganization = 'Please Fill in the form!'
    // }

    // if(!values.honorSocieties){
    //   errors.honorSocieties = 'Please Fill in the form!'
    //  }
    //  if(!values.fraternity){
    //   errors.fraternity = 'Please Fill in the form!'
    //  }

    //  if(!values.sorority){
    //   errors.sorority = 'Please Fill in the form!'
    //  }
    //  if(!values.sports ){
    //   errors.sports = 'Please Fill in the form!'
    //  }

    //  if(!values.perfomingArts){
    //   errors.perfomingArts= 'Please Fill in the form!'
    //  }

    return errors;
  };

  const submitData = async (val) => {
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });

      const res = await client.put("/profile", val);
      return res;
      //dispatch(updateProfileSuccess(res.data))
    } catch (error) {
      console.log(error);
      //dispatch(updateProfileFailure());
    }
  };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isNext) {
      if (formType == "personalprofileform") {
        console.log("validate and go to next page", values);
        setFormType("educationform");
      }
      if (formType == "educationform") {
        console.log("validate and go to next page", values);
        setFormType("studentactivitiesform");
      }

      if (formType == "studentactivitiesform") {
        console.log("validate and go to next page", values);
        values.profileCompleted = true;
        const res = submitData(values);
        if (res) {
          navigate("/dashboard");
        }
      }
    }
  }, [formErrors]);

  // useEffect(() => {

  // })

  const TypeOfForm = () => {
    if (formType == "personalprofileform") {
      return (
        <PERSONALPROFILE
          values={values}
          onChange={onChange}
          formErrors={formErrors}
          onChangeAddress={onChangeAddress}
        />
      );
    }
    if (formType == "educationform") {
      return (
        <EDUCATIONPROFILE
          values={values}
          onChange={onChange}
          formErrors={formErrors}
          onChangeCollege={onChangeCollege}
        />
      );
    }
    if (formType == "studentactivitiesform") {
      return (
        <STUDENTACTIVITIESPROFILE
          values={values}
          onChange={onChange}
          formErrors={formErrors}
        />
      );
    }
  };
  const TypeOfButton = () => {
    if (formType == "personalprofileform") {
      return (
        <button className="submitFormButton" onClick={handleSubmit}>
          {" "}
          <a className="submitbuttontext">Next</a>
        </button>
      );
    }
    if (formType == "educationform") {
      return (
        <div className="profileNextExit">
          <button
            className="submitFormButton"
            onClick={() => setFormType("personalprofileform")}
          >
            {" "}
            <a className="submitbuttontext">Back</a>
          </button>
          <button
            className="submitFormButton"
            type="submit"
            onClick={handleSubmit}
          >
            {" "}
            <a className="submitbuttontext">Next</a>
          </button>
        </div>
      );
    }

    if (formType == "studentactivitiesform") {
      return (
        // <>
        // <button className='submitFormButton'> Back</button>
        // <button className='submitFormButton' type='submit' onClick={handleSubmit}> <a className='submitbuttontext'>Submit</a></button>
        // </>

        <div className="profileNextExit">
          <button
            className="submitFormButton"
            onClick={() => setFormType("educationform")}
          >
            {" "}
            <a className="submitbuttontext">Back</a>
          </button>
          <button
            className="submitFormButton"
            type="submit"
            onClick={handleSubmit}
          >
            {" "}
            <a className="submitbuttontext">Submit</a>
          </button>
        </div>
      );
    }
  };
  console.log(values);
  //console.log(educationProfileValues)

  function ErrorAvailable() {
    console.log("it is", formErrors.length);
    if (formErrors) {
      return (
        <span className="errormessage">Please fill all forms correctly</span>
      );
    }
  }
  return (
    <>
      <div className="personal-profile">
        <div className="body14">
          <div className="header13">
            <b className="scholist-profile2">{`Scholist Profile `}</b>
          </div>
          <div className="selection2">
            <div className="frame-parent20">
              <button
                className={
                  formType == "personalprofileform"
                    ? "personal-information-frame"
                    : "education-frame"
                } /* onClick={()=>setFormType('personalprofileform')} */
              >
                <div
                  className={
                    formType == "personalprofileform"
                      ? "personal-information2"
                      : "education4"
                  }
                >
                  Personal Information
                </div>
              </button>
              <button
                className={
                  formType == "educationform"
                    ? "personal-information-frame"
                    : "education-frame"
                }
              >
                <div
                  className={
                    formType == "educationform"
                      ? "personal-information2"
                      : "education4"
                  }
                >
                  Education
                </div>
              </button>
              <button
                className={
                  formType == "studentactivitiesform"
                    ? "personal-information-frame"
                    : "education-frame"
                } /* onClick={()=>setFormType('studentactivitiesform')} */
              >
                <div
                  className={
                    formType == "studentactivitiesform"
                      ? "personal-information2"
                      : "education4"
                  }
                >
                  Student Activities
                </div>
              </button>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            {TypeOfForm()}
            <div className="submitbuttonbody">
              {TypeOfButton()}
              {/* {ErrorAvailable()} */}
            </div>
          </form>

          {/* <div className="exit-button1">
              <div className="frame-parent21">
                <button className="exit-frame" type="Submit">
                  <div className="exit2">Exit</div>
                </button>
                <button
                  className="vector-wrapper1"
                  onClick={onFrameButton4Click}
                >
                  <img className="vector-icon99" alt="" src="/vector34.svg" />
                </button>
              </div>
            </div> */}
        </div>
      </div>
    </>
  );
};

export default Profile;
