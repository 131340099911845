import "./EditPassword.css";
const EditPassword = ({ onClose }) => {
  return (
    <div className="edit-password">
      <div className="editpassword">
        <div className="editpasswordexiticon">
          <div className="editpasswordexiticon2" />
          <div
            className="editpasswordexiticon1"
            style={{ cursor: "pointer" }}
            onClick={onClose}
          >
            +
          </div>
        </div>
      </div>
      <div className="editpasswordmainbody">
        <div className="editpasswordgroup">
          <div className="editpasswordchangbody">
            <div className="editpasswordcurrentpassword">
              <div className="editpasswordnewpassword">Current Password</div>
              <div className="editpasswordcurrentpasswordbox">
                <div className="editpasswordcurrentpasswordbox1">
                  <input
                    className="editpasswordcurrentpasswordbox2"
                    type="text"
                    placeholder="***********"
                  />
                  <img
                    className="editpasswordcurrentpasswordbox-icon"
                    alt=""
                    src="/editpasswordcurrentpasswordboxeye.svg"
                  />
                </div>
              </div>
            </div>
            <div className="editpasswordcurrentpassword">
              <div className="editpasswordnewpassword">New Password</div>
              <div className="editpasswordcurrentpasswordbox">
                <div className="editpasswordcurrentpasswordbox1">
                  <input
                    className="editpasswordcurrentpasswordbox2"
                    type="text"
                    placeholder="***********"
                  />
                  <img
                    className="editpasswordcurrentpasswordbox-icon"
                    alt=""
                    src="/editpasswordcurrentpasswordboxeye.svg"
                  />
                </div>
              </div>
            </div>
            <div className="editpasswordcurrentpassword">
              <div className="editpasswordnewpassword">Confirm Password</div>
              <div className="editpasswordcurrentpasswordbox">
                <div className="editpasswordcurrentpasswordbox1">
                  <input
                    className="editpasswordcurrentpasswordbox2"
                    type="text"
                    placeholder="***********"
                  />
                  <img
                    className="editpasswordcurrentpasswordbox-icon"
                    alt=""
                    src="/editpasswordcurrentpasswordboxeye.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="editpasswordchangebutton">
            <div
              className="editpasswordchangebuttontext"
              style={{ cursor: "pointer" }}
            >
              Change Password
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPassword;
