import { useState, useCallback, useEffect } from "react";
import EditPassword from "../components/EditPassword";
import PortalPopup from "../components/PortalPopup";
import "./Settings.css";
import axios from "axios";
import { BASE_Server_URL } from "../requestMethods";
const Settings = () => {
  const [isEditPasswordPopupOpen, setEditPasswordPopupOpen] = useState(false);

  const openEditPasswordPopup = useCallback(() => {
    setEditPasswordPopupOpen(true);
  }, []);

  const closeEditPasswordPopup = useCallback(() => {
    setEditPasswordPopupOpen(false);
  }, []);

  const [type, setType] = useState("security");
  const [emailEditing, setEmailEditing] = useState(false);
  const [passwordEditing, setPassordEditing] = useState(false);
  const [profile, setProfile] = useState({});

  const viewUserProfile = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });

      const res = await client.get("/profile");
      console.log("res", res);
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    viewUserProfile()
      .then((result) => {
        setProfile(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const emailUpdateUser = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL, // Use an environment variable
        headers: { Authorization: `Bearer ${TOKEN}` },
      });

      const res = await client.post("/support", {
        type: supportType,
        question: supportQuestion,
      });

      console.log("email updated:", res);

      // Handle success or show a success message to the user
    } catch (error) {
      // Handle specific error types and show appropriate messages
      console.error("Error submitting support:", error);
      // You might want to set an error state here to display a message to the user
    }
  };

  const emailUpdateProfile = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL, // Use an environment variable
        headers: { Authorization: `Bearer ${TOKEN}` },
      });

      const res = await client.post("/support", {
        type: supportType,
        question: supportQuestion,
      });

      console.log("email updated:", res);

      // Handle success or show a success message to the user
    } catch (error) {
      // Handle specific error types and show appropriate messages
      console.error("Error submitting support:", error);
      // You might want to set an error state here to display a message to the user
    }
  };

  return (
    <>
      <div className="settings">
        <div className="settingsbox">
          <div className="settingsbox1">
            <div className="settingbox2">Settings</div>
          </div>
          <div className="settingdetailsbox">
            <div className="settingdetailsbox1">
              {type === "security" ? (
                <>
                  {emailEditing ? (
                    <div className="settingsdetailsemail">
                      <div className="settingsdetailsemailbox">
                        <div className="settingsdetailsemailheader">
                          Email Address
                        </div>
                        <div className="settingsdetailsemailbox12">
                          <input
                            className="settingsdetailsemailtext2"
                            type="text"
                            placeholder="abidemiawojuyigbe@gmail.com"
                          />
                          <div className="settingsdetailsemailheader">
                            Save Changes
                          </div>
                        </div>
                        <div className="settingsdetailsemailline" />
                      </div>
                    </div>
                  ) : (
                    <div className="settingsdetailsemail">
                      <div className="settingsdetailsemailbox">
                        <div className="settingsdetailsemailheader">
                          Email Address
                        </div>
                        <div className="settingsdetailsemailbox1">
                          <div className="settingsdetailsemailtext">
                            {profile.email}
                          </div>
                          {/* <div
                            className="settingsdetailsemailedit"
                            onClick={() => setEmailEditing(true)}
                          >
                            Edit
                          </div> */}
                        </div>
                        <div className="settingsdetailsemailline" />
                      </div>
                    </div>
                  )}

                  {passwordEditing ? (
                    <div className="settingsdetailsemail">
                      <div className="settingsdetailsemailbox">
                        <div className="settingsdetailsemailheader">
                          Password
                        </div>
                        <div className="settingsdetailspasswordbox2">
                          {/* <div className="settingsdetailspasswordtext">
                            <input placeholder=""></input>
                          </div> */}
                          <button
                            className="settingsdetailspasswordbutton"
                            onClick={openEditPasswordPopup}
                          >
                            Change Password
                          </button>
                        </div>
                        <div className="settingsdetailsemailline" />
                      </div>
                    </div>
                  ) : (
                    <div className="settingsdetailsemail">
                      <div className="settingsdetailspassword1box1">
                        <div className="settingsdetailsemailheader">
                          Password
                        </div>
                        <div className="settingsdetailspassword1box11">
                          <div className="settingsdetailspassword1box2">
                            <div className="settingsdetailspasswordtext">
                              {profile.password}
                            </div>
                            <div className="settingsdetailspasswordhideedi">
                              {/* <div className="settingsdetailsemailheader">
                                Hide
                              </div> */}
                              <div
                                className="settingsdetailsemailheader"
                                style={{ cursor: "pointer" }}
                                onClick={() => setPassordEditing(true)}
                              >
                                Edit
                              </div>
                            </div>
                          </div>
                          <div className="settingsdetailsemailline" />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : type === "deactivate" ? (
                <div className="settingsdetailsemail">
                  <div className="settingsdetailsemailbox">
                    <div className="settingsdetailsemailheader">Deactivate</div>
                    <div className="settingsdetailsdeactivatebox1">
                      <div className="settingsdetailspasswordtext">
                        Delete Account
                      </div>
                      <div className="settingsdetailsdeactivateboxic">
                        <div className="settingsdetailsdeactivateboxic-child" />
                        <div className="settingsdetailsdeactivateboxic-item" />
                      </div>
                    </div>
                    <div className="settingsdetailsemailline" />
                  </div>
                </div>
              ) : (
                type === "notification" && (
                  <div className="settingsdetailsemail">
                    <div className="settingsdetailsemailbox">
                      <div className="settingsdetailsemailheader">
                        Notification
                      </div>
                      <div className="settingsdetailsdeactivatebox1">
                        <div className="settingsdetailsdeactivatebox2t">On</div>
                        <div className="settingsdetailsdeactivateboxic">
                          <div className="settingsdetailsdeactivateboxic-child" />
                          <div className="settingsdetailsdeactivateboxic-item" />
                        </div>
                      </div>
                      <div className="settingsdetailsemailline" />
                    </div>
                  </div>
                )
              )}
            </div>

            <div className="settingsdetailsheader">
              <div className="settingsdetailsheader1">
                {/* <div className="settingsdetailsheadersecurity">

                 
                 <div className="settingsdetailsheadersecurity-child" />
                </div> */}
                <div
                  className="settingsdetailsheadersecurityt"
                  style={{ cursor: "pointer" }}
                  onClick={() => setType("security")}
                >
                  <span
                    style={{
                      color: type === "security" ? "blue" : "black",
                      textDecoration:
                        type === "security" ? "underline" : "none",
                    }}
                  >
                    {`Security & Login`}
                  </span>
                </div>

                <div
                  className="settingsdetailsheadersecurityt"
                  style={{ cursor: "pointer" }}
                  onClick={() => setType("deactivate")}
                >
                  <span
                    style={{
                      color: type === "deactivate" ? "blue" : "black",
                      textDecoration:
                        type === "deactivate" ? "underline" : "none",
                    }}
                  >{`Deactivate `}</span>
                </div>

                <div
                  className="settingsdetailsheadersecurityt"
                  style={{ cursor: "pointer" }}
                  onClick={() => setType("notification")}
                >
                  <span
                    style={{
                      color: type === "notification" ? "blue" : "black",
                      textDecoration:
                        type === "notification" ? "underline" : "none",
                    }}
                  >
                    Notification
                  </span>
                </div>
              </div>
              <div className="settingsdetailsheaderline" />
            </div>
          </div>
        </div>
      </div>
      {isEditPasswordPopupOpen && (
        <PortalPopup
          overlayColor="rgba(1, 1, 1, 0.7)"
          placement="Centered"
          onOutsideClick={closeEditPasswordPopup}
        >
          <EditPassword onClose={closeEditPasswordPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default Settings;
