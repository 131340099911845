import React from "react";
import { GoogleLogin } from "@react-oauth/google";

function GoogleSignUpButton() {
  const responseGoogle = (response) => {
    console.log(response);
    // Handle Google response here
  };

  return (
    <GoogleLogin
      onSuccess={(credentialResponse) => {
        console.log(credentialResponse);
      }}
      onError={() => {
        console.log("Login Failed");
      }}
    />
  );
}

export default GoogleSignUpButton;
