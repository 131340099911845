import { Navigate } from "react-router-dom";
import { publicRequest, userRequest } from "../requestMethods";
import {
  getCoachBookmarkFailure,
  getCoachBookmarkStart,
  getCoachBookmarkSuccess,
} from "./bookmarkRedux";
import {
  clearProfileFailure,
  clearProfileStart,
  clearProfileSuccess,
  createProfileFailure,
  createProfileStart,
  createProfileSuccess,
  getProfileFailure,
  getProfileStart,
  getProfileSuccess,
  updateProfileFailure,
  updateProfileStart,
  updateProfileSuccess,
} from "./userProfileRedux";
import {
  loginFailure,
  loginStart,
  loginSuccess,
  signUpStart,
  signUpFailure,
  signUpSuccess,
  logoutStart,
  logoutSuccess,
  logoutFailure,
  clearErrorStart,
  clearErrorSuccess,
  resetIsFetching,
} from "./userRedux";

// export const login = async (dispatch, user) =>{
//     dispatch(loginStart());
//     try {
//         const res = await publicRequest.post("/auth/login", user)
//         console.log(res)
//         dispatch(loginSuccess(res.data))

//     } catch (error) {
//         dispatch(loginFailure());
//     }
// }

export const login = async (user) => {
  try {
    const res = await publicRequest.post("/auth/login", user);
    return res.data;
  } catch (error) {
    throw error;
  }
};

// export const signUp= async (dispatch, user) =>{
//     dispatch(signUpStart());
//     try {
//         const res = await publicRequest.post("/auth/register", user)
//         dispatch(signUpSuccess(res.data))
//         Navigate('/welcome')

//     } catch (error) {
//         //console.log(error)
//         dispatch(signUpFailure());
//     }
// }

export const mailChimp = async (email) => {
  try {
    const res = await publicRequest.post("/subscribe", email);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const signUp = async (user) => {
  try {
    const res = await publicRequest.post("/auth/register", user);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const clearErrorAction = () => (dispatch) => {
  dispatch(clearErrorStart());

  dispatch(clearErrorSuccess({ message: "", code: "" }));

  // console.log('gfdfgh',error)
  // dispatch(clearErrorFailure({ message: 'error.message', code: '' }));
};

export const clearIsFetching = () => (dispatch) => {
  dispatch(resetIsFetching());

  //dispatch(clearErrorSuccess({ message: '', code: '' }));

  // console.log('gfdfgh',error)
  // dispatch(clearErrorFailure({ message: 'error.message', code: '' }));
};

export const viewUserProfile = async (dispatch) => {
  // console.log("start of something", userRequest);
  dispatch(getProfileStart());
  try {
    const res = await userRequest.get("/profile");
    // console.log("res", res);
    dispatch(getProfileSuccess(res.data));
  } catch (error) {
    console.log(error);
    dispatch(getProfileFailure());
  }
};

export const logout = async (dispatch, refreshToken) => {
  dispatch(clearProfileStart());
  dispatch(logoutStart());

  console.log(refreshToken);

  try {
    const res = await publicRequest.post("/auth/logout", refreshToken);
    // console.log(res);
    await dispatch(clearProfileSuccess());
    await dispatch(logoutSuccess());
  } catch (error) {
    console.log(error);
    dispatch(clearProfileFailure());
    dispatch(logoutFailure());
  }
};

export const createUserProfile = async (dispatch) => {
  //console.log('start of something', JSON.stringify(userRequest))
  dispatch(createProfileStart());
  try {
    const res = await userRequest.post("/profile");
    // console.log(res);
    dispatch(createProfileSuccess(res.data));
  } catch (error) {
    // console.log(error)
    dispatch(createProfileFailure());
  }
};

export const updateUserProfile = async (dispatch, profile) => {
  //console.log('start of something', JSON.stringify(userRequest))
  dispatch(updateProfileStart());
  try {
    const res = await userRequest.put("/profile", profile);
    // console.log(res);
    dispatch(updateProfileSuccess(res.data));
  } catch (error) {
    // console.log(error)
    dispatch(updateProfileFailure());
  }
};

export const viewCoachBookmark = async (dispatch) => {
  //console.log('start of something', JSON.stringify(userRequest))
  dispatch(getCoachBookmarkStart());
  try {
    const res = await userRequest.get("/coachbookmark");
    // console.log(res);
    dispatch(getCoachBookmarkSuccess(res.data));
  } catch (error) {
    // console.log(error)
    dispatch(getCoachBookmarkFailure());
  }
};
