import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./EMAILSIGNUP.css";
import { clearErrorAction, signUp } from "../redux/apiCalls";
import { signUpFailure, signUpStart, signUpSuccess } from "../redux/userRedux";
import CoachMailchimpSubscribe from "../components/NewsletterCoach";

const CoachSignUpMobile = () => {
  const navigate = useNavigate();
  const [signUpWithEmail, setSignUpWithEmail] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submittedEmail, setSubmittedEmail] = useState(null);
  const [submittedFName, setSubmittedFName] = useState(null);
  const [submittedLName, setSubmittedLName] = useState(null);
  const dispatch = useDispatch();

  const { isFetching, error } = useSelector((state) => state.user);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [values, setValues] = useState({});
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (error && error.message) {
      return () => {
        clearErrorAction()(dispatch);
      };
    }
  }, [error, dispatch]);

  function isValidEmail(email) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  const validateForm = (values) => {
    const errorsF = {};
    if (!values.firstName) {
      errorsF.firstName = "Please fill in the form!";
    }
    if (!values.lastName) {
      errorsF.lastName = "Please fill in the form!";
    }
    if (!values.email) {
      errorsF.email = "Please fill in the form!";
    } else if (!isValidEmail(values.email)) {
      errorsF.email = "Not a valid email!";
    }
    if (!values.password) {
      errorsF.password = "Please fill in the form!";
    }
    return errorsF;
  };

  useEffect(() => {
    if (isSubmitting) {
      const errorsF = validateForm(values);
      setFormErrors(errorsF);
      if (Object.keys(errorsF).length === 0) {
        dispatch(signUpStart());
        const { firstName, lastName, email, password } = values;
        const user = {
          name: `${firstName} ${lastName}`,
          email,
          password,
          role: "coach",
        };

        const signUpUser = async () => {
          try {
            const data = await signUp(user);
            dispatch(signUpSuccess(data));
            setIsSubmitting(false);
            navigate("/profilefill");
          } catch (error) {
            setIsSubmitting(false);
            dispatch(
              signUpFailure({
                message: error.response
                  ? error.response.data.message
                  : "An error occurred",
                code: error.response ? error.response.status : 500,
              })
            );
          }
        };

        signUpUser();
      } else {
        setIsSubmitting(false);
      }
    }
  }, [isSubmitting, values, dispatch, navigate]);

  // Use useEffect to log submittedEmail once it updates
  useEffect(() => {
    console.log("submittedEmail updated:", submittedEmail);
    console.log("submittedFName updated:", submittedFName);
    console.log("submittedLName updated:", submittedLName);
  }, [submittedEmail, submittedFName, submittedLName]);

  const handleClick = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(values));
    if (Object.keys(formErrors).length === 0) {
      setSubmittedFName(values.firstName);
      setSubmittedLName(values.lastName);
      setSubmittedEmail(values.email); // Set submittedEmail before setting isSubmitting
      setIsSubmitting(true);
    }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div className="signup">
      <div className="onboardingbox11">
        <div className="rightonboarding11">
          <div className="rightonboardingcreateaccount">
            <div className="rightonboardingcreateaccountbo">
              <div className="create-your-account">Create your account</div>
            </div>
            <div className="rightonboardingfirstname">
              <input
                className="rightonboardingcreateaccountfi"
                type="text"
                placeholder="First Name"
                onChange={onChange}
                value={values.firstName}
                required="required"
                name="firstName"
              />
              <span className="errormessage">{formErrors.firstName}</span>
            </div>
            <div className="rightonboardinglastname">
              <input
                className="rightonboardingcreateaccountfi"
                type="text"
                placeholder="Last Name"
                onChange={onChange}
                value={values.lastName}
                required="required"
                name="lastName"
              />
              <span className="errormessage">{formErrors.lastName}</span>
            </div>
            <div className="rightonboardingemail1">
              <input
                className="rightonboardingcreateaccountfi"
                type="text"
                placeholder="E-mail"
                onChange={onChange}
                value={values.email}
                required="required"
                name="email"
              />
              <span className="errormessage">{formErrors.email}</span>
            </div>
            <div className="rightonboardingpassword1">
              <input
                className="rightonboardingcreateaccountfi"
                type="password"
                placeholder="Create a password"
                onChange={onChange}
                value={values.password}
                required="required"
                name="password"
              />
              <span className="errormessage">{formErrors.password}</span>
            </div>
            <div className="rightonboardingsignupbuttonbox">
              <button
                className="rightonboardingsignupbutton"
                onClick={handleClick}
              >
                <div className="sign-up">Sign up</div>
              </button>
            </div>
            {error && <b className="signuperror">{error.message}</b>}
            <div className="rightonboardingtermsbox">
              <div className="rightonboardingtermsbox1">
                <div className="by-submitting-this-container1">
                  <span>
                    {`By submitting this form, you confirm that you have read and agreed to our`}{" "}
                  </span>
                  <span className="terms-conditions1">
                    <a
                      href="https://scholistapp.notion.site/Scholist-Terms-of-Service-5c12bc657bbb4796a8c90a4e62146513"
                      className="log-in"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Service
                    </a>{" "}
                    and{" "}
                  </span>
                  <span>
                    <a
                      href="https://scholistapp.notion.site/Scholist-Privacy-Policy-137c666c50f745fa8676b3083448899f?pvs=25"
                      className="log-in"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </span>
                </div>
              </div>
            </div>
            {submittedEmail && submittedEmail && submittedLName && (
              <CoachMailchimpSubscribe
                email={submittedEmail}
                firstName={submittedFName}
                lastName={submittedLName}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachSignUpMobile;
