import React, { useState } from 'react'

export const Faq = ({question, answer}) => {
    const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

    const toggleDescription = () => {
      setIsDescriptionVisible(!isDescriptionVisible);
    };
  return (
    <div className="faqcard" onClick={toggleDescription}>
    <div className="faqcardbox">
      <div className="faqcardbox1">
        
        {
          isDescriptionVisible ? <div className="faqcardicon" /> : 
          <svg xmlns="http://www.w3.org/2000/svg" className="faqcardiconPlus" viewBox="0 0 96 96" fill="none">
          <g clip-path="url(#clip0_1617_36274)">
            <path d="M76 52H52V76H44V52H20V44H44V20H52V44H76V52Z" className="faqcardiconPlus" fill="black"/>
          </g>
          <defs>
            <clipPath id="clip0_1617_36274">
              <rect width="96" height="96" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        }
        
        <div className="faqcardheader" >
          {question}
        </div>
      </div>
      <div className="faqcarddescriptioncardbox2" style={{ display: isDescriptionVisible ? 'block' : 'none' }}>
        {answer}
        </div>
    </div>
  </div>

  )
}
