import "./SavedScholarshipNotification.css";

function getSubstringForLogo(string, char1, char2) {

  console.log(string)
  if(string !== ''){
    
  if(string.split(".").length - 1 > 1) {
    const first = string.indexOf(char1) + 1
    const link = string.slice(
      string.indexOf(char1) + 1,
      //string.lastIndexOf(char2),
      string.indexOf(char2, first + 1) + 4
    );
    //console.log(link)

    return(link.charAt(0).toUpperCase() + link.slice(1))
  }

  else{

    //console.log(string)
    const firstSlash = string.indexOf("/") + 1
    //console.log(string.indexOf("/", firstSlash + 1))
    // console.log(  string.indexOf('/') + 1)
    // console.log( string.indexOf(char1) + 1)
    const link = string.slice(
      // string.indexOf(char1) + 1,
       //string.lastIndexOf(char2),
       //string.indexOf("/", firstSlash + 1),
       string.indexOf('/') + 2,
       string.indexOf(char1) +4
 
 
 
     );
     //console.log(link)
     
     return (link.charAt(0).toUpperCase() + link.slice(1));
    //return ()
  }
  }

  //console.log(string.split(".").length - 1)

  //count number of dot in string
  //get word between second slash

}

function ScholarshipName(scholarship){
  //{elem.name.split(' ').slice(0,3).join(' ') + ' '+ elem.awardType}

  if(scholarship){
    const elem = scholarship.name.split(' ')
    //console.log(elem)
    if(elem[2] == 'Scholarship' || elem[2] == 'Grant'  || elem[2] == 'Fellowship'   || elem[2] == 'Loan'  ){
      return(scholarship.name.split(' ').slice(0,2).join(' '))
    }
  
    if(elem[1] == 'Scholarship' || elem[1] == 'Grant'  || elem[1] == 'Fellowship'   || elem[1 ] == 'Loan'  ){
      return(scholarship.name.split(' ').slice(0,2).join(' '))
    }
    else{
      return(scholarship.name.split(' ').slice(0,2).join(' ') + ' '+ scholarship.awardType)
    }
  }


}


function extractScholarshipInfo(scholarshipName) {
  const awardTypes = /(scholarship|grant|loan|fellowship)/i;
  const match = scholarshipName.match(awardTypes);

  if (match) {
    const words = scholarshipName.split(' ');
    const awardIndex = words.findIndex(word => word.toLowerCase() === match[1].toLowerCase());

    if (awardIndex > 0) {
      if (awardIndex >= 2) {
        return `${words.slice(0, 2).join(' ')} ${words[awardIndex]}`;
      } else {
        return `${words[0]} ${words[awardIndex]}`;
      }
    }
  }

  return scholarshipName.split(' ').slice(0, 2).join(' ');
}
const SavedScholarshipNotification = (props) => 
{
  console.log('props nm', props.scholarship.link)
  return (
    <div className="savedscholarshipnotification">
      <div className="savedscholarshipnotificationbo">
        <div className="leftsavedscholarshipnotificati">
          <img
            className="leftsavedscholarshipnotimgbox-icon"
            alt=""
           // src="/cardscholarshipsimage@2x.png"
            src={"https://logo.clearbit.com/"+getSubstringForLogo(props.scholarship.link, '.', '.')+"/"}
          />
          <b className="be-bold-no-essay">{extractScholarshipInfo(props.scholarship.name)}</b>
        </div>
        <div className="rightsavedscholarshipnotbox">
          <b className="rightsavedscholarshipnotboxsav">Saved</b>
          {/* <img
            className="rightsavedscholarshipnoticon"
            alt=""
            src="/component-2.svg"
          /> */}
          <svg  className="rightsavedscholarshipnoticon" xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 27 25" fill="none">
  <path d="M13.7553 23.9454C14.0634 23.9454 14.513 23.7109 14.8832 23.4821C21.6301 19.1317 25.9617 14.0335 25.9617 8.87035C25.9617 4.425 22.8925 1.34082 19.0415 1.34082C16.6437 1.34082 14.8419 2.66551 13.7553 4.65074C12.6885 2.67542 10.8647 1.34082 8.46907 1.34082C4.61801 1.34082 1.54883 4.425 1.54883 8.87035C1.54883 14.0335 5.88036 19.1317 12.6372 23.4821C12.9975 23.7109 13.445 23.9454 13.7553 23.9454Z" fill="#ED5043" stroke="#ED5043" stroke-width="1.6183" stroke-linejoin="round"/>
</svg>
        </div>
      </div>
    </div>
  );
};

export default SavedScholarshipNotification;
