import { useState, useEffect, useCallback, useRef  } from "react";
import CoachFilters from "../components/CoachFilters";
import PortalDrawer from "../components/PortalDrawer";
import { Link, useNavigate } from "react-router-dom";
import "./Services.css";

import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import "antd/dist/antd.min.css";

import { Checkbox as AntCheckbox } from "antd";
import CoachDetails from "../components/CoachDetails";


import { Button as MuiButton } from "@mui/material";
import { Input } from "antd";
import { Coaches } from "../components/Coaches";
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import FormControl from '@mui/material/FormControl';
import Modal from '@mui/material/Modal';

import axios from "axios";

import "./CoachBookmark.css";
import debounce from 'lodash.debounce';
import Box from '@mui/material/Box';


import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Avatar from '@mui/material/Avatar';
import { ReviewNumber } from "../components/ReviewNumber";
import { useSelector } from "react-redux";
import CoachCard from "../components/CoachCard";
import { BASE_Server_URL } from "../requestMethods";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress  sx={{
                  backgroundColor: 'rgb(217 214 214)',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: 'black'
                  }
                }}variant="determinate" {...props} />
      </Box>
      {/* <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box> */}
    </Box>
  );
}


const CoachBookmark = () => {

 


const [coachLoading, setCoachloading] = useState(false)
const [coachBookmarks, setCoachBookmarks] = useState([]);
const [loadValue, setLoadValue] = useState(4);

const user = useSelector(state => state.user.currentUser)


 
const getCoachBookmarks = async () =>{
  try {

    setCoachloading(true)

   // console.log('get coach bookmarks')
    const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
    const currentUser = user && JSON.parse(user).currentUser;
    const TOKEN = currentUser?.tokens.access.token;

//console.log(currentUser)
     const client = axios.create({
      baseURL: BASE_Server_URL,
      headers: { Authorization: `Bearer ${TOKEN}` },
  });
      const res = await client.get('/coachbookmark')
      console.log('coach bookmarks',res.data)
      setCoachBookmarks(res.data)

      setCoachloading(false)
      
     


  } catch (error) {
      console.log(error)
  }
};
 
useEffect(() => {

  getCoachBookmarks()
},[])


 

const handleCoachBookmark = async(event, data) =>{
  //console.log(data)
  event.preventDefault();
  try {


    const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
const currentUser = user && JSON.parse(user).currentUser;
const TOKEN = currentUser?.tokens.access.token;

//console.log(currentUser)
const client = axios.create({
baseURL: BASE_Server_URL,
headers: { Authorization: `Bearer ${TOKEN}` },
});
    const res =  await client.post('/coachbookmark', {
      img: data.img,
      coachId: data.id,
      name: data.name,
      services: data.services,
      rating: data.rating.$numberDecimal
    })
    console.log(res)
    getCoachBookmarks()


    setCoachNotDetail({
      img: data.img,
      coachId: data.id,
      name: data.name,
      services: data.services,
      rating: data.rating.$numberDecimal
      
    })
  } catch (error) {
     console.log(error)
  }
 
}

const deleteCoachBookmark = async(event, id) =>{
  console.log('delete',event)
  console.log(id)
  event.preventDefault();
  
  
  
  try {
  
    const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
  const currentUser = user && JSON.parse(user).currentUser;
  const TOKEN = currentUser?.tokens.access.token;
  
  //console.log(currentUser)
  const client = axios.create({
  baseURL:BASE_Server_URL,
  headers: { Authorization: `Bearer ${TOKEN}` },
  });
    const res =  await client.delete('/coachbookmark/'+id)
    console.log('delete', res)
    getCoachBookmarks()
  } catch (error) {
     console.log(error)
  }
  
  }
  
    function BookmarkChecker(Id, elem) {
      //console.log(coachBookmarks)
      // const filteredResult = coachBookmarks.filter((item) => {
      //   return (item.coachId.indexOf(coachId) > 0);
  
     
  
    //});
    const isObjectPresent = coachBookmarks.find((o) => o.coachId === Id);
    if(isObjectPresent) {
      //console.log(isObjectPresent)
      return(
      // <BookmarkIcon  onClick={ (e)=>deleteCoachBookmark(e, elem.id)}/>
    //   <svg xmlns="http://www.w3.org/2000/svg" className="cardicon-bookmark10" width="19" height="24" viewBox="0 0 19 24" fill="none" onClick={ (e)=>handleCoachBookmark(e, elem)} >
    //   <path d="M15.8438 0H3.15625C2.55537 0 1.9791 0.238699 1.55421 0.663586C1.12932 1.08847 0.890625 1.66474 0.890625 2.26562V22.2031C0.890544 22.4458 0.955437 22.6841 1.07857 22.8932C1.20169 23.1024 1.37857 23.2748 1.59082 23.3924C1.80307 23.5101 2.04296 23.5688 2.28557 23.5625C2.52818 23.5561 2.76467 23.485 2.97047 23.3563L9.49887 19.2782L16.0295 23.3563C16.2353 23.485 16.4718 23.5561 16.7144 23.5625C16.957 23.5688 17.1969 23.5101 17.4092 23.3924C17.6214 23.2748 17.7983 23.1024 17.9214 22.8932C18.0446 22.6841 18.1095 22.4458 18.1094 22.2031V2.26562C18.1094 1.66474 17.8707 1.08847 17.4458 0.663586C17.0209 0.238699 16.4446 0 15.8438 0ZM10.2193 16.5187C10.0033 16.3836 9.75364 16.312 9.49887 16.312C9.24409 16.312 8.99445 16.3836 8.7784 16.5187L3.60938 19.7506V17.5189V2.71875H8.7784H15.3906V17.5189V19.7506L10.2193 16.5187Z" fill="#393939"/>
    // </svg>
  
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24" viewBox="0 0 19 24" fill="none" onClick={ (e)=>deleteCoachBookmark(e, elem.id)}>
        <path d="M15.8438 0.191406H3.15625C2.55537 0.191406 1.9791 0.430105 1.55421 0.854992C1.12932 1.27988 0.890625 1.85615 0.890625 2.45703V22.3945C0.890544 22.6372 0.955437 22.8755 1.07857 23.0847C1.20169 23.2938 1.37857 23.4662 1.59082 23.5838C1.80307 23.7015 2.04296 23.7602 2.28557 23.7539C2.52818 23.7475 2.76466 23.6764 2.97047 23.5477L9.49887 19.4696L16.0295 23.5477C16.2353 23.6764 16.4718 23.7475 16.7144 23.7539C16.957 23.7602 17.1969 23.7015 17.4092 23.5838C17.6214 23.4662 17.7983 23.2938 17.9214 23.0847C18.0446 22.8755 18.1095 22.6372 18.1094 22.3945V2.45703C18.1094 1.85615 17.8707 1.27988 17.4458 0.854992C17.0209 0.430105 16.4446 0.191406 15.8438 0.191406ZM10.6879 11.5654L15.3906 14.5045L10.2193 11.2726C10.0033 11.1375 9.75364 11.0659 9.49887 11.0659C9.24409 11.0659 8.99445 11.1375 8.7784 11.2726L3.60938 14.5045L7.51033 12.0654L9.89062 11.5654L10.6879 11.5654ZM10.2193 16.7101C10.0033 16.575 9.75364 16.5034 9.49887 16.5034C9.24409 16.5034 8.99445 16.575 8.7784 16.7101L3.60938 19.942L6.19389 18.326L9.49887 17.7104L12.8906 18.3796L15.3906 19.942L10.2193 16.7101Z" fill="#FE4A49"/>
         </svg>
    
  
  
      )
      
    }
    else{
      return(
        // <BookmarkBorderIcon  onClick={ (e)=>handleCoachBookmark(e, elem)}/>
      
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24" viewBox="0 0 19 24" fill="none"  onClick={ (e)=>handleCoachBookmark(e, elem)}>
        <path d="M15.8438 0.191406H3.15625C2.55537 0.191406 1.9791 0.430105 1.55421 0.854992C1.12932 1.27988 0.890625 1.85615 0.890625 2.45703V22.3945C0.890544 22.6372 0.955437 22.8755 1.07857 23.0847C1.20169 23.2938 1.37857 23.4662 1.59082 23.5838C1.80307 23.7015 2.04296 23.7602 2.28557 23.7539C2.52818 23.7475 2.76467 23.6764 2.97047 23.5477L9.49887 19.4696L16.0295 23.5477C16.2353 23.6764 16.4718 23.7475 16.7144 23.7539C16.957 23.7602 17.1969 23.7015 17.4092 23.5838C17.6214 23.4662 17.7983 23.2938 17.9214 23.0847C18.0446 22.8755 18.1095 22.6372 18.1094 22.3945V2.45703C18.1094 1.85615 17.8707 1.27988 17.4458 0.854992C17.0209 0.430105 16.4446 0.191406 15.8438 0.191406ZM10.2193 16.7101C10.0033 16.575 9.75364 16.5034 9.49887 16.5034C9.24409 16.5034 8.99445 16.575 8.7784 16.7101L3.60938 19.942V17.7103V2.91016H8.7784H15.3906V17.7103V19.942L10.2193 16.7101Z" fill="#393939"/>
      </svg>
     
      )
  
    }
    }


    function LoadMore(){
      //setSearchLoading(true)
      if(loadValue < coachBookmarks.length){
        setLoadValue(loadValue + 8)
      }
      if(loadValue > coachBookmarks.length){
        setLoadValue(coachBookmarks.length)
        console.log('this')
      }
    
      
      if(loadValue + 8 > coachBookmarks.length){
        setLoadValue(coachBookmarks.length)
      }
    
      // if(!user && loadValue + 8 > 20){
      //   console.log('Pls sign UPdcec')
      // }
      else{
        console.log('No more data to display')
      }
      //setSearchLoading(false)
    }
    


  return (
    <>

<div className="bookmarkHeader-dash">
<div className="back-to-services"><Link className='link' to={`/dashboard`}>{`< Dashboard`}</Link></div>
    </div>

    {/* <div className="bookmarkHeader-Coach">
      <span className="bookmarkHeader-text">Bookmarked Coaches</span>
    </div> */}

 

      <div className="services1">
      <div className="mybookingsupcoming">
          <div className="mybookingsupcomingheader">
            <b className="mybookingsupcomingheadertext">Bookmarked Coaches</b>
          </div>
          <div className="mybookingsupcomingsubheader">
            <div className="mybookingsupcomingsubheadertex">
              <span className="saved-scholarships-matches">You have {coachBookmarks.length}</span>
              <b>{` `}</b>
              <span className="saved-scholarships-matches">
                bookmarked coaches
              </span>
            </div>
          </div>
          </div>


          
     
      
            {/* <ServiceOptionButton/> */}
            {/* <Coaches serviceOffered={serviceOffered} filters = {filters} phonefilter = {filterFromPhone} filterCoachDetails= {filterCoachDetails} filterLanguages ={filterLanguages} budgetMin={budgetMin} budgetMax={budgetMax} sort={sort} filterLocations={filterLocations}/> */}


            
        <div className="coachesBookmarkContainer">

{
  coachLoading ? (
    <CircularProgress/>
  ):(


    coachBookmarks.slice(0, loadValue).map(elem => (
    
    <CoachCard id = {elem.coachId} getCoachBookmarksParent = {getCoachBookmarks}/>
    ))
  )
}

</div> 


<div className="seemore-progress-body">
<div className="see-more-progress-bar11">
  <div className="progress-body">
  <div className="div58">{loadValue > coachBookmarks.length ? coachBookmarks.length : loadValue} / {coachBookmarks.length}</div>
  {/* <img
    className="progress-bar-icon6"
    alt=""
    src="/progress-bar.svg"
  /> */}


  </div>
  <Box sx={{ width: '100%' }}>
    <LinearProgressWithLabel value={loadValue/coachBookmarks.length * 100} />
  </Box>
  <div>

</div>


  <div className="buttonlarge30">
   
    <button className="button-seemore" onClick={() => !user && loadValue > 20 ? console.log('pls sign updnmm') : LoadMore()} disabled={loadValue == coachBookmarks.length}>See More</button>
  </div>
 
</div>
</div>
     
        




      









{/* 
          </div>
        </div> */}
        {/* <div className="see-more-progress-bar-container">
          <div className="see-more-progress-bar11">
            <div className="div58">24 / 785</div>
            <div className="buttonlarge30">
              <div className="button46" />
              <div className="button-text73">See More</div>
            </div>
            <img
              className="progress-bar-icon6"
              alt=""
              src="/progress-bar.svg"
            />
          </div>
        </div> */}
      
      </div>
      {/* {isCoachFiltersOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Bottom"
          onOutsideClick={closeCoachFilters}
        >
          <CoachFilters onClose={closeCoachFilters} />
        </PortalDrawer>
      )} */}

          </>

 


  );
};

export default CoachBookmark;
