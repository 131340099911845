import React, { useState } from "react";
import Calendar from "react-calendar";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Sidebar from "../components/CoachSidebar";
import "react-circular-progressbar/dist/styles.css";
import "react-calendar/dist/Calendar.css";
import "./CoachBookingsPage.css";
import { useSelector } from "react-redux";

const DashboardMainContent = () => {
  const user = useSelector((state) => state.user.currentUser);
  const bookings = [
    {
      date: "09/31/2024",
      name: "Ethan Harper",
      objective: "Essay Workshop",
      amount: "$100.00",
      status: "Upcoming",
    },
    {
      date: "09/28/2024",
      name: "Todd Taylor",
      objective: "Essay Review",
      amount: "$200.00",
      status: "Upcoming",
    },
    {
      date: "09/27/2024",
      name: "Scholist Community",
      objective: "Coach Workshop",
      amount: "$100.00",
      status: "Upcoming",
    },
    {
      date: "09/25/2024",
      name: "Tom Smith",
      objective: "Meeting",
      amount: "$100.00",
      status: "Today",
    },
    {
      date: "09/13/2024",
      name: "Taylor Todd",
      objective: "Essay Review",
      amount: "$200.00",
      status: "Completed",
    },
    {
      date: "09/11/2024",
      name: "Jason Johnson",
      objective: "Introduction",
      amount: "$100.00",
      status: "Completed",
    },
  ];

  return (
    <div className="dashboard-main-content">
      {/* Community Statistics Section */}
      <div className="communities-stats">
        <div className="stat-item">
          <img src="/booksCoach.svg" alt="Book Icon" className="book-icon" />
          <p className="value2">6</p>
          <span className="description">Total Bookings</span>
        </div>
        <div className="stat-item">
          <img src="/booksCoach.svg" alt="Book Icon" className="book-icon" />
          <p className="value2">3</p>
          <span className="description">Bookings This Week</span>
        </div>
        <div className="stat-item">
          <p className="value">$400.00</p>
          <span className="currency">USD</span>
          <span className="description" style={{ marginTop: "5%" }}>
            Total Revenue Earned
          </span>
        </div>
      </div>

      <div className="welcome-new-section">
        <h2 className="coach-name ">Bookings</h2>
        <div className="upcoming-events">
          <table className="bookings-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Objective</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking, index) => (
                <tr key={index}>
                  <td>{booking.date}</td>
                  <td>{booking.name}</td>
                  <td
                    style={{
                      color: booking.objective.includes("Essay")
                        ? "#6BC844" 
                        : booking.objective.includes("Meeting")
                        ? "#A0A0A0" 
                        : "#1B59F8", 
                    }}
                  >
                    {booking.objective}
                  </td>
                  <td style={{color: "#4A93CF"}}>{booking.amount}</td>
                  <td style={{
                      color: booking.status.includes("Today")
                        ? "#6BC844" 
                        : booking.status.includes("Completed")
                        ? "#A0A0A0" 
                        : "#1B59F8", 
                    }}>
                    {booking.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const CoachBookmarkPage = () => {
  const [activeLink, setActiveLink] = useState("Home");

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };

  return (
    <div className="coach-dashboard">
      <Sidebar activeLink={activeLink} onLinkClick={handleLinkClick} />
      <DashboardMainContent />
    </div>
  );
};

export default CoachBookmarkPage;
