import "./Communities.css";

import LoggedInNavBar from "../components/LoggedInNavBar";
const Communities = () => {
  return (
    <div className="communities1">
      {/* <div className="navbar6">
        <div className="info6">
          <div className="left-side-top-nav7">
            <img className="headerlogo-icon7" alt="" src="/headerlogo2.svg" />
            <div className="top-nav-left7">
              <div className="headernav-link35">
                <div className="s-header-active">Home</div>
              </div>
              <div className="headernav-link36">
                <div className="s-header-active">Scholarships</div>
              </div>
              <div className="headernav-link37">
                <div className="s-header-active">Services</div>
              </div>
              <div className="headernav-link37">
                <div className="s-header-active">{`Community `}</div>
              </div>
              <div className="headernav-link37">
                <div className="s-header-active">Resources</div>
              </div>
            </div>
          </div>
          <div className="right-side-top-nav7">
            <button className="menu7">
              <img className="vector-icon49" alt="" />
              <img className="vector-icon50" alt="" src="/vector19.svg" />
            </button>
            <div className="messages-parent4">
              <img className="messages-icon6" alt="" src="/messages4.svg" />
              <img
                className="notifications-icon6"
                alt=""
                src="/notifications6.svg"
              />
              <div className="mask-group-parent4">
                <img
                  className="mask-group-icon6"
                  alt=""
                  src="/mask-group4@2x.png"
                />
                <img className="vector-icon51" alt="" src="/vector20.svg" />
              </div>
            </div>
          </div>
        </div>
        <img
          className="scholarships-line-icon6"
          alt=""
          src="/scholarships-line.svg"
        />
        <img className="services-line-icon6" alt="" src="/services-line.svg" />
        <img
          className="community-line-icon6"
          alt=""
          src="/community-line.svg"
        />
        <img className="resources-icon6" alt="" src="/resources.svg" />
      </div>
       */}
       {/* <LoggedInNavBar/> */}
      <div className="frame-parent7">
        <div className="side-navcommunities-wrapper">
          <div className="side-navcommunities">
            <div className="side-navsection-title">
              <div className="s-header-active">DASHBOARD</div>
            </div>
            <div className="divider5" />
            <div className="side-navsection-title">
              <div className="s-header-active">PROFILE</div>
            </div>
            <div className="divider5" />
            <div className="search3">
              <div className="side-navsearch-bar">
                <img
                  className="magnifying-glass-icon"
                  alt=""
                  src="/magnifying-glass.svg"
                />
                <div className="search4">Search...</div>
              </div>
            </div>
            <div className="left-menu-links">
              <div className="side-navsection-link">
                <div className="section-link-text">My Groups</div>
              </div>
              <div className="side-navsection-link">
                <div className="section-link-text">Discover</div>
              </div>
            </div>
            <div className="side-navbuttonlarge">
              <div className="side-navbuttonlarge1">
                <div className="button13" />
                <div className="button-text35">+Create New Group</div>
              </div>
            </div>
            <div className="divider5" />
            <div className="left-menu-link-section">
              <div className="side-navsection-title2">
                <div className="section-title-and-arrow">
                  <div className="my-communities">My Communities</div>
                  <img
                    className="right-arrow-icon"
                    alt=""
                    src="/right-arrow-icon.svg"
                  />
                </div>
              </div>
              <div className="left-menu-links">
                <div className="side-navsection-link">
                  <div className="section-link-text2">Group Name</div>
                </div>
                <div className="side-navsection-link">
                  <div className="section-link-text2">Group Name</div>
                </div>
                <div className="side-navsection-link">
                  <div className="section-link-text2">Group Name</div>
                </div>
                <div className="side-navsection-link">
                  <div className="section-link-text2">Group Name</div>
                </div>
                <div className="side-navsection-link">
                  <div className="section-link-text2">Group Name</div>
                </div>
                <div className="side-navsection-link">
                  <div className="section-link-text2">Group Name</div>
                </div>
                <div className="side-navsection-link">
                  <div className="section-link-text2">Group Name</div>
                </div>
                <div className="side-navsection-link">
                  <div className="section-link-text2">Group Name</div>
                </div>
              </div>
            </div>
            <div className="side-navsection-title">
              <div className="s-header-active">Settings</div>
            </div>
          </div>
        </div>
        <div className="allpage-header-parent">
          <div className="allpage-header4">
            <b className="communities2">Communities</b>
          </div>
          <div className="trending-communities-wrapper">
            <b className="trending-communities">Trending Communities</b>
          </div>
          <div className="cardcommunitysmall-parent">
            <div className="cardcommunitysmall">
              <div className="cardcommunitybackgroundsmal">
                <img
                  className="unsplashxrbbxixawy0-icon"
                  alt=""
                  src="/unsplashxrbbxixawy0@2x.png"
                />
              </div>
              <div className="cardcommunitysmall-inner">
                <div className="community-title-parent4">
                  <div className="community-title6">
                    Essay Writing and Research Papers group
                  </div>
                  <button className="cardicon-bookmark">
                    <img
                      className="vector-icon49"
                      alt=""
                      src="/bookmark-disabled.svg"
                    />
                  </button>
                </div>
              </div>
              <div className="members-wrapper4">
                <div className="members7">2.2K Members</div>
              </div>
              <div className="cardcommunitydescription-wrapper">
                <div className="cardcommunitydescription">
                  <div className="description7">
                    This is a community description section in which a brief
                    outline is provided regarding that community.
                  </div>
                </div>
              </div>
              <div className="cardcommunitysmall-child">
                <div className="buttonsmall-parent">
                  <button className="buttonsmall">
                    <div className="button14" />
                    <div className="button-text36">See more</div>
                  </button>
                  <button className="buttonsmall1">
                    <div className="button15" />
                    <div className="button-text37">Join</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="cardcommunitysmall1">
              <div className="cardcommunitybackgroundsmal">
                <img
                  className="unsplasheesdjflfx1a-icon"
                  alt=""
                  src="/unsplasheesdjflfx1a@2x.png"
                />
              </div>
              <div className="cardcommunitysmall-inner">
                <div className="community-title-parent4">
                  <div className="community-title6">
                    Essay Writing and Research Papers group
                  </div>
                  <button className="cardicon-bookmark">
                    <img
                      className="vector-icon49"
                      alt=""
                      src="/bookmark-disabled1.svg"
                    />
                  </button>
                </div>
              </div>
              <div className="members-wrapper4">
                <div className="members7">2.2K Members</div>
              </div>
              <div className="cardcommunitydescription-wrapper">
                <div className="cardcommunitydescription">
                  <div className="description7">
                    This is a community description section in which a brief
                    outline is provided regarding that community.
                  </div>
                </div>
              </div>
              <div className="cardcommunitysmall-child">
                <div className="buttonsmall-parent">
                  <button className="buttonsmall2">
                    <div className="button14" />
                    <div className="button-text36">See more</div>
                  </button>
                  <button className="buttonsmall1">
                    <div className="button15" />
                    <div className="button-text37">Join</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="cardcommunitysmall1">
              <div className="cardcommunitybackgroundsmal">
                <img
                  className="unsplashxrbbxixawy0-icon"
                  alt=""
                  src="/unsplashxrbbxixawy0@2x.png"
                />
              </div>
              <div className="cardcommunitysmall-inner">
                <div className="community-title-parent4">
                  <div className="community-title6">
                    Essay Writing and Research Papers group
                  </div>
                  <button className="cardicon-bookmark">
                    <img
                      className="vector-icon49"
                      alt=""
                      src="/bookmark-disabled.svg"
                    />
                  </button>
                </div>
              </div>
              <div className="members-wrapper4">
                <div className="members7">2.2K Members</div>
              </div>
              <div className="cardcommunitydescription-wrapper">
                <div className="cardcommunitydescription">
                  <div className="description7">
                    This is a community description section in which a brief
                    outline is provided regarding that community.
                  </div>
                </div>
              </div>
              <div className="cardcommunitysmall-inner4">
                <div className="buttonsmall-parent">
                  <button className="buttonsmall2">
                    <div className="button14" />
                    <div className="button-text36">See more</div>
                  </button>
                  <button className="buttonsmall1">
                    <div className="button15" />
                    <div className="button-text37">Join</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="cardcommunitysmall1">
              <div className="cardcommunitybackgroundsmal">
                <img
                  className="unsplashxrbbxixawy0-icon"
                  alt=""
                  src="/unsplashxrbbxixawy0@2x.png"
                />
              </div>
              <div className="cardcommunitysmall-inner">
                <div className="community-title-parent4">
                  <div className="community-title6">
                    Essay Writing and Research Papers group
                  </div>
                  <button className="cardicon-bookmark">
                    <img
                      className="vector-icon49"
                      alt=""
                      src="/bookmark-disabled.svg"
                    />
                  </button>
                </div>
              </div>
              <div className="members-wrapper4">
                <div className="members7">2.2K Members</div>
              </div>
              <div className="cardcommunitydescription-wrapper">
                <div className="cardcommunitydescription">
                  <div className="description7">
                    This is a community description section in which a brief
                    outline is provided regarding that community.
                  </div>
                </div>
              </div>
              <div className="cardcommunitysmall-child">
                <div className="buttonsmall-parent">
                  <button className="buttonsmall2">
                    <div className="button14" />
                    <div className="button-text36">See more</div>
                  </button>
                  <button className="buttonsmall1">
                    <div className="button15" />
                    <div className="button-text37">Join</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="cardcommunitysmall1">
              <div className="cardcommunitybackgroundsmal">
                <img
                  className="unsplashxrbbxixawy0-icon"
                  alt=""
                  src="/unsplashxrbbxixawy0@2x.png"
                />
              </div>
              <div className="cardcommunitysmall-inner">
                <div className="community-title-parent4">
                  <div className="community-title6">
                    Essay Writing and Research Papers group
                  </div>
                  <button className="cardicon-bookmark">
                    <img
                      className="vector-icon49"
                      alt=""
                      src="/bookmark-disabled.svg"
                    />
                  </button>
                </div>
              </div>
              <div className="members-wrapper4">
                <div className="members7">2.2K Members</div>
              </div>
              <div className="cardcommunitydescription-wrapper">
                <div className="cardcommunitydescription">
                  <div className="description7">
                    This is a community description section in which a brief
                    outline is provided regarding that community.
                  </div>
                </div>
              </div>
              <div className="cardcommunitysmall-child">
                <div className="buttonsmall-parent">
                  <button className="buttonsmall2">
                    <div className="button14" />
                    <div className="button-text36">See more</div>
                  </button>
                  <button className="buttonsmall1">
                    <div className="button15" />
                    <div className="button-text37">Join</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="cardcommunitysmall1">
              <div className="cardcommunitybackgroundsmal">
                <img
                  className="unsplashxrbbxixawy0-icon"
                  alt=""
                  src="/unsplashxrbbxixawy0@2x.png"
                />
              </div>
              <div className="cardcommunitysmall-inner">
                <div className="community-title-parent4">
                  <div className="community-title6">
                    Essay Writing and Research Papers group
                  </div>
                  <button className="cardicon-bookmark">
                    <img
                      className="vector-icon49"
                      alt=""
                      src="/bookmark-disabled.svg"
                    />
                  </button>
                </div>
              </div>
              <div className="members-wrapper4">
                <div className="members7">2.2K Members</div>
              </div>
              <div className="cardcommunitydescription-wrapper">
                <div className="cardcommunitydescription">
                  <div className="description7">
                    This is a community description section in which a brief
                    outline is provided regarding that community.
                  </div>
                </div>
              </div>
              <div className="cardcommunitysmall-child">
                <div className="buttonsmall-parent">
                  <button className="buttonsmall2">
                    <div className="button14" />
                    <div className="button-text36">See more</div>
                  </button>
                  <button className="buttonsmall1">
                    <div className="button15" />
                    <div className="button-text37">Join</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="page-divider-wrapper">
            <div className="page-divider3" />
          </div>
          <div className="more-suggestions-wrapper">
            <b className="trending-communities">More Suggestions</b>
          </div>
          <div className="frame-wrapper55">
            <div className="cardcommunitysmall-group">
              <div className="cardcommunitysmall6">
                <div className="cardcommunitybackgroundsmal6">
                  <img
                    className="unsplashwe-kv-zb1l0-icon"
                    alt=""
                    src="/unsplashwe-kv-zb1l0@2x.png"
                  />
                </div>
                <div className="community-title-parent10">
                  <div className="community-title12">Community Title</div>
                  <img
                    className="cardicon-bookmark6"
                    alt=""
                    src="/bookmark-disabled.svg"
                  />
                </div>
                <div className="cardcommunitydescription6">
                  <div className="description13">
                    This is a community description section in which a brief
                    outline is provided regarding that community.
                  </div>
                </div>
                <div className="members-wrapper10">
                  <div className="members13">22.2K Members</div>
                </div>
                <div className="cardcommunitysmall-inner11">
                  <div className="buttonsmall-parent4">
                    <div className="buttonsmall12">
                      <div className="button14" />
                      <div className="button-text48">See more</div>
                    </div>
                    <div className="buttonsmall13">
                      <div className="button15" />
                      <div className="button-text48">Join</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cardcommunitysmall7">
                <div className="cardcommunitydescription7">
                  <div className="community-title13">Community Title</div>
                  <div className="members14">22.2K Members</div>
                  <div className="description14">
                    This is a community description section in which a brief
                    outline is provided regarding that community.
                  </div>
                </div>
                <div className="buttonsmall14">
                  <div className="button14" />
                  <div className="button-text48">See more</div>
                </div>
                <div className="buttonsmall15">
                  <div className="button15" />
                  <div className="button-text48">Join</div>
                </div>
                <img
                  className="cardicon-bookmark7"
                  alt=""
                  src="/cardicon-bookmark.svg"
                />
                <img
                  className="cardcommunitybackgroundsmal-icon"
                  alt=""
                  src="/cardcommunitybackgroundsmall@2x.png"
                />
              </div>
              <div className="cardcommunitysmall7">
                <div className="cardcommunitydescription7">
                  <div className="community-title13">Community Title</div>
                  <div className="members14">22.2K Members</div>
                  <div className="description14">
                    This is a community description section in which a brief
                    outline is provided regarding that community.
                  </div>
                </div>
                <div className="buttonsmall14">
                  <div className="button14" />
                  <div className="button-text48">See more</div>
                </div>
                <div className="buttonsmall15">
                  <div className="button15" />
                  <div className="button-text48">Join</div>
                </div>
                <img
                  className="cardicon-bookmark7"
                  alt=""
                  src="/cardicon-bookmark.svg"
                />
                <img
                  className="cardcommunitybackgroundsmal-icon1"
                  alt=""
                  src="/cardcommunitybackgroundsmall1@2x.png"
                />
              </div>
            </div>
          </div>
          <div className="see-more-progress-bar-wrapper">
            <div className="see-more-progress-bar6">
              <div className="div43">24 / 785</div>
              <div className="buttonlarge24">
                <div className="button32" />
                <div className="button-text48">See More</div>
              </div>
              <img
                className="progress-bar-icon3"
                alt=""
                src="/progress-bar1.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="footer7">
        <div className="footer-grid-row7">
          <div className="footer-menu35">
            <img className="footerlogo-icon7" alt="" src="/footerlogo5.svg" />
            <div className="footer-links35">
              <div className="footerlinknav21">
                <div className="nav-link21">info@example.com</div>
              </div>
              <div className="footerlinknav21">
                <div className="nav-link21">+1 234 567 9830</div>
              </div>
              <div className="footerlinknav21">
                <div className="nav-link21">Address</div>
              </div>
            </div>
            <div className="icon-wrapper7">
              <img
                className="footericoninstagram7"
                alt=""
                src="/footericoninstagram.svg"
              />
              <img
                className="footericonfacbook7"
                alt=""
                src="/footericonfacbook.svg"
              />
              <img
                className="footericontwitter7"
                alt=""
                src="/footericontwitter.svg"
              />
              <img
                className="footericonfacbook7"
                alt=""
                src="/footericonlinkedin.svg"
              />
              <img
                className="footericonyoutube7"
                alt=""
                src="/footericonyoutube.svg"
              />
            </div>
          </div>
          <div className="footer-menu-parent5">
            <div className="footer-menu36">
              <div className="students7">Students</div>
              <div className="footer-links35">
                <div className="main-footer-link91">
                  <div className="footer-link91">How it works</div>
                </div>
                <div className="main-footer-link91">
                  <div className="footer-link91">Scholarships</div>
                </div>
                <div className="main-footer-link91">
                  <div className="footer-link91">Community</div>
                </div>
                <div className="main-footer-link91">
                  <div className="footer-link91">Services</div>
                </div>
              </div>
            </div>
            <div className="footer-menu36">
              <div className="students7">Coaches</div>
              <div className="footer-links35">
                <div className="main-footer-link91">
                  <div className="footer-link91">How it works</div>
                </div>
                <div className="main-footer-link91">
                  <div className="footer-link91">Blog</div>
                </div>
                <div className="main-footer-link91">
                  <div className="footer-link91">Mission</div>
                </div>
                <div className="main-footer-link91">
                  <div className="footer-link91">Footer Link</div>
                </div>
              </div>
            </div>
            <div className="footer-menu36">
              <div className="students7">Company</div>
              <div className="footer-links35">
                <div className="main-footer-link91">
                  <div className="footer-link91">About Us</div>
                </div>
                <div className="main-footer-link91">
                  <div className="footer-link91">Partners</div>
                </div>
                <div className="main-footer-link91">
                  <div className="footer-link91">Careers</div>
                </div>
                <div className="main-footer-link91">
                  <div className="footer-link91">Contact</div>
                </div>
              </div>
            </div>
            <div className="footer-menu36">
              <div className="students7">Join our Email List</div>
              <div className="footer-links39">
                <div className="main-footer-link103">
                  <div className="footer-link103">
                    Be the first to know about new scholarships, events,
                    workshops and much more!
                  </div>
                </div>
                <div className="subscribe-email-text-box7">
                  <div className="text-box6" />
                  <div className="input-placeholder6">Email</div>
                </div>
                <div className="buttonlarge25">
                  <div className="button33" />
                  <div className="button-text48">Sign Up</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-legal7">
          <div className="scholist-all-rights7">
            © 2022 Scholist. All rights reserved.
          </div>
          <div className="scholist-all-rights7">Disclaimer Cookies</div>
        </div>
      </div>
    </div>
  );
};

export default Communities;
