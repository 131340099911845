const organizationsList = [
  "4-H Club",
  "AACN",
  "Academy of Medical-Surgical Nurses",
  "ACFEF registered apprentice",
  "AFL-CIO",
  "AGU",
  "Air and Waste Management Association",
  "Aircraft Electronics Association",
  "Alabama Farmers Federation",
  "Alpha Beta Gamma",
  "Alpha Chi Omega",
  "Alpha Chi Rho",
  "Alpha Delta Kappa",
  "Alpha Epsilon Delta",
  "Alpha Epsilon Phi",
  "Alpha Eta Rho",
  "Alpha Gamma Delta",
  "Alpha Gamma Rho",
  "Alpha Gamma Sigma",
  "Alpha Kappa Alpha",
  "Alpha Lambda Delta Member",
  "Alpha Mu Gamma",
  "Alpha Omega Epsilon",
  "Alpha Phi Omega",
  "Alpha Psi Omega",
  "Alpha Sigma Lambda",
  "Alpha Sigma Tau",
  "Alpha Zeta",
  "Amalgamated Transit Union (ATC)",
  "Amarillo Young American Bowling Association",
  "American Academy of Family Physicians",
  "American Art Therapy Association (AATA)",
  "American Association for Geodetic Surveying (AAGS)",
  "American Association of Blacks in Energy",
  "American Association of Bovine Practitioners",
  "American Association of Critical Care Nurses(AACN)",
  "American Association of Family & Consumer Sciences",
  "American Association of Professional Landmen (AAPL)",
  "American Association of Law Libraries",
  "American Business Women’s Association (ABWA)",
  "American Chemical Society (ACS)",
  "American College of Health Care Executives (ACHE)",
  "American College of Medical Practice Executives (ACMPE)",
  "American College of Nurse-Midwives (ACNM)",
  "American College Theater Festival (ACTF)",
  "American Congress on Surveying and Mapping (ACSM)",
  "American Contract Bridge League",
  "American Counseling Assocation",
  "American Culinary Federation",
  "American Dental Assistants Association (ADAA)",
  "American Dental Hygienists Association",
  "American Dietetic Association",
  "American Federation of Teachers",
  "American Federation of Television and Radio Artists (AFTRA)",
  "American Goat Society (AGS)",
  "American Guild of Musical Artists (AGMA)",
  "American Guild of Organists (AGO)",
  "American Health Information Management Assn. AHIMA",
  "American Heart Association",
  "American Holistic Nurses’ Association",
  "American Indian Science & Engineering Society",
  "American Institute of Aeronautics & Astronautics",
  "American Institute of Architecture Students",
  "American Institute of Certified Public Accountants",
  "American Institute of Chemical Engineers (AIChE)",
  "American Institute of Constructors (AIC)",
  "American Institute of Professional Geologists",
  "American Legion",
  "American Legion Auxiliary",
  "American Legion Junior Auxiliary",
  "American Legion Oratorical Contest",
  "American Library Association (ALA)",
  "American Marketing Association",
  "American Maine-Anjou Association (AMAA)",
  "American Medical Tecnhologists",
  "American Medical Women’s Association (AMWA)",
  "American Meterological society",
  "American Nephrology Nurses Association",
  "American Nephrology Nurses’ Association (ANNA)",
  "American Nuclear Society",
  "American Nurses Association (ANA)",
  "American Optometric Association (AOA)",
  "American Orff-Schulwerk Association",
  "American Paint Horse Association (APHA)",
  "American Pharmacists Association",
  "American Physics Society",
  "American Physical Therapy Association",
  "American Planning Association (APA)",
  "American Postal Workers Union (APWU)",
  "American Psychological Association",
  "American Production and Inventory Control Society",
  "American Quarter Horse Foundation",
  "American Radio Relay League (ARRL)",
  "American School Food Service Association (ASFSA)",
  "American Society for Microbiology",
  "American Society for Photogrammetry and Remote Sensing",
  "American Society for Testing and Materials",
  "American Society of Agricultural and Biological Engineers",
  "American Society of Agricultural Engineers (ASAE)",
  "American Society of Arms Collectors",
  "American Society of Civil Engineers (ASCE)",
  "American Society of Mechanical Engineers (ASME)",
  "American Society of Metals (ASM)",
  "American Society of PeriAnesthesia Nurses (ASPAN)",
  "American Society of Plumbing Engineers (ASPE)",
  "American Society of Safety Engineers",
  "American Society of Safety Professionals",
  "American Society of Travel Agents",
  "American Society of Women Accountants (ASWA)",
  "American Society-Photogrammetry & Remote Sensing",
  "American Veterinary Medical Association",
  "American Water Resources Association (AWRA)",
  "American Welding Society",
  "AmeriCorps",
  "AMVETS Auxiliary",
  "Angel Flight",
  "Appaloosa Horse Club",
  "Appaloosa Youth Association",
  "Archaeological Institute of America",
  "Arnold Air Society",
  "ASHA",
  "Asian Pacific American Heritage Association",
  "Asian Pacific Islanders (APIA)",
  "Asian Pacific fund",
  "Aspen Institute Intern",
  "Assn. for Educational Communications & Technology",
  "Associated General Contractors",
  "Association for Nursing Professional Development (ANPD)",
  "Association for Women in Communications (AWC)",
  "Association of American Geographers (AAG)",
  "Association of International Students",
  "Association of Operating Room Nurses (AORN)",
  "Association of periOperative Registered Nurses (AORN)",
  "Association of the Sons of Poland",
  "Association of Women’s Health, Obstetric and Neonatal Nurses (AWHONN)",
  "Baptist Health Foundation’s Volunteer Program",
  "Baptist Life Association",
  "Beta Alpha Psi",
  "Beta Gamma Sigma",
  "Beta Sigma Phi",
  "Beta Sigma Psi",
  "Beta Theta Pi",
  "Big Brothers/Big Sisters of America",
  "Black Girls Code",
  "Black Girls in Cyber",
  "Boy Scouts of America",
  "Boys and Girls Clubs of America",
  "Boys’ State",
  "Broadcast Cable Financial Management Association",
  "Broadcast Education Association (BEA)",
  "Bulwark Pest Control Services",
  "Business Owner",
  "Cadet Patrol Academy",
  "California Conservation Corps",
  "California Correctional Peace Officers Association (CCPOA)",
  "California School Library Association",
  "California Society of Professional Engineers",
  "California Teachers Association (CTA)",
  "Camden Clark Medical Center",
  "Camp Fire Boys and Girls",
  "Catholic Aid Association",
  "Catholic Kolping Society of America",
  "Catholic Library Association",
  "Children of the Confederacy",
  "Civil Air Patrol (CAP)",
  "Colorstack Member",
  "Computer Club",
  "Costume Society of America",
  "Council of the Great City Schools",
  "Credit Union member",
  "Dakota Alliance Soccer Club",
  "Danish Sisterhood of America",
  "Defense Eligibility Enrollment Reporting System (DEERS)",
  "Degree of Honor Protective Association",
  "Delta Epsilon Chi",
  "Delta Gamma",
  "Delta Kappa",
  "Delta Phi Epsilon",
  "Delta Sigma Pi",
  "Delta Sigma Theta",
  "DeMolay International",
  "Descendants of the Signers of the Declaration of Independence (DSDI)",
  "Disabled American Veterans Auxiliary",
  "Distinguished Young Women",
  "Distributive Education Clubs of America (DECA)",
  "Dollars for Scholars",
  "Eagle Scout",
  "Emergency Nurses Association (ENA)",
  "Energy Management Student Association (EMSA)",
  "Engineering Society",
  "Equals6",
  "Eta Sigma Gamma",
  "Eta Sigma Phi",
  "Family, Career and Community Leaders of America (FCCLA)",
  "Farm Bureau Member",
  "Federation Life Insurance of America",
  "Federation of American Consumers and Travelers (FACT)",
  "First Catholic Slovak Ladies Association",
  "First Command Educational Foundation",
  "Florida Roofing and Sheet Metal Contractors Association",
  "Florida School Nutrition Association",
  "Forensics Squad",
  "Foundry Educational Foundation (FEF)",
  "Future Business Leaders of America (FBLA)",
  "Future Educators Association",
  "Future Farmers of America (FFA)",
  "Future Homemakers of America (FHA)",
  "Girl Scouts of America",
  "Girls Incorporated",
  "Girls’ State",
  "Girls Who Code",
  "Gleaner Life Insurance Society",
  "Globe-Trotters",
  "Golden Key Honor Society",
  "Golf Course Superintendents Association of America (GCSAA)",
  "Gridiron Club",
  "Hamilton Leaders Academy",
  "Hawaiian Civic Club",
  "HBCU Connect",
  "Healthcare Information and Management Systems Society (HIMSS)",
  "Hispanic Student Dental Association",
  "Hispanic Student Society",
  "Honeywell Retirees Club",
  "Honpa or Higashi Hongwanji",
  "Horatio Alger Association",
  "Human Resources Association of the Midlands",
  "HVACR",
  "Ice Skating Institute of America (ISIA)",
  "IDDBA-zinternational Dairy-Deli-Bakery Association",
  "IEEE Computer Society",
  "IIE",
  "Illinois Farm Bureau",
  "Institute of Food Technologists",
  "Institute of Industrial Engineers (IIE)",
  "Institute of International Education",
  "Institute of Electrical/Electronic Engineers",
  "Instrument Society of America",
  "International Brotherhood of Electrical Workers",
  "International Business Student Organization",
  "International Dairy-Deli-Bakery Association",
  "International Executive Housekeepers Association",
  "International Furnishings and Design Association (IFDA)",
  "International Order of Job’s Daughters",
  "International Students Organization",
  "International Technology and Engineering Educators Association",
  "International Technology Education Association",
  "International Thespian Society",
  "Iota Sigma Pi",
  "Italian Catholic Federation",
  "Japanese American Citizens League (JACL)",
  "Job Corps",
  "JROTC",
  "Junior Achievement",
  "Kappa Alpha Psi",
  "Kappa Delta Pi",
  "Kappa Kappa Gamma",
  "Kappa Kappa Psi",
  "Kappa Omicron Nu",
  "Kappa Sigma",
  "Key Club International",
  "Knights of Columbus",
  "Knights of Lithuania",
  "La Leche League",
  "Lambda Chi Alpha",
  "Lambda Iota Tau",
  "Lambda Kappa Sigma",
  "Latin American Club",
  "leadership",
  "League for Nursing",
  "LGBTQ",
  "Library Association",
  "Lions Club",
  "Luso-American Fraternal Federation",
  "Lutheran Volunteer Corps (LVC)",
  "Macedonian Patriotic Organization",
  "MAES",
  "Maids of Athena",
  "Medical Group Management Association (MGMA)",
  "MENSA Education & Research Foundation",
  "Mexican American Women’s National Association-MANA",
  "Michigan Society of Professional Engineers (MSPE)",
  "Minnesota Association of Law Libraries",
  "Mortar Board",
  "Muslim Students Association",
  "NABF-National Amateur Baseball Federation",
  "Nat’l Assn. for the Advancement of Colored People",
  "Nat’l Society Daughters of the American Revolution",
  "National Academic Advising Association",
  "National Action Council for Minorities in Engineering ( NACME)",
  "National Achievement Scholar",
  "National Art Honor Society",
  "National Association for the Advancement of Colored People (NAACP)",
  "National Association of Black Accountants (NABA)",
  "National Association of Black County Officials (NABCO)",
  "National Association of Black Journalists (NABJ)",
  "National Association of Congregational Christian Churches (NACCC)",
  "National Association of Directors of Nursing Administration in Long Term Care (NADONA/LTC)",
  "National Association of Health Services Executives (NAHSE)",
  "National Association of Hispanic Nurses",
  "National Association of Home Builders",
  "National Association of Orthopaedic Nurses (NAON)",
  "National Association of Pastoral Musicians",
  "National Association of pediatric Nurse practitioners",
  "National Association of Railway Business Women (NARBW)",
  "National Beta Club",
  "National Black Law Students Association",
  "National Black MBA Association",
  "National Black Nurses’ Association (NBNA)",
  "National Broadcasting Society (NBS)",
  "National Court Reporters Association",
  "National Defense Transportation Association (NDTA)",
  "National Federation of Music Clubs (NFMC)",
  "National Fraternal Society of the Deaf",
  "National Gay and Lesbian Task Force (NGLTF)",
  "National Gerontological Nurses Association (NGNA)",
  "National Honor Society",
  "National Hook-Up of Black Women (NHBW)",
  "National Ice Cream Retailers Association",
  "National Junior Angus Association (NJAA)",
  "National Junior Hereford Association (NJHA)",
  "National Rifle Association (NRA)",
  "National Scholars Honor Society",
  "National Scholastic Press Association (NSPA)",
  "National Scholastic Surfing Association (NSSA)",
  "National Science Olympiad",
  "National Society of Black Engineers",
  "National Society of Collegiate Scholars(NSCS)",
  "National Society of High School Scholars (NSHSS)",
  "National Society of Leadership and Success",
  "National Society of Minorities in Hospitality",
  "National Society of Professional Engineers (NSPE)",
  "National Society of the Sons of the American Revolution (NSSAR)",
  "National Strength & Conditioning Association-NSCA",
  "National Student Nurses Association (NSNA)",
  "National Urban League",
  "National Women’s Studies Association (NWSA)",
  "Native American Journalists Association (NAJA)",
  "Native American Journalists Association (NAJA)",
  "Neighbors of Woodcraft",
  "Nevada State FireFighter’s Association (NSFA)",
  "New England Hemophilia Association",
  "New England Water Works Association",
  "New Members of the Round Table (NMRT)",
  "NIAF",
  "NLN (National League for Nursing)",
  "Nonprofit Organization",
  "NSAA",
  "Nurses Association",
  "Nursing Student Association",
  "Ohio GFOA",
  "Oklahoma Cattlemen’s Association",
  "Olivet Boys & Girls Club",
  "Omega Boys Club",
  "Omega Psi Phi",
  "Omicron Delta Kappa",
  "Order of De Molay",
  "Order of the Arrow",
  "Oregon Independent College Foundation (OICF)",
  "Palomino Horse Breeders of America (PHBA-Y)",
  "Panhellenic/Greek Student Organizations",
  "Parent Teacher Association/Parent Teacher Student Association (PTA/PTSA)",
  "PDCA",
  "PEO",
  "Phi Alpha Delta",
  "Phi Alpha Theta",
  "Phi Beta Kappa",
  "Phi Beta Kappa",
  "Phi Delta Gamma",
  "Phi Delta Kappa International",
  "Phi Eta Sigma",
  "Phi Gamma Delta",
  "Phi Gamma Nu",
  "Phi Kappa Lambda",
  "Phi Kappa Phi",
  "Phi Kappa Sigma",
  "Phi Mu Alpha",
  "Phi Sigma Iota",
  "Phi Sigma Kappa",
  "Phi Theta Kappa",
  "Phi Upsilon Omicron",
  "Philippine Nurses Association of America",
  "Pi Kappa Alpha",
  "Pi Lambda Theta",
  "Pi Sigma Alpha",
  "Pilot International Foundation",
  "Political Animals Club",
  "Pony Club",
  "Pork Producers Association",
  "Portuguese Continental Union of the USA",
  "Prospective Gents Club-Bridge Builders",
  "Public Relations Student Society of America (PRSSA)",
  "Quill and Scroll Society",
  "Recording for the Blind and Dyslexic",
  "Religious Group",
  "Reno Pops Orchestra",
  "Rotary Club",
  "ROTC",
  "Royal Neighbors of America",
  "San Antonio Music Teachers Association",
  "School Nutrition Association",
  "Science Club",
  "Screen Actors Guild (SAG)",
  "Serteen Club",
  "Sertoma Collegiate Club",
  "Sheriffs Boys & Girls Youth Ranches",
  "Sigma Alpha Epsilon",
  "Sigma Alpha Iota",
  "Sigma Chi",
  "Sigma Delta Chi",
  "Sigma Gamma Rho",
  "Sigma Phi Alpha",
  "Sigma Pi",
  "Sigma Pi Epsilon",
  "Sigma Xi",
  "Silicon Valley Community Foundation",
  "SkillsUSA",
  "Slovene National Benefit Society",
  "Slovenian Women’s Union of America",
  "Soaring Society of America (SSA)",
  "Society Farsarotul",
  "Society for Mining, Metallurgy, and Exploration (SME)",
  "Society for Signers of Declaration of Independence",
  "Society for the Advancement of Scandinavian Study",
  "Society of Automative Engineers",
  "Society of Broadcast Engineers (SBE)",
  "Society of Certified Public Accountants",
  "Society of Hispanic Professional Engineers",
  "Society of Human Resource Management",
  "Society of Manufacturing Engineers (SME)",
  "Society of Mexican American Engineers & Scientists",
  "Society of Mining Engineers",
  "Society of Motion Picture and Television Engineers (SMPTE)",
  "Society of Otorhinolaryngology and Head-Neck Nurses\\",
  "Society of Petroleum Engineers",
  "Society of Physics Students",
  "Society of Plastic Engineers",
  "Society of Women Engineers (SWE)",
  "Soil and Water Conservation Society (SWCS)",
  "Sons of Italy",
  "Sons of Norway",
  "Sons of Pericles",
  "Special Libraries Association",
  "Spina Bifida Association of America",
  "State Grange Club",
  "Student Dietetic Association",
  "Student Educatuon Association (SEA)",
  "Student Fashion Association",
  "Tau Beta Pi",
  "Tau Kappa Epsilon Fraternity",
  "Teachers of Tomorrow Academy",
  "Technology Student Association",
  "Texas Medical Association",
  "The Minerals, Metals & Materials Society (TMS)",
  "Theta Delta Chi",
  "Theta Xi",
  "Tile Contractors’ Association of America (TCAA)",
  "Tri Delta",
  "UNICO National",
  "United Commercial Travelers of America",
  "United Daughters of the Confederacy",
  "United Negro College Fund (UNCF)",
  "United Paperworkers International Union (UPIU)",
  "United States Association for Blind Athletes USABA",
  "United States Bowling Congress (USBC)",
  "United States Chess Federation",
  "United States Junior Chamber (Jaycees)",
  "United States Navy Radioman Association (USNRMA)",
  "United States Pony Club Member",
  "University Film and Video Association",
  "Upward Bound",
  "Urban Servant Corps",
  "USA Water Ski",
  "USAA Mentor Program",
  "Vasa Order of America",
  "Veterans of the Vietnam War (VVnW)",
  "VICA",
  "Washington Correctional Association (WCA)",
  "Washington Osteopathic Medical Association",
  "Western Fraternal Life Association (WFLA)",
  "Western Undergraduate Exchange (WUE)",
  "WHCC Junior Golf Program",
  "Women in Aviation, International",
  "Women in CyberSecurity",
  "Women’s Association",
  "Worcester Fellowship",
  "World Future Society",
  "Young Judaea"
];


function convertToFormat(organizations) {
  return organizations.map(org => {
    return {
      "name": org,
      "value": org,
      "checked": false
    };
  });
}

const Organizations = convertToFormat(organizationsList);



const Religion = [
    {
      "name": "African Methodist Episcopal",
      "value": "African Methodist Episcopal",
      "checked": false
    },
    {
      "name": "Agnostic",
      "value": "Agnostic",
      "checked": false
    },
    {
      "name": "Assemblies of God",
      "value": "Assemblies of God",
      "checked": false
    },
    {
      "name": "Baptist",
      "value": "Baptist",
      "checked": false
    },
    {
      "name": "Buddhist",
      "value": "Buddhist",
      "checked": false
    },
    {
      "name": "Catholic",
      "value": "Catholic",
      "checked": false
    },
    {
      "name": "Christian",
      "value": "Christian",
      "checked": false
    },
    {
      "name": "Eastern Orthodox",
      "value": "Eastern Orthodox",
      "checked": false
    },
    {
      "name": "Episcopalian",
      "value": "Episcopalian",
      "checked": false
    },
    {
      "name": "Hindu",
      "value": "Hindu",
      "checked": false
    },
    {
      "name": "Jehovah’s Witness",
      "value": "Jehovah’s Witness",
      "checked": false
    },
    {
      "name": "Jewish",
      "value": "Jewish",
      "checked": false
    },
    {
      "name": "Lutheran",
      "value": "Lutheran",
      "checked": false
    },
    {
      "name": "Methodist",
      "value": "Methodist",
      "checked": false
    },
    {
      "name": "Muslim",
      "value": "Muslim",
      "checked": false
    },
    {
      "name": "Pagan/Wiccan",
      "value": "Pagan/Wiccan",
      "checked": false
    },
    {
      "name": "Pentecostal",
      "value": "Pentecostal",
      "checked": false
    },
    {
      "name": "Quaker",
      "value": "Quaker",
      "checked": false
    },
    {
      "name": "Seventh-day Adventist",
      "value": "Seventh-day Adventist",
      "checked": false
    },
    {
      "name": "Sikh",
      "value": "Sikh",
      "checked": false
    },
    {
      "name": "Other",
      "value": "Other",
      "checked": false
    }
  ]
  
const Disabilities = [
  {
    "name": "Physical Disability",
    "value": "Physical Disability",
    "checked": false
  },
  {
    "name": "Behavioral Disability",
    "value": "Behavioral Disability",
    "checked": false
  },
  {
    "name": "Mental Disability",
    "value": "Mental Disability",
    "checked": false
  },
  {
    "name": "Learning Disability",
    "value": "Learning Disability",
    "checked": false
  },
  {
    "name": "Emotional Disability",
    "value": "Emotional Disability",
    "checked": false
  },
  {
    "name": "Intellectual Disability",
    "value": "Intellectual Disability",
    "checked": false
  },
  {
    "name": "Multiple Disabilities",
    "value": "Multiple Disabilities",
    "checked": false
  }
]


const PersonalAttributes = [
  {
    "name": "First-generation college student",
    "value": "First-generation college student",
    "checked": false
  },
  {
    "name": "Veteran status",
    "value": "Veteran status",
    "checked": false
  },
  {
    "name": "Refugee or asylum seeker status",
    "value": "Refugee or asylum seeker status",
    "checked": false
  },
  {
    "name": "Single parent",
    "value": "Single parent",
    "checked": false
  },
  {
    "name": "Caregiver responsibilities",
    "value": "Caregiver responsibilities",
    "checked": false
  },
  {
    "name": "LGBTQ+",
    "value": "LGBTQ+",
    "checked": false
  },
  {
    "name": "Non traditional Student",
    "value": "Non traditional Student",
    "checked": false
  },
  {
    "name": "DACA (Deferred Action for Childhood Arrivals)",
    "value": "DACA (Deferred Action for Childhood Arrivals)",
    "checked": false
  },
  {
    "name": "Foster Care",
    "value": "Foster Care",
    "checked": false
  },
  {
    "name": "Orphan",
    "value": "Orphan",
    "checked": false
  },
  {
    "name": "Other",
    "value": "Other",
    "checked": false
  }
]

const Sports = [
  {
    "name": "Aerobics",
    "value": "Aerobics",
    "checked": false
  },
  {
    "name": "Archery",
    "value": "Archery",
    "checked": false
  },
  {
    "name": "Athletics",
    "value": "Athletics",
    "checked": false
  },
  {
    "name": "Badminton",
    "value": "Badminton",
    "checked": false
  },
  {
    "name": "Baseball",
    "value": "Baseball",
    "checked": false
  },
  {
    "name": "Basketball",
    "value": "Basketball",
    "checked": false
  },
  {
    "name": "Baton Twirling",
    "value": "Baton Twirling",
    "checked": false
  },
  {
    "name": "Bowling",
    "value": "Bowling",
    "checked": false
  },
  {
    "name": "Cheerleader",
    "value": "Cheerleader",
    "checked": false
  },
  {
    "name": "Cricket",
    "value": "Cricket",
    "checked": false
  },
  {
    "name": "Cross country",
    "value": "Cross country",
    "checked": false
  },
  {
    "name": "Cycling",
    "value": "Cycling",
    "checked": false
  },
  {
    "name": "Dance/ Drill team",
    "value": "Dance/ Drill team",
    "checked": false
  },
  {
    "name": "Fencing",
    "value": "Fencing",
    "checked": false
  },
  {
    "name": "Field Hockey",
    "value": "Field Hockey",
    "checked": false
  },
  {
    "name": "Fishing",
    "value": "Fishing",
    "checked": false
  },
  {
    "name": "Flag Football",
    "value": "Flag Football",
    "checked": false
  },
  {
    "name": "Football",
    "value": "Football",
    "checked": false
  },
  {
    "name": "Frisbee",
    "value": "Frisbee",
    "checked": false
  },
  {
    "name": "Golf",
    "value": "Golf",
    "checked": false
  },
  {
    "name": "Gymnastics",
    "value": "Gymnastics",
    "checked": false
  },
  {
    "name": "Handball",
    "value": "Handball",
    "checked": false
  },
  {
    "name": "Horseback riding/ Equestrian",
    "value": "Horseback riding/ Equestrian",
    "checked": false
  },
  {
    "name": "Ice Hockey",
    "value": "Ice Hockey",
    "checked": false
  },
  {
    "name": "Ice/ figure Skating",
    "value": "Ice/ figure Skating",
    "checked": false
  },
  {
    "name": "Lacrosse",
    "value": "Lacrosse",
    "checked": false
  },
  {
    "name": "Martial Arts",
    "value": "Martial Arts",
    "checked": false
  },
  {
    "name": "Mountain Biking",
    "value": "Mountain Biking",
    "checked": false
  },
  {
    "name": "Racquetball",
    "value": "Racquetball",
    "checked": false
  },
  {
    "name": "Rodeo",
    "value": "Rodeo",
    "checked": false
  },
  {
    "name": "Rowing",
    "value": "Rowing",
    "checked": false
  },
  {
    "name": "Rugby",
    "value": "Rugby",
    "checked": false
  },
  {
    "name": "Running",
    "value": "Running",
    "checked": false
  },
  {
    "name": "Sailing",
    "value": "Sailing",
    "checked": false
  },
  {
    "name": "Skateboarding",
    "value": "Skateboarding",
    "checked": false
  },
  {
    "name": "Skiing",
    "value": "Skiing",
    "checked": false
  },
  {
    "name": "Snowboarding",
    "value": "Snowboarding",
    "checked": false
  },
  {
    "name": "Soccer",
    "value": "Soccer",
    "checked": false
  },
  {
    "name": "Softball",
    "value": "Softball",
    "checked": false
  },
  {
    "name": "Surfing",
    "value": "Surfing",
    "checked": false
  },
  {
    "name": "Swimming",
    "value": "Swimming",
    "checked": false
  },
  {
    "name": "Table Tennis",
    "value": "Table Tennis",
    "checked": false
  },
  {
    "name": "Track Field",
    "value": "Track Field",
    "checked": false
  },
  {
    "name": "Volleyball",
    "value": "Volleyball",
    "checked": false
  },
  {
    "name": "Water Polo",
    "value": "Water Polo",
    "checked": false
  },
  {
    "name": "Weight Lifting",
    "value": "Weight Lifting",
    "checked": false
  },
  {
    "name": "Wrestling",
    "value": "Wrestling",
    "checked": false
  },
  {
    "name": "Other",
    "value": "Other",
    "checked": false
  }
]


const companiesList = [
  "3M",
  "Abbott Laboratories",
  "Adobe",
  "Amazon",
  "American Express",
  "Amgen",
  "Apple",
  "AT&T",
  "Abbott Laboratories",
  "ALCO",
  "Allstate",
  "Amtrak",
  "AT&T",
  "Avis Industrial Corporation",
  "Bank of America Corporation",
  "Bank of Hawaii",
  "BASF",
  "Best Buy",
  "Best Foods",
  "The Boeing Company",
  "Boys and Girls Clubs of America",
  "Booz Allen Hamilton inc.",
  "Boston Scientific",
  "Brunswick Corporation",
  "Burger King, Inc.",
  "Campbell Soup Company",
  "Cargill Limited",
  "CenterPoint Energy",
  "Chevron",
  "Chevron Philips Chemical",
  "Chick-fil-a",
  "Cisco",
  "Coca-Cola Enterprises",
  "Costco Wholesale",
  "CVS",
  "Daikin North America",
  "Dell Technologies",
  "Delta Airlines",
  "Discount Tire",
  "Dow Chemicals",
  "Dupont",
  "Dr. Pepper",
  "Dunkin’ Donuts",
  "Ecolab",
  "Emerson Electric Company",
  "Exxon Mobil Corporation",
  "Federal Bureau of Investigations F.B.I.",
  "Federal Government Employee",
  "Fedex Express",
  "Finserv",
  "Flow Company",
  "Fluor Corporation",
  "Ford Motor Company",
  "Fort Worth Star-Telegram",
  "Fox Grocery Company",
  "Fox Industries",
  "Frito-Lay",
  "FT Yocum Oil",
  "Garmin",
  "Geico",
  "General Electric Co.",
  "General Motors",
  "Georgia Pacific",
  "Geosynthetic Materials Association",
  "Gerber Products Company",
  "Goldman Sachs",
  "Goodyear Tire & Rubber Company",
  "Google, Inc",
  "GOYA",
  "Graco, Inc.",
  "H-E-B",
  "Halliburton",
  "Hertz",
  "Honda",
  "H.P. Inc.",
  "Haggar Company",
  "Hallmark",
  "Honeywell",
  "Hygeia Company",
  "Hypertherm",
  "IBM",
  "Idaho Power",
  "Intel Corporation",
  "Johnson Controls, Inc.",
  "Johnson & Johnson Family of Companies",
  "JPMorgan Chase & Co.",
  "Kentucky Fried Chicken-KFC",
  "Key Technology, Inc.",
  "Kimwood Corporation",
  "KLN Steel Products Co.",
  "Kodak",
  "KPMG LLP",
  "Kroger",
  "Little Caesars Pizza",
  "Loews Corporation",
  "Lubrizol",
  "Manville Corporation",
  "Marathon Oil",
  "McDonald’s",
  "Medtronic",
  "Mercedes Benz",
  "Merck & Company, Inc.",
  "Meta",
  "Michelin",
  "Microsoft",
  "Morgan Stanley",
  "Nike, Inc.",
  "Oracle",
  "Oshkosh Truck Corporation",
  "Paypal",
  "Pennsylvania Steel Foundry & Machine Company",
  "Pepsi-Cola Bottling Company",
  "PepsiCo Food Systems",
  "Pfizer, inc.",
  "Philadelphia Gas Works",
  "Phillips Petroleum Company",
  "Pierce Company",
  "Pizza Hut",
  "PPG",
  "PROCTER & Gamble (P&G)",
  "PG&E Corporation",
  "Qualcomm",
  "Quaker Chemical Corporation",
  "Rent-A-Center",
  "Russell Industries, Inc.",
  "Salesforce",
  "Sallie Mae",
  "Safeway",
  "Servco, Inc.",
  "Siemens",
  "Sierra Pacific Industries",
  "State Farm",
  "Stop & Shop Companies, Inc.",
  "Subway",
  "Sysco",
  "Taco Bell",
  "Target",
  "The Walt Disney Company",
  "Thermo Fisher Scientific",
  "TMCF",
  "Toyota",
  "Tylenol",
  "Tyson Foods",
  "United Health Group",
  "Verizon",
  "Visa",
  "Walgreen",
  "Wal-Mart Company",
  "Wells Fargo Bank",
  "West Company",
  "Western Digital",
  "Whirlpool",
  "Young Men’s Christian Association (YMCA)",
  "Xerox"
];


const allCitizenships = [
  { name: 'Select Citizenship', value: '' },
  { name: 'Afghanistan', value: 'Afghan' },
  { name: 'Albania', value: 'Albanian' },
  { name: 'Algeria', value: 'Algerian' },
  { name: 'Andorra', value: 'Andorran' },
  { name: 'Angola', value: 'Angolan' },
  { name: 'Antigua and Barbuda', value: 'Antiguan, Barbudan' },
  { name: 'Argentina', value: 'Argentine' },
  { name: 'Armenia', value: 'Armenian' },
  { name: 'Australia', value: 'Australian' },
  { name: 'Austria', value: 'Austrian' },
  { name: 'Azerbaijan', value: 'Azerbaijani' },
  { name: 'Bahamas', value: 'Bahamian' },
  { name: 'Bahrain', value: 'Bahraini' },
  { name: 'Bangladesh', value: 'Bangladeshi' },
  { name: 'Barbados', value: 'Barbadian' },
  { name: 'Belarus', value: 'Belarusian' },
  { name: 'Belgium', value: 'Belgian' },
  { name: 'Belize', value: 'Belizean' },
  { name: 'Benin', value: 'Beninese' },
  { name: 'Bhutan', value: 'Bhutanese' },
  { name: 'Bolivia', value: 'Bolivian' },
  { name: 'Bosnia and Herzegovina', value: 'Bosnian, Herzegovinian' },
  { name: 'Botswana', value: 'Botswana' },
  { name: 'Brazil', value: 'Brazilian' },
  { name: 'Brunei', value: 'Bruneian' },
  { name: 'Bulgaria', value: 'Bulgarian' },
  { name: 'Burkina Faso', value: 'Burkinabé' },
  { name: 'Burundi', value: 'Burundian' },
  { name: 'Cabo Verde', value: 'Cabo Verdean' },
  { name: 'Cambodia', value: 'Cambodian' },
  { name: 'Cameroon', value: 'Cameroonian' },
  { name: 'Canada', value: 'Canadian' },
  { name: 'Central African Republic', value: 'Central African' },
  { name: 'Chad', value: 'Chadian' },
  { name: 'Chile', value: 'Chilean' },
  { name: 'China', value: 'Chinese' },
  { name: 'Colombia', value: 'Colombian' },
  { name: 'Comoros', value: 'Comorian' },
  { name: 'Congo (Congo-Brazzaville)', value: 'Congolese' },
  { name: 'Costa Rica', value: 'Costa Rican' },
  { name: 'Croatia', value: 'Croatian' },
  { name: 'Cuba', value: 'Cuban' },
  { name: 'Cyprus', value: 'Cypriot' },
  { name: 'Czechia (Czech Republic)', value: 'Czech' },
  { name: 'Denmark', value: 'Danish' },
  { name: 'Djibouti', value: 'Djiboutian' },
  { name: 'Dominica', value: 'Dominican' },
  { name: 'Dominican Republic', value: 'Dominican' },
  { name: 'Ecuador', value: 'Ecuadorian' },
  { name: 'Egypt', value: 'Egyptian' },
  { name: 'El Salvador', value: 'Salvadoran' },
  { name: 'Equatorial Guinea', value: 'Equatorial Guinean' },
  { name: 'Eritrea', value: 'Eritrean' },
  { name: 'Estonia', value: 'Estonian' },
  { name: 'Eswatini (fmr. "Swaziland")', value: 'Swazi' },
  { name: 'Ethiopia', value: 'Ethiopian' },
  { name: 'Fiji', value: 'Fijian' },
  { name: 'Finland', value: 'Finnish' },
  { name: 'France', value: 'French' },
  { name: 'Gabon', value: 'Gabonese' },
  { name: 'Gambia', value: 'Gambian' },
  { name: 'Georgia', value: 'Georgian' },
  { name: 'Germany', value: 'German' },
  { name: 'Ghana', value: 'Ghanaian' },
  { name: 'Greece', value: 'Greek' },
  { name: 'Grenada', value: 'Grenadian' },
  { name: 'Guatemala', value: 'Guatemalan' },
  { name: 'Guinea', value: 'Guinean' },
  { name: 'Guinea-Bissau', value: 'Guinea-Bissauan' },
  { name: 'Guyana', value: 'Guyanese' },
  { name: 'Haiti', value: 'Haitian' },
  { name: 'Holy See', value: 'Vatican' },
  { name: 'Honduras', value: 'Honduran' },
  { name: 'Hungary', value: 'Hungarian' },
  { name: 'Iceland', value: 'Icelandic' },
  { name: 'India', value: 'Indian' },
  { name: 'Indonesia', value: 'Indonesian' },
  { name: 'Iran', value: 'Iranian' },
  { name: 'Iraq', value: 'Iraqi' },
  { name: 'Ireland', value: 'Irish' },
  { name: 'Israel', value: 'Israeli' },
  { name: 'Italy', value: 'Italian' },
  { name: 'Jamaica', value: 'Jamaican' },
  { name: 'Japan', value: 'Japanese' },
  { name: 'Jordan', value: 'Jordanian' },
  { name: 'Kazakhstan', value: 'Kazakhstani' },
  { name: 'Kenya', value: 'Kenyan' },
  { name: 'Kiribati', value: 'I-Kiribati' },
  { name: 'Korea, North', value: 'North Korean' },
  { name: 'Korea, South', value: 'South Korean' },
  { name: 'Kosovo', value: 'Kosovar' },
  { name: 'Kuwait', value: 'Kuwaiti' },
  { name: 'Kyrgyzstan', value: 'Kyrgyzstani' },
  { name: 'Laos', value: 'Laotian' },
  { name: 'Latvia', value: 'Latvian' },
  { name: 'Lebanon', value: 'Lebanese' },
  { name: 'Lesotho', value: 'Basotho' },
  { name: 'Liberia', value: 'Liberian' },
  { name: 'Libya', value: 'Libyan' },
  { name: 'Liechtenstein', value: 'Liechtensteiner' },
  { name: 'Lithuania', value: 'Lithuanian' },
  { name: 'Luxembourg', value: 'Luxembourger' },
  { name: 'Madagascar', value: 'Malagasy' },
  { name: 'Malawi', value: 'Malawian' },
  { name: 'Malaysia', value: 'Malaysian' },
  { name: 'Maldives', value: 'Maldivian' },
  { name: 'Mali', value: 'Malian' },
  { name: 'Malta', value: 'Maltese' },
  { name: 'Marshall Islands', value: 'Marshallese' },
  { name: 'Mauritania', value: 'Mauritanian' },
  { name: 'Mauritius', value: 'Mauritian' },
  { name: 'Mexico', value: 'Mexican' },
  { name: 'Micronesia', value: 'Micronesian' },
  { name: 'Moldova', value: 'Moldovan' },
  { name: 'Monaco', value: 'Monegasque' },
  { name: 'Mongolia', value: 'Mongolian' },
  { name: 'Montenegro', value: 'Montenegrin' },
  { name: 'Morocco', value: 'Moroccan' },
  { name: 'Mozambique', value: 'Mozambican' },
  { name: 'Myanmar (formerly Burma)', value: 'Burmese' },
  { name: 'Namibia', value: 'Namibian' },
  { name: 'Nauru', value: 'Nauruan' },
  { name: 'Nepal', value: 'Nepali' },
  { name: 'Netherlands', value: 'Dutch' },
  { name: 'New Zealand', value: 'New Zealander' },
  { name: 'Nicaragua', value: 'Nicaraguan' },
  { name: 'Niger', value: 'Nigerien' },
  { name: 'Nigeria', value: 'Nigerian' },
  { name: 'North Macedonia', value: 'Macedonian' },
  { name: 'Norway', value: 'Norwegian' },
  { name: 'Oman', value: 'Omani' },
  { name: 'Pakistan', value: 'Pakistani' },
  { name: 'Palau', value: 'Palauan' },
  { name: 'Palestine State', value: 'Palestinian' },
  { name: 'Panama', value: 'Panamanian' },
  { name: 'Papua New Guinea', value: 'Papua New Guinean' },
  { name: 'Paraguay', value: 'Paraguayan' },
  { name: 'Peru', value: 'Peruvian' },
  { name: 'Philippines', value: 'Filipino' },
  { name: 'Poland', value: 'Polish' },
  { name: 'Portugal', value: 'Portuguese' },
  { name: 'Qatar', value: 'Qatari' },
  { name: 'Romania', value: 'Romanian' },
  { name: 'Russia', value: 'Russian' },
  { name: 'Rwanda', value: 'Rwandan' },
  { name: 'Saint Kitts and Nevis', value: 'Kittitian, Nevisian' },
  { name: 'Saint Lucia', value: 'Saint Lucian' },
  { name: 'Saint Vincent and the Grenadines', value: 'Saint Vincentian' },
  { name: 'Samoa', value: 'Samoan' },
  { name: 'San Marino', value: 'Sammarinese' },
  { name: 'Sao Tome and Principe', value: 'São Toméan' },
  { name: 'Saudi Arabia', value: 'Saudi' },
  { name: 'Senegal', value: 'Senegalese' },
  { name: 'Serbia', value: 'Serbian' },
  { name: 'Seychelles', value: 'Seychellois' },
  { name: 'Sierra Leone', value: 'Sierra Leonean' },
  { name: 'Singapore', value: 'Singaporean' },
  { name: 'Slovakia', value: 'Slovak' },
  { name: 'Slovenia', value: 'Slovenian' },
  { name: 'Solomon Islands', value: 'Solomon Islander' },
  { name: 'Somalia', value: 'Somali' },
  { name: 'South Africa', value: 'South African' },
  { name: 'South Sudan', value: 'South Sudanese' },
  { name: 'Spain', value: 'Spanish' },
  { name: 'Sri Lanka', value: 'Sri Lankan' },
  { name: 'Sudan', value: 'Sudanese' },
  { name: 'Suriname', value: 'Surinamese' },
  { name: 'Sweden', value: 'Swedish' },
  { name: 'Switzerland', value: 'Swiss' },
  { name: 'Syria', value: 'Syrian' },
  { name: 'Taiwan', value: 'Taiwanese' },
  { name: 'Tajikistan', value: 'Tajikistani' },
  { name: 'Tanzania', value: 'Tanzanian' },
  { name: 'Thailand', value: 'Thai' },
  { name: 'Timor-Leste', value: 'East Timorese' },
  { name: 'Togo', value: 'Togolese' },
  { name: 'Tonga', value: 'Tongan' },
  { name: 'Trinidad and Tobago', value: 'Trinidadian or Tobagonian' },
  { name: 'Tunisia', value: 'Tunisian' },
  { name: 'Turkey', value: 'Turkish' },
  { name: 'Turkmenistan', value: 'Turkmen' },
  { name: 'Tuvalu', value: 'Tuvaluan' },
  { name: 'Uganda', value: 'Ugandan' },
  { name: 'Ukraine', value: 'Ukrainian' },
  { name: 'United Arab Emirates', value: 'Emirati' },
  { name: 'United Kingdom', value: 'British' },
  { name: 'United States', value: 'United States' },
  { name: 'Uruguay', value: 'Uruguayan' },
  { name: 'Uzbekistan', value: 'Uzbekistani' },
  { name: 'Vanuatu', value: 'Ni-Vanuatu' },
  { name: 'Venezuela', value: 'Venezuelan' },
  { name: 'Vietnam', value: 'Vietnamese' },
  { name: 'Yemen', value: 'Yemeni' },
  { name: 'Zambia', value: 'Zambian' },
  { name: 'Zimbabwe', value: 'Zimbabwean' },
];


const gpa = [
  "0",
  "0.1",
  "0.2",
  "0.3",
  "0.4",
  "0.5",
  "0.6",
  "0.7",
  "0.8",
  "0.9",
  "1",
  "1.1",
  "1.2",
  "1.3",
  "1.4",
  "1.5",
  "1.6",
  "1.7",
  "1.8",
  "1.9",
  "2",
  "2.1",
  "2.2",
  "2.3",
  "2.4",
  "2.5",
  "2.6",
  "2.7",
  "2.8",
  "2.9",
  "3",
  "3.1",
  "3.2",
  "3.3",
  "3.4",
  "3.5",
  "3.6",
  "3.7",
  "3.8",
  "3.9",
  "4",
  "4.1",
  "4.2",
  "4.3",
  "4.4",
  "4.5",
  "4.6",
  "4.7",
  "4.8",
  "4.9",
  "5.0",
];

const majors = [
  "Accounting", "Active Paramedics", "Actuarial Science", "Administration", "Advertising",
  "Aeronautics", "Aerospace", "African Cultural Studies", "Agribusiness", "Agricultural Technology/Production",
  "Agriculture", "Agronomy", "Aircraft Maintenance", "Airline Management", "Allied Health", "Ambulatory Care",
  "American History", "American Sign Language", "American Studies", "Anatomy", "Anesthesia", "Animal Science",
  "Animation", "Anthropology", "Applied Geophysics", "Applied Science", "Arabic Language", "Archaeology",
  "Architecture", "Architecture", "Archives Management", "Art", "Art Education", "Art History",
  "Asian American Studies", "Asian Languages", "Astronomy", "Astrophysics", "Athletic Training",
  "Atmospheric Science", "Audiology", "Auto Body Repair and Painting", "Auto Mechanics", "Automotive",
  "Automotive Technology", "Aviation", "Aviation Technology", "Avionics", "Avionics", "Ballet", "Band", "Banking",
  "Barbering", "Behavioral Science", "Biblical Studies", "Bilingual Studies", "Biochemistry", "Biology",
  "Biometrics", "Biostatistics", "Biotechnology", "Botany", "Broadcast Meteorology", "Broadcasting", "Business",
  "Business Administration", "Carpentry", "Cartography", "Ceramics", "Chemistry", "Child Development",
  "Chinese Cultural Studies", "Chiropractic", "Choreography", "Cinematography", "City Planning", "Civics",
  "Classical Studies", "Classics and Letters", "CNA", "Coastal Science", "Commercial Art", "Communication Technology",
  "Communications", "Communicative Disorders", "Computer Aided Design", "Computer Information Systems",
  "Computer Science", "Conservation Science", "Construction", "Construction Management", "Construction Sciences",
  "Consumer Product Safety", "Consumer Studies", "Coptic Studies", "Cosmetics", "Cosmetology", "Counseling",
  "Counseling & Guidance", "Court Reporting", "Creative Arts", "Creative Writing", "Criminal Justice",
  "Criminology", "Crop Science", "Culinary Arts", "Cyber Security", "Dairy Science", "Dance", "Dance",
  "Dental Assistant", "Dental Hygiene", "Dental Technology", "Dentistry", "Design", "Design, Engineering Technology",
  "Diesel Mechanics", "Dietetics", "Digital Journalism", "Digital Marketing", "Digital Media", "Divinity",
  "Drafting & Design", "Drama", "Drug Research", "Early Childhood Education", "Earth Science", "Ecology", "eCommerce",
  "Economics", "Education", "Education – Music", "Education-Adult", "Education-Agriculture", "Education-Business",
  "Education-Early Childhood", "Education-Elementary", "Education-Engineering and Technology", "Education-English",
  "Education-Fine Arts", "Education-Health", "Education-History", "Education-Home Economics", "Education-Humanities",
  "Education-Industrial", "Education-Literature", "Education-Mathematics", "Education-Music", "Education-Philosophy",
  "Education-Physics", "Education-Religion", "Education-Science", "Education-Secondary", "Education-Social Science",
  "Education-Social Studies", "Education-Special Needs/Handicapped", "Education-Special/Handicapped",
  "Education-Technology", "Education-Vocational/Technical", "Educational Technology, Research, and Assessment",
  "Egyptology", "Electrical Technology", "Electronic Media", "Electronics", "Emergency Medical Technician",
  "Emergency Medical Technology", "Energy Management", "Energy Sciences", "Engineering", "Engineering – Architectural",
  "Engineering – Optical", "Engineering-Aerospace", "Engineering-Agricultural", "Engineering-Biological",
  "Engineering-Biomedical", "Engineering-Chemical", "Engineering-Civil", "Engineering-Computer", "Engineering-Construction",
  "Engineering-Electrical", "Engineering-Electronic", "Engineering-Energy", "Engineering-Environmental",
  "Engineering-Geological", "Engineering-Geophysical", "Engineering-Industrial", "Engineering-Manufacturing",
  "Engineering-Materials", "Engineering-Mechanical", "Engineering-Metallurgical", "Engineering-Mining",
  "Engineering-Naval", "Engineering-Nuclear", "Engineering-Ocean", "Engineering-Petroleum", "Engineering-Physics",
  "Engineering-Plastics", "Engineering-Software", "Engineering-Structural", "Engineering-Technology", "Engineering-Welding",
  "English", "English Literature", "Enology", "Entertainment Industry", "Entomology", "Entrepreneurship",
  "Environmental Chemistry", "Environmental Design", "Environmental Health", "Environmental Protection",
  "Environmental Science", "Epidemiology", "Equine Science", "Esthetics", "Ethnic Studies", "European Cultural Studies",
  "Exercise and Sport Science", "Facilities Planning", "Facility Management", "Family & Consumer Studies",
  "Family and Consumer Science", "Family Studies", "Fashion Design/Merchandising", "Fiber Arts", "Film/Video Studies",
  "Finance", "Financial Planning", "Fine Arts", "Fire Science", "Fire Service Management; Public Safety Administration",
  "Fish & Wildlife Science", "Floriculture", "Floriculture", "Food & Nutritional Science", "Food Safety", "Food Service",
  "Food Service Management", "Food Technology", "Foreign Cultural Studies", "Foreign Language", "Foreign Studies",
  "Forensics", "Forestry", "Franchise Management", "French Cultural Studies", "French Language", "Funeral Services",
  "General Studies", "Geochemistry", "Geography", "Geology", "Geophysics", "Geoscience", "Geospatial Information and Technology",
  "Geotechnology", "Geriatrics", "German", "Gerontology", "Government", "Graphic Arts", "Graphic Design", "Hairdressing",
  "Hawaiian Studies/Language", "Health", "Health Administration", "Health Care", "Health Informatics", "Health Outcomes",
  "Health Professions", "Health Promotion and Education", "Health Sciences", "Heating, Refrigeration & Air Conditioning",
  "Historic Preservation", "History", "Home Economics", "Horticulture", "Hospital ,Long Term Care", "Hospitality",
  "Hotel/Restaurant Management", "Human Development", "Human Relations", "Human Resource Development", "Human Resources",
  "Human Sciences", "Human Services", "Human Sexuality Studies", "Humanities", "Hydrology", "Illustration", "Industrial Arts",
  "Industrial Design", "Industrial Technology", "Industry", "Informatics", "Information Studies", "Information Systems Technology",
  "Information Technology", "Instructional Technology", "Insurance", "Interactive Entertainment", "Interdisciplinary Studies",
  "Interfaith Relations", "Interior Architecture", "Interior Design", "International Business.", "International Law",
  "International Relations", "International Studies", "Islamic Studies", "Italian Cultural Studies", "Italian Language",
  "Japanese", "Japanese Cultural Studies", "Jewelry Design/Repair", "Jewish Cultural Studies", "Journalism", "Justice",
  "Kinesiology", "Labor Relations", "Laboratory Science", "Laboratory Technology", "Land Economics", "Landscape Architecture",
  "Language Arts", "Languages and Literature", "Latin", "Law", "Law Enforcement", "Legal", "Legal Administration",
  "Legal Interpreter", "Liberal Arts", "Library Media", "Library Science", "Life Science", "Linguistics", "Literature",
  "Logistics", "Long Term Care Administration", "Machining", "Management", "Management Information Systems",
  "Manufacturing Technology", "Marine Science", "Marketing", "Marriage and Family Therapy", "Masonry",
  "Mass Communications", "Mass Media", "Massage Therapy", "Material Science", "Mathematics", "MBA", "Mechanical Technology",
  "Medical Administration", "Medical Assistant", "Medical Field", "Medical Research", "Medical Sciences", "Medical Technology",
  "Medical-clinical", "Medicine", "Medieval Studies", "Mental Health", "Metallurgical Engineering", "Metalsmithing",
  "Meteorology", "Microbiology", "Military Science", "Mineral Economics", "Mineral Science", "Mines and Earth Sciences",
  "Mining", "Mining Technology", "Ministry", "Modern Dance", "Modern European History", "Molecular Biology", "Morphology",
  "Mortuary Science", "Museum Studies", "Music", "Music-Composition", "Music-Instrumental", "Music-Piano", "Music-Voice",
  "National Security", "Natural Resources", "Natural Science", "Naval Science", "Navigation", "Nondestructive Testing",
  "Nuclear Science", "Nursing", "Nutrition", "Nutrition Science", "Occupational Safety", "Occupational Therapy", "Oceanography",
  "Office Administration", "Office Technology", "Operations and Information Management", "Ophthalmology", "Optical Design",
  "Optometry", "Orthotics", "Osteopathic Medicine", "Painting", "Paleontology", "Paralegal Studies", "Paramedics",
  "Parks & Recreation Management", "Pastoral Music", "Pastry Arts", "Pathology", "Performing Arts", "Performing Arts",
  "Pharmaceutics", "Pharmacology", "Philanthropy", "Philosophy", "Phlebotomy", "Photography", "Photojournalism",
  "Physical Education", "Physical Science", "Physical Therapy", "Physician Assistant", "Physics", "Physiology",
  "Planetary Geology", "Plant/Soil Science", "Plastics Technology", "Playwriting", "Plumbing", "Podiatry",
  "Police Science", "Polish Language", "Political Science", "Polymers & Coatings", "Polytechnic", "Poultry Science",
  "Pre-Dental", "Pre-Law", "Pre-Medicine", "Print Journalism", "Printing", "Product Design",
  "Professional Golf Management Program", "Professional Pilot Program", "Psychiatry", "Psychology", "Public Administration",
  "Public Health", "Public Policy", "Public Relations", "Public Services", "Radio", "Radiologic Science", "Radiology",
  "Real Estate", "Recording Technology", "Records Management", "Recreation", "Rehabilitation", "Religious Studies",
  "Religious Vocation", "Respiratory Care", "Restaurant Management", "Retail", "Risk Management", "Russian", "Safety",
  "Sales", "Science", "Scientific Research", "Secretarial Sciences", "Security Sciences", "Shipping and Packaging",
  "Social Sciences", "Social Services", "Social Work", "Sociology", "Software Development", "Soil Science",
  "Southeast Asian Studies", "Space History", "Space Technology", "Spanish", "Spanish Cultural Studies", "Special Education",
  "Speech Communication", "Speech Therapy", "Speech/Drama", "Sport and Leisure Studies", "Sports Management",
  "Sports Medicine", "Statistics", "STEM", "Student teaching", "Supply Chain Management",
  "Surgical Technology and Assisting", "Surveying", "Swedish Studies", "Swine Production", "Teacher in Inner-City Schools",
  "Teaching", "Teaching and Learning", "Technical Science", "Technology", "Telecommunications", "Television",
  "Textile Science", "Theatre Arts", "Theology", "Toxicology", "Trades", "Traffic Control", "Transportation",
  "Travel & Tourism", "Turf Management", "Turf Management", "Turkish Language", "Urban Design", "Urban Planning",
  "Vacuum Science", "Veterinary Medicine", "Video Game Arts", "Visual and Performing Arts", "Visual Arts",
  "Visual Communication", "Viticulture", "Vocal", "Vocational/Technical", "Waste Management", "Water Resources",
  "Water Technology", "Web Design", "Welding Technology", "Wetlands Science", "Wildlife Biology", "Wildlife Management",
  "Women’s History", "Women’s Studies", "Wood Science", "Wood Technology", "Writing", "Zoology"
]

function formatMajors(array) {
const formattedArray = [
  { name: "Select Major", value: "" },
  ...array.map((organization) => ({
    name: organization,
    value: organization,
  })),
];

return formattedArray;
}

const formattedMajorsArray = formatMajors(majors);

const Companies = convertToFormat(companiesList);

const honorSocietiesArray = [
  { name: "Select Society", value: "" },
  { name: "Alpha Beta Gamma", value: "alpha-beta-gamma" },
  { name: "Alpha Chi", value: "alpha-chi" },
  { name: "Alpha Chi Sigma", value: "alpha-chi-sigma" },
  { name: "Alpha Delta Mu", value: "alpha-delta-mu" },
  { name: "Alpha Epsilon", value: "alpha-epsilon" },
  { name: "Alpha Epsilon Delta", value: "alpha-epsilon-delta" },
  { name: "Alpha Eta Rho", value: "alpha-eta-rho" },
  { name: "Alpha Kappa Delta", value: "alpha-kappa-delta" },
  { name: "Alpha Kappa Psi", value: "alpha-kappa-psi" },
  { name: "Alpha Lambda Delta", value: "alpha-lambda-delta" },
  { name: "Alpha Mu Gamma", value: "alpha-mu-gamma" },
  { name: "Alpha Omega Epsilon", value: "alpha-omega-epsilon" },
  { name: "Alpha Phi Mu", value: "alpha-phi-mu" },
  { name: "Alpha Phi Sigma", value: "alpha-phi-sigma" },
  { name: "Alpha Psi Omega", value: "alpha-psi-omega" },
  { name: "Alpha Rho Chi", value: "alpha-rho-chi" },
  { name: "Alpha Sigma Kappa", value: "alpha-sigma-kappa" },
  { name: "Alpha Sigma Mu", value: "alpha-sigma-mu" },
  { name: "Alpha Sigma Nu", value: "alpha-sigma-nu" },
  { name: "Alpha Zeta", value: "alpha-zeta" },
  { name: "Beta Alpha Psi", value: "beta-alpha-psi" },
  { name: "Beta Beta Beta", value: "beta-beta-beta" },
  { name: "Beta Gamma Sigma", value: "beta-gamma-sigma" },
  { name: "Beta Kappa Chi", value: "beta-kappa-chi" },
  { name: "Beta Phi Mu", value: "beta-phi-mu" },
  { name: "Chi Epsilon", value: "chi-epsilon" },
  { name: "Chi Eta Phi", value: "chi-eta-phi" },
  { name: "Chi Sigma Iota", value: "chi-sigma-iota" },
  { name: "College Board National Recognition Program", value: "college-board-national-recognition-program" },
  { name: "Delta Alpha Pi", value: "delta-alpha-pi" },
  { name: "Delta EPSILON cHI", value: "delta-epsilon-chi" },
  { name: "Delta Epsilon Sigma", value: "delta-epsilon-sigma" },
  { name: "Delta Mu Delta", value: "delta-mu-delta" },
  { name: "Delta Omicron", value: "delta-omicron" },
  { name: "Delta Omicron Sigma", value: "delta-omicron-sigma" },
  { name: "Delta Rho", value: "delta-rho" },
  { name: "Delta Sigma Pi", value: "delta-sigma-pi" },
  { name: "Delta tau Alpha", value: "delta-tau-alpha" },
  { name: "Eta Kappa Nu", value: "eta-kappa-nu" },
  { name: "Gamma Beta Phi", value: "gamma-beta-phi" },
  { name: "Gamma Iota Sigma", value: "gamma-iota-sigma" },
  { name: "Gamma Theta Upsilon", value: "gamma-theta-upsilon" },
  { name: "Golden Key International Hour Society", value: "golden-key-international-hour-society" },
  { name: "Honors Program", value: "honors-program" },
  { name: "Kappa Delta pi", value: "kappa-delta-pi" },
  { name: "Kappa Epsilon", value: "kappa-epsilon" },
  { name: "Kappa kappa psi", value: "kappa-kappa-psi" },
  { name: "Kappa Mu EPSILON", value: "kappa-mu-epsilon" },
  { name: "Kappa Omicron Nu", value: "kappa-omicron-nu" },
  { name: "Kappa Psi", value: "kappa-psi" },
  { name: "Kappa Tau Alpha", value: "kappa-tau-alpha" },
  { name: "Lambda Alpha Upsilon", value: "lambda-alpha-upsilon" },
  { name: "Lambda Iota Tau", value: "lambda-iota-tau" },
  { name: "Lambda Pi Eta", value: "lambda-pi-eta" },
  { name: "Lambda Sigma", value: "lambda-sigma" },
  { name: "Mortar Board", value: "mortar-board" },
  { name: "Mu Alpha Theta", value: "mu-alpha-theta" },
  { name: "Mu Kappa Tau", value: "mu-kappa-tau" },
  { name: "Mu Phi Epsilon", value: "mu-phi-epsilon" },
  { name: "National Achievement Finalist", value: "national-achievement-finalist" },
  { name: "National Achievement Scholar", value: "national-achievement-scholar" },
  { name: "National Achievement Semifinalist", value: "national-achievement-semifinalist" },
  { name: "National Hispanic Honorable mention", value: "national-hispanic-honorable-mention" },
  { name: "National Honor society", value: "national-honor-society" },
  { name: "National merit finalist", value: "national-merit-finalist" },
  { name: "National society of collegiate scholars", value: "national-society-of-collegiate-scholars" },
  { name: "National society of high school scholars", value: "national-society-of-high-school-scholars" },
  { name: "Omega Chi Epsilon", value: "omega-chi-epsilon" },
  { name: "Omega Rho", value: "omega-rho" },
  { name: "Omicron Delta Epsilon", value: "omicron-delta-epsilon" },
  { name: "Omicron Delta Kappa", value: "omicron-delta-kappa" },
  { name: "Order of Omega", value: "order-of-omega" },
  { name: "Phi Alpha Honor Society", value: "phi-alpha-honor-society" },
  { name: "Phi Alpha Theta", value: "phi-alpha-theta" },
  { name: "Phi Beta", value: "phi-beta" },
  { name: "Phi Beta Kappa", value: "phi-beta-kappa" },
  { name: "Phi Chi Sigma", value: "phi-chi-sigma" },
  { name: "Phi Chi Theta", value: "phi-chi-theta" },
  { name: "Phi Delta Chi", value: "phi-delta-chi" },
  { name: "Phi Eta Sigma", value: "phi-eta-sigma" }
  // Add more societies here...
];

const fraternitiesArray = [
  { name: "Select Fraternity", value: "" },
  { name: "Acacia", value: "acacia" },
  { name: "Alpha Chi Rho", value: "alpha-chi-rho" },
  { name: "Alpha Delta Gamma", value: "alpha-delta-gamma" },
  { name: "Alpha Delta Phi", value: "alpha-delta-phi" },
  { name: "Alpha Epsilon Pi", value: "alpha-epsilon-pi" },
  { name: "Alpha Gamma Rho", value: "alpha-gamma-rho" },
  { name: "Alpha Gamma Sigma", value: "alpha-gamma-sigma" },
  { name: "Alpha Kappa Lambda", value: "alpha-kappa-lambda" },
  { name: "Alpha Nu Omega", value: "alpha-nu-omega" },
  { name: "Alpha Phi Delta", value: "alpha-phi-delta" },
  { name: "Alpha Phi Omega", value: "alpha-phi-omega" },
  { name: "Alpha Sigma Phi", value: "alpha-sigma-phi" },
  { name: "Alpha Tau Omega", value: "alpha-tau-omega" },
  { name: "Beta Sigma Psi", value: "beta-sigma-psi" },
  { name: "Beta Theta Pi", value: "beta-theta-pi" },
  { name: "Chi Phi", value: "chi-phi" },
  { name: "Chi Psi", value: "chi-psi" },
  { name: "Delta Chi", value: "delta-chi" },
  { name: "Delta Kappa Epsilon", value: "delta-kappa-epsilon" },
  { name: "Delta Lambda Phi", value: "delta-lambda-phi" },
  { name: "Delta Phi", value: "delta-phi" },
  { name: "Delta Sigma Phi", value: "delta-sigma-phi" },
  { name: "Delta Tau Delta", value: "delta-tau-delta" },
  { name: "Delta Upsilon", value: "delta-upsilon" },
  { name: "FarmHouse Fraternity", value: "farmhouse-fraternity" },
  { name: "Gamma Phi Sigma", value: "gamma-phi-sigma" },
  { name: "Gamma Zeta Alpha", value: "gamma-zeta-alpha" },
  { name: "Iota Phi Theta", value: "iota-phi-theta" },
  { name: "Kappa Alpha Order", value: "kappa-alpha-order" },
  { name: "Kappa Alpha Psi", value: "kappa-alpha-psi" },
  { name: "Kappa Alpha Society", value: "kappa-alpha-society" },
  { name: "Kappa Delta Phi", value: "kappa-delta-phi" },
  { name: "Kappa Delta Rho", value: "kappa-delta-rho" },
  { name: "Kappa Sigma", value: "kappa-sigma" },
  { name: "Lambda Chi Alpha", value: "lambda-chi-alpha" },
  { name: "Lambda Sigma Upsilon", value: "lambda-sigma-upsilon" },
  { name: "Lambda Theta Phi", value: "lambda-theta-phi" },
  { name: "Lambda Upsilon Lambda", value: "lambda-upsilon-lambda" },
  { name: "Nu Alpha Kappa", value: "nu-alpha-kappa" },
  { name: "Phi Beta Sigma", value: "phi-beta-sigma" },
  { name: "Phi Delta Theta", value: "phi-delta-theta" },
  { name: "Phi Eta Psi", value: "phi-eta-psi" },
  { name: "Phi Gamma Delta", value: "phi-gamma-delta" },
  { name: "Phi Iota Alpha", value: "phi-iota-alpha" },
  { name: "Phi Kappa Psi", value: "phi-kappa-psi" },
  { name: "Phi Kappa Sigma", value: "phi-kappa-sigma" },
  { name: "Phi Kappa Tau", value: "phi-kappa-tau" },
  { name: "Phi Kappa Theta", value: "phi-kappa-theta" },
  { name: "Phi Lambda Chi", value: "phi-lambda-chi" },
  { name: "Phi Mu Delta", value: "phi-mu-delta" },
  { name: "Phi Sigma Kappa", value: "phi-sigma-kappa" },
  { name: "Phi Sigma Phi", value: "phi-sigma-phi" },
  { name: "Pi Kappa Alpha", value: "pi-kappa-alpha" },
  { name: "Pi Kappa Phi", value: "pi-kappa-phi" },
  { name: "Pi Lambda Phi", value: "pi-lambda-phi" },
  { name: "Psi Upsilon", value: "psi-upsilon" },
  { name: "Sigma Alpha Epsilon", value: "sigma-alpha-epsilon" },
  { name: "Sigma Alpha Mu", value: "sigma-alpha-mu" },
  { name: "Sigma Chi", value: "sigma-chi" },
  { name: "Sigma Lambda Beta", value: "sigma-lambda-beta" },
  { name: "Sigma Nu", value: "sigma-nu" },
  { name: "Sigma Phi Epsilon", value: "sigma-phi-epsilon" },
  { name: "Sigma Phi Society", value: "sigma-phi-society" },
  { name: "Sigma Pi", value: "sigma-pi" },
  { name: "Sigma Tau Gamma", value: "sigma-tau-gamma" },
  { name: "Tau Delta Phi", value: "tau-delta-phi" },
  { name: "Tau Epsilon Phi", value: "tau-epsilon-phi" },
  { name: "Tau Kappa Epsilon", value: "tau-kappa-epsilon" },
  { name: "Theta Chi", value: "theta-chi" },
  { name: "Theta Delta Chi", value: "theta-delta-chi" },
  { name: "Theta Xi", value: "theta-xi" },
  { name: "Triangle Fraternity", value: "triangle-fraternity" },
  { name: "Zeta Beta Tau", value: "zeta-beta-tau" },
  { name: "Zeta Psi", value: "zeta-psi" }
];
const sororitiesArray =[
  { name: "Select Sorority", value: "" },
  { name: "Alpha Chi Omega", value: "alpha-chi-omega" },
  { name: "Alpha Delta Pi", value: "alpha-delta-pi" },
  { name: "Alpha Epsilon Phi", value: "alpha-epsilon-phi" },
  { name: "Alpha Gamma Delta", value: "alpha-gamma-delta" },
  { name: "Alpha Kappa Alpha", value: "alpha-kappa-alpha" },
  { name: "Alpha Kappa Delta Phi", value: "alpha-kappa-delta-phi" },
  { name: "Alpha Omicron Pi", value: "alpha-omicron-pi" },
  { name: "Alpha Phi", value: "alpha-phi" },
  { name: "Alpha Sigma Alpha", value: "alpha-sigma-alpha" },
  { name: "Alpha XI Delta", value: "alpha-xi-delta" },
  { name: "Chi Omega", value: "chi-omega" },
  { name: "Chi Upsilon Sigma", value: "chi-upsilon-sigma" },
  { name: "Delta Delta Delta", value: "delta-delta-delta" },
  { name: "Delta Gamma", value: "delta-gamma" },
  { name: "Delta Phi Epsilon", value: "delta-phi-epsilon" },
  { name: "Delta Phi Lambda", value: "delta-phi-lambda" },
  { name: "Delta Sigma Theta", value: "delta-sigma-theta" },
  { name: "Delta Zeta", value: "delta-zeta" },
  { name: "Gamma Phi Beta", value: "gamma-phi-beta" },
  { name: "Kappa Alpha Theta", value: "kappa-alpha-theta" },
  { name: "Kappa Delta", value: "kappa-delta" },
  { name: "Kappa Delta Chi", value: "kappa-delta-chi" },
  { name: "Kappa Kappa Gamma", value: "kappa-kappa-gamma" },
  { name: "Lambda Pi Chi", value: "lambda-pi-chi" },
  { name: "Lambda Theta Nu", value: "lambda-theta-nu" },
  { name: "Phi Mu", value: "phi-mu" },
  { name: "Phi Sigma Sigma", value: "phi-sigma-sigma" },
  { name: "Pi Beta Phi", value: "pi-beta-phi" },
  { name: "Sigma Alpha", value: "sigma-alpha" },
  { name: "Sigma Delta Tau", value: "sigma-delta-tau" },
  { name: "Sigma Gamma Rho", value: "sigma-gamma-rho" },
  { name: "Sigma Kappa", value: "sigma-kappa" },
  { name: "Sigma Sigma Sigma", value: "sigma-sigma-sigma" },
  { name: "Theta Phi Alpha", value: "theta-phi-alpha" },
  { name: "Zeta Phi Beta", value: "zeta-phi-beta" },
  { name: "Zeta Tau Alpha", value: "zeta-tau-alpha" }
]

const sportsArray = [
  { name: "Select Sports", value: "" },
  { name: "Aerobics", value: "aerobics" },
  { name: "Archery", value: "archery" },
  { name: "Athletics", value: "athletics" },
  { name: "Badminton", value: "badminton" },
  { name: "Baseball", value: "baseball" },
  { name: "Basketball", value: "basketball" },
  { name: "Baton Twirling", value: "baton-twirling" },
  { name: "Bowling", value: "bowling" },
  { name: "Cheerleader", value: "cheerleader" },
  { name: "Cricket", value: "cricket" },
  { name: "Cross country", value: "cross-country" },
  { name: "Cycling", value: "cycling" },
  { name: "Dance/Drill team", value: "dance/drill-team" },
  { name: "Fencing", value: "fencing" },
  { name: "Field Hockey", value: "field-hockey" },
  { name: "Fishing", value: "fishing" },
  { name: "Flag Football", value: "flag-football" },
  { name: "Football", value: "football" },
  { name: "Frisbee", value: "frisbee" },
  { name: "Golf", value: "golf" },
  { name: "Gymnastics", value: "gymnastics" },
  { name: "Handball", value: "handball" },
  { name: "Horseback riding/Equestrian", value: "horseback-riding/equestrian" },
  { name: "Ice Hockey", value: "ice-hockey" },
  { name: "Ice/Figure Skating", value: "ice/figure-skating" },
  { name: "Lacrosse", value: "lacrosse" },
  { name: "Martial Arts", value: "martial-arts" },
  { name: "Mountain Biking", value: "mountain-biking" },
  { name: "Racquetball", value: "racquetball" },
  { name: "Rodeo", value: "rodeo" },
  { name: "Rowing", value: "rowing" },
  { name: "Rugby", value: "rugby" },
  { name: "Running", value: "running" },
  { name: "Sailing", value: "sailing" },
  { name: "Skateboarding", value: "skateboarding" },
  { name: "Skiing", value: "skiing" },
  { name: "Snowboarding", value: "snowboarding" },
  { name: "Soccer", value: "soccer" },
  { name: "Softball", value: "softball" },
  { name: "Surfing", value: "surfing" },
  { name: "Swimming", value: "swimming" },
  { name: "Table Tennis", value: "table-tennis" },
  { name: "Track and Field", value: "track-and-field" },
  { name: "Volleyball", value: "volleyball" },
  { name: "Water Polo", value: "water-polo" },
  { name: "Weight Lifting", value: "weight-lifting" },
  { name: "Wrestling", value: "wrestling" },
  { name: "Other", value: "other" }
];

  export { Organizations, Religion, Disabilities, PersonalAttributes, Sports, Companies, allCitizenships, gpa, formattedMajorsArray, honorSocietiesArray, fraternitiesArray, sportsArray, sororitiesArray};